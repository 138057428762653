import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import useStore from '../../store';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "./CustomErrorMsg";
import Copyright from '../Copyright';

// Css 
import '../../assests/new/frontend/css/custom2.css'
import Dashboard2 from './Dashboard2';


function Login2 (){
    const navigate = useNavigate();

    const isLogin = useStore((state) => state.isLogin);
    const userType = useStore((state) => state.userType);
    const afterLogin = useStore((state) => state.afterLogin);
    const [loadershow, setloadershow] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const inputReference = useRef(null);
    useEffect(() => {
        // inputReference.current.focus();
        window.scrollTo(0, 0);
        if (isLogin == 1) {
            if(localStorage.getItem('loggedInAs') === 'admin'){
                navigate('/login');
            } else {
                if (userType == 2 || userType == 1) {
                    navigate("/admin/dashboard");
                }
                else {
                    navigate("/dashboard");
                }
            }   
        }
    }, []);


    const loginSubmit = (data) => {
        if (rememberMe) {
            localStorage.setItem('rememberMe', JSON.stringify(rememberMe));
            localStorage.setItem('username', data.email);
            localStorage.setItem('password', data.password);
        } else {
            localStorage.removeItem('rememberMe');
            localStorage.removeItem('username');
            localStorage.removeItem('password');
        }
        
        setloadershow(true);
        axios.post(`api/login_sticky_user`, data).then(res => {
            if (res.data.response_code === '100') {
                localStorage.setItem('authToken', res.data.member_token);
                localStorage.setItem('permission', res.data.user_info.account_permission);
                localStorage.setItem('userId', res.data.user_info.user_id);
                localStorage.setItem('userEmal', res.data.data.email);

                axios.get(`api/check_user_membership/${data.email}`).then(result => {
                    let is_membership = 0;
                    if(parseInt(result.data) === 1){
                        is_membership = 1;
                    } else {
                        is_membership = 0;
                    }
                    afterLogin(res.data.user_info.account_permission, res.data.user_info.user_type, res.data.user_info.user_id, res.data.user_info, is_membership);
                    setloadershow(false);
                    Swal.fire({
                        title: 'Success',
                        html: "You Have Successfully Logged In",
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    if (res.data.user_info.user_type == 1 || res.data.user_info.user_type == 2) {
                        navigate("/admin/dashboard")
                    }
                    else {
                        navigate("/dashboard")
                    }
                });
            }
            else {
                if (res.data.response_code === '4003') {
                    Swal.fire({
                        title: 'Please Check the below response!',
                        html: "We don't have any account with this email or please check your email and reset the password.",
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: true,
                        // timer: 4000
                    });
                    setloadershow(false);

                }
                if (res.data.response_code === 'NO_SUB') {
                    Swal.fire({
                        title: 'Please Check the below response!',
                        html: "You don't have any Membership",
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: true,
                        // timer: 4000
                    });
                    setloadershow(false);
                }
                else {
                    Swal.fire({
                        title: 'Please Check the below response!',
                        html: res.data.response_message,
                        icon: 'error',
                        showConfirmButton: true,
                        showCancelButton: true,
                        showCloseButton: true,
                        confirmButtonText: "Reset Password"

                        // timer: 4000
                    }).then(res => {
                        if (res.isConfirmed) {
                            navigate("/forget-password");
                        }
                    })
                    setloadershow(false);
                }

            }
            }).catch(err => {
                Swal.fire({
                    title: 'error',
                    html: err.response.data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    closeOnConfirm: false,
                    showCancelButton: true,
                    closeOnCancel: true
                })
            })
    }

    useEffect(() => {
        const storedRememberMe = localStorage.getItem('rememberMe');
        const storedUsername = localStorage.getItem('username');
        const storedPassword = localStorage.getItem('password');
        if (storedRememberMe) {
            setRememberMe(JSON.parse(storedRememberMe));
            setUsername(storedUsername);
            setPassword(storedPassword);
        }
    }, []);

    return (
        <Formik
            initialValues={{ password: password, email: username }}
            enableReinitialize={true}
            validationSchema={Yup.object({
                email: Yup.string().email('Invalid email address').required('Email is required'),
                password: Yup.string()
                    .required('Password is required')
                    .min(8, 'Password must be at least 8 characters')
                    .max(40, 'Password must not exceed 40 characters'),

            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                // alert(JSON.stringify(values, null, 2));
                loginSubmit(values);

            }}
        >
        <>
            <div className={loadershow ? 'loading' : 'd-none'}></div>
            <main className="nw_main">
                <section className="nwSignin_section">
                    <div className="container">
                        <div className="nwSignin_wrapper">
                            <div className="nwSignin_inner text-center">
                                <p>Starting today, a healthy lifestyle doesn’t have to cost a fortune! Shop our curated selection of the highest quality, hand-selected supplements, skincare, and powders at the best price imaginable.</p>
                                <p>This is your one stop shop for healthy living! Sign up today for a membership with Wholesale Health Club and reap amazing products with savings you won’t find anywhere else.</p>
                                <p>Enter your username and password below</p>
                                <Form className="nwLogin_form">
                                    <div className="form-group">
                                        <Field className='form-control' type='text' name='email' placeholder="Enter User Name"/>
                                        <CustomErrorMsg name="email" />
                                    </div>
                                    <div className="form-group">
                                        <Field className='form-control' name='password' type="password" placeholder="Enter password"/>
                                        <CustomErrorMsg name="password" />
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn nwBtn-succes w-100">Login</button>
                                        {/* <p><Link to="" className="remember_me">Remember Me</Link></p> */}
                                        <div className="remember_me">
                                            <Field
                                                type="checkbox"
                                                checked={rememberMe}
                                                onChange={() => setRememberMe(!rememberMe)}
                                            />
                                            <p><Link to="" className="">Remember Me</Link></p>
                                        </div>
                                    </div>
                                    <p className="or_divider">OR</p>
                                    <div className="form-group">
                                        <Link to="/create-membership" className="btn nwBtn-succes w-100">CREATE ACCOUNT</Link>
                                    </div>
                                    <div className="form-group">
                                        <p><Link to="/forget-password" className="forgot_password">Forgot Password?</Link></p>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
        </Formik>
    );
}

export default Login2;