import React, { useState, useEffect } from 'react'
import '../../assests/admin/css/thankyou.css';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useStore from '../../store';
import cartIcon from '../../assests/frontend/assets/images/shopping-cart.png';
import money from '../../assests/frontend/assets/images/notes.png';
import axios from 'axios';
import Swal from 'sweetalert2';
import { keyBy } from 'lodash';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Link } from 'react-router-dom';
import Copyright from '../Copyright';
import { CartState } from '../../context/Context';

function Final2() {
    const afterLogin = useStore((state) => state.afterLogin);
    const { state: { cart }, dispatch } = CartState();
    const [show, setShow] = useState(false);
    const isLogin = useStore((state) => state.isLogin);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    //let order_id = localStorage.getItem('order_id');
    const [orderData, setOrderData] = useState([]);
    const [total, setTotal] = useState('');
    let userDetails = useStore((state) => state.userDetails);
    const moment = extendMoment(Moment);
    const [userInfo, setUserInfo] = useState([]);
    const [loadershow, setloadershow] = useState(true);
    let urlParams = new URLSearchParams(window.location.search);
    let responseCode = urlParams.get('responseCode');
    let order_id = "";
    
    let getOrderList = () => {
        if(urlParams.get('orderId')){
            order_id = urlParams.get('orderId');
        } else {
            order_id = localStorage.getItem('order_id');
        }
        localStorage.setItem('order_id',order_id);
        
        const formdata = new FormData();
        formdata.append('order_id', order_id);
        axios.post(`api/get_order_list`, formdata).then(res => {
            //console.log(res.data.data);
            if (res.data.status === true) {
                dispatch({
                    type: "EMPTY_CART",
                });
                localStorage.setItem("V1email",'');
                setOrderData(res.data.data.items);
                setTotal(res.data.data.total);
                axios.post(`api/get_order_by_id`, formdata).then(result => {
                    //setUserInfo(result.data.data);
                    setUserInfo({'name' : result.data.data.first_name+' '+result.data.data.last_name,
                                    'address' : result.data.data.billing_street_address,
                                    'city' : result.data.data.billing_city,
                                    'state' : result.data.data.billing_state,
                                    'zip' : result.data.data.billing_postcode,
                                    'country': result.data.data.billing_country,
                                    'email': result.data.data.email_address,
                                    'phone': result.data.data.customers_telephone
                                });
                    formdata.append('referral_code', localStorage.getItem('referral_code'));
                    formdata.append('email', result.data.data.email_address);
                    formdata.append('firstName', result.data.data.first_name);
                    formdata.append('lastName', result.data.data.last_name);
                    formdata.append('billingAddress1', result.data.data.billing_street_address);
                    formdata.append('billingCity', result.data.data.billing_city);
                    formdata.append('billingState', result.data.data.billing_state);
                    formdata.append('billingZip', result.data.data.billing_postcode);
                    formdata.append('billingCountry', result.data.data.billing_country);
                    formdata.append('phone', result.data.data.customers_telephone);
                    if(responseCode == 100){
                        axios.post(`api/create-user-local-db`, formdata).then(response => {
                            //console.log(response.data.data);
                            afterLogin(response.data.data.account_permission, response.data.data.user_type, response.data.data.id, response.data.data, response.data.data.is_membership);
                        }).catch(err => {
                            console.log(err);
                        })
                    }
                    
                }).catch(error => {
                    console.log(error);
                    Swal.fire({
                        title: 'Warning',
                        html: error.resp.msg,
                        icon: 'warning'
                    })
                });
            }
        }).catch(err => {
            console.log(err.response);
            Swal.fire({
                title: 'Warning',
                html: err.response.message,
                icon: 'warning'
            })
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        const timer = setTimeout(() => {
            setloadershow(false);
        }, 5000);
    
        if(responseCode == 100) {
            getOrderList();
        } else if(responseCode == 800){
            axios.post(`api/get-pending-order-details`, {'order_id':order_id}).then(res => {
                if(res.data.response_code == 350) { // if pending order details not found
                    window.location.href = '/'+localStorage.getItem('c_s');
                }
                else {
                    if (res.data.affid != '' & res.data.sid != '' & res.data.c1 != '' & res.data.c2 != '' & res.data.c3 != '' & res.data.click_id != ''){
                        window.location.href = '/'+localStorage.getItem('c_s')+'?affid='+res.data.affid+'&sid='+res.data.sid+
                        '&s1='+res.data.c1+'&s2='+res.data.c2+'&s3='+res.data.c3+'&click_id='+res.data.click_id;
                    } else {
                        window.location.href = '/'+localStorage.getItem('c_s');
                    }
                }
            });
        } else {
            getOrderList();
        }
        
    }, []);

    return (
        <>
            {loadershow  ? <div className="loader-new loading"></div> : ''}
            <div className="thankYou-page">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-12 col-lg-12 mx-auto  mt-5 thankYou-cont'>
                            <div className="thankyouTable-wrapper">
                                <div className="thankyouTable-hdr">
                                    <h4 className='title'><span className="cartIcon"> <img src={cartIcon} className="img-fluid" /></span> Order History (most recent orders) - {moment().format('M/D/Y')}</h4>
                                    {/* <Button variant="primary" onClick={handleShow}>
                                    View All
                                </Button> */}
                                </div>
                                <div className='table-responsive'>
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th>ORDER #</th>
                                                {/* <th>ORDER DATE</th> */}
                                                <th>DESCRIPTION</th>
                                                <th>Quantity</th>
                                                <th>Unit Price</th>
                                                <th>AMOUNT</th>
                                                <th>STATUS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderData.map((item) => (
                                                <tr>
                                                    <td>{item.order_id}</td>
                                                    {/* <td>{moment(item.acquisition_date).format('M/D/Y')}</td> */}
                                                    <td>{item.name}</td>
                                                    <td className='qty'>{item.product_qty}</td>
                                                    <td>${item.unit_price}</td>
                                                    <td>${item.price}</td>
                                                    <td>{item.order_status}</td>
                                                </tr>
                                            ))}
                                        
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-12 mx-auto  mt-5 thankYou-cont'>
                            <h3>Thank you for your order, <Link to="/bonus">GET YOUR FREE BONUSES NOW!</Link></h3>
                        </div>
                        <div className='col-md-7 col-lg-5 mx-auto  mt-5 thankYou-cont'>
                            <div className="thankYou-card">
                                <p className='thankYouTopTxt'>If you have any questions concerning your order, feel free to contact us at <a
                                    href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a>.</p>
                            </div>

                            <div className="thankYou-card">
                                <p className='cardTitle'>Your login credentials are:</p>
                                <p>Username: {userDetails ? userDetails.email : userInfo.email}<br></br>
                                    Password: ********
                                </p>
                            </div>

                            <div className="thankYou-card">
                                <p className='thankYou-card'>Order Total: ${total}</p>
                            </div>

                            <div className="thankYou-card">
                                <p className='cardTitle'>Here are your billing details:</p>
                                <p>Name: {userDetails ? userDetails.name : userInfo.name}</p>
                                <p>Address: {userDetails ? userDetails.address : userInfo.address}</p>
                                <p>City: {userDetails ? userDetails.city : userInfo.city}</p>
                                <p>State: {userDetails ? userDetails.state : userInfo.state}</p>
                                <p>Zip: {userDetails ? userDetails.zip : userInfo.zip}</p>
                                <p>Country: {userDetails ? userDetails.country : userInfo.country}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Final2