import React from 'react';
import { Link } from 'react-router-dom';

function Copyright() {
    let URL = window.location.href;
    let urlSlug = URL.substring(URL.lastIndexOf('/') + 0);

    const currentYear = new Date().getFullYear();

    return (
        <>
            <div className='footerDark'>
                <div className='footer-container'>
                    <ul className='footer-links'>
                        <li>
                            <Link to='#' onClick={(event) => {
                                event.preventDefault();
                                window.open('/term-and-condition', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                            }}>Terms And Conditions</Link>
                        </li>
                        <li>
                            <Link to="#" onClick={(event) => {
                                event.preventDefault();
                                window.open('/privacy-policy', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                            }}>Privacy Policy</Link>
                        </li>
                        <li>
                            <Link to="#" onClick={(event) => {
                                event.preventDefault();
                                window.open('/citations', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                            }}>Citations</Link>
                        </li>
                        <li>
                            <Link to='/contact-us'> Contact Us </Link>
                        </li>
                    </ul>
                    <p className="copy-right-txt">
                        Copyright © {currentYear}, Wholesale Health Club, {(urlSlug === '/v1') || (urlSlug === '/v2') || (urlSlug === '/v1t') || (urlSlug === '/v2t') ? '' : '9393 N 90th St #102-167 Scottsdale, AZ 85258'}<br />
                        Billing descriptor: <a href='https://wholesalehealthclub.com/'>wholesalehealthclub.com</a><br />
                        Customer Service Phone: <a href="tel:(480)573-0829">(480)573-0829</a><br />
                        Customer Service Email: <a href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a>
                    </p>
                </div>
            </div>
        </>
    )
}

export default Copyright