import React, {useState, useEffect} from 'react'
import Footer from './Footer'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import '../../assests/admin/css/styles.css'
import '../../assests/admin/js/scripts'
import { Outlet, useNavigate } from 'react-router-dom'
import axios from "axios";

function MasterLayout() {
    let navigate = useNavigate();
    const [show, setShow] = useState(false);
    // const result = routes.map((el) => el)
    


    function addClassForMobileMenu(value){
        setShow(!value);
    }


    return (
        <div className='sb-nav-fixed'>
            <Navbar />
            <div id="layoutSidenav">
                <div id="layoutSidenav_nav">
                    <Sidebar />
                </div>
                <div id="layoutSidenav_content">

                    <Outlet />

                    <Footer /> </div>
            </div>
        </div>
    )
}

export default MasterLayout