import React, { useState, useEffect } from 'react';
import { useRef } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';
import CustomErrorMsg from "../frontend/CustomErrorMsg";
import { Link, useLocation, useParams } from 'react-router-dom';
import '../../assests/admin/css/landing.css';
import Copyright from "../Copyright";
import {BASE_URL,stateList,GetCardType, CountryList} from '../Config';
import { Helmet } from 'react-helmet';

// css 
import style from '../../assests/funnel/assets/css/Synaptic.module.css';


// images 
import siteSecurity from '../../assests/admin/assets/img/site-security-img.png';
import bannerProductImg from '../../assests/Lander/assets/images/synaptic-banner-product-img.png';
import midBanner from '../../assests/admin/assets/img/brain4-compressed/photo_2.png';
import mentalAbility from '../../assests/admin/assets/img/brain4-compressed/mental-ability.png';
import brainPower1 from '../../assests/admin/assets/img/brain4-compressed/brain-power1.jpg';
import brainPower2 from '../../assests/admin/assets/img/brain4-compressed/brain-power2.jpg';
import product2 from '../../assests/Lander/assets/images/SynapticXR-Bottle.png';
import genius1 from '../../assests/admin/assets/img/brain4-compressed/genius-ba1.png';
import genius2 from '../../assests/admin/assets/img/brain4-compressed/genius-ba2.png';
import womanWithGlass from '../../assests/admin/assets/img/brain4-compressed/women-glass.png';
import manBag from '../../assests/admin/assets/img/brain4-compressed/men-bag.png';
import testImg1 from '../../assests/admin/assets/img/brain4-compressed/testiminal-pf1.png';
import testImg2 from '../../assests/admin/assets/img/brain4-compressed/testiminal-pf2.png';
import testImg3 from '../../assests/admin/assets/img/brain4-compressed/testiminal-pf3.png';
import formBottomImg from '../../assests/Lander/assets/images/form-bottom-synaptic.png';
import guarnteedMob from '../../assests/admin/assets/img/brain4-compressed/guarnteed-coin-mob.png';
import drImage from '../../assests/admin/assets/img/Dr.png';
import Footer from '../../layouts/frontend/FooterV2';

function SynapticTemplate() {
    const ref = useRef(null);
    const [loadershow, setloadershow] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const location = useLocation();
    let params = location.search;
    const [minutes, setMinutes] = useState(9);
    const [seconds, setSeconds] = useState(59);
    const options = stateList;
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
        setIsClicked(true);
    };
    let URL = window.location.href;
    let urlSlug =URL.split('/');
    const params2 = useParams();
    

    function getDateOneWeekFromNow() {
        // Create a new Date object for the current date
        const date = new Date();

        // Add 7 days to the date in milliseconds (1 week)
        //date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

        // Get the month, day, and year from the date
        const month = date.getMonth();
        const day = date.getDate();
        const year = date.getFullYear();

        // Convert the month number to the month's name
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const monthName = months[month];

        // Return the date in the format you specified
        return `${monthName} ${day}, ${year}`;
    }

    const dateOneWeekFromNow = getDateOneWeekFromNow();

    function confirmSubmit(values) {
        let campaign_id = 61;
        //console.log(campaign_id);
        setloadershow(true);
        const formdata = new FormData();
        formdata.append('firstName',values.firstName);
        formdata.append('lastName',values.lastName);
        formdata.append('email',values.email);
        formdata.append('phone',values.phone);
        formdata.append('tag1','brainxr');
        formdata.append('tag2','brainxr-prospect');
        formdata.append('shippingAddress',values.shippingAddress1);
        formdata.append('shippingCity',values.shippingCity);
        formdata.append('shippingCountry',values.country);
        formdata.append('shippingState',values.shippingState);
        formdata.append('shippingZip',values.shippingZip);
        formdata.append('campaign_id',campaign_id);

        let affid = new URLSearchParams(location.search).get('affid');
        let sid = new URLSearchParams(location.search).get('sid');
        let s1 = new URLSearchParams(location.search).get('s1');
        let s2 = new URLSearchParams(location.search).get('s2');
        let s3 = new URLSearchParams(location.search).get('s3');
        let click_id = new URLSearchParams(location.search).get('click_id');
        let notes = new URLSearchParams(location.search).get('notes');

        localStorage.setItem('affid',affid);
        localStorage.setItem('sid',sid);
        localStorage.setItem('s1',s1);
        localStorage.setItem('s2',s2);
        localStorage.setItem('s3',s3);
        localStorage.setItem('click_id',click_id);
        localStorage.setItem('notes',notes);

        formdata.append('affid', affid);
        formdata.append('sid', sid);
        formdata.append('s1', s1);
        formdata.append('s2', s2);
        formdata.append('s3', s3);
        formdata.append('click_id', click_id);
        formdata.append('notes', notes);
        
        //console.log(Object.fromEntries(formdata));

        axios.post(`api/maropost_tag_add`, formdata).then(res => {
            if (res.data.status === true) {
                axios.post(`api/create-prospect`, formdata).then(result => {
                    if(result.data.status === true) {
                        localStorage.setItem('prospectId',result.data.prospectId);
                        window.location.href = '/' + localStorage.getItem('c_s')+params;
                    }
                })
            }
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        });
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [minutes, seconds]);

    useEffect(()=>{
        handleCountryChange('239');
    },[]);

    const currentYear = new Date().getFullYear();
    const [states, setStates] = useState([]);

    const handleCountryChange = (country_id) => {
        
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    return (
        <>
            <div className={loadershow ? 'loading' : 'd-none'}></div>
            <div className={`${style.main_page} ${style.landing}`}>
                {/* Header : Begin  */}
                <header>
                    <div className={style.site_secure}><img src={siteSecurity} width="112" alt="site security" /></div>
                    <div className={style.top_header}>
                        <div className={`${style.customContainer} container`}>
                            <div className={`${style.warning_notice_timer} sm-hide`}>
                                <span>Warning: </span>Due to extremely high media demand, there is limited supply of <span className={style.product_name}>SynapticXR</span> in stock as of <span style={{color: '#fb9841'}}> {dateOneWeekFromNow}</span>
                                <span className={style.hurry}>
                                    . HURRY! <span id="time">{minutes}:{seconds}</span>
                                    <div><span id="milliseconds"></span></div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={`${style.customContainer} container`}>
                        <div className={`${style.header_section} clearfix`}>
                            <div className={style.logo}>
                                <span className={`${style.logo_text} ${style.text_unset}`}>SynapticXR</span>
                            </div>
                            <div className={style.right_header}>
                                <div className={`${style.right_header_section} clearfix`}>
                                    <div className={style.header_risk_trial}>
                                        Get Your First Bottle Today
                                        <i className={`${style.sprite} ${style.sprite_header_arrow}`}></i>
                                    </div>
                                    <button type="button" onClick={handleClick} className={`${style.btn} ${style.header_btn} ${style.heartbeat} ${style.scroll_btn} ${style.btn_with}`}><i className={style.right_arrow}></i>  Get my bottle today</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {/* Header End  */}
                {/* Banner Begin  */}
                <div className={style.banner}>
                    <div className={`${style.customContainer} container`}>
                        <div className={`${style.banner_col} clearfix`}>
                            <div className={style.banner_col1}>
                                <span className={`${style.logo_text} ${style.text_unset}`}>SynapticXR</span>
                                <div className={style.sapill}>
                                    Revolutionary
                                    <br />
                                    Clinically-Proven <span>Smart Pill</span>
                                </div>
                                <ul className={style.banner_lists}>
                                    <li>Think Faster - Be Smarter</li>
                                    <li>Remember EVERYTHING</li>
                                    <li>Focus Clearly and Boost Energy</li>
                                    <li>Eliminate “Brain Fog Syndrome”</li>
                                    <li>Supercharge Success</li>
                                </ul>
                                <div className={`${style.guarnteedMob} d-md-none`}>
                                    <img src={guarnteedMob} className="img-fluid" alt="" />
                                </div>
                                <button type="button" onClick={handleClick} className={`${style.btn} ${style.banner_btn} ${style.heartbeat} ${style.scroll_btn}`}><i className={style.right_arrow2}></i> Get my risk free bottle</button>
                            </div>
                            <div className={style.banner_col2}>
                                <div className={style.banner_right}>
                                    <div className={`${style.banner_right_img} clearfix`}>                                        
                                        <img className={style.bannerProductImg} src={bannerProductImg} width="200" alt="" />
                                        <ul className={style.banner_right_list}>
                                            <li className="wow zoomOut">&nbsp;</li>
                                            <li className="wow zoomOut" >&nbsp;</li>
                                        </ul>
                                    </div>
                                    <div className={style.clear}></div>
                                    <ul className={`d-none d-md-inline-block clearfix ${style.banner_client_icon}`}>
                                        <li>
                                            <i className={`${style.sprite} ${style.sprite_ba_client1}`}></i>
                                        </li>
                                        <li>
                                            <i className={`${style.sprite} ${style.sprite_ba_client2}`}></i>
                                        </li>
                                        <li>
                                            <i className={`${style.sprite} ${style.sprite_ba_client3}`}></i>
                                        </li>
                                        <li>
                                            <i className={`${style.sprite} ${style.sprite_ba_client4}`}></i>
                                        </li>
                                        <li>
                                            <i className={`${style.sprite} ${style.sprite_ba_client5}`}></i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-md-none'>
                        <ul className={`${style.banner_client_icon} ${style.clearfix}`}>
                            <li>
                                <i className={`${style.sprite} ${style.sprite_ba_client1}`}></i>
                            </li>
                            <li>
                                <i className={`${style.sprite} ${style.sprite_ba_client2}`}></i>
                            </li>
                            <li>
                                <i className={`${style.sprite} ${style.sprite_ba_client3}`}></i>
                            </li>
                            <li>
                                <i className={`${style.sprite} ${style.sprite_ba_client4}`}></i>
                            </li>
                            <li>
                                <i className={`${style.sprite} ${style.sprite_ba_client5}`}></i>
                            </li>
                        </ul>
                    </div>
                    <div className={style.dr_img}><img src={drImage} width="95" alt="" /></div>
                </div>
                {/* Banner End  */}
                {/* Mid banner  */}
                <div className={style.mb_banner}>
                    <img src={midBanner} width="100%" alt="Banner" />
                </div>
                {/* Mid banner End  */}
                {/* Mental Ability Section  */}
                <section className={`${style.mental_ability}`}>
                    <div className={`${style.customContainer} container`}>
                        <h2 className={style.bold_blk}>Instant Super Computer <span className={style.orange2}>Mental Ability</span></h2>
                        <div className={`${style.mental_ability_inner} ${style.clearfix}`}>
                            <div className={style.mental_ability_img}>
                                <img src={mentalAbility} width="305" alt="" />
                            </div>
                            <div className={style.mental_ability_content}>
                                <p>
                                    SynapticXR is a revolutionary brain supplement formulated to give you ultimate brain power. Known
                                    in Scientific Terms as a “<span className={`${style.orange} ${style.uppercase}`}>Nootropic</span>” or “
                                    <span className={`${style.orange} ${style.uppercase}`}>Genius Pill</span>” SynapticXR improves mental functions such as
                                    <b>cognition, memory, intelligence, motivation, attention, concentration</b> and therefore&nbsp;
                                    <b>happiness and success.</b> You will be limitless!
                                </p>
                                <div className={`${style.ability_box} ${style.clearfix}`}>
                                    <div className={style.ability_orange_box}>
                                        A healthy mind that is supercharged to fire on <span className={style.black}>all 12-Cylinders</span> will
                                        propel you to higher standards. SynapticXR’s advanced cognitive formula is made with all
                                        natural occurring
                                        ingredients to fuel your brain. It is a safe and fast way to increase your daily energy levels,
                                        and will put you on the path to ultimate success in life.
                                    </div>
                                    <div className={style.ability_realy_work}>
                                        <i className={`${style.sprite} ${style.sprite_really_works}`}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={style.ability_list}>
                            <h5 className={style.orange}>SynapticXR Will:</h5>
                            <ul>
                                <li>Increase Academic and Work Performance</li>
                                <li>Study Less and Play Harder</li>
                                <li>Blow Away the Competition at Job Interviews, Work or School</li>
                                <li>Reduce Stress and Increase Your Happiness and Success Quotients</li>
                            </ul>
                        </div>
                    </div>
                </section>
                {/* Mental Ability Section End  */}
                {/* Brain Power Section  */}
                <section id="steps-slider" className={`${style.brain_power} ${style.steps_slider} ${style.slider_wrap} ${style.disable} ${style.steps}`}>
                    <div className={`${style.customContainer} container`}>
                        <h2 className={style.bold_blk}>Turbo-Charge <span className={style.orange2}>Key Aspects of Brain Power</span></h2>
                        <p>SynapticXR simultaneously stimulates four areas of brain power: focus, memory, mental energy, and overall
                            brain health. That means winning results in everything you do.</p>
                        <div className={style.slider_ctrl}>                            
                            <ul className={`${style.after_before} ${style.img_slides_wrap} ${style.slides_wrap} ${style.steps} ${style.clearfix}`}>
                                <li className={`${style.product} ${style.active}`} id="steps-slide1">
                                    <div className={`${style.brain_before} ${style.step}`}>
                                        <img src={brainPower1} alt="" />
                                        <span className={style.after_before_txt}>Before</span>
                                    </div>
                                </li>
                                <li className={style.product} id="steps-slide2">
                                    <div className={`${style.brain_after} ${style.step}`}>
                                        <img src={brainPower2} alt="" />
                                        <span className={`${style.after_before_txt} ${style.orange}`}>After</span>
                                    </div>
                                </li>
                            </ul>
                            <ul className={style.dotlist}>
                                <li className={`${style.dot_wrap} ${style.active}`}>
                                    <a className={style.dot} href="#steps-slide1"></a>
                                </li>
                                <li className={style.dot_wrap}>
                                    <a className={style.dot} href="#steps-slide2"></a>
                                </li>
                            </ul>
                        </div>
                        <ul className={`${style.brain_list} ${style.clearfix}`}>
                            <li>
                                <i className={`${style.sprite} ${style.sprite_brain_list_icon1}`}></i>
                                <h5>Attention and Focus</h5>
                                <p>SynapticXR enhances the brain so you have razor sharp focus. Anywhere! Anytime!</p>
                            </li>
                            <li>
                                <i className={`${style.sprite} ${style.sprite_brain_list_icon2}`}></i>
                                <h5>Unlock Long-Term Memory</h5>
                                <p>A key component to success. See once and remember forever.</p>
                            </li>
                            <li>
                                <i className={`${style.sprite} ${style.sprite_brain_list_icon3}`}></i>
                                <h5>Working Memory</h5>
                                <p>It is crucial to quickly master tasks and making you so efficient that you get the job done with
                                    extreme performance.</p>
                            </li>
                            <li>
                                <i className={`${style.sprite} ${style.sprite_brain_list_icon4}`}></i>
                                <h5>Information Processing</h5>
                                <p>The speed with which your brain acts and sometimes the difference between success and failure is the
                                    lightning quick thinking that SynapticXR provides.</p>
                            </li>
                        </ul>
                        <h5 className={style.orange2}>Extra Benefit:</h5>
                        <br />
                        <p>SynapticXR Safeguards your brain and provides a layer of cognitive protection, like an invisible super
                            charger to boost your memory and guard your neural function against brain fog syndrome.</p>
                    </div>
                </section>
                {/* Brain Power Section End */}
                {/* Genius Section Begin  */}
                <section className={style.genius}>
                    <div className={`${style.customContainer} container`}>
                        <h2 className={style.bold_blk}><span className={style.orange2}>SynapticXR Releases</span> Your Genius</h2>
                        <div className={`${style.genius_inner} ${style.clearfix}`}>
                            <div className={style.genius_img}>
                                <img src={product2} width="200" alt="" />
                            </div>
                            <div className={style.genius_content}>
                                <p>
                                    SynapticXR is a 100% natural water-soluble supplement that quickly enters the brain, to protect neurons, improve signal transmission, and support brain function and learning processes. It stimulates brain function
                                    so you can actually build new neurons and neural pathways.
                                </p>
                                <p>
                                    Adequate functioning of neurotransmitter synthesis is essential in maintaining a healthy cognitive state that will supercharge your thinking capacity and lead to your ultimate success in anything that requires
                                    <span className={`${style.black} ${style.bold}`}>&nbsp;SUPERIOR BRAIN POWER.</span>
                                </p>
                                <ul className={`${style.genius_after_before} ${style.clearfix}`}>
                                    <li>
                                        <img src={genius1} width="220" alt="" />
                                        <span className={style.genius_after_before_txt}>Before</span>
                                    </li>
                                    <li>
                                        <img src={genius2} width="220" alt="" />
                                        <span className={`${style.genius_after_before_txt} ${style.orange}`}>After</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={style.ability_list}>
                            <h5 className={style.orange}>SynapticXR is Clinically Supported to:</h5>
                            <ul className={`${style.p-0} ${style.genius_lists}`}>
                                <li>Promote Nerve Growth In The Brain</li>
                                <li>Support Brain Cell Walls</li>
                                <li>Supercharge Crucial Neurotransmitters</li>
                                <li>Deliver Vital Nutrients, Vitamins &amp; Amino Acids</li>
                                <li>Support Increased Blood Flow &amp; Oxygenation To The Brain</li>
                                <li>Support Brain Protection From Neurotoxins &amp; Free Radicals</li>
                                <li>Increase Brain Energy &amp; Your Ability To Use It</li>
                                <li>Stimulate Brain Plastiticity For Ultimate Brain Boost</li>
                            </ul>
                        </div>
                    </div>
                </section>
                {/* Genius Section End  */}
                {/* Hard Clear Section  */}
                <section className={style.hard_clear}>
                    <div className={`${style.customContainer} container`}>
                        <div className={`${style.hardclear_inner} ${style.clearfix}`}>
                            <div className={style.hardclear_content}>
                                <h2 className={style.bold_blk}>
                                    Enhance Your <br />
                                    <span className={style.white}>Mental State</span>
                                </h2>
                                <p>
                                    SynapticXR is so effective you will have Lightning Fast thinking under any circumstances, including a genius-level boost when you are tired, have brain fog syndrome or even after a heavy night of drinking. Don’t
                                    let the demands of your job, school or social life slow you down. SynapticXR maximizes your concentration with ultimate efficiency so that you have more time for the things you and your brain would rather be
                                    doing!
                                </p>
                                <p>The health boost you receive from SynapticXR is so successful at keeping your brain optimized that you can solve problems quicker, from academic pressure to helping you quickly solve personal issues.</p>
                            </div>
                            <div className={style.hardclear_img}><img src={womanWithGlass} alt="" /></div>
                        </div>
                    </div>
                </section>
                {/* Hard Clear Section End  */}
                {/* Boosttest Section  */}
                <section className={style.boosttest}>
                    <div className={`${style.customContainer} container`}>
                        <div className={`${style.boosttest_inner} ${style.clearfix}`}>
                            <div className={style.boosttest_img}><img src={manBag} alt="" /></div>
                            <div className={style.boosttest_content}>
                                <h2 className={style.bold_blk}>
                                    <span className={style.orange}>Give Yourself a Mental Edge</span><br />
                                    in Any Circumstance
                                </h2>
                                <p>Don't ever fall prey to Brain Fog at a critical time. When you are dealing with the pressure of academic tests or increased career workload, you need exceptional neural performance exactly when you need it.</p>
                                <p>
                                    Don't spend hours and hours trying to remember that friend’s name or phone number only to let insufficient brain function destroy your thought process. SynapticXR keeps you sharp, focused and optimized for all
                                    moments in life when you need <span className={`${style.black} ${style.bold}`}>SUPERCHARGED BRAIN PERFORMANCE</span>.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Boosttest Section End */}
                {/* Cognitive Enhancement Section  */}
                <section className={style.cognitive_enhancement}>
                    <div className={`${style.customContainer} container`}>
                        <h2 className={style.bold_blk}><span className={style.orange2}>SynapticXR is The #1 Choice</span> <br />For Cognition Enhancement</h2>
                        <h5>Premium Brain Supplement</h5>
                        <p>SynapticXR’s unique formula is scientifically designed and tested to meet the highest standards of supreme cognitive function. We manufacture every pill with the utmost care in our ultra-modern facilities with full scientific quality assurance
                            testing at every lab stage. Where other products depend on fillers and synthetic products, SynapticXR is formulated with only the best natural ingredients that are clinically proven to give you the mental boost you need. We Guarantee
                            it! </p>
                        <div className={`${style.ability_list} ${style.clearfix}`}>
                            <ul>
                                <li>Supports Complex and Efficient Functioning of your Brain Cells</li>
                                <li>Research Driven Human Studies Confirmed safety and effectiveness</li>
                                <li>Manufactured in an Expert Certified laboratory environment</li>
                                <li>We Do Not Test On Animals</li>
                            </ul>
                        </div>
                    </div>
                </section>
                {/* Cognitive Enhancement Section  */}
                {/* Testimonial Section  */}
                <section id="testimonials-slider" className={`${style.slider_wrap} ${style.testimonial}`}>
                    <div className={`${style.customContainer} container`}>
                        <h2 className={style.bold_blk}>
                            <span className={style.white}>What Our Satisfied, Real Customers</span> <br />
                            Have to Say About SynapticXR
                        </h2>
                        <div className={style.slider_ctrl}>
                            <div className={`${style.slider_arrow} ${style.slider_left_arrow}`}>
                                <div className={style.table}>
                                    <div className={style.table_cell}><i className={style.testiminial_arrow_left}></i></div>
                                </div>
                            </div>
                            <div className={`${style.slider_arrow} ${style.slider_right_arrow}`}>
                                <div className={style.table}>
                                    <div className={style.table_cell}><i className={style.testiminial_arrow_left}></i></div>
                                </div>
                            </div>
                            <ul className={`${style.img_slides_wrap} ${style.slides_wrap} ${style.wrap}`}>
                                <li id="slide1" className={`${style.product} ${style.active}`}>
                                    <div className={`${style.testimonial_box} ${style.clearfix}`}>
                                        <div className={style.testimonial_img}><img src={testImg1} width="196" alt="" /></div>
                                        <div className={style.testimonial_content}>
                                            <p>
                                                I used to cram for exams. I’d stay up all night, force myself to re-read every textbook. It never worked. I know the class material, but somehow when I’m nervous and taking a test, I just used to run
                                                up against a block. One week after taking SynapticXR, I felt confident. I didn’t have to cram, I could visualize everything perfectly in my head. For the first time, I passed in the highest percentile!
                                                Thank you, this stuff is almost a miracle! Now I know I’ll pass law school!
                                            </p>
                                            <div className={`${style.testi_name} ${style.bold}`}>John D. - Fort Lauderdale, Florida</div>
                                        </div>
                                    </div>
                                </li>
                                <li id="slide2" className={style.product}>
                                    <div className={`${style.testimonial_box} ${style.clearfix}`}>
                                        <div className={style.testimonial_img}><img src={testImg2} width="196" alt="" /></div>
                                        <div className={style.testimonial_content}>
                                            <p>
                                                People used to say I was “ditzy” behind my back. I was always forgetting appointments, names, addresses. Nobody wanted to trust me to do anything, and I was afraid I was going to be a social outcast.
                                                I read about SynapticXR, so I tried it. I really don’t believe in these things, but I had nothing to lose. WOW! Was I wrong! I feel like I’m some sort of rocket scientist now! I never forget a thing,
                                                not one thing! Everyone likes me and trusts me again!!
                                            </p>
                                            <div className={`${style.testi_name} ${style.bold}`}>Jane M. – Bakersfield, California</div>
                                        </div>
                                    </div>
                                </li>
                                <li id="slide3" className={style.product}>
                                    <div className={`${style.testimonial_box} ${style.clearfix}`}>
                                        <div className={style.testimonial_img}><img src={testImg3} width="196" alt="" /></div>
                                        <div className={style.testimonial_content}>
                                            <p>
                                                I really wanted to take the next step in my career. There are lots of younger hires at my company that I have to compete with, but I’m not as fast as I used to be now that I’m 39. Maybe there was
                                                something that could help me? One of my buddies was on SynapticXR, and he said it was like breaking through into another dimension. I tried SynapticXR for one month, just took the recommended amount.
                                                BAM! Everyone is noticing the difference, and work is great. I’ll never stop using it!
                                            </p>
                                            <div className={`${style.testi_name} ${style.bold}`}>Peter O. – Manhattan, New York</div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <ul className={style.dotlist}>
                            <li className={`${style.dot_wrap} ${style.active}`}>
                                <a className={style.dot} href="#slide1"></a>
                            </li>
                            <li className={style.dot_wrap}>
                                <a className={style.dot} href="#slide2"></a>
                            </li>
                            <li className={style.dot_wrap}>
                                <a className={style.dot} href="#slide3"></a>
                            </li>
                        </ul>
                    </div>
                </section>
                {/* Testimonial Section End  */}
                {/* Form Section Begin  */}
                <section id="index-form" className={style.form_section}>
                    <div className={`${style.customContainer} container`}>
                        <div className={`${style.form_section__inner} ${style.clearfix}`}>
                            <div className={style.form_product}>
                                <div className={`${style.form_product_img} ${style.clearfix}`}>
                                    <ul className={style.form_product__list}>
                                        <li>&nbsp;</li>
                                        <li>&nbsp;</li>
                                    </ul>
                                    <img className="wow zoomIn" src={formBottomImg} alt="" />
                                </div>
                            </div>
                            <div className={style.form_box} id="form-box" ref={ref}>
                                <h3>
                                    Where Can We Send<br />
                                    Your First Bottle
                                </h3>
                                <Formik initialValues={{
                                    firstName: '',
                                    lastName: '',
                                    shippingAddress1: '',
                                    shippingZip: '',
                                    shippingCity: '',
                                    shippingState: '',
                                    email: '',
                                    phone: '',
                                    country:'US'
                                }}
                                    validationSchema={Yup.object({
                                        firstName: Yup.string().required('First name is required'),
                                        lastName: Yup.string().required('Last name is required'),
                                        shippingAddress1: Yup.string().required('Address is required'),
                                        //shippingZip: Yup.string().required('Zip code is required').matches(/^[a-zA-Z0-9]+$/, 'Zip code must contain only letters and numbers').min(5, 'Zip code should contain min 5 digits!'),
                                        shippingZip: Yup.string().when('country', {
                                            is: 'US',
                                            then: Yup.string()
                                              .required('Zip code is required')
                                              .matches(/^\d{5}$/, 'Zip code must be exactly 5 digits number'),
                                            otherwise: Yup.string().when('country', {
                                              is: 'CA',
                                              then: Yup.string()
                                                .required('Zip code is required')
                                                .matches(/^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/, 'Zip code must contain only letters and numbers')
                                                .min(6, 'Zip code should contain at least 6 characters')
                                                .max(6, 'Zip code should contain at most 6 characters'),
                                            }),
                                        }),
                                        shippingCity: Yup.string().required('City is required'),
                                        shippingState: Yup.string().required('State is required'),
                                        email: Yup.string().email("Email is required").max(255).required("Please enter a valid email"),
                                        phone: Yup.string().required('Phone no is required')
                                            .matches(/^[0-9]+$/, "Please enter only number!")
                                            .min(10, 'Please enter a valid contact number!')
                                            .max(10, 'Please enter a valid contact number!'),
                                        country: Yup.string().required('Country is required')
                                    })}
                                    onSubmit={(values) => {
                                        //console.log(values);
                                        localStorage.setItem('firstName', values.firstName);
                                        localStorage.setItem('lastName', values.lastName);
                                        localStorage.setItem('shippingAddress1', values.shippingAddress1);
                                        localStorage.setItem('shippingZip', values.shippingZip);
                                        localStorage.setItem('shippingState', values.shippingState);
                                        localStorage.setItem('shippingCity', values.shippingCity);
                                        localStorage.setItem('phone', values.phone);
                                        localStorage.setItem('email', values.email);
                                        localStorage.setItem('country', values.country);
                                        confirmSubmit(values);
                                    }}
                                >
                                    {(formProps) => (
                                        <Form id="create_prospect">
                                            <div className={`${style.form_col2} ${style.clearfix}`}>
                                                <div className={style.form_col1__2}>
                                                    <div className={style.form_holder}>
                                                        <label>First Name:</label>
                                                        <Field type="text" name="firstName" className={`${style.form_control} validate[required]`} placeholder="First Name*" />
                                                        <CustomErrorMsg name="firstName" />
                                                    </div>
                                                </div>
                                                <div className={style.form_col1__2}>
                                                    <div className={style.form_holder}>
                                                        <label>Last Name:</label>
                                                        <Field type="text" name="lastName" className={`${style.form_control} validate[required]`} placeholder="Last Name*" />
                                                        <CustomErrorMsg name="lastName" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={style.form_col}>
                                                <div className={style.form_holder}>
                                                    <label>Street:</label>
                                                    <Field type="text" name="shippingAddress1" className={`${style.form_control} validate[required]`} placeholder="Address*" />
                                                    <CustomErrorMsg name="shippingAddress1" />
                                                </div>
                                            </div>
                                            <div className={style.form_col}>
                                                    <div className={style.form_holder}>
                                                        <label>ZIP:</label>
                                                        <Field type="text" maxLength="6" name="shippingZip" className={`${style.form_control} validate[required]`} placeholder="Zip*" />
                                                        <CustomErrorMsg name="shippingZip" />
                                                    </div>
                                            </div>
                                            <div className={`${style.form_col2} ${style.clearfix}`}>
                                                <div className={style.form_col1__2}>
                                                    <div className={style.form_holder}>
                                                        <label>Country:</label>
                                                        <Field
                                                            name="country"
                                                            as="select"
                                                            placeholder="Select an option*"
                                                            className={`${style.form_control} validate[required]`}
                                                            onChange={(event) => {
                                                                formProps.setFieldValue("shippingState", '');
                                                                formProps.handleChange(event);
                                                                handleCountryChange(event.target[event.target.selectedIndex].getAttribute('data-id')); 
                                                                // Call the custom function here if needed
                                                                }}
                                                            >
                                                            {CountryList.map((country) => (
                                                            <option key={country.id} data-id={country.id} value={country.short_name}>
                                                             {country.country}
                                                            </option>
                                                             ))}
                                                        </Field>
                                                        <CustomErrorMsg name="country" />
                                                    </div>
                                                </div>
                                                <div className={style.form_col1__2}>
                                                    <div className={style.form_holder}>
                                                        <label>State:</label>
                                                        <Field
                                                            name="shippingState"
                                                            as="select"
                                                            placeholder="Select an option*"
                                                            className={`${style.form_control} validate[required]`}
                                                        >
                                                            <option value="">Select state</option>
                                                            {states.map((state) => (
                                                                <option key={state.id} value={state.abbreviation}>
                                                                    {state.state}
                                                                </option>
                                                            ))}
                                                        </Field>
                                                        <CustomErrorMsg name="shippingState" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={style.form_col}>
                                                <div className={style.form_holder}>
                                                    <label>City:</label>
                                                    <Field type="text" name="shippingCity" className={`${style.form_control} validate[required]`} placeholder="City*" />
                                                    <CustomErrorMsg name="shippingCity" />
                                                </div>
                                            </div>
                                            <div className={style.form_col}>
                                                <div className={style.form_holder}>
                                                    <label>Phone</label>
                                                    <Field type="tel" name="phone" className={`${style.form_control} validate[required]`} placeholder="Phone*" maxLength={10} />
                                                    <CustomErrorMsg name="phone" />
                                                </div>
                                            </div>
                                            <div className={style.form_col}>
                                                <div className={style.form_holder}>
                                                    <label>Email</label>
                                                    <Field type="email" name="email" className={`${style.form_control} validate[required]`} placeholder="Email*" />
                                                    <CustomErrorMsg name="email" />
                                                </div>
                                            </div>
                                            <div id="rushtop" className={style.submit_btn_div}>
                                                <button id="rushOrderButton" type="submit" className={`${style.submit_btn} ${style.heartbeat}`}><i className={style.right_arrow2}></i><span> Get my first bottle today </span></button>
                                            </div>
                                            <div className={style.secure_form}><i className={style.secure_lock}></i> Secure 256-bit SSL Encryption</div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                    <div className={style.dr_img}>
                        <i className={`${style.sprite} ${style.sprite_Dr}`}></i>
                    </div>
                </section>
                {/* Form Section Begin End */}
                {/* Footer Client  */}
                <div className={style.footer_client}>
                    <div className={`${style.customContainer} container`}>
                        <ul>
                            <li><i className={`${style.sprite} ${style.sprite_f_norton}`}></i></li>
                            <li><i className={`${style.sprite} ${style.sprite_f_mcafee}`}></i></li>
                            <li><i className={`${style.sprite} ${style.sprite_f_visa}`}></i></li>
                            {/* <li><i className="sprite sprite-f-mastercard"></i></li> */}
                        </ul>
                    </div>
                </div>
                {/* Footer Client End  */}
                <footer className={style.inner_footer}>
                    <div className={`${style.customContainer} container`}>
                        <ul className={style.footer_menu}>
                            <li>
                                <Link className={`${style.open_popup_link} cm-ajax-popup`} to='#' onClick={(event) => {
                                    event.preventDefault();
                                    window.open('/term-and-condition', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                }}>Terms And Conditions</Link>
                            </li>
                            <li>
                                <Link className={`${style.open_popup_link} cm-ajax-popup`} to='/contact-us'>Contact Us</Link>
                            </li>
                            <li>
                                <Link className={`${style.open_popup_link} cm-ajax-popup`} to="#" onClick={(event) => {
                                    event.preventDefault();
                                    window.open('/privacy-policy', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                }}>Privacy Policy</Link>
                            </li>
                            <li>
                                <Link className={`${style.open_popup_link} cm-ajax-popup`} to="#" onClick={(event) => {
                                    event.preventDefault();
                                    window.open('/citations', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                }}>Citations</Link>
                            </li>
                        </ul>

                        <div className={style.copyright}>
                            {currentYear} © All Rights Reserved - SynapticXR USA
                        </div>
                        <p>
                            The statements made on our websites have not been evaluated by the FDA (U.S. Food &amp; Drug Administration). This product is not intended to diagnose, cure or prevent any disease. The information provided by this
                            website or this company is not a substitute for a face-to-face consultation with your physician, and should not be construed as individual medical advice. The testimonials on this website are individual cases and do not
                            guarantee that you will get the same results.
                        </p>
                    </div>
                </footer>
                <div id="bottom-floating-bar" className={`${style.floating_bar} ${style.float} ${style.float} ${isClicked ? 'd-none' : ""}`}>
                    <button type="button" onClick={handleClick}  className={isClicked ? 'd-none' : `${style.btn} ${style.heartbeat} ${style.scroll_btn} ${style.mobileOptn}`}><i className={style.right_arrow}></i> Get my first bottle today </button>
                </div>
            </div>
        </>
    )
}

export default SynapticTemplate