import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import axios from 'axios';
import Swal from 'sweetalert2';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';

function SupportLogs() {
    const [List, setList] = useState([]);
    const [pending, setPending] = useState(true);
    const [search, setSearch] = useState("");
    const [searchData, setsearchData] = useState([]);
    const today = new Date();
    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
    ]);
    const [page, setPage] = useState(1);
    const countPerPage = 10;
    const [searchTerm, setSearchTerm] = useState('');

    const handleSelect = (ranges) => {
        const startDate = moment(ranges.selection.startDate);
        const formattedStartDate = startDate.format('YYYY-MM-DD');
        const endDate = moment(ranges.selection.endDate);
        const formattedEndDate = endDate.format('YYYY-MM-DD');
        let data = {'startDate':formattedStartDate, 'endDate':formattedEndDate}
        axios.post(`api/get-logs-by-date`,data).then(res => {
            if(res.data.status === true){
                setList(res.data.data);
                setPending(false);
            } else {
                setList([]);
                setSearch([]);
                setPending(false);
            }
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }

    function getSupportLogs(){
        setPending(true);
        axios.get(`api/getSupportLogs`).then(res => {
            setList(res.data.data);
            setPending(false);
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }

    useEffect(() => {
        setsearchData('');
        getSupportLogs();
    }, []);

    /*useEffect(() => {
        const result = List && List?.filter(email => {
            return email.email.toLowerCase().match(searchData.toLowerCase());
        })
        setSearch(result);
    }, [searchData]);*/

    const columns = [
        {
            name: "Email",
            selector: row => row.email
        },
        {
            name: "Site name",
            selector: row => row.site_name
        },
        {
            name: "IP",
            selector: row => row.ip
        },
        {
            name: "Created date",
            cell: row => (
                <span>{row.created_at.split("T")[0]}</span>
            )
        }
    ]

    const handleSearch = (e) => {
        const searchValue = e.target.value;
        setsearchData(searchValue);
        
        if(searchValue.length > 3){
            axios.get(`/api/search-support-logs?searchItems=${searchValue}`)
                .then(res => {
                    setList(res.data.data);
                    setPending(false);
                })
            .catch(error => {
                setList({ total: 0, data: [] });
                setPending(false);
            });
        } else {
            getSupportLogs();
        }
    };

    return (
        <div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12 pl-3'>
                        <div className="pageheader card mb-4">
                            <div className="card-body row align-items-center">
                                <div className="page-name col-md-3">Support Logs</div>
                            </div>
                        </div>
                        <h1 className="display-6"></h1>
                        <div className='card '>
                            <div className='card-body '>
                                <div className='datatable'>
                                    <DateRangePicker
                                        onChange={(item) => {
                                            setState([item.selection]);
                                            handleSelect(item);
                                        }}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        ranges={state}
                                        maxDate={today}
                                        direction="horizontal"
                                    />
                                    {/* <DataTable
                                        noDataComponent="No data found"
                                        columns={columns}
                                        data={search}
                                        progressPending={pending}
                                        pagination
                                        fixedHeader
                                        subHeader
                                        subHeaderComponent={
                                            // <input type="text" placeholder="search by email" value={searchData} onChange={(e) => { setsearchData(e.target.value) }} />
                                            <input type="text" placeholder="search by email" value={searchData} onChange={handleSearch} />
                                        }
                                        subHeaderAlign="right"
                                    /> */}
                                    <DataTable
                                        noDataComponent="No data found"
                                        columns={columns}
                                        data={List}
                                        highlightOnHover
                                        progressPending={pending}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={List.total}
                                        paginationPerPage={countPerPage}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true
                                        }}
                                        fixedHeader
                                        subHeader
                                        subHeaderComponent={
                                            <input type="text" placeholder="search" value={searchData} onChange={handleSearch} />
                                        }
                                        onChangePage={page => setPage(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupportLogs;