import React from 'react'
import { Link } from 'react-router-dom';
import Copyright from '../Copyright';
import {BASE_URL} from '../Config';

function TermAndCond() {

    return (
        <>
            <div className='infoPages term-and-cond'>
                <div className='container'>
                    <div className='breadcrumbs_nav text-center  mb-4'>
                        <Link to='#'>HOME</Link>
                        <Link to='#' className='lastElem'>TERMS AND CONDITIONS</Link>
                    </div>
                    <div className="infoPage-container">
                        <h2 className="text-center">TERMS OF USE &amp; CONDITIONS OF SALE</h2>
                        <p>
                            <em>
                                <strong>
                                    IMPORTANT – PLEASE READ OUR TERMS OF USE AND CONDITIONS OF SALE CAREFULLY BEFORE ACCESSING, USING, OR PLACING AN ORDER. THESE TERMS CONTAIN DISCLAIMERS OF WARRANTIES, LIMITATIONS OF LIABILITIES, AND CLASS ACTION WAIVER AND
                                    MANDATORY ARBITRATION PROVISIONS (SEE SECTIONS 13, 14, AND 15, RESPECTIVELY.)
                                </strong>
                            </em>
                        </p>
                        <p>
                            Genius Wholesale, LLC (“Genius Wholesale Club,” “we,” or “us”) provides its website, Genius Wholesale Club, located at <a href="/">www.wholesalehealthclub.com</a> (together with all subdomains, other websites, mobile applications,
                            podcasts, and services operated on behalf of Genius Wholesale, LLC, referred to hereafter as the “Site”), to you, an individual user (“you”) for your individual usage, subject to your acceptance of, and compliance with, the terms
                            and conditions set forth herein.
                        </p>

                        <h3 className="subTitile text-center">TABLE OF CONTENTS</h3>
                        <ul className="list-1 list-column">
                            <li>Agreement</li>
                            <li>Changes to these Terms</li>
                            <li>Privacy and Security Disclosure</li>
                            <li>Intellectual Property</li>
                            <li>User Conduct</li>
                            <li>Account Registration</li>
                            <li>Tiered Membership</li>
                            <li>Automatic Billing and Cancellation Policy</li>
                            <li>Refunds</li>
                            <li>Payment Methods</li>
                            <li>Social Media</li>
                            <li>Copyright Notice</li>
                            <li>Disclaimer of Warranties</li>
                            <li>Disclaimer of Liabilities</li>
                            <li>Dispute Resolution by Mandatory Binding Arbitration and Class Action Waiver</li>
                            <li>Health Related Information</li>
                            <li>No Resale of Products</li>
                            <li>Indemnification</li>
                            <li>Third-Party Websites and Links</li>
                            <li>Testimonials, Reviews, and Other Submissions</li>
                            <li>Electronic Communications</li>
                            <li>Assignment</li>
                            <li>No Waiver</li>
                            <li>No Agency Relationship</li>
                            <li>Severability</li>
                            <li>Termination</li>
                            <li>SMS</li>
                            <li>Entire Agreement</li>
                            <li>Questions or Additional Information</li>
                        </ul>

                        <h3 className="subTitile text-center">TERMS OF USE &amp; CONDITIONS OF SALE</h3>

                        <p className="titleTag"><strong>Agreement</strong></p>
                        <p>
                            By accessing or using this Site, or placing an order through the Site, you agree to be bound by our Terms of Use &amp; Conditions of Sale (“Terms”) and Genius Wholesale Club’s Privacy Policy, which is expressly incorporated into
                            these Terms. If you do not agree to these terms and conditions in their entirety, you are not authorized to use the Site in any manner or form whatsoever.
                        </p>
                        <p>
                            THIS AGREEMENT CONTAINS ARBITRATION AND CLASS ACTION WAIVER PROVISIONS THAT WAIVE YOUR RIGHT TO A COURT HEARING, RIGHT TO A JURY TRIAL AND RIGHT TO PARTICIPATE IN A CLASS ACTION. ARBITRATION IS MANDATORY AND IS THE EXCLUSIVE REMEDY
                            FOR ANY AND ALL DISPUTES UNLESS SPECIFIED BELOW IN SECTION 15 OR IF YOU OPT-OUT. PLEASE CAREFULLY REVIEW THE DISPUTE RESOLUTION PROVISIONS IN SECTION 15 BELOW WHICH ALSO DESCRIBES YOUR RIGHT TO OPT-OUT.
                        </p>
                        <p>
                            By using the Site and agreeing to these Terms, you represent that you are at least the age of majority in your state or province of residence. If you use the Site, you are affirming that you have the legal capacity to enter into a
                            binding contract with us, and have read this Agreement and understand and agree to its terms.
                        </p>

                        <p className="titleTag"><strong>Changes to these Terms</strong></p>
                        <p>
                            Genius Wholesale Club reserves the right to update, change, or replace any part of these Terms by posting updates and/or changes to our Site. You can review the most current version of these Terms at any time by visiting this page
                            <a href={BASE_URL+'term-and-condition'}>(https://wholesalehealthclub.com/terms/)</a>. It is your responsibility to check this page periodically for changes. Your continued use of, or access to, the Site
                            following the posting of any changes constitutes your binding acceptance of those changes. GENIUS WHOLESALE CLUB MAY CHANGE THESE TERMS, IN WHOLE OR IN PART, AT ANY TIME WITHOUT NOTICE, EVEN THOUGH CHANGES MAY AFFECT THE BENEFITS
                            AVAILABLE TO MEMBERS AND/OR THE VALUE OF THE BENEFITS ALREADY ACCUMULATED BY A MEMBER.
                        </p>

                        <p className="titleTag"><strong>Privacy and Security Disclosure</strong></p>
                        <p>
                            Genius Wholesale Club’s Privacy Policy may be viewed at <a href={BASE_URL+'privacy-policy'}>https://wholesalehealthclub.com/privacy/</a>. The Privacy Policy is hereby incorporated into these Terms by
                            reference and constitute a part of this Agreement. Genius Wholesale Club reserves the right to modify the Privacy Policy at its sole discretion.
                        </p>

                        <p className="titleTag"><strong>Intellectual Property</strong></p>
                        <p>
                            The Site and the content contained herein, as well as all copyrights, including without limitation, the text, documents, articles, products, software, graphics, photos, sounds, videos, interactive features, services, links, user
                            submissions (as defined in Section 18), third-party apps, and any other content on the Site (collectively, “Content”) and the trademarks, service marks and logos contained therein are the property of Genius Wholesale Club and its
                            third-party licensors or providers. You may access and use the Content, and download and/or print out copies of any content from the Site, solely for your personal, non-commercial use. If you download or print a copy of the Content
                            for personal use, you must retain all copyright and other proprietary notices contained therein. You acknowledge that you do not acquire any ownership rights by using the Site. Genius Wholesale Club reserves all rights not expressly
                            granted in and to the Site.
                        </p>
                        <p>The site may contain references to protected trademarks and service marks of other parties, but Genius Wholesale Club may not specifically identity them as such. Genius Wholesale Club does not claim ownership in any such marks.</p>

                        <p className="titleTag"><strong>User Conduct</strong></p>
                        <p>
                            You may not use, copy, display, sell, license, de-compile, republish, upload, post, transmit, distribute, create derivative works or otherwise exploit Content from the Site to online bulletin boards, message boards, newsgroups, chat
                            rooms, or in other any manner, without our prior written permission. Modification of the Content or use of the Content for any purpose other than your own personal, noncommercial use is a violation of our copyright and other
                            proprietary rights, and can subject you to legal liability.
                        </p>
                        <p>In addition, in connection with your use of the Site and its services, you agree not to:</p>

                        <ul className="listUnderline">
                            <li>Restrict or inhibit any other visitor from using the Site, including, without limitation, by means of “hacking” or defacing any portion of the Site;</li>
                            <li>Use the Site for any unlawful purpose;</li>
                            <li>Express or imply that any statements you make are endorsed by us, without our prior written consent;</li>
                            <li>Modify, adapt, sublicense, translate, sell, reverse engineer, decompile or disassemble any portion of the Site;</li>
                            <li>Disable, damage or alter the functioning or appearance of the Site, including the presentation of advertising;</li>
                            <li>“Frame” or “mirror” any part of the Site without our prior written authorization;</li>
                            <li>
                                Use any robot, spider, site search/retrieval application, or other manual or automatic device or process to download, retrieve, index, “data mine,” “scrape,” “harvest” or in any way reproduce or circumvent the navigational
                                structure or presentation of the Site or its contents;
                            </li>
                            <li>Harvest or collect information about visitors to the Site without their express consent;</li>
                            <li>Send unsolicited or unauthorized advertisements, spam, chain letters, etc. to other users of the Site; or</li>
                            <li>Transmit any Content which contains software viruses, or other harmful computer code, files or programs.</li>
                        </ul>

                        <p className="titleTag"><strong>Account Registration</strong></p>
                        <p>In order to access some of the services of the Site, you will be required to create an account. By creating this account you agree to the following:</p>
                        <ul className="listUnderline">
                            <li>You may only maintain a single account;</li>
                            <li>You may never share your account username or password or knowingly provide or authorize access to your account (including without limitation the premium subscription services provided by Genius Wholesale Club);</li>
                            <li>You may never use another user's account without permission;</li>
                            <li>When creating your account, you must provide accurate and complete information;</li>
                            <li>You are solely responsible for the activity that occurs on your account, and you must keep your account password secure;</li>
                            <li>You must notify us immediately of any breach of security or unauthorized use of your account; and</li>
                            <li>
                                You will be liable for any use made of your account or password and the losses of Genius Wholesale Club or others due to such unauthorized use. We will not be liable for your losses caused by any unauthorized use of your
                                account.
                            </li>
                        </ul>
                        <p>Genius Wholesale Club has the right, in its sole discretion, to cancel your subscription or suspend your access to the Site.</p>

                        <p className="titleTag"><strong>Tiered Membership</strong></p>
                        <p>
                            Genius Wholesale Club offers a number of tiered memberships for our users. To subscribe to any membership tier, you will be required to provide your name, email address, billing address, and credit card information. By subscribing
                            to a membership, you agree to pay the applicable subscription fee associated with that tier. Sales taxes and shipping may be charged in addition to the subscription fee and offerings listed below.
                        </p>
                        <p>
                            <em><strong>Basic Membership Level Benefits:</strong> </em>
                        </p>
                        <ul>
                            <li>Purchase unlimited supplements at $15 per bottle.</li>
                            <li>Purchase unlimited skincare products at $20 per item.</li>
                            <li>Purchase unlimited powder products at $25 per item.</li>
                            <li>
                                The Basic membership will be automatically charged $5.89 every month thereafter for a period of 30 days from the date of purchase. You can cancel anytime by calling <a href="tel:(480) 573-0829">(480) 573-0829</a> or by emailing <a href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a>.
                            </li>
                        </ul>

                        <p>
                            <em><strong>Silver Membership Level Benefits:</strong> </em>
                        </p>
                        <ul>
                            <li>Purchase unlimited supplements at $15 per bottle.</li>
                            <li>Purchase unlimited skincare products at $20 per item.</li>
                            <li>Purchase unlimited powder products at $25 per item.</li>
                            <li>
                                The Silver membership will be automatically charged $10.00 every month thereafter for a period of 30 days from the date of purchase. You can cancel anytime by calling <a href="tel:(480) 573-0829">(480) 573-0829</a> or by emailing&nbsp; 
                                <a href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a>.
                            </li>
                        </ul>

                        <p>
                            <em><strong>Gold Membership Level Benefits:</strong> </em>
                        </p>
                        <ul>
                            <li>All Silver Membership Level Benefits.</li>
                            <li>Purchase unlimited supplements at $15 per bottle.</li>
                            <li>Purchase unlimited skincare products at $20 per item.</li>
                            <li>Purchase unlimited powder products at $25 per item.</li>
                            <li>Bonus item: Better Health Overnight Newsletter.</li>
                            <li>Bonus item: The Celebrity Diet Cookbook.</li>
                            <li>Bonus item: Look Younger, Feel Younger Guide.</li>
                            <li>Bonus offer: you will receive 5 free months of membership when 5 Successful Referrals sign up using your referral code.</li>
                            <li>
                                The Gold membership will be automatically charged $17.00 every month thereafter for a period of 30 days from the date of purchase. You can cancel anytime by calling <a href="tel:(480) 573-0829">(480) 573-0829</a> or by emailing <a href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a>.
                            </li>

                        </ul>

                        <p>
                            <em><strong>Sapphire Membership Level Benefits:</strong></em>
                        </p>
                        <ul>
                            <li>All Silver Membership Level Benefits.</li>
                            <li>All Gold Membership Level Benefits.</li>
                            <li>Purchase unlimited supplements at $15 per bottle.</li>
                            <li>Purchase unlimited skin care products at $20 per item.</li>
                            <li>Purchase unlimited powder products at $25 per item.</li>
                            <li>Bonus item: Better Health Overnight Newsletter.</li>
                            <li>Bonus item: The Celebrity Diet Cookbook.</li>
                            <li>Bonus item: Look Younger, Feel Younger Guide.</li>
                            <li>Bonus offer: you will receive 1 free month of membership with each Successful Referral sign-up using your referral code.</li>
                            <li>Bonus offer: for every 5 bottles purchased, get 1 free.</li>
                            <li>
                                The Sapphire membership will be automatically charged $19.83 every month thereafter for a period of 30 days from date of purchase. You can cancel anytime by calling <a href="tel:(480) 573-0829">(480) 573-0829</a> or by emailing <a href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a>.
                            </li>
                        </ul>

                        <p>
                            <em><strong>Platinum Membership Level Benefits:</strong></em>
                        </p>
                        <ul>
                            <li>All Silver Membership Level Benefits.</li>
                            <li>All Gold Membership Level Benefits.</li>
                            <li>Purchase unlimited supplements at $15 per bottle.</li>
                            <li>Purchase unlimited skin care products at $20 per item.</li>
                            <li>Purchase unlimited powder products at $25 per item.</li>
                            <li>Bonus item: Better Health Overnight Newsletter.</li>
                            <li>Bonus item: The Celebrity Diet Cookbook.</li>
                            <li>Bonus item: Look Younger, Feel Younger Guide.</li>
                            <li>Bonus offer: you will receive 1 free month of membership with each Successful Referral sign-up using your referral code.</li>
                            <li>Bonus offer: for every 5 bottles purchased, get 1 free.</li>
                            <li>
                                The Platinum membership will be automatically charged $21.00 every month thereafter for a period of 30 days from date of purchase. You can cancel anytime by calling <a href="tel:(480) 573-0829">(480) 573-0829</a> or by emailing <a href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a>.
                            </li>
                        </ul>

                        <p>
                            A “Successful Referral” occurs when an individual signs up to any membership tier and remains a member for at least one month. Referrals are non-transferable. Any failure by you to comply with these Terms may subject you to
                            forfeiture of the Bonus Offer. There will be no partial redemptions of the Bonus Offer and any accrued period of free monthly membership as a result of Successful Referrals will be applied beginning on the date of such accrual and
                            continuing over consecutive months.
                        </p>

                        <p className="titleTag"><strong>Automatic Billing and Cancellation Policy</strong></p>
                        <p className="text-justify">
                            Some of Genius Wholesale Club’s Premium Services are provided on a subscription basis. Your subscription will automatically renew at the then-current rate for the subscribed service, until you notify us of your decision to terminate
                            your subscription. The majority of subscriptions available through the Site renew on a monthly basis, and the pricing and benefits provided through the subscription may be substantially different from those provided in the initial
                            offer. It is your responsibility to be aware of the details of the subscription, information about which will be available through the Site
                        </p>
                        <p className="text-justify">
                            By placing an order and subscribing, you have provided your electronic authorization for future charges against the card that you have on file. You further agree that your subscription fee(s) will be billed automatically at the
                            beginning of each renewal period at the then-current rate (plus sale tax, if applicable) to the card(s) you have on file. If you have more than one card on file, the first card will be used. If you have more than one card on file,
                            and one or more cards have expired, we will use the unexpired card.
                        </p>
                        <p className="text-justify">
                            <strong>
                                You may cancel your subscription(s) at any time prior to the renewal term. To cancel your membership, please send an email to <a href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a> or call customer service at&nbsp;
                                <a href="tel:(480)573-0829">(480)573-0829</a>.
                            </strong>
                        </p>

                        <p className="titleTag"><strong>Refunds</strong></p>
                        <p>
                            Except where specified, all products sold through the Wholesale Club website include a 30-days refund policy, allowing you to claim a complete refund for the product’s price for 30 days after initial purchase. If you are dissatisfied
                            with your purchase for any reason, contact our Customer Service team for instructions on how to claim your refund.
                        </p>

                        <p className="titleTag"><strong>Payment Methods</strong></p>
                        <p>All charges are in U.S. Dollars. We accept U.S. issued credit and debit cards from Visa, MasterCard, American Express, and Discover.</p>
                        <p>When placing an order online, you will need:</p>

                        <ul>
                            <li>The address the card’s statement is sent to (billing address);</li>
                            <li>The card number and expiration date; and</li>
                            <li>The 3 or 4 digit code found only on the card (CVV2 code).</li>
                        </ul>

                        <p>
                            By submitting credit card information or other payment information to us, you represent and agree that: (i) you are fully entitled to use that card or account; (ii) if you choose a subscription-based purchase, that you will pay all
                            payments for your subscriptions by the date due; (iii) all payment information provided is complete and accurate; (iv) you will be responsible for any credit card fees; and (v) that sufficient funds exist to pay us the amount(s)
                            due.
                        </p>
                        <p>
                            We and our third party payment service providers may request, and we may receive, updated credit card information from your credit card issuer, such as updated card numbers and expiration date information when your credit card has
                            expired. If such updated information is provided to us and our third-party payment service providers, we will update your account information accordingly, and will use such updated information to process payments for your
                            subscription(s) if you signed up for subscription(s). Your credit card issuer may give you the right to opt out of providing vendors and third-party payment service providers with your updated credit card information. If you wish to
                            opt out of your credit card’s updating service, you should contact your credit card issuer.
                        </p>
                        <p>
                            We are not responsible for any fees or charges that your bank or credit card issuer may apply. If your bank or credit card issuer reverses a charge to your credit card, we may bill you directly and seek payment by another method
                            including a mailed statement.
                        </p>

                        <p className="titleTag"><strong>Social Media</strong></p>
                        <p className="text-justify">
                            This section applies to everyone who interacts with our social media presence, including comment sections, feeds, and other elements of social media presence viewable on Facebook, Instagram, Twitter, YouTube, Google+, LinkedIn, or
                            any of the many other available external third party social media platforms we may utilize (“Social Media Presence”).
                        </p>
                        <p className="text-justify">
                            Social media platforms are places of public information exchange, and you should have no expectation of privacy when using them. Specifically, neither these Terms nor our Privacy Statement apply to our Social Media Presence. The
                            sites and platforms that host our Social Media Presence are not controlled by us and therefore have their own privacy policies and terms of use. The comments and opinions expressed by users on social media are theirs alone and do
                            not reflect the opinions of Genius Wholesale Club. Comments that some would consider inappropriate or offensive may appear on our Social Media Presence and may remain there until they have been identified by us or called to our
                            attention and we are able to work through the necessary procedures and technical processes to have them removed. If you see an offensive or inappropriate post or comment on our Social Media Presence, you should report it to the
                            operator of the applicable site or platform using the procedures they have established for that purpose.
                        </p>

                        <p className="titleTag"><strong>Copyright Notice</strong></p>
                        <p className="text-justify">
                            The Site maintains specific contact information provided below, including an e-mail address, for notifications of claimed infringement regarding materials posted to this Website. All notices should be addressed to the contact
                            specified below (our agent for notice of claimed infringement):
                        </p>
                        <p className="text-justify">Notification of Claimed Infringement:</p>
                        <address>
                            Genius Wholesale, LLC<br />
                            Attn: Copyright Agent<br />
                            PO BOX 90129 <br />
                            Lakeland FL 33804 <br />
                            Email Address: <a href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a><br />
                            Telephone: <a href="tel:(480)573-0829">(480)573-0829</a> <br />
                        </address>
                        <p className="text-justify">
                            You may contact our agent for notice of claimed infringement specified above with complaints regarding allegedly infringing posted material and we will investigate those complaints. If the posted material is believed in good faith
                            by us to violate any applicable law, we will remove or disable access to any such material, and we will notify the posting party that the material has been blocked or removed.
                        </p>
                        <p className="text-justify">
                            In notifying us of alleged copyright infringement, the Digital Millennium Copyright Act requires that you include the following information: (i) description of the copyrighted work that is the subject of claimed infringement; (ii)
                            description of the infringing material and information sufficient to permit us to locate the alleged material; (iii) contact information for you, including your address, telephone number and/or e-mail address; (iv) a statement by
                            you that you have a good faith belief that the material in the manner complained of is not authorized by the copyright owner, or its agent, or by the operation of any law; (v) a statement by you, signed under penalty of perjury,
                            that the information in the notification is accurate and that you have the authority to enforce the copyrights that are claimed to be infringed; and (vi) a physical or electronic signature of the copyright owner or a person
                            authorized to act on the copyright owner’s behalf. Failure to include all of the above-listed information may result in the delay of the processing of your complaint.
                        </p>

                        <p className="titleTag"><strong>DISCLAIMER OF WARRANTIES</strong></p>
                        <p className="text-justify">
                            EXCEPT WHERE OTHERWISE INAPPLICABLE OR PROHIBITED BY LAW, THE WEBSITE AND ALL CONTENT ARE PROVIDED ON AN “AS IS”, “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO
                            WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. WE MAKE NO, AND EXPRESSLY DISCLAIM ANY AND ALL, REPRESENTATIONS AND WARRANTIES AS TO THE RELIABILITY, TIMELINESS, QUALITY,
                            SUITABILITY, AVAILABILITY, ACCURACY AND/OR COMPLETENESS OF ANY INFORMATION ON THIS WEBSITE. WE DO NOT REPRESENT OR WARRANT, AND EXPRESSLY DISCLAIM THAT: (A) THE USE OF THE WEBSITE WILL BE SECURE, TIMELY, UNINTERRUPTED OR ERROR-FREE,
                            OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM OR DATA, (B) THE WEBSITE OR OUR PRODUCTS WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, (C) ANY STORED DATA WILL BE ACCURATE OR RELIABLE, (D) THE QUALITY OF ANY
                            PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE WEBSITE WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, (E) ERRORS OR DEFECTS IN THE WEBSITE WILL BE CORRECTED, OR (F) THE WEBSITE OR THE
                            SERVER(S) THAT MAKE THE WEBSITE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED
                            WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT OF THIRD PARTY RIGHTS, ARE HEREBY DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
                        </p>

                        <p className="titleTag"><strong>DISCLAIMER OF LIABILITIES</strong></p>
                        <p className="text-justify">
                            EXCEPT WHERE OTHERWISE INAPPLICABLE OR PROHIBITED BY LAW, IN NO EVENT SHALL GENIUS WHOLESALE, LLC OR ANY OF ITS OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, INDEPENDENT CONTRACTORS, TELECOMMUNICATIONS PROVIDERS, AND/OR AGENTS BE
                            LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY, CONSEQUENTIAL, PUNITIVE, OR ANY OTHER DAMAGES, FEES, COSTS OR CLAIMS ARISING FROM OR RELATED TO THIS AGREEMENT, THE PRIVACY POLICY, THE PRODUCTS, OR YOUR OR A THIRD PARTY’S
                            USE OR ATTEMPTED USE OF THE WEBSITE OR ANY PRODUCT, REGARDLESS OF WHETHER GENIUS WHOLESALE, LLC HAS HAD NOTICE OF THE POSSIBILITY OF SUCH DAMAGES, FEES, COSTS, OR CLAIMS. THIS INCLUDES, WITHOUT LIMITATION, ANY LOSS OF USE, LOSS OF
                            PROFITS, LOSS OF DATA, LOSS OF GOODWILL, COST OF PROCUREMENT OF SUBSTITUTE SERVICES OR PRODUCTS, OR ANY OTHER INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, CONSEQUENTIAL, OR OTHER DAMAGES. THIS APPLIES REGARDLESS OF THE MANNER IN WHICH
                            DAMAGES ARE ALLEGEDLY CAUSED, AND ON ANY THEORY OF LIABILITY, WHETHER FOR BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY), WARRANTY, OR OTHERWISE. IF, NOTWITHSTANDING THE LIMITATIONS OF LIABILITY SET FORTH
                            ABOVE, GENIUS WHOLESALE, LLC IS FOUND LIABLE UNDER ANY THEORY, GENIUS WHOLESALE, LLC’S LIABILITY AND YOUR EXCLUSIVE REMEDY WILL BE LIMITED TO USD $500.00. THIS LIMITATION OF LIABILITY SHALL APPLY FOR ALL CLAIMS, REGARDLESS OF
                            WHETHER GENIUS WHOLESALE, LLC WAS AWARE OF OR ADVISED IN ADVANCE OF THE POSSIBILITY OF DAMAGES OR SUCH CLAIMS. SOME STATES DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU AND
                            YOU MAY HAVE ADDITIONAL RIGHTS.
                        </p>

                        <p className="titleTag"><strong>DISPUTE RESOLUTION BY MANDATORY BINDING ARBITRATION AND CLASS ACTION WAIVER</strong></p>
                        <p className="text-justify">PLEASE READ THIS ARBITRATION AND CLASS ACTION WAIVER PROVISION CAREFULLY. IT REQUIRES YOU TO ARBITRATE DISPUTES WITH GENIUS WHOLESALE AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM US.</p>
                        <p className="text-justify">
                            EXCEPT WHERE PROHIBITED BY LAW, YOU AGREE THAT ANY CLAIM THAT YOU MAY HAVE IN THE FUTURE MUST BE RESOLVED THROUGH FINAL AND BINDING CONFIDENTIAL ARBITRATION. YOU ACKNOWLEDGE AND AGREE THAT YOU ARE WAIVING THE RIGHT TO A TRIAL BY
                            JURY. THE RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT, SUCH AS DISCOVERY OR THE RIGHT TO APPEAL, MAY BE MORE LIMITED OR MAY NOT EXIST. YOU AGREE THAT YOU MAY ONLY BRING A CLAIM IN YOUR INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF
                            (LEAD OR OTHERWISE) OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. YOU FURTHER AGREE THAT THE ARBITRATOR MAY NOT CONSOLIDATE PROCEEDINGS OR CLAIMS OR OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS
                            PROCEEDING.
                        </p>
                        <p className="text-justify">
                            THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED. HOWEVER, AN ARBITRATOR CAN AWARD ON AN INDIVIDUAL BASIS THE SAME DAMAGES AND RELIEF AS A COURT (INCLUDING INJUNCTIVE AND DECLARATORY
                            RELIEF OR STATUTORY DAMAGES), AND MUST FOLLOW THE TERMS OF THESE CONDITIONS OF USE AS A COURT WOULD.
                        </p>

                        <p className="titleTag"><strong>General</strong></p>
                        <p className="text-justify">
                            Arbitration is a manner of resolving a “Claim” without filing a lawsuit. “Claim” means any dispute between you, Genius Wholesale Club, or any involved third-party relating to your account, your use of the Website, your relationship
                            with Genius Wholesale Club, these Terms, or the Privacy Policy. This includes any and all claims that relate in any way to your use or attempted use of the products, and any act or omission by Genius Wholesale Club or any
                            third-party related to your use or attempted use of the products. You, Genius Wholesale Club, or any involved third-party may pursue a Claim. Genius Wholesale Club agrees to final and binding confidential arbitration should it have
                            any Claims against you. Likewise, you agree to final and binding confidential arbitration should you have any Claims against Genius Wholesale Club. By agreeing to arbitrate, you waive the right to go to court and agree instead to
                            submit any Claims to final and binding confidential arbitration. You further agree that all claims must be arbitrated on an individual basis and not on a class basis, only individual relief is available, and that claims of more than
                            one customer cannot be arbitrated or consolidated with those of any other customer. This arbitration provision sets forth the terms and conditions of our agreement to final and binding confidential arbitration and is governed by and
                            enforceable under the Federal Arbitration Act (the “FAA”), 9 U.S.C. §§ 1-16, as amended.
                        </p>
                        <p className="text-justify">
                            Notwithstanding anything to the contrary herein, (a) a representative action for public injunctive relief pursuant to California’s Consumer Legal Remedies Act (Cal. Civ. Code § 1750 et seq.), Unfair Competition Law (Cal. Bus. &amp;
                            Prof. Code § 17200 et seq.) and/or False Advertising Law (Cal. Bus. &amp; Prof. Code § 17500 et seq.) must be arbitrated on a class basis, (b) in the event that the foregoing clause is deemed invalid or unenforceable, a
                            representative action for public injunctive relief pursuant to California’s Consumer Legal Remedies Act (Cal. Civ. Code § 1750 et seq.), Unfair Competition Law (Cal. Bus. &amp; Prof. Code § 17200 et seq.) and/or False Advertising
                            Law (Cal. Bus. &amp; Prof. Code § 17500 et seq.) may be brought in the state or federal courts located in Arizona on a class basis, and (c) any claims other than for public injunctive relief must be arbitrated on an individual,
                            non-class basis as otherwise set forth in this Section.
                        </p>

                        <p className="titleTag"><strong>Exceptions</strong></p>
                        <p className="text-justify">
                            Notwithstanding the foregoing, and as an exception to final and binding confidential arbitration, you and Genius Wholesale Club both retain the right to pursue, in small claims court, any claim that is within that court’s
                            jurisdiction and proceeds on an individual (non-class) basis, including overdue account matters within the small claims court’s jurisdiction. Genius Wholesale Club will not demand arbitration in connection with any individual claim
                            that you properly file and pursue in a small claims court, so long as the claim is and remains pending in that court. The following claims shall not be subject to final and binding arbitration and must be adjudicated only in the
                            state or federal courts located in Arizona: (i) an action by Genius Wholesale Club relating to the infringement or validity of our proprietary rights, including without limitation, trademarks, service marks, trade dress, copyrights,
                            trade secrets, or patents; or (ii) an action by Genius Wholesale Club for temporary, preliminary, or permanent injunctive relief, whether prohibitive or mandatory, or other provisional relief, against you for breach or threatened
                            breach of this Agreement. You expressly agree to refrain from bringing or joining any claims that are excluded from final and binding arbitration pursuant to this subsection “b” in any representative or class-wide capacity,
                            including but not limited to bringing or joining any claims in any class action or any class-wide arbitration. Small claims matters may be filed in any small claims court with personal and subject matter jurisdiction over the
                            parties. For all other matters excluded from final and binding arbitration by this subsection “b,” the parties consent to exclusive jurisdiction and venue in the state and federal courts located in Arizona, and forever waive any
                            challenge to said courts’ jurisdiction and venue.
                        </p>

                        <p className="titleTag"><strong>Required Pre-Dispute Procedures</strong></p>
                        <p className="text-justify">
                            We acknowledge and agree that before initiating any Claim against the other, we agree to first contact the other with a written description of the dispute, which shall include all relevant documents and information, and the proposed
                            resolution. You may send the written description of any dispute you have with us by U.S. Mail to Genius Wholesale, LLC, Attn: Legal Department, 20638 N 16th Way, Phoenix, AZ 85024. Genius Wholesale Club will contact you by letter at
                            the billing address you provided to us or at the email address you provided to us. You agree to negotiate with Genius Wholesale Club or its designated representative in good faith about your problem or dispute. If for some reason
                            the dispute is not resolved within 60 days after receipt of the written dispute, we agree to the dispute resolution provisions below. Notwithstanding the foregoing or any other term of this arbitration agreement, we will have the
                            right to seek injunctive or other equitable relief in state or federal court located in Arizona to enforce these Terms or prevent an infringement of a third party’s rights or our intellectual property rights, as stated in subsection
                            “b” above. You hereby expressly consent to, and forever waive any challenge to, the exclusive personal jurisdiction and venue of said courts in such actions.
                        </p>

                        <p className="titleTag"><strong>Commencing Arbitration</strong></p>
                        <p className="text-justify">
                            You and Genius Wholesale Club agree to commence any arbitration proceeding within 1 year after the Claim arises (the 1 year period includes the required pre-dispute procedures set forth above) and that any arbitration proceeding
                            commenced after 1 year shall be forever barred.
                        </p>

                        <p className="titleTag"><strong>Arbitration Location</strong></p>
                        <p className="text-justify">
                            If the amount in controversy is $500 or less, then the arbitration may be conducted by telephone or by written submissions. Otherwise, the arbitration shall be conducted in Arizona unless Genius Wholesale Club otherwise agrees to
                            arbitrate in another forum requested by you.
                        </p>

                        <p className="titleTag"><strong>Organization, Rules and the Arbitrator</strong></p>
                        <p className="text-justify">
                            We each agree that any and all Claims other than those exempted under subsection “b” above shall be submitted to final and binding confidential arbitration before a single arbitrator of the American Arbitration Association (“AAA”).
                            Either party may commence the arbitration process by submitting a written demand for arbitration with the AAA, and providing a copy to the other party, within the time period set forth in subsection “d” above. The arbitrator shall
                            be selected by agreement of the parties or, if the parties cannot agree, chosen in accordance with Rules of the AAA. The arbitration will be conducted in accordance with the provisions of the AAA’s Commercial Dispute Resolutions
                            Procedures, Supplementary Procedures for Consumer-Related Disputes, in effect at the time of submission of the demand for arbitration. The AAA’S Rules are available at www.adr.org or by calling 1-800-778-7879. The arbitrator shall
                            have the exclusive and sole authority to resolve any dispute relating to the interpretation, construction, validity, applicability, or enforceability of these Terms, the Privacy Policy, and this arbitration provision. The arbitrator
                            shall have the exclusive and sole authority to determine whether any dispute is arbitrable. The arbitrator shall have the exclusive and sole authority to determine whether this arbitration agreement can be enforced against a
                            non-signatory to this agreement and whether a non-signatory to this agreement can enforce this provision against you or Genius Wholesale, LLC
                        </p>

                        <p className="titleTag"><strong> Fees</strong></p>
                        <p className="text-justify">Payment of all filing, administration and arbitrator fees will be governed by the AAA’s Rules.</p>

                        <p className="titleTag"><strong>Governing Law and Award</strong></p>
                        <p className="text-justify">
                            The arbitrator shall follow the substantive law of the State of Arizona without regard to its conflicts of laws principles. Any award rendered shall include a confidential written opinion and shall be final, subject to appeal under
                            the FAA. Judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.
                        </p>

                        <p className="titleTag"><strong>Enforceability</strong></p>
                        <p className="text-justify">
                            This provision survives termination of your account or relationship with Genius Wholesale Club, bankruptcy, assignment, or transfer. If the class action waiver is deemed unenforceable (i.e., unenforceability would allow arbitration
                            to proceed as a class or representative action), then this entire arbitration provision shall be rendered null and void and shall not apply. If a portion of this arbitration provision (other than the class action waiver) is deemed
                            unenforceable, the remaining portions of this arbitration provision shall remain in full force and effect.
                        </p>

                        <p className="titleTag"><strong>Miscellaneous</strong></p>
                        <p className="text-justify">
                            Failure or any delay in enforcing this arbitration provision in connection with any particular Claim will not constitute a waiver of any rights to require arbitration at a later time or in connection with any other Claims except all
                            Claims must be brought within the 1 year limitation period set forth above. This provision is the entire arbitration agreement between you and Genius Wholesale Club and shall not be modified except in writing by Genius Wholesale
                            Club.
                        </p>

                        <p className="titleTag"><strong>Amendments</strong></p>
                        <p className="text-justify">
                            Genius Wholesale Club reserves the right to amend this arbitration provision at any time. Your continued use of the Website, purchase of a product on or through the Website, or use or attempted use of a Genius Wholesale Club
                            product, is affirmation of your consent to such changes. Should the changes to this arbitration provision be material, Genius Wholesale Club will provide you notice and an opportunity to opt-out. Your continued use of the Website,
                            purchase of a product on or through the Website, or use or attempted use of a Genius Wholesale Club product or service, is affirmation of your consent to such material changes.
                        </p>
                        <p className="text-justify">
                            YOU UNDERSTAND AND AGREE TO HAVE ANY CLAIMS DECIDED INDIVIDUALLY AND ONLY THROUGH BINDING, FINAL, AND CONFIDENTIAL ARBITRATION. YOU HAVE THE RIGHT TO OPT-OUT OF THIS ARBITRATION PROVISION WITHIN THIRTY (30) DAYS FROM THE DATE THAT
                            YOU PURCHASE, USE, OR ATTEMPT TO USE A PRODUCT PURCHASED ON OR THROUGH THE WEBSITE (WHICHEVER COMES FIRST) BY WRITING TO US AT GENIUS WHOLESALE, LLC, ATTN: LEGAL DEPARTMENT, 20638 N 16TH WAY, PHOENIX, AZ 85024. FOR YOUR OPT-OUT TO
                            BE EFFECTIVE, YOU MUST SUBMIT A SIGNED WRITTEN NOTICE IDENTIFYING ANY PRODUCT YOU PURCHASED, USED OR ATTEMPTED TO USE WITHIN THE 30 DAYS AND THE DATE YOU FIRST PURCHASED, USED OR ATTEMPTED TO USE THE PRODUCT. IF MORE THAN THIRTY
                            (30) DAYS HAVE PASSED, YOU ARE NOT ELIGIBLE TO OPT OUT OF THIS PROVISION AND YOU MUST PURSUE YOUR CLAIM THROUGH BINDING ARBITRATION AS SET FORTH IN THIS AGREEMENT. SHOULD EITHER PARTY FILE AN ACTION CONTRARY TO THIS PROVISION, THE
                            OTHER PARTY MAY RECOVER ATTORNEY’S FEES AND COSTS UP TO $1000.00.
                        </p>

                        <p className="titleTag"><strong>Health Related Information</strong></p>
                        <p className="text-justify">
                            The information contained in the Site is provided for informational purposes only and is not meant to substitute for the advice provided by your doctor or other health care professional. You should not use the information available
                            on or through the Site (including, but not limited to, information that may be provided on the Site by healthcare or nutrition professionals employed by or contracting with Genius Wholesale Club) for diagnosing or treating a health
                            problem or disease, or prescribing any medication. Information and statements regarding dietary supplements have not been evaluated by the Food and Drug Administration and are not intended to diagnose, treat, cure, or prevent any
                            disease. You should read carefully all product packaging prior to use.
                        </p>

                        <p className="titleTag"><strong>No Resale of Products</strong></p>
                        <p className="text-justify">
                            Products purchased or obtained through the Site are for Members’ personal use only. Members are prohibited from reselling any product acquired through the Site. Should a Member resell any product purchased or otherwise obtained
                            through the Site, Genius Wholesale Club reserves its rights to take any and all actions it deems necessary to address such violation, including, but not limited to, terminating the Member’s Membership and forfeiture of any accrued
                            benefit belonging to the Member.
                        </p>

                        <p className="titleTag"><strong>Indemnification</strong></p>
                        <p className="text-justify">
                            To the fullest extent permitted by law, you agree to indemnify, defend, and hold harmless Genius Wholesale Club, its affiliates (including without limitation all affiliated professional entities), subsidiaries, and their directors,
                            officers, employees, contractors, licensors, suppliers, representatives, proprietors, partners, shareholders, servants, principals, agents, predecessors, successors, assigns, accountants, and attorneys from and against any claims,
                            actions, losses, liabilities, damages, expenses, demands, and costs of any kind, including, but not limited to, reasonable attorneys’ fees, arising out of, resulting from, or in any way connected with or related to (1) your breach
                            of these Terms, the documents they incorporate by reference, or the Agreement; (2) your breach of any representations or warranties in this Agreement; or (3) your violation of any law or the rights of a third-party.
                        </p>

                        <p className="titleTag"><strong>Third-Party Websites and Links</strong></p>
                        <p className="text-justify">
                            Our Site may include materials from third-parties or links to third-party websites. Genius Wholesale Club is not liable for any third-party materials or websites. Please review carefully the third-party’s policies and practices and
                            make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.
                        </p>

                        <p className="titleTag"><strong>Testimonials, Reviews, and Other Submissions</strong></p>
                        <p className="text-justify">
                            Genius Wholesale Club may use testimonials and/or product/service reviews, in whole or in part, together with the name and state/country of the person submitting it. Testimonials may be used for any form of advertising relating to
                            Genius Wholesale Club’s products or services, in printed and online media, as Genius Wholesale Club determines in its absolute discretion. Testimonials represent the unique experience of the customers submitting the testimonial, and
                            do not necessarily reflect the experience that you may have using our products or services.
                        </p>
                        <p className="text-justify">
                            Anything that you submit or post to the Website and/or provide us, including without limitation, photographs, testimonials, ideas, know-how, techniques, questions, reviews, comments, and suggestions (collectively, “User Submissions”
                            or simply, “Submissions”) is and will be treated as non-confidential and nonproprietary, and we shall have the royalty-free, worldwide, perpetual, irrevocable and transferable right to use, copy, distribute, display, publish,
                            perform, sell, lease, transmit, adapt, and create derivative works from such Submissions by any means and in any form, and to translate, modify, reverse-engineer, disassemble, or decompile such Submissions. You represent and warrant
                            that you are the owner or have sufficient rights to share the Submissions with us. All Submissions shall automatically become our sole and exclusive property and shall not be returned to you.
                        </p>
                        <p className="text-justify">
                            Additionally, Genius Wholesale Club reserves the right to correct grammatical and typing errors, to shorten testimonials prior to publication or use, and to review all testimonials prior to publication or use. Genius Wholesale Club
                            shall be under no obligation to use any, or any part of, any testimonial or product/service review submitted. If you submit a testimonial, you are confirming that you have read, understood and agree to these Terms. If you disagree
                            with any part of these Terms, do not submit a testimonial.
                        </p>

                        <p className="titleTag"><strong>Electronic Communications </strong></p>
                        <p className="text-justify">
                            You agree that we may communicate electronically with you and that such communications, as well as notices, disclosures, agreements, and other communications that we provide to you electronically, are equivalent to communications in
                            writing and shall have the same force and effect as if they were in writing and signed by the party sending the communication.
                        </p>

                        <p className="titleTag"><strong>Assignment </strong></p>
                        <p className="text-justify">
                            You may not assign any of your rights under these Terms, and any such attempt will be null and void. Genius Wholesale Club and its affiliates may, in their individual discretion, transfer, without further consent or notification,
                            all contractual rights and obligations pursuant to these Terms if some or all of Genius Wholesale Club’s business is transferred to another entity by way of merger, sale of its assets or otherwise.
                        </p>

                        <p className="titleTag"><strong>No Waiver </strong></p>
                        <p className="text-justify">
                            No waiver by Genius Wholesale Club of any term or condition set forth in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure by Genius
                            Wholesale Club to assert a right or provision under these Terms shall not constitute a waiver of such right or provision.
                        </p>

                        <p className="titleTag"><strong>No Agency Relationship </strong></p>
                        <p className="text-justify">
                            No joint venture, partnership, employment, or agency relationship exists between you and Genius Wholesale Club as a result of your receipt of any Genius Wholesale Club product, your use of any Genius Wholesale Club Service, or use
                            of the Site.
                        </p>

                        <p className="titleTag"><strong>Severability </strong></p>
                        <p className="text-justify">
                            In the event that any provision of these Terms is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion
                            shall be deemed to be severed from these Terms. Such determination shall not affect the validity and enforceability of any other remaining provisions.
                        </p>

                        <p className="titleTag"><strong>Termination</strong></p>
                        <p className="text-justify">In the event that we terminate this Agreement, Sections 1-14 and 16-28, as well as any representations, warranties, and other obligations made or taken by you, shall survive the termination of this Agreement.</p>

                        <p className="titleTag"><strong>SMS</strong></p>
                        <p className="titleTag"><u>Wholesale Health Club Terms Of Service</u></p>
                        <p>1. You are opting in to receive messages for 2FA as well as promotional material.</p>
                        <p>2. You can cancel the SMS service at any time. Just text "STOP" to the short code. After you send the SMS message "STOP" to us, we will send you an SMS message to confirm that you have been unsubscribed. After this, you will no longer receive SMS messages from us. If you want to join again, just sign up as you did the first time and we will start sending SMS messages to you again.</p>
                        <p>3. If you are experiencing issues with the messaging program you can reply with the keyword HELP for more assistance, or you can get help directly at <a href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a> or <a href="tel:(480)573-0829">(480)573-0829</a>.</p>
                        <p>4. Carriers are not liable for delayed or undelivered messages.</p>
                        <p>5. As always, message and data rates may apply for any messages sent to you from us and to us from you. You will receive only requested messages. If you have any questions about your text plan or data plan, it is best to contact your wireless provider.</p>
                        <p>6. If you have any questions regarding privacy, please read our privacy policy:
                            <Link to="#" onClick={(event) => {
                                    event.preventDefault();
                                    window.open('/privacy-policy', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                }}>Privacy Policy
                            </Link>
                        </p>
                        
                        <p className="titleTag"><strong>Entire Agreement </strong></p>
                        <p className="text-justify">
                            These Terms, the Agreement, and any policies or operating rules posted by us on the Website or in respect to the Website constitutes the entire agreement and understanding between you and Genius Wholesale Club, and supersedes and
                            replaces any prior or contemporaneous agreements. Any ambiguities in the interpretation of these Terms or the Agreement shall not be construed against the drafting party.
                        </p>

                        <p className="titleTag"><strong>Questions or Additional Information</strong></p>
                        <p className="text-justify">If you have any questions regarding your account, subscription services, or these Terms, please email <a href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a> for assistance.</p>
                    </div>

                </div>
            </div>
        </>
    )
}

export default TermAndCond