import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import axios from 'axios';
import Swal from 'sweetalert2';
import ShopSidebar from "../../layouts/frontend/ShopSidebar";
import { useRef } from 'react';
import { CartState } from '../../context/Context';
import useStore from '../../store';
// Images 
import sappireBanner from '../../assests/new/frontend/images/sapphire-banner.jpg';
import lifeNutBanner from '../../assests/new/frontend/images/total-life-nutrition.jpg';
import trueBodySupBanner from '../../assests/new/frontend/images/trubody-supplements.jpg';
import nutriSupBanner from '../../assests/new/frontend/images/nutritional-supplements.jpg';
import healthyLiveBanner from '../../assests/new/frontend/images/healthy-living-products.jpg';
import blueForrestBanner from '../../assests/new/frontend/images/blue-forrest-beauty.jpg';
import emmaBeanBanner from '../../assests/new/frontend/images/emma-bean.jpg';
import homeIcon from '../../assests/new/frontend/images/home-icon.png';
import {BASE_URL} from '../Config';


function ShopPage2() {
    const navigate = useNavigate();
    const [isNavVisible, setNavVisibility] = useState(false);
    const [sidebarData, setSidebarData] = useState([]);
    const ref = useRef(null);
    const { state: { cart }, dispatch } = CartState();
    const isLogin = useStore((state) => state.isLogin);
    const isMember = useStore((state) => state.isMember);
    const [is_membership, setIsMembership] = useState('');
    let imgURL = BASE_URL+"whc-custom/storage/app/public/";
    let URL = window.location.href;
    let URLparam = decodeURIComponent(URL.substring(URL.lastIndexOf('/') + 1));
    const [productBrand, setProductBrand] = useState([]);
    const [loadershow, setloadershow] = useState(false);
    
    const handleClick = () => {
        //ref.current?.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const toggleNavVisibility = () => {
        setNavVisibility(!isNavVisible);
    };

    function addToCartAccessControl(message,isLoggedIn){
        Swal.fire({
            title: message,
            showCancelButton: true,
            showConfirmButton: isLoggedIn,
            confirmButtonText: 'Login',
            cancelButtonText: "Register",
            confirmButtonColor: '#7066e0',
            cancelButtonColor: '#18aebf',
            showCloseButton: true,

        }).then((result) => {
            if (result.isConfirmed) {
                navigate("/login");
            }
            if (result.dismiss === 'cancel') {
                navigate("/create-membership");
            }
        })
    }


    useEffect(() =>{
        window.scrollTo(0, 0);
        URLparam = URLparam.replaceAll("-", " ");
        console.log(URLparam);
    },[URLparam,sidebarData]);

    return(
        <>
            {/* Main Cont Begin  */}
            <main>
                <div className="nwShopList-page">
                    <div className="container-fluid">
                        <div className="nwShopTitleDiv">
                            <h2>Shop Wholesale Health Club Brands</h2>
                            <p>Shop All of Wholesale Health Club Brands and Products</p>
                            <button className={`btn nwSideNav_btn ${isNavVisible ? 'show' : ''}`} onClick={toggleNavVisibility}><span></span><span></span><span></span></button>
                        </div>
                        {/* Row Begins  */}
                        <div className="row newShopList_row" ref={ref}>
                            <ShopSidebar sidebarData={sidebarData} setSidebarData={setSidebarData} isNavVisible={isNavVisible}/>
                            {/* Left Col Begin  */}
                            <div className="col-md-8 col_right">
                                <>
                                    <p className="border_title text-start">{URLparam.charAt(0).toUpperCase() + URLparam.slice(1).replaceAll("-", " ")}</p>
                                    {Object.keys(sidebarData).map(function (item) {
                                        return (
                                            <>
                                                <p className="border_title border-none">{item}</p>
                                                <div className="row nwProd_row">
                                                    {sidebarData[item].map(function (item1) {
                                                        return (
                                                            <>
                                                                <div className="col-md-6 col-lg-3 nwProd_col">
                                                                    <div className="nwProdCard">
                                                                        <figure className="nwProdFig">
                                                                            <Link to={'/product/' + (item1.slug === null ? item1.product_id : item1.slug)}><img className="img-fluid" src={imgURL + item1.image} alt="" /></Link>
                                                                        </figure>
                                                                        <div className="text-center">
                                                                            {/* <Link to="" className="btn nwCart_btn"><img src={cart} className="img-fluid" /> Add to Cart</Link> */}
                                                                            {cart.map((prod) => {
                                                                                if (prod.id === item1.id) {
                                                                                    return (
                                                                                        <button onClick={() => {
                                                                                            if(is_membership === 0){
                                                                                                addToCartAccessControl('Please purchase membership to get this item',false);
                                                                                            } else {
                                                                                                dispatch({
                                                                                                    type: "CHANGE_QTY",
                                                                                                    payload: {
                                                                                                        id: item1.id,
                                                                                                        qty: Number(prod.qty) + 1
                                                                                                    },
                                                                                                })
                                                                                            }
                                                                                        }} className='btn cartBtn'> Add To Cart </button>
                                                                                    )
                                                                                }

                                                                            })}
                                                                            {cart.some(pro => pro.id === item1.id) ? null : <button onClick={() => {

                                                                                if (isLogin === 0) {
                                                                                    addToCartAccessControl('Login OR, Create an Account',true);
                                                                                }
                                                                                else {
                                                                                    if(isMember === 0){ 
                                                                                        addToCartAccessControl('Please purchase membership to get this item',false);
                                                                                    } else {
                                                                                        if(is_membership === 0){
                                                                                            addToCartAccessControl('Please purchase membership to get this item',false);
                                                                                        } else {
                                                                                            dispatch({
                                                                                                type: 'ADD_TO_CART',
                                                                                                payload: item1
                                                                                            })
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }} className='btn cartBtn'>Add To Cart</button>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        );
                                                    })}
                                                </div>
                                                <div className="nwDivider"></div>
                                            </>
                                        );
                                            
                                    })}
                                </>
                            </div>
                            {/* Left Col End  */}
                        </div>
                    </div>
                </div>
            </main>
            {/* Main Cont End  */}
            <a id="bt-button" onClick={handleClick}><img src={homeIcon} className="img-fluid" alt="" /></a>
        </>
    );
}


export default ShopPage2;