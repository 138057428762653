import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2'
import { Link, useNavigate, useLocation }  from 'react-router-dom';
import '../../assests/upsell/css/gummiesupsell.css';
import useStore from '../../store';
import AccessDeniedPage from "../AccessDeniedPage";

// Images 
import upsellProduct from '../../assests/upsell/images/gummies_upsell_product.png'
import moneyBack from '../../assests/upsell/images/100-money-back.png'
import logo from '../../assests/upsell/images/Wholesale_Health_Club_Logo_440x180-01.png'
import label1Cleance from '../../assests/upsell/images/label1-cleance.png'
import visaUpsell from '../../assests/upsell/images/visa-upsell.png'
import pic2 from '../../assests/upsell/images/pic2.png'
import textPic from '../../assests/upsell/images/text_pic.png'
import Copyright from '../Copyright';





function GummiesUpsell1() {
    const [loadershow, setloadershow] = useState(false);
    const [disable,setDisable] = useState(false);
    const navigate = useNavigate();
    const currentYear = new Date().getFullYear();
    const location = useLocation();
    let params = location.search;
    let userDetails = useStore((state) => state.userDetails);
    const isLogin = useStore((state) => state.isLogin);

    function upsellProcced(){
        setDisable(true);
        setloadershow(true);
        const formdata = new FormData();
        formdata.append('first_name', localStorage.getItem('firstName'));
        formdata.append('last_name', localStorage.getItem('lastName'));
        formdata.append('email', localStorage.getItem('email'));
        formdata.append('is_membership', 0);
        if((localStorage.getItem('c_s') === 'acvgummies2-checkout')){
            formdata.append('product_id', 228);
        } else {
            formdata.append('product_id', 82);
        }
        formdata.append('is_upsell', 1);
        formdata.append('order_id', localStorage.getItem('orderId'));
        formdata.append('affid', localStorage.getItem('affid'));
        formdata.append('sid', localStorage.getItem('sid'));
        formdata.append('s1', localStorage.getItem('s1'));
        formdata.append('s2', localStorage.getItem('s2'));
        formdata.append('s3', localStorage.getItem('s3'));
        formdata.append('click_id', localStorage.getItem('click_id'));
        formdata.append('notes', localStorage.getItem('notes'));
        formdata.append('user_id',userDetails.user_id);
        formdata.append('method',localStorage.getItem('method'));
        if(localStorage.getItem('funnel')){
            formdata.append('funnel',localStorage.getItem('funnel'));
        }
        if(localStorage.getItem('orderId')){
            axios.post(`api/place-upsell-order`, formdata).then(res => {
                if (res.data.status === true) {
                    //console.log(res.data.data);
                    if(localStorage.getItem('up2_s') === 'gummies-upsell-1'){
                        localStorage.setItem('Upsell2_orderId', res.data.data.order_id);
                        if(localStorage.getItem('method') == 'paypal'){
                            window.location.href = '/'+localStorage.getItem('con_s')+params;
                        } else {
                            if(localStorage.getItem('up3_s')){
                                window.location.href = '/'+localStorage.getItem('up3_s')+params;
                            } else {
                                window.location.href = '/'+localStorage.getItem('con_s')+params;
                            }
                        }
                    } else {
                        localStorage.setItem('Upsell1_orderId', res.data.data.order_id);
                        if(localStorage.getItem('up2_s')){
                            window.location.href = '/'+localStorage.getItem('up2_s')+params;
                        }
                        else{
                            window.location.href = '/'+localStorage.getItem('con_s')+params;
                        }
                    }
                }
                else{
                    setDisable(false);
                    setloadershow(false);
                    Swal.fire({
                        title: res.data.data.decline_reason,
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33'
                    })
                }
            })
        } else {
            Swal.fire({
                title: 'Order Decline',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33'
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.location.href = '/dashboard';
                }
            })
        }
            
        

    }

    function goToConfirmation(){
        setloadershow(true);
        if(localStorage.getItem('up2_s') === 'gummies-upsell-1'){
            if(localStorage.getItem('method') == 'paypal'){
                window.location.href = '/'+localStorage.getItem('con_s')+params;
            } else {
                if(localStorage.getItem('up3_s')){
                    window.location.href = '/'+localStorage.getItem('up3_s')+params;
                } else {
                    window.location.href = '/'+localStorage.getItem('con_s')+params;
                }
            }
            
        } else {
            if(localStorage.getItem('up2_s')){
                window.location.href = '/'+localStorage.getItem('up2_s')+params;
            }
            else{
                window.location.href = '/'+localStorage.getItem('con_s')+params;
            }
        }
    }

    /*useEffect(() => {
        navigate("/total-cleanse-formula", { replace: true });
    }, []);*/

    return (
        <>
            { isLogin === 1 ?
            <>
            <div className={loadershow ? 'loading' : 'd-none'}></div>
            <div className="gummiesUpsell1">
                {/* <!-- Ups  --> */}
                <div className="ups ups1">
                    <div className="u-main">
                        <div className="container">
                            <div className="u-heading">
                                <h5> This Offer Expires When Sold Out! </h5>
                                <h1> Wait, your order is not complete...</h1>
                                <p> Others who have purchased Tru Body ACV Keto Gummies have also had incredible results with this <span> TOTAL CLEANSE FORMULA (MAINTANCE CLEANSE)</span> Would you like to add this to your order?</p>
                            </div>
                            <div className="u-box clearfix">
                                <div className="u-left">
                                    <img src={upsellProduct} alt="" width="170" />
                                    <div className="u-price clearfix">
                                        <span className="u-price-left">
                                            <span className="u-old-price">$58.95</span>
                                            <span>
                                                Your Total <br />
                                                Today Only:
                                            </span>
                                        </span>
                                        <span className="u-new-price">$29.47<font>/bottle</font></span>
                                        <img src={moneyBack} alt="" className="u-label3" />
                                    </div>
                                </div>
                                <div className="u-right">
                                    <span className="logo"><img src={logo} alt="" className="header__logoX" /></span>
                                    <span className="u-title-lg">45% OFF</span>
                                    <span className="u-title2">Grab the chance while you still got it!</span>
                                    <div className="sub_text">GROUND-BREAKING PAIN MANAGEMENT SUPPLEMENT</div>
                                    <p>
                                        TOTAL CLEANSE FORMULA (MAINTANCE CLEANSE) works WITH your body to ELIMINATE YOUR PAIN FROM WITHIN. And it goes to work quickly. After over 20,000 clinical studies, it has been proven over and over again ... The
                                        cannabinoids found in TruBody ACV Keto Gummies are the SAME compounds that regulate mood and pain in the brain and body!
                                    </p>
                                </div>
                                <img src={label1Cleance} alt="" className="u-label" />
                            </div>
                            <div className="btnSet">
                                <div className="only_text2">ONLY 22 LEFT!</div>
                                <span id="gummiesUpsellOrderButton">
                                    <a className="button__yes checkout_btn2" disabled={(disable)?'disabled':''} onClick={upsellProcced}>COMPLETE MY ORDER</a>
                                </span>
                                <br />
                                <div className="pic2"><img src={pic2} alt="" /></div>
                                <a onClick={goToConfirmation} className="button__no u-button__no model_close cursor-pointer"><span className="cross">x</span> No, I Do Not Want To Optimize My Results</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ups end  */}
                {/* <!-- comnt area  --> */}
                <div className="comnt_area2">
                    <div className="container">
                        <div className="connection_text">
                            <span><img src={textPic} alt="" /></span>
                            <span><img src={visaUpsell} alt="" /></span>
                        </div>
                        {/* <div className="footer-link-area mt-3">
                            <div className="text-center">
                                <p>
                                    Copyright ©
                                    {" "+currentYear+" "}
                                    Wholesale Health Club. All Rights Reserved.
                                </p>

                                <ul className="terms-links">
                                    <li>
                                        <Link to="#" onClick={(event) => {
                                            event.preventDefault();
                                            window.open('/privacy-policy', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                        }}>Privacy Policy |&nbsp;</Link>
                                    </li>
                                    <li>
                                        <Link to="#" onClick={(event) => {
                                            event.preventDefault();
                                            window.open('/term-and-condition', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                        }}>Terms and Conditions |</Link>
                                    </li>
                                    <li><Link to="/contact-us">&nbsp;Contact Us</Link></li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* <!-- comnt area End --> */}
                <Copyright/>
            </div>
            </>
            : <AccessDeniedPage/> }
        </>
    )
}

export default GummiesUpsell1