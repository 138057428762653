import React, { useState, useEffect } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom"; // v6
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faDeleteLeft, faEye } from '@fortawesome/free-solid-svg-icons'
import CheckPermission from '../frontend/auth/CheckPermission';


function Orders() {
    CheckPermission()
    const [List, setList] = useState([]);
    const [pending, setPending] = useState(true);

    const [Details, setDetails] = useState([]);
    const [Id, setId] = useState(false);
    const [search, setSearch] = useState("");
    const [searchTable, setsearchTable] = useState([]);

    async function getList() {
        axios.get(`api/listOrder`).then(res => {
            console.warn(res.data.data);
            setList(res.data.data);
            setsearchTable(res.data.data)
            setPending(false);
        })

    }
    const columns = [
        {
            name: "Order Id",
            cell: row => (
                <span><a style={{ marginRight: 1 + 'em' }} target='_blank' href={'https://genius.sticky.io/admin/orders.php?show_details=show_details&show_folder=view_all&fromPost=1&act=&sequence=1&show_by_id=' + row.order_id} >{row.order_id} </a></span>
            )
        },
        {
            name: "Name",
            selector: row => row.name
        },
        {
            name: "Phone",
            selector: row => row.phone
        },
        {
            name: "Email",
            cell: row => (
                <span>{row.email}</span>
            )
        },

        {
            name: "Total Amount",
            cell: row => (
                <span>${row.total_ammount}</span>
            )
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <a style={{ marginRight: 1 + 'em' }} target='_blank' className='btn btn-primary' href={'https://genius.sticky.io/admin/orders.php?show_details=show_details&show_folder=view_all&fromPost=1&act=&sequence=1&show_by_id=' + row.order_id} ><FontAwesomeIcon icon={faEye} /></a>
                    <button className='btn btn-danger' onClick={() => { deleteData(row.id) }} ><FontAwesomeIcon icon={faDeleteLeft} /></button>
                </>
            )
        }
    ]
    useEffect(() => {
        getList()
    }, []);

    function deleteData(id) {
        Swal.fire({
            title: 'Are you sure you want to delete this Order?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showCloseButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`api/deleteOrder/${id}`).then(res => {

                    Swal.fire({
                        title: 'Deleted',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    // window.location.reload();
                    getList();
                })
            }
        })
    }

    useEffect(() => {
        const result = List.filter(country => {
            return (country.first_name?.toLowerCase().includes(search.toLowerCase()) ||
            country.last_name?.toLowerCase().includes(search.toLowerCase()) ||
            country.email?.toLowerCase().includes(search.toLowerCase()) ||
            country.order_id?.toString().includes(search.toLowerCase()) ||
            country.total_ammount?.toString().includes(search.toLowerCase())
                )
        })
        setsearchTable(result);
    }, [search]);

    return (
        <div>
            <div className='container'>
                <div className='col-md-12 pl-3'>
                    <div className="pageheader card mb-4">
                        <div className="card-body row align-items-center">
                            <div className="page-name col-md-3">Orders List</div>
                            <div className="buttun_div d-none col-md-9">  <Button variant="primary">

                            </Button>
                            </div>
                        </div>
                    </div>
                    <h1 className="display-6"></h1>
                    <div className='card '>
                        <div className='card-body '>
                            <div className='datatable '>
                                <DataTable
                                    noDataComponent="Orders table is empty" //or your component
                                    columns={columns}
                                    data={searchTable}
                                    progressPending={pending}
                                    pagination
                                    fixedHeader
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text" placeholder="search" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                                    }
                                    subHeaderAlign="right"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Orders