import React  from "react";
import { Link }  from 'react-router-dom';
import { useEffect,useState } from 'react';

import accessDeniedImg from '../assests/frontend/assets/images/access-denied.jpg';

function AccessDeniedPage(){

    const [loadershow, setloadershow] = useState(true);

    useEffect(() => {
        // Simulate loading for 3 seconds
        const timer = setTimeout(() => {
            setloadershow(false);
        }, 5000);
    
        return () => clearTimeout(timer); // Clean up the timer on component unmount
    }, []);

    return(
        <>
            {loadershow  ? <div className="loader-new loading"></div> : ''}
            <div className="accessDeniedPage">
                <div className="container">
                    <div className="deniedBox text-center">
                        <img className="de-img" src={accessDeniedImg} alt="" />
                        <div className="accessDenied-cont">
                            <h2>Access Denied !</h2>
                            <p>You don’t have access to view this page.</p>
                            <p>Please purchase the membership plan using this <Link to="/create-membership">link</Link></p>
                        </div>
                    </div>
                </div>
            </div>        
        </>
    )
}


export default AccessDeniedPage;