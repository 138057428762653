import React, { useEffect, useState } from "react";
import axios from 'axios';
import { BASE_URL } from "../../components/Config";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import useStore from '../../store';

function ShopSidebar({ sidebarData, setSidebarData, isNavVisible  }) {
    const [category, setCategoryList] = useState([]);
    const [brand, setBrand] = useState([]);
    let URL = window.location.href;
    let URLparam = URL.substring(URL.lastIndexOf('/') + 1);
    const [loadershow, setloadershow] = useState(false);
    const isLogin = useStore((state) => state.isLogin);
    const isMembership = useStore((state) => state.isMember);
    const afterLogout = useStore((state) => state.afterLogout);

    async function getBrand(){
        axios.get(`api/get-brand-frontend`).then(res => {
            setBrand(res.data.data);
        })
    }

    async function getCategoryList(ProductClass) {
        console.log(ProductClass);
        axios.get(`api/frontEndListCategory`).then(res => {
            setCategoryList(res.data.data);
            if((ProductClass === 'gummies') || (ProductClass === 'skincare') || (ProductClass === 'supplements')){
                axios.get(`api/get-product-by-category/${ProductClass}`).then(res => {
                    setSidebarData(res.data.data);
                }).catch(err => {
                    Swal.fire({
                        title: 'Warning',
                        html: err,
                        icon: 'warning'
                    })
                });
            } else if (ProductClass === 'the complete life' || ProductClass === 'blue forrest beauty' || ProductClass === 'emma bean skin care' || ProductClass === 'supplements' || ProductClass === 'healthy living products' || ProductClass === 'sapphire natural' || ProductClass === 'tru body supplements' || ProductClass === 'total life nutrition'){
                axios.get(`api/get-product-by-brand/${ProductClass}`).then(res => {
                        setloadershow(false);
                        setSidebarData(res.data.data);
                    }).catch(err => {
                        Swal.fire({
                            title: 'Warning',
                            html: err,
                            icon: 'warning'
                        })
                    });
            }
        })
    }

    function getProductListing(ProductClass){
        window.scrollTo(0, 0);
        setloadershow(true);
        if((ProductClass === 'gummies') || (ProductClass === 'skincare') || (ProductClass === 'supplements')){
            axios.get(`api/get-product-by-category/${ProductClass}`).then(res => {
                setloadershow(false);
                setSidebarData(res.data.data);
            }).catch(err => {
                Swal.fire({
                    title: 'Warning',
                    html: err,
                    icon: 'warning'
                })
            });
        } else {
            axios.get(`api/get-product-by-brand/${ProductClass}`).then(res => {
                setloadershow(false);
                setSidebarData(res.data.data);
            }).catch(err => {
                Swal.fire({
                    title: 'Warning',
                    html: err,
                    icon: 'warning'
                })
            });
        }
    }

    window.onpopstate = () => {
        setTimeout(() => {
            let URL = window.location.href;
            let URLparam = URL.substring(URL.lastIndexOf('/') + 1);
            getCategoryList(URLparam.toLowerCase().replaceAll("-", " "));
        }, 1000); // 3000 milliseconds (3 seconds)
    }

    useEffect(()=>{
        let URL = window.location.href;
        let URLparam = URL.substring(URL.lastIndexOf('/') + 1);
        getBrand();
        getCategoryList(URLparam.toLowerCase().replaceAll("-", " "));
    },[URLparam]);

    const logoutSubmit = e => {
        e.preventDefault();
        sessionStorage.removeItem('yp');
        localStorage.removeItem('method');
        localStorage.removeItem('userEmal');
        localStorage.removeItem('userId');

        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
        localStorage.removeItem('phone');
        localStorage.removeItem('email');
        localStorage.removeItem('shippingAddress1');
        localStorage.removeItem('shippingCity');
        localStorage.removeItem('shippingState');
        localStorage.removeItem('shippingZip');
        localStorage.removeItem('order_id');

        if(localStorage.getItem('loggedInAs') === 'admin'){
            console.log(localStorage.getItem('loggedInAs'));
        } else {
            localStorage.removeItem('authToken');
            localStorage.removeItem('bearer');
            localStorage.removeItem('permission');
            localStorage.removeItem('useData');
            afterLogout()
        }
        Swal.fire({
            title: 'Logout!',
            html: 'Logout Successful',
            icon: 'success'
        })
        window.location.href = '/login'
    }

    return (
        <>
                <div className={loadershow ? 'loading loading_sidebar' : 'd-none'}></div>
                {/* Left Col Begin  */}
                <div className="col-md-4 col_left">
                    <div className={`nwSideNav ${isNavVisible ? 'show' : ''}`}>
                        <div className="nwSideNav_div">
                            <p className="border_title">Product Types</p>
                            <ul className="nwSideNav_lists">
                                {category.map(category => {
                                    if (category.name.toLowerCase().replace(" ", "-") !== "bundle-packs") {
                                        return (
                                            <li className={(URLparam === category.name.toLowerCase().replace(" ", "-")) ? 'active':''} >
                                                <Link to={"/shop/" + category.name.toLowerCase().replace(" ", "-")} onClick={()=>getProductListing(category.name.toLowerCase().replaceAll("-", " "))}> {category.name}</Link>
                                            </li>
                                        );
                                    }
                                })}
                                {isLogin === 0 ? 
                                    <li className={URLparam === 'discount' ? 'active':''}>
                                        <Link to={"/discount/"}> Discount</Link>
                                    </li>
                                : ''}
                                {isLogin ?
                                    <li className={URLparam === '/referral' ? "active" : null}>
                                        <Link to="/referral">Referral</Link>
                                    </li>
                                :''}
                                {isLogin && isMembership ?
                                    <li className={URLparam === '/bonus' ? "active" : null}>
                                        <Link to="/bonus">Bonuses</Link>
                                    </li>
                                :''}
                                {isLogin ?
                                    <li className={URLparam === '/my-account-info' ? "active" : null}>
                                        <Link to="/my-account-info">My Account Info</Link>
                                    </li>
                                :''}
                                {(isLogin) ?
                                <li className={URLparam === 'cart' ? 'active':''}>
                                    <Link to={"/cart/"}> View Cart</Link>
                                </li>
                                :''}
                            </ul>
                        </div>
                        <div className="nwSideNav_div">
                            <p className="border_title">Brands</p>
                            <ul className="nwSideNav_lists">
                                {brand.map((item) => (
                                    <li key={item.id} className={URLparam ===  item.brand_name.toLowerCase().replaceAll(" ", "-") ? "active" :''}>
                                        <Link to={"/shop/" + item.brand_name.toLowerCase().replaceAll(" ", "-")} onClick={()=>getProductListing(item.brand_name.toLowerCase().replaceAll("-", " "))}> {item.brand_name}</Link>
                                    </li> 
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Left Col Ends  */}
            
        </>
    )
}

export default ShopSidebar;