 import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';

// css
import style from '../../assests/frontend/assets/css/Tost.module.css';


function TOST(){
    return(
        <>
            <section className={style.tostSec}>
                <div className={style.searchSection}>
                    <div className='container'>
                        <h2 className='mb-4'>How can we help you?</h2>
                        <Form>
                            <div className={`${style.searchDiv} d-flex`}>
                                <Form.Control type="search" placeholder="Serach..." />
                                <button type='submit'><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADsAAAA6CAYAAAAOeSEWAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAM6SURBVHgB7Znfiw5RGMe/1lorrWVFkuy7hCg/IpSrpZTcobgT+REXLrRukFar5M4W+QOESO5oxcVqXbjYskVolfaVIuvHm/Ur5MfzNO9bbzPPmZl35pw584751Pdizsycc56Z5/x4ngPk5OTUE+Ogn6mkFtJfUon0FSkhrrH8/nrSdtJ80jLSTNcz70mPSU9JfaRbqDOaSUdIRTh/sBaNkPaRxqMOWEl6gdqNlIxegBTTRfqJ+IZW9Jt0DAkRdsw2ki6RdgQ895Y0RBotvzODtJg0J+C9q6SdpF9IAeeh/jsfSSfhGKViCakHjuuq6rmAFHAI6g6eI02roS7+w34f7gAs0kEag7dTr0hbEJ2tpJdCvZ/gLGFWeAhvh3hczkN8+EN+Eeq/Dwtsg+xqB6GPPYo2NiJh7gqduAz93BTa6UeCdEBeEwvQTzvpu6utPybaalCUrxPKrsHZHuqGJyr3fpnX/zXQjMrY5ULZDZjjulC2GpqpxdhBmGNAKFuIhOAtX/UYGoN5Sq42n0Azqj/bAm9HTOP+oJOgmYaQzzXCPE2u6x/QjMrYUdc1Ry/NMEdTuY1qPkAzKmOHXdcTSEthjlXwZi6K0IzK2CGhbBPMsUEoe4CE2AzvDuoZzGQjGSnN04mEYLctCR3YD/3sFdopwtyHFekROvGONAv64H2xFNeeRsJMhxy434E+BiCnedphgS7I8eYpOK4elYmkXkXdR2GRfsideoRoYRiHj4OKOvtgmdlwjjCkzvE5zglSW4h6WkndkIdGJd0zFwYJO+NxfMtjdbLi/mfSbTjZDT7T4YmMg30+9+EIis+DeJ2eAn+Ok65UXRdhiUXwz/tGFa+xw4p7Z2ARdumL0Gco57TayvWWFM+chWU4KziC6Ea+Ie121elXXy8sw2HfLngDfT/dK78jRVBBH68bMdG1JeN1cy2cyIgT6K3lunnmfQ7nMJoT7t986uCPtgL+8B8+jAzAE+BrBHuHdZfWRViDrc7SOgm7xMUew2mhgNylc4Mz59JhxnBmJq0CcpfOvsFBLs0BRQEZoQC1wXyobTK/bQXJpTNpaIVqgzNtaAU2mE8qOvGfYPJ0MScnxyL/AIwt6wJ+lgsuAAAAAElFTkSuQmCC" class="img-fluid" alt="" /></button>
                            </div>
                        </Form>
                    </div>
                </div>
                <div className={`${style.tostContent}`}>
                    <div className='container'>
                        {/* <h2 className='mb-4'>Commodi, dicta vitae at odio obcaecati</h2> */}                        
                        <div className='mb-4'>
                            <h3 className='mb-4'> Wholesale Health Club Terms Of Service</h3>
                            <p>1. You are opting in to receive messages for 2FA as well as promotional material.</p>
                            <p>2. You can cancel the SMS service at any time. Just text "STOP" to the short code. After you send the SMS message "STOP" to us, we will send you an SMS message to confirm that you have been unsubscribed. After this, you will no longer receive SMS messages from us. If you want to join again, just sign up as you did the first time and we will start sending SMS messages to you again.</p>
                            <p>3. If you are experiencing issues with the messaging program you can reply with the keyword HELP for more assistance, or you can get help directly at <a href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a> or <a href="tel:(480)573-0829">(480)573-0829</a>.</p>
                            <p>4. Carriers are not liable for delayed or undelivered messages.</p>
                            <p>5. As always, message and data rates may apply for any messages sent to you from us and to us from you. You will receive only requested messages. If you have any questions about your text plan or data plan, it is best to contact your wireless provider.</p>
                            <p>6. If you have any questions regarding privacy, please read our privacy policy:
                                <Link to="#" onClick={(event) => {
                                        event.preventDefault();
                                        window.open('/privacy-policy', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                    }}>Privacy Policy
                                </Link>
                            </p>
                        </div>

                        <div className={`${style.helpSection} d-flex justify-content-between  align-items-md-center`}>
                            <h4>Still can't find what you need?</h4>
                            <button className={style.helpBtn}>Chat with our Help Center Assistant</button>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default TOST;