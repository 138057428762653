import React, { useState, useEffect } from 'react'
import Template from '../funneltemplates/Template-1'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from "react-router-dom"; // v6
import MasterTemplate1 from "../CheckoutTemplates/MasterTemplate1";
import MasterTemplate3 from "../CheckoutTemplates/MasterTemplate3";
import BrainTemplate from "../LanderTemplate/BrainTemplate";

import Upsell from "../UpsellTemplate/Upsell";
import GummiesUpsell1 from "../UpsellTemplate/GummiesUpsell1";
import GummiesUpsell2 from "../UpsellTemplate/GummiesUpsell2";
import FaceCreamUpsell1 from "../UpsellTemplate/FaceCreamUpsell1";

import ThankYou from "../ThankYouTemplates/ThankYou";
import NotFound from "../admin/NotFound";
import ACVTemplate from "../LanderTemplate/AcvgummiesTemplate";
import AcvCheckout from "../CheckoutTemplates/AcvCheckout";
import FaceCream from "../LanderTemplate/FaceCreamTemplate.js";
import FaceCreamCheckout from '../CheckoutTemplates/FacecreamCheckout';
import NeobodUpsell from '../UpsellTemplate/NeobodUpsell';
import AcvgummiesMobileTemplate from "../LanderTemplate/AcvgummiesMobileTemplate";
import AcvMobileCheckout from "../CheckoutTemplates/AcvMobileCheckout";
import ThankYouCrypto from "../frontend/ThankYouCrypto";
import SkincareCheckout from '../CheckoutTemplates/SkincareCheckout';
import Skincare from '../LanderTemplate/SkincareTemplate';
import Skincare6pCheckout from '../CheckoutTemplates/Skincare6pCheckoutTemplate.js';
import DickPillHome from '../LanderTemplate/DickPillTemplate.js';
import DickPillUpsell1 from '../UpsellTemplate/DickPillUpsell1Template.js';
import DickPillCheckout from "../CheckoutTemplates/DickPillCheckoutTemplate.js";
import DickPillCheckoutMob from "../CheckoutTemplates/DickPillCheckoutMobTemplete.js";
import Acvgummies2Template from '../LanderTemplate/Acvgummies2Template.js';
import Brain15pTemplate from '../LanderTemplate/Brain15pTemplate.js';
import Brain15pCheckout from '../CheckoutTemplates/Brain15pCheckout.js';
import Brain20pTemplate from '../LanderTemplate/Brain20pTemplate.js';
import Brain20pCheckout from '../CheckoutTemplates/Brain20pCheckout.js';
import Get1hardpp from '../LanderTemplate/Get1hardpp.js';
import Get1hardppCheckoutMobile from '../CheckoutTemplates/Get1hardppCheckoutMobile.js';
import Get1hardppCheckoutDesktop from '../CheckoutTemplates/Get1hardppCheckoutDesktop.js';
import SynapticTemplate from '../LanderTemplate/SynapticTemplate.js';
import SynapticCheckout from '../CheckoutTemplates/SynapticCheckout.js';
import Alphadrive from '../LanderTemplate/AlphadriveTemplate.js';
import AlphadriveCheckoutMobile from '../CheckoutTemplates/AlphadriveCheckoutMobile.js';
import AlphadriveCheckoutDesktop from '../CheckoutTemplates/AlphadriveCheckoutDesktop.js';
import BlueForrest from '../LanderTemplate/BlueForrestTemplate.js';
import NectorCheckoutTemplate from '../CheckoutTemplates/NectorCheckoutTemplate.js';
import Skincare2 from '../LanderTemplate/Skincare2Template.js';
import Skincare2pTemplate from '../LanderTemplate/Skincare2pTemplate.js';
import Brain4Template from '../LanderTemplate/Brain4Template.js';
import Brain10pTemplate from '../LanderTemplate/Brain10pTemplate.js';
import NootropicLander from '../LanderTemplate/NootropicLander.js';
import Brain4Checkout from '../CheckoutTemplates/Brain4Checkout.js';
import Brain10pCheckout from '../CheckoutTemplates/Brain10pCheckout.js';
import NootrpicCheckoutTemplate from '../CheckoutTemplates/NootropicCheckoutTemplate.js';
import Nootropic2p from '../LanderTemplate/Nootropic2pTemplate.js';
import Blueskin from '../LanderTemplate/BlueskinTemplate.js';
import Skincarebp from '../LanderTemplate/SkincarebpTemplate.js';
import SkincarebpCheckout from '../CheckoutTemplates/SkincarebpCheckoutTemplate.js';
import Nootropic2pCheckout from '../CheckoutTemplates/Nootropic2pCheckout.js';
import DirectCheckout from '../DirectCheckout/DirectCheckout.js';
import DirectSynapticCheckout from '../DirectCheckout/DirectSynapticCheckout.js';
import DirectNurotech from '../DirectCheckout/DirectNurotech.js';


function MasterCheckout() {
    const params = useParams()
    const navigate = useNavigate();
    const [Data, setCheckoutFunnelData] = useState({})
    const [slugData, setSlugdata] = useState('')
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    function getDeviceType() {
        let width = window.innerWidth;
        let height = window.innerHeight;
    
        // Define breakpoints for different device types
        let isMobile = width <= 700;  // Mobile devices
        let isTablet = width > 700 && width <= 1024; // Tablets
        let isDesktop = width > 1024; // Desktops
    
        if (isMobile) {
            return 'mobile';
        } else if (isTablet) {
            return 'tablet';
        } else if (isDesktop) {
            return 'desktop';
        }
    }

    let deviceType = getDeviceType();

    function setFunnelFlowData(data){
        //console.log(data);
        //axios.get(`api/getFlowByLanderId/${landerID}`).then(res1 => {
            // setData(res.data.data[0]);
            // console.log(res1.data)
            localStorage.removeItem('funnel');
            localStorage.removeItem('combine_product');
            localStorage.removeItem('combine_product_id');
            localStorage.removeItem('prospectId');
            localStorage.removeItem('method');
            localStorage.removeItem('c_s');
            localStorage.removeItem('con_s');
            localStorage.removeItem('up1_s');
            localStorage.removeItem('up2_s');
            localStorage.removeItem('up3_s');
            localStorage.removeItem('up4_s');
            localStorage.removeItem('up5_s');
            localStorage.removeItem('order_id');
            localStorage.removeItem('membership_orderId');
            localStorage.removeItem('orderId');
            localStorage.removeItem('Upsell1_orderId');
            localStorage.removeItem('Upsell2_orderId');
            localStorage.removeItem('CCNumber');
            localStorage.removeItem('CCType');
            localStorage.removeItem('cvv');
            localStorage.removeItem('expirationDate');
            localStorage.removeItem('neobodOrderId');
            localStorage.removeItem('neobodOrderStatus');

            localStorage.removeItem('firstName');
            localStorage.removeItem('lastName');
            localStorage.removeItem('email');
            localStorage.removeItem('phone');
            localStorage.removeItem('shippingAddress1');
            localStorage.removeItem('shippingCity');
            localStorage.removeItem('shippingState');
            localStorage.removeItem('shippingZip');
            localStorage.removeItem('country');
            localStorage.removeItem('billingSameAsShipping');
            localStorage.removeItem('shipping_address');
            localStorage.removeItem('shipping_city');
            localStorage.removeItem('shipping_state');
            localStorage.removeItem('shipping_zip');
            localStorage.removeItem('shipping_country');

            localStorage.removeItem('affid');
            localStorage.removeItem('sid');
            localStorage.removeItem('s1');
            localStorage.removeItem('s2');
            localStorage.removeItem('s3');
            localStorage.removeItem('click_id');
            localStorage.removeItem('notes');
            localStorage.removeItem('funnel');
            localStorage.removeItem("pixel");
            
            localStorage.setItem('c_s', data.checkout.funnel_slug)
            localStorage.setItem('con_s', data.confirmation.confirmation_slug)

            if (data.upsell_1) {
                localStorage.setItem('up1_s', data.upsell_one.upsell_slug)
            }

            if (data.upsell_2) {
                localStorage.setItem('up2_s', data.upsell_two.upsell_slug)
            }

            if (data.upsell_3) {
                localStorage.setItem('up3_s', data.upsell_three.upsell_slug)
            }

            if (data.upsell_4) {
                localStorage.setItem('up4_s', data.upsell_four.upsell_slug)
            }

            if (data.upsell_5) {
                localStorage.setItem('up5_s', data.upsell_five.upsell_slug)
            }
        //})
    }

    async function getCheckoutFunnelBySlug() {
        
        const slug = params.slug

        if (slug != null) {
            await axios.get(`api/getSlugDataBySlug/${slug}`).then(res => {
                //console.log(res.data);
                if (res.data.status) {
                    // console.log(res.data.data[0].template_name);
                    setSlugdata(res.data.data[0].template_name);
                    setCheckoutFunnelData(res.data.checkoutData.data);
                    if(Object.values(res.data.funnelFlow).length !== 0){
                        setFunnelFlowData(res.data.funnelFlow[0]);
                    }
            
                }
                else {
                    //console.log(res.data.status);
                    navigate('/notfound')
                }

            })
        }
    }

    useEffect(() => { 
        //console.log(deviceType);
        getCheckoutFunnelBySlug();
    }, []);

    let component = null;
    
    //Select Component based on Slug
    //console.log(slugData);
    if(slugData != '' )
    {
        switch(slugData) {
            //Checkout Templates 
            case 'Brain3Checkout':
            component = <MasterTemplate1 data={Data} />;
            break;

            case 'AcvCheckout':
            component = <AcvCheckout data={Data} /> 
            break;
            
            case 'FaceCreamCheckout':
            component = <FaceCreamCheckout data={Data}/>;
            break;

            case 'MasterTemplate3':
            component = <MasterTemplate3 data={Data} />;
            break;

            case 'AcvMobileCheckout':
            component = <AcvMobileCheckout data={Data} />;
            break;

            case 'Nootropic2pCheckout':
            component = <Nootropic2pCheckout data={Data} />;
            break;

            case 'SkincareCheckout':
            component = <SkincareCheckout data={Data} />;
            break;

            case 'Skincare6pCheckout':
            component = <Skincare6pCheckout data={Data} />;
            break;

            case 'SkincarebpCheckout':
            component = <SkincarebpCheckout data={Data} />;
            break;

            case 'Gethard1pCheckout':
            {(deviceType === 'mobile') ? component = <DickPillCheckoutMob data={Data}/> : component = <DickPillCheckout data={Data}/>}
            break;

            case 'Acvgummies2Checkout':
            {(deviceType === 'mobile') ? component = <AcvMobileCheckout data={Data}/> : component = <AcvCheckout data={Data}/>}
            // component = <AcvCheckout data={Data} />;
            break;

            case 'Brain15pCheckout':
            component = <Brain15pCheckout data={Data} />;
            break;

            case 'Brain20pCheckout':
            component = <Brain20pCheckout data={Data} />;
            break;

            case 'Get1hardppCheckout':
            {(deviceType === 'mobile') ? component = <Get1hardppCheckoutMobile data={Data}/> : component = <Get1hardppCheckoutDesktop data={Data}/>}
            break;

            case 'SynapticCheckout':
            component = <SynapticCheckout data={Data} />;
            break;

            case 'AlphaCheckout':
            {(deviceType === 'mobile') ? component = <AlphadriveCheckoutMobile data={Data}/> : component = <AlphadriveCheckoutDesktop data={Data}/>}
            break;

            case 'NectarCheckout':
            component = <NectorCheckoutTemplate data={Data} />;
            break;

            case 'Brain4Checkout':
            component = <Brain4Checkout data={Data}/>;
            break;

            case 'Brain10pCheckout':
            component = <Brain10pCheckout data={Data}/>;
            break;

            case 'NootropicCheckout':
            component = <NootrpicCheckoutTemplate data={Data}/>;
            break;

            case 'DTCAlphaCheckout':
            component = <DirectCheckout data={Data}/>;
            break;

            case 'DTCSynapticCheckout':
            component = <DirectSynapticCheckout data={Data}/>;
            break;

            case 'DTCNeurotechCheckout':
            component = <DirectNurotech data={Data}/>;
            break;

            //Upsell Templates 
            case 'sleep-formula':
            component = <Upsell />;
            break;

            case 'total-cleanse-formula':
            component = <GummiesUpsell1 />;
            break;

            case 'double-offer':
            component = <GummiesUpsell2  />;
            break;

            case 'blue-forrest-goddess-eye-cream':
            component = <FaceCreamUpsell1 />;
            break;

            case 'neobod-upsell':
            component = <NeobodUpsell />;
            break;

            case 'healthy-living-product-total-men-health':
            component = <DickPillUpsell1 />;
            break;
            

            //Lander Templates 

            case 'ACV-Gummies':
            component = <ACVTemplate />
            break;

            case 'Facecream':
            component = <FaceCream />;
            break;

            case 'Brain':
            component = <BrainTemplate />;
            break;

            case 'Acvgummies-Mobile-Lander':
            component = <AcvgummiesMobileTemplate />;
            break;

            case 'Nootropic2p':
            component = <Nootropic2p />;
            break;

            case 'Skincare':
            component = <Skincare />;
            break;

            case 'Blueskin':
            component = <Blueskin />;
            break;

            case 'Skincarebp':
            component = <Skincarebp />;
            break;

            case 'DickPillOffer':
            component = <DickPillHome data={Data}/>;
            break;

            case 'Acvgummies2':
            component = <Acvgummies2Template />;
            break;

            case 'Brain15p':
            component = <Brain15pTemplate />;
            break;

            case 'Brain20p':
            component = <Brain20pTemplate />;
            break;

            case 'Get1hardpp':
            component = <Get1hardpp data={Data}/>;
            break;

            case 'Synaptic':
            component = <SynapticTemplate data={Data}/>;
            break;

            case 'Alphadrive':
            component = <Alphadrive data={Data}/>;
            break;

            case 'Blueforrest':
            component = <BlueForrest data={Data}/>;
            break;

            case 'Skincare2':
            component = <Skincare2 data={Data}/>;
            break;

            case 'Skincare2p':
            component = <Skincare2pTemplate data={Data}/>;
            break;

            case 'Brain4':
            component = <Brain4Template />;
            break;

            case 'Brain10p':
            component = <Brain10pTemplate />;
            break;

            case 'Nootropic':
            component = <NootropicLander />;
            break;

            //Confirmation Templates 

            case 'thankyou':
            component = <ThankYou />;
            break;

            case 'crypto-thank-you':
            component = <ThankYouCrypto />;
            break;

          default:
            component = <NotFound />;
        }
        
    }
    
    return (
        <>
            {component}
        </>

    )
}

export default MasterCheckout