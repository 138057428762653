import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import '../../assests/frontend/assets/css/custom.css';
import cartProduct1 from '../../assests/frontend/assets/images/trubody-daily-multivitamin-1-1-300x574.png';
import emptyCart from '../../assests/frontend/assets/images/empty-cart.png';
import { CartState } from '../../context/Context';
import { Link } from 'react-router-dom';
import {BASE_URL} from '../Config';
import Copyright from '../Copyright';



function Cart2() {
    const [productList, setproductLists] = useState([]);
    const [loading, setLoading] = useState(false);
    const { state: { cart }, dispatch } = CartState();
    const [discount, setDiscount] = useState();
    const [totalQuantity, setTotalQuantity] = useState();

    async function getProductList() {
        setLoading(true);

        axios.get(`api/listproduct`).then(res => {
            console.warn(res.data.data);
            setproductLists(res.data.data);
            // setSearchProducts(res.data.data)
            setLoading(false);

        }).catch(err => {

            console.log(err.response)
            // Swal.fire({
            //     title: 'Warning',
            //     html: err.response.data.message,
            //     icon: 'warning'
            // })

        }
        )
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getProductList()

    }, []);

    const [total, setTotal] = useState();

    useEffect(() => {

        setTotal(cart.reduce((acc, curr) => acc + Number(curr.price) * curr.qty, 0))
        updateCart();

    }, [cart]);


    const updateCart = () => {
        let totalQuantity = 0;
        let discountQuantity = 0;
        cart.map(function (item) {
            totalQuantity += parseInt(item.qty);
        });
        setTotalQuantity(totalQuantity);
        let sortedCart = cart.sort((a, b) => { return a.price - b.price });
        let DiscountQty = Math.floor(totalQuantity / 5);
        let totalDiscount = calculateDiscount(DiscountQty, sortedCart);
        setDiscount(totalDiscount);
    }

    let calculateDiscount = (DiscountQty, sortedCart) => {

        let totalDiscount = 0;
        for (let cartItem of sortedCart) {


            if (DiscountQty > 0) {

                if (cartItem.qty >= DiscountQty) {

                    totalDiscount += (parseInt(cartItem.price) * DiscountQty);
                    DiscountQty = DiscountQty - parseInt(cartItem.qty);

                }
                if (cartItem.qty < DiscountQty) {

                    totalDiscount += (parseInt(cartItem.price) * parseInt(cartItem.qty));
                    DiscountQty = DiscountQty - parseInt(cartItem.qty);
                }


            } else {
                break;
            }

        };

        return totalDiscount;
    }

    const Discounts = () => (
        <tr className="cart-subtotal">
            <th>Discount</th>
            <td data-title="Subtotal"><span className="woocommerce-Price-amount amount"><bdi><span className="woocommerce-Price-currencySymbol">$</span>{parseFloat(discount).toFixed(2)}</bdi></span></td>
        </tr>
    )

    return (
        <>
            <section className='cartPage'>
                <div className='container'>
                {cart.length > 0 ? (

                    <>
                    <form>
                        <div className='cartTable-wrap'>
                            <div className='table-responsive'>
                                <table className='table cartTable'>
                                    <thead>
                                        <th className='product-name' colspan="2">Product</th>
                                        <th className='product-price'>Price</th>
                                        <th className='product-quantity'>Quantity</th>
                                        <th className='product-subtotal'>Total</th>
                                        <th className='product-remove'></th>
                                    </thead>
                                    <tbody>

                                     


                                                {cart.map(function (item) {

                                                    return (

                                                        <tr>

                                                            <td className='product-thumbnail'>
                                                                <img src={BASE_URL+'whc-custom/storage/app/public/' + item.image} alt='' className='img-fluid' />
                                                            </td>
                                                            <td className='product-name'>
                                                                <a href="#">{item.name}</a>
                                                            </td>
                                                            <td className='product-price'>
                                                                <i>{'$' + parseFloat(item.price).toFixed(2)}</i>
                                                            </td>
                                                            <td className='product-quantity'>
                                                                <input type="number" min={1} className='form-control' value={item.qty} onChange={e => dispatch({
                                                                    type: "CHANGE_QTY",
                                                                    payload: {
                                                                        id: item.id,
                                                                        qty: e.target.value
                                                                    },
                                                                })} />
                                                            </td>
                                                            <td className='product-subtotal'>
                                                                <i>{'$' + parseFloat(item.price * item.qty).toFixed(2)}</i>
                                                            </td>
                                                            <td className='product-remove' onClick={() => {
                                                                dispatch({
                                                                    type: "REMOVE_FROM_CART",
                                                                    payload: item
                                                                })
                                                            }}><a href='#'>Remove</a></td>
                                                        </tr>
                                                    )

                                                })}


                                          
                                       


                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='cartBtns'>
                            <button className='btn cartBtn2 cartBtn2-solidBlack mb-3 mb-md-0' onClick={(e) => {
                                e.preventDefault();
                                dispatch({
                                    type: "EMPTY_CART",
                                })
                            }}> Clear Shopping Cart  </button>
                            {/* <a href='#' className='btn cartBtn2 updateCart mb-3 mb-md-0' disabled aria-disabled="true"> Update Cart </a> */}
                            <Link to='/shop/supplements' className='btn cartBtn2 cartBtn2-solidBlack ml-auto'> Back To Shop </Link>
                        </div>

                    </form>


                    {/* cart-collaterals  */}
                    <div className='cart-collaterals'>
                        <div className='row'>
                            <div className='col-md-6'>
                                {/* <div className="coupon">
                                    <h2>Coupon discount</h2>
                                    <form method="post" className="at_coupon_form">
                                        <input type="text" name="coupon_code" className="input-text" id="coupon_code" value="" placeholder="Enter your coupon code if you have one" />
                                        <input type="submit" className="button" name="apply_coupon" value="Apply Coupon" />
                                    </form>
                                </div> */}
                            </div>
                            <div className='col-md-6'>
                                <div className="cart_totals calculated_shipping">
                                    {/* <h2>Cart totals</h2> */}
                                    <div class="table-responsive">
                                        <table cellspacing="0" className="shop_table">
                                            <tbody>
                                                <tr className="cart-subtotal">
                                                    <th>Subtotal</th>
                                                    <td data-title="Subtotal"><span className="woocommerce-Price-amount amount"><bdi><span className="woocommerce-Price-currencySymbol">$</span>{parseFloat(total).toFixed(2)}</bdi></span></td>
                                                </tr>
                                                {discount ? <Discounts /> : null}
                                                <tr className="order-total">
                                                    <th>Total</th>
                                                    <td data-title="Total"><strong><span className="woocommerce-Price-amount amount"><bdi><span className="woocommerce-Price-currencySymbol">$</span>{discount ? parseFloat((parseFloat(total).toFixed(2) - discount)).toFixed(2) : parseFloat(total).toFixed(2)}</bdi></span></strong> </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="wc-proceed-to-checkout">
                                        <Link to="/checkout-2" className="btn cartBtn2 cartBtn2-solidBlack w-100">Proceed to checkout</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* cart-collaterals End  */}
               </> ) : (<> 
                
                <div className='emptyCartPage'>
                    <div className='text-center'>
                        <img width={150} src={emptyCart} alt='' className='mb-2' />
                        <h2>Your Cart is Empty!</h2>
                        <p>Start shopping now to find great deals.</p>
                        <Link to='/shop/supplements' className='btn cartBtn2 cartBtn2-solidBlack ml-auto'> Back To Shop </Link>
                    </div>
                </div>
                
                </>)}
                </div>
            </section>
        </>

    )
}

export default Cart2