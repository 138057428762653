import React, { useEffect } from "react";
import pill from '../../assests/frontend/assets/images/pill.png';
import lotion from '../../assests/frontend/assets/images/Lotion.png';
import gummies from '../../assests/frontend/assets/images/gummies-icon.png';
import cclogos from '../../assests/frontend/assets/images/cclogos.png';
import mcafee from '../../assests/frontend/assets/images/mcafee.png';
import norton from '../../assests/frontend/assets/images/norton.png';
import leaf1 from '../../assests/frontend/assets/images/leaf-left.png';
import leaf2 from '../../assests/frontend/assets/images/leaf-right.png';
import leaf3 from '../../assests/frontend/assets/images/leaf-right-leftfaced.png';
import moneyBack from '../../assests/frontend/assets/images/30-day-money-back.png';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Copyright from "../Copyright";



function Landing2() {
    const location = useLocation();
    let params = location.search;
    
    const email = localStorage.getItem('V1email');
    if(email){
        //console.log('Name exists');
    }else{
        localStorage.setItem("V1email",'')
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    
    //console.log(location.search);
    return (
        <>
            <div className="landingPage">

                <section className="landingMain">
                    <div className="container">
                        <div className="prieCard-cont">
                            <div className="prieCard mx-auto">
                                <img src={leaf1} className="leaf-1" />
                                <img src={leaf2} className="leaf-2" />
                                <img src={leaf3} className="leaf-3" />
                                <div className="priecCard-hdr">
                                    <h3 className="priceTitle">PLATINUM</h3>
                                </div>
                                <div className="priceInfoCir">
                                    <p className="priceInfo-title">$21 <sup>.00</sup></p>
                                    <p className="priceDur">per month</p>
                                </div>
                                <div className="prieCard-signUp text-center">
                                    <Link to={`/order-step-1${params}`} className="btn btn_com">Sign Up</Link>
                                </div>
                                <div className="prieCard-body">
                                    <h3 className="priceCard-title">Standard PLUS </h3>
                                    <ul className="priceLists">
                                        <li>
                                            <div className="iconText">
                                                <span className="icon"><img src={pill} /></span>
                                                <span className="text">Supplements</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="iconText">
                                                <span className="icon"><img src={lotion} /></span>
                                                <span className="text">Skin Care </span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="iconText">
                                                <span className="icon"><img src={gummies} /></span>
                                                <span className="text">Gummies </span>
                                            </div>
                                        </li>
                                        <li>1 Referral =<br /> 1 Month FREE</li>
                                        <li>Buy 5 Bottles or More<br /> Get 1 Bottle FREE</li>
                                        <li>BONUS eBooks</li>
                                    </ul>
                                </div>
                                <div className="prieCard-footer">
                                    <div className="buyBtn-div text-center">
                                        <Link to={`/order-step-1${params}`} className='btn btn_com'>Buy Now</Link>
                                        {/* <a href="#" className="btn btn_com">Buy Now</a> */}
                                    </div>
                                    <div className="visaCard text-center">
                                        <img src={cclogos} className="img-fluid" />
                                    </div>
                                    <div className="antiVirus text-center">
                                        <img src={norton} className="img-fluid" />
                                        <img src={mcafee} className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="guranteeSection">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-lg-11 mx-auto">
                                <div className="row align-items-center">
                                    <div className="col-md-3">
                                        <img src={moneyBack} className="img-fluid" />
                                    </div>
                                    <div className="col-md-9">
                                        <p className="guranteeText">100% 30 DAYS MONEY BACK GUARANTEE <br />
                                            Wholesale Health Club comes with a 30 Days, 100% Money Back Guarantee. That means if you change your mind about this decision at any point in the next 30 days all you need to do is email us, and we'll refund your purchase.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}


export default Landing2