import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import CustomErrorMsg from './CustomErrorMsg';


export default function ResetPassword2() {
    let navigate = useNavigate(); 
    let  resetSubmit=(data)=>{

        axios.post(`api/login_reset_password`, data).then(res => {
            // 4013
        console.log(res.data);
        if (res.data.response_code === '100') {
            Swal.fire({
                title: 'Success',
                html: "Your Password Has Been Reset Successfully",
                icon: 'success',
                showConfirmButton: true,
                // timer: 100
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate("/login");
                }
            });
            
        }
        else{
            Swal.fire({
                title: 'Please see the below response',
                html: res.data.response_message,
                icon: 'error',
                showConfirmButton: false,
                showCancelButton: true,
                // timer: 4000
            });
        }
    }).catch(err => {
        console.log(err)
     
        Swal.fire({
            title: 'error',
            html: err.response.data.message,
            icon: 'error',
            showConfirmButton: false,
            showCancelButton: true,
        })
    });
}

return (
    <Formik
        initialValues={{ password: '', temp_password: '', confirmPassword:'', email: '' }}
        validationSchema={Yup.object({
            email: Yup.string().email('Invalid email address').required('Required'),
            temp_password: Yup.string()
                .required('Temp Password is required')
                .min(8, 'Password must be at least 8 characters')
                .max(40, 'Password must not exceed 40 characters'),
            password: Yup.string()
                .required('Password is required')
                .min(8, 'Password must be at least 8 characters')
                .max(40, 'Password must not exceed 40 characters'),
            confirmPassword: Yup.string()
                .required('Confirm Password is required')
                .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
        })}
        onSubmit={(values, { setSubmitting }) => {
            setSubmitting (true);
            // alert(JSON.stringify(values, null, 2));
            resetSubmit(values);
        
        }}
    >
    {/* Main Begin  */}
    <main className="nw_main">
        <section className="nwSignin_section">
            <div className="container">
                <div className="nwSignin_wrapper">
                    <div className="nwSignin_inner text-center">
                        <p>Reset Your Temp. Password</p>            
                        {/* Form Begin  */}
                        <Form className="nwLogin_form">
                                <div className="form-group">
                                    <Field className='form-control' type='email' name='email' placeholder="Enter Email Id" />
                                    <CustomErrorMsg name="email" />
                                </div>
                                <div className="form-group">
                                    <Field className='form-control' type='password' name='temp_password' placeholder="Enter Temp. password" />
                                    <CustomErrorMsg name="temp_password" />
                                </div>
                                <div className="form-group">
                                    <Field className='form-control' type='password' name='password' placeholder="Enter password" />
                                    <CustomErrorMsg name="password" />
                                </div>
                                <div className="form-group">
                                    <Field className='form-control' type='password' name='confirmPassword' placeholder="Enter confirm password" />
                                    <CustomErrorMsg name='confirmPassword'/>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn nwBtn-succes w-100">Reset Password</button>
                                </div>
                        </Form>
                    </div>
                </div>
            </div>
        </section>
    </main> 
    </Formik>
    )
}
