
import React, {useState} from "react";
import { Link } from "react-router-dom";
import styles from '../../assests/admin/css/Admin.module.css';
import cross from '../../assests/admin/assets/img/close.png';

function ProspectReport(){
    
    const [show, setShow] = useState(false);

    return (
        <>
            <div className={styles.pageWrapper}>
                <div className='mb-4'>
                    <h2 className={styles.pageTitle}>Analytics & Reports</h2>
                </div>
                <div className='prospectSearch-outter'>
                    <div className='form-inline row mb-4'>
                        <div className='form-group col-md-7'>
                            <div className={styles.serachDiv}>
                                <input className='form-control' type='text' placeholder='Filter dashboards by keyword...'  />
                                <span className={styles.clearBtn}><img src={cross} /></span>
                            </div>
                        </div>
                        <div className='form-group d-inline-flex align-items-center col-md-4'>
                            <label className='mb-md-0 me-2'>Type:</label>
                            <select className='form-control'>
                                <option>Reports</option>
                            </select>
                        </div>
                    </div>
                </div>
                <h3>Sales</h3>
                <hr />
                <div className='row'>
                    <div className='col-md-4 mb-4'>
                        <Link to="/admin/prospect-detail" className={styles.reportCard}>
                            <div className={styles.reportCard__body}>
                                <h3 className={styles.reportCard__bodytitle}>Sales By Prospect</h3>
                                <p class={styles.reportCard__desc}>Assess sales by individuals that visited and completed a portion of their informaiton buy have not placed an order to help determine what you need to do to get them to order.</p>
                            </div>
                            {/* <div className={styles.reportCard__footer}>
                                Updated: 2/18
                            </div> */}
                        </Link>
                    </div>
                    <div className='col-md-4 mb-4'>
                        <a href="/admin/report-by-product" className={styles.reportCard}>
                            <div className={styles.reportCard__body}>
                                <h3 className={styles.reportCard__bodytitle}>Sales By Product</h3>
                                <p class={styles.reportCard__desc}>Evaluate product performance data elements such as revenue, cancelations and refunds.</p>
                            </div>
                            {/* <div className={styles.reportCard__footer}>
                                Updated: 2/18
                            </div> */}
                        </a>
                    </div>
                    <div className='col-md-4 mb-4'>
                        <a href="#" className={styles.reportCard}>
                            <div className={styles.reportCard__body}>
                                <h3 className={styles.reportCard__bodytitle}>Sales By Date</h3>
                                <p class={styles.reportCard__desc}>Track, monitor and appraise daily sales data elements such as revenue, cancelations and refunds.</p>
                            </div>
                            {/* <div className={styles.reportCard__footer}>
                                Updated: 2/18
                            </div> */}
                        </a>
                    </div>
                    <div className='col-md-4 mb-4'>
                        <a href="#" className={styles.reportCard}>
                            <div className={styles.reportCard__body}>
                                <h3 className={styles.reportCard__bodytitle}>Sales By Campaign</h3>
                                <p class={styles.reportCard__desc}>Analyze campaign data elements such as revenue, cancelations and refunds to help access campaign performance.</p>
                            </div>
                            {/* <div className={styles.reportCard__footer}>
                                Updated: 2/18
                            </div> */}
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProspectReport;