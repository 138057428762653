import React, { useState, useEffect } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom"; // v6
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faDeleteLeft } from '@fortawesome/free-solid-svg-icons'
import {BASE_URL} from '../Config';


function AddProds() {
    const navigate = useNavigate();
    const [productList, setproductLists] = useState([]);
    const [pending, setPending] = useState(true);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [loadershow, setloaderShow] = useState(false);

    const loaderHandleClose = () => setloaderShow(false);
    const loaderHandleShow = () => setloaderShow(true);

    const [productInstance, setProductInstance] = useState({
        pname: "",
        ptype: "0",
        price: "",
        disc: "",
        disclaimer: "",
        ingri: "",
        category: "0",
        validErr: ""
    });

    const [bimage, setBimage] = useState("");
    const [limage, setLimage] = useState("");

    const [productId, setproductId] = useState(false);

    const [errors, setErros] = useState({});
    const [isPSubmit, setIsPSubmit] = useState(false);

    const validate = (productInstance) => {
        const errors = {};
        if (!productInstance.pname) {
            errors.pname = "Must provide a Product name";
        }
        console.warn(productInstance.ptype);
        if (productInstance.ptype == "0") {
            errors.ptype = "Must Select a Product type!";
        }

        if (!productInstance.price) {
            errors.price = "Must provide a Product price!";
        }

        if (!productInstance.disc) {
            errors.disc = "Must provide a Description";
        }
        if (!productInstance.disclaimer) {
            errors.disclaimer = "Must provide a Disclaimer";
        }

        if (!productInstance.ingri) {
            errors.ingri = "Must provide an Ingredients";
        }

        if (productInstance.category == "0") {
            errors.category = "Must select a Category";
        }

        if (!bimage) {
            errors.bimage = "Must select an Product image";
        }

        if (!limage) {
            errors.limage = "Must select an Label image";
        }
        return errors;
    }

    const handelInput = (e) => {
        if (productId == false) {
            setErros(validate(productInstance));
        }
        e.persist();
        setProductInstance({ ...productInstance, [e.target.name]: e.target.value })
        setIsPSubmit(true);

    }

    const submitProduct = (e) => {
        e.preventDefault();
        setErros(validate(productInstance));
        if (Object.keys(errors).length === 0 && isPSubmit) {
            handleClose();
            loaderHandleShow();
            const formdata = new FormData();
            formdata.append('category_id', productInstance.category);
            formdata.append('name', productInstance.pname);
            formdata.append('product_type', productInstance.ptype);
            formdata.append('description', productInstance.disc);
            formdata.append('disclaimer', productInstance.disclaimer);
            formdata.append('ingredients', productInstance.ingri);

            const imageFormdata = new FormData;
            imageFormdata.append('image', bimage);

            const labelFormdata = new FormData;
            labelFormdata.append('label_image', limage);

            const data = {
                category_id: productInstance.category,
                name: productInstance.pname,
                product_type: productInstance.ptype,
                description: productInstance.disc,
                disclaimer: productInstance.disclaimer,
                ingredients: productInstance.ingri,
                image: bimage.name,
                label_image: limage.name,
                price: productInstance.price
            }
            // console.warn(formdata);

            axios.post(`api/imageUpload`, imageFormdata).then(res => {
                if (res.data.status === true) {
                    formdata.append('image', res.data.data);
                    axios.post(`api/labelImageUpload`, labelFormdata).then(res => {
                        if (res.data.status === true) {
                            formdata.append('label_image', res.data.data);
                            formdata.append('price', productInstance.price);
                            axios.post(`api/createproduct`, formdata).then(res => {
                                if (res.data.status === true) {
                                    handleClose();
                                    loaderHandleClose();
                                    Swal.fire({
                                        title: 'Success',
                                        html: res.data.message,
                                        icon: 'success',
                                        showConfirmButton: false,
                                        timer: 1000
                                    })
                                    getProductList();
                                }
                            }).catch(err => {
                                console.log(err.response)
                                loaderHandleClose();
                                window.location.reload();
                                Swal.fire({
                                    title: 'Warning',
                                    html: err.response.data.message,
                                    icon: 'warning'
                                })

                            });
                        }
                    }).catch(err => {
                        console.log(err.response)
                        loaderHandleClose();
                        Swal.fire({
                            title: 'Warning',
                            html: err.response.data.message,
                            icon: 'warning'
                        })

                    });
                }
            }).catch(err => {
                console.log(err.response)
                loaderHandleClose();
                Swal.fire({
                    title: 'Warning',
                    html: err.response.data.message,
                    icon: 'warning'
                })

            });
        }

    }
    async function getProductList() {

        axios.get(`api/listproduct`).then(res => {
            // console.warn(res.data.data);
            setproductLists(res.data.data);
            setPending(false);

        }).catch(err => {

            console.log(err.response)
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })

        }
        )
    }
    function deleteProduct(id) {
        Swal.fire({
            title: 'Are you sure you want to delete this Product?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`api/deleteproduct/${id}`).then(res => {

                    Swal.fire({
                        title: 'Deleted',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    window.location.reload();
                })
            }
        })
    }

    const [productDetails, setProductDetails] = useState([]);
    function showSingleData(id) {

        axios.get(`api/detailsproduct/${id}`).then(res => {
            setproductId(res.data.id);
            setBimage(res.data.image);
            setLimage(res.data.label_image);
            setPimg(BASE_URL+"whc-custom/storage/app/public/" + res.data.image);
            setLimg(BASE_URL+"whc-custom/storage/app/public/" + res.data.label_image);
            setProductDetails(res.data);
            setErros({});
            handleShow();
        })
    }

    function createProductModal() {
        setProductDetails([]);
        setproductId(false);
        setErros({});
        setBimage('');
        setLimage('');
        setPimg('');
        setLimg('');
        handleShow();
    }

    function updateProduct() {
        setErros(validate(productInstance));
        if (Object.keys(errors).length === 0) {
            handleClose();
            loaderHandleShow();
            const formdata = new FormData();
            if (typeof bimage != "string") {
                const imageFormdata = new FormData;
                imageFormdata.append('image', bimage);
                axios.post(`api/updateimageUpload`, imageFormdata).then(res => {
                    if (res.data.status === true) {
                        formdata.append('image', res.data.data);
                    }
                })
            }
            else {
                formdata.append('image', productDetails.image);
            }

            if (typeof limage != "string") {
                const labelFormdata = new FormData;
                labelFormdata.append('label_image', limage);
                axios.post(`api/updatelabelimageUpload`, labelFormdata).then(res => {
                    if (res.data.status === true) {
                        formdata.append('label_image', res.data.data);
                    }
                })
            }
            else {
                formdata.append('label_image', productDetails.label_image);
            }

            formdata.append('price', productInstance.price ? productInstance.price : productDetails.price);
            formdata.append('category_id', productInstance.category != "0" ? productInstance.category : productDetails.category_id);
            formdata.append('name', productInstance.pname ? productInstance.pname : productDetails.name);
            formdata.append('product_type', productInstance.ptype != "0" ? productInstance.ptype : productDetails.product_type);
            formdata.append('description', productInstance.disc ? productInstance.disc : productDetails.description);
            formdata.append('disclaimer', productInstance.disclaimer ? productInstance.disclaimer : productDetails.disclaimer);
            formdata.append('ingredients', productInstance.ingri ? productInstance.ingri : productDetails.ingredients);
            console.warn(formdata);
            axios.post(`api/updateproduct`, formdata).then(res => {
                if (res.data.status === true) {
                    handleClose();
                    loaderHandleClose();
                    Swal.fire({
                        title: 'Success',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    getProductList();
                }
            }).catch(err => {
                console.log(err.response)
                loaderHandleClose();
                //window.location.reload();
                Swal.fire({
                    title: 'Warning',
                    html: err.response.data.message,
                    icon: 'warning'
                })

            });
        }
    }

    const [Pimg, setPimg] = useState();
    const [Limg, setLimg] = useState();
    const onPImageChange = (e) => {
        const [file] = e.target.files;
        setBimage(e.target.files[0]);
        setPimg(URL.createObjectURL(file));
    };

    const onLImageChange = (e) => {
        const [file] = e.target.files;
        setLimage(e.target.files[0]);
        setLimg(URL.createObjectURL(file));
    };

    const columns = [
        {
            name: "Product Name",
            selector: row => row.name
        },
        {
            name: "Product Type",
            selector: row => row.product_type
        },
        {
            name: "Price",
            cell: row => (
                <span>${row.price}</span>
            )
        },
        {
            name: "Product Image",
            cell: row => (
                <img src={row.image} width="100" height="50" />
            )
        },
        {
            name: "Label Image",
            cell: row => (

                <img src={row.label_image} width="100" height="50" />

            )
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <button style={{ marginRight: 1 + 'em' }} className='btn btn-primary' onClick={() => { showSingleData(row.id) }} title="Edit Product" ><FontAwesomeIcon icon={faGear} title='Edit Product' /></button>
                    <button className='btn btn-danger' onClick={() => { deleteProduct(row.id) }} ><FontAwesomeIcon icon={faDeleteLeft} /></button>
                </>
            )
        }
    ]
    useEffect(() => { getProductList(); }, []);
    return (
        <div>

            <div className='container py-5'>
                <>
                    <Button variant="primary" onClick={createProductModal}>
                        Create Product
                    </Button>

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Create Product</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-md-12'>
                                        <div className='card'>
                                            <div className='card-body'>
                                                <form >
                                                    <div className='form-group md-3'>
                                                        <label> Name </label>
                                                        <input type="" name="pname" className="form-control" defaultValue={productDetails.name} onChange={handelInput}></input>
                                                    </div>
                                                    {errors.pname && <p class="text-danger">{errors.pname}</p>}

                                                    <div className='form-group md-3'>
                                                        <label> Product Type </label>
                                                        <select name="ptype" className="form-control" defaultValue={productDetails.product_type} onChange={handelInput}>
                                                            <option value="0" selected>Select Product Type</option>
                                                            <option value="Regular">Regular</option>
                                                            <option value="Membership">Membership</option>
                                                        </select>
                                                    </div>
                                                    {errors.ptype && <p class="text-danger">{errors.ptype}</p>}

                                                    <div className='form-group md-3'>
                                                        <label> Price</label>
                                                        <input type="" name="price" className="form-control" defaultValue={productDetails.price} onChange={handelInput}></input>
                                                    </div>
                                                    {errors.price && <p class="text-danger">{errors.price}</p>}

                                                    <div className='form-group md-3'>
                                                        <label> Description</label>
                                                        <input type="" name="disc" className="form-control" defaultValue={productDetails.description} onChange={handelInput}></input>
                                                    </div>
                                                    {errors.disc && <p class="text-danger">{errors.disc}</p>}

                                                    <div className='form-group md-3'>
                                                        <label> Disclaimer</label>
                                                        <input type="" name="disclaimer" className="form-control" defaultValue={productDetails.disclaimer} onChange={handelInput}></input>
                                                    </div>
                                                    {errors.disclaimer && <p class="text-danger">{errors.disclaimer}</p>}

                                                    <div className='form-group md-3'>
                                                        <label> Ingredients</label>
                                                        <input type="" name="ingri" className="form-control" defaultValue={productDetails.ingredients} onChange={handelInput}></input>
                                                    </div>
                                                    {errors.ingri && <p class="text-danger">{errors.ingri}</p>}

                                                    <div className='form-group md-3'>
                                                        <label> Category</label>
                                                        <select name="category" className="form-control" defaultValue={productDetails.category_id} onChange={handelInput}>
                                                            <option value="0" selected>Select Category</option>
                                                            <option value="1">Category-1</option>
                                                            <option value="2">Category-2</option>
                                                        </select>
                                                    </div>
                                                    {errors.category && <p class="text-danger">{errors.category}</p>}

                                                    <div className='form-group md-3'>
                                                        <label>Botle Image</label>
                                                        <input type="file" name="bimage" className="form-control" onChange={onPImageChange}></input>
                                                        <img width={100} src={Pimg} />
                                                    </div>
                                                    {errors.bimage && <p class="text-danger">{errors.bimage}</p>}

                                                    <div className='form-group md-3'>
                                                        <label>Lable Image</label>
                                                        <input type="file" name="limage" className="form-control" onChange={onLImageChange}></input>
                                                        <img width={100} src={Limg} />
                                                    </div>
                                                    {errors.limage && <p class="text-danger">{errors.limage}</p>}

                                                    <br />
                                                    <div className='form-group md-3 d-none'>
                                                        <Button type="submit">Save!</Button>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={productId ? updateProduct : submitProduct}>
                                {productId ? "Update" : "Save"}
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={loadershow} className="text-center">
                        <Modal.Header>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Spinner animation="grow" />
                        </Modal.Body>
                        <Modal.Footer></Modal.Footer>
                    </Modal>
                </>

            </div>
            <div className='container'>
                <div className='col-md-12 pl-3'>
                    <h1 className="display-6">Product List</h1>
                    <div className='card'>
                        <div className='card-body'>
                            <DataTable
                                noDataComponent="Product table is empty" //or your component
                                columns={columns}
                                data={productList}
                                progressPending={pending}
                                pagination
                                fixedHeader
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddProds