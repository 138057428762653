import React, { useState, useEffect } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom"; // v6
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faDeleteLeft, faEye } from '@fortawesome/free-solid-svg-icons'
import CheckPermission from '../frontend/auth/CheckPermission';
import {BASE_URL} from '../Config';
import { Formik, Field, Form, useField } from 'formik';
import * as Yup from 'yup';
import { MultiSelect } from "react-multi-select-component";
import CustomErrorMsg from "../../components/frontend/CustomErrorMsg";

function AssignementUpsell() {
    CheckPermission()
    const [Instance, setInstance] = useState({
        upsell_name: "",
        upsell_slug: "",
        upsell_file_name: "",
        upsell_access_product : ""
    });
    const [slug, setslug] = useState('')
    const [show, setShow] = useState(false);
    const [List, setList] = useState([]);
    const [pending, setPending] = useState(true);

    const [errors, setErros] = useState({});
    const [isPSubmit, setIsPSubmit] = useState(false);
    const [Details, setDetails] = useState([]);
    const [Id, setId] = useState(false);
    const [search, setSearch] = useState("");
    const [searchTable, setsearchTable] = useState([]);
    const [getOption,setoption] = useState([]);
    const [initialdata,setInitialData] = useState([]);

    const handleClose = () => {
        setShow(false)
        setSearch('')
    }
    const handleShow = () => setShow(true);

    const validate = (Instance) => {
        
        const errors = {};
        
        if (!Instance.upsell_name) {
            errors.upsell_name = "Must Provide a upsell name";
        }

        if (!Instance.upsell_slug) {
            errors.upsell_slug = "Must provide a upsell slug";
        }

        if (!Instance.upsell_file_name) {
            errors.upsell_file_name = "Must select a upsell file name";
        }

        if(!Instance.upsell_access_product){
            
            errors.upsell_access_product = "Must select at least one initial product";
        }
        return errors;
    }

    const handelInput = (e) => {
        
            var valueLander = e.target.value.trim().toLowerCase();
            var slugArrSplit = valueLander.split(' ')
            var slug = slugArrSplit.join('-');
            setslug(slug)
            axios.get(`api/landerSlugcheck/${slug}`).then(res => {
                if (!res.data.status) {
                    errors.upsell_slug = res.data.message;
                }
                else {
                    errors.upsell_slug = '';
                }
            })

    }

    async function getList() {
        axios.get(`api/listUpsell`).then(res => {
            console.warn(res.data.data);
            setList(res.data.data);
            setsearchTable(res.data.data)
            setPending(false);
        })

    }
    const columns = [
        {
            name: "Name",
            selector: row => row.upsell_name
        },
        {
            name: "Slug",
            selector: row => row.upsell_slug
        },
        {
            name: "Template Name",
            cell: row => (
                <span>{row.upsell_file_name}</span>
            )
        },
        {
            name: "Created",
            cell: row => (
                <span>{row.created_at.split("T")[0]}</span>
            )
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <a style={{ marginRight: 1 + 'em' }} target='_blank' className='btn btn-primary' href={'/' + row.upsell_slug} ><FontAwesomeIcon icon={faEye} /></a>

                    <button style={{ marginRight: 1 + 'em' }} className='btn btn-primary' onClick={() => { showSingleData(row.id) }} ><FontAwesomeIcon icon={faGear} /></button>
                    <button className='btn btn-danger' onClick={() => { deleteData(row.id) }} ><FontAwesomeIcon icon={faDeleteLeft} /></button>
                </>
            )
        }
    ]
    useEffect(() => {
        getList()
    }, []);


    function createModal() {
        setDetails([]);
        setId(false);
        
        axios.get(`api/getInitialProduct`).then(res => {
            setoption(res.data.products)
        })

        setInitialData({
            upsell_name: '',
            upsell_file_name: '',
            upsell_slug: '', 
            selectedOptions: [], 
            mode : 'add'
        })
        
        setErros({});
        handleShow();
    }

    const submit = (data) => {
            // handleClose();
            const formdata = new FormData();
            formdata.append('upsell_name', data.upsell_name);
            formdata.append('upsell_slug', data.upsell_slug);
            formdata.append('upsell_file_name', data.upsell_file_name);
            formdata.append('initial_product_id', JSON.stringify(data.selectedOptions));
            axios.post(`api/createUpsell`, formdata).then(res => {
                if (res.data.status === true) {
                    handleClose();
                    Swal.fire({
                        title: 'Success',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    getList();
                    //window.location.reload();
                }
                else {
                    Swal.fire({
                        title: 'Warning',
                        html: res.data.message,
                        icon: 'warning'
                    })
                }
            }).catch(err => {
                console.log(err.response)
                Swal.fire({
                    title: 'Warning',
                    html: err.response.data.message,
                    icon: 'warning'
                })

            });
    }

    function deleteData(id) {
        Swal.fire({
            title: 'Are you sure you want to delete this Upsell?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showCloseButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`api/deleteUpsell/${id}`).then(res => {

                    if (res.data.status) {
                        Swal.fire({
                            title: 'Deleted',
                            html: res.data.message,
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1000
                        })
                        getList();
                    }
                    else {
                        Swal.fire({
                            title: 'Warning',
                            html: res.data.message,
                            icon: 'warning'
                        })
                    }
                })
            }
        })
    }

    function showSingleData(id) {
        //console.warn(id);
        setId(id);
        axios.get(`api/detailsUpsell/${id}`).then(res => {
            setslug(res.data.upsell_slug)
            setoption(res.data.products)
            setDetails(res.data);
            
            if(res.data.upsell_access_permission !== "undefined" && res.data.upsell_access_permission !== null && Object.keys(res.data.upsell_access_permission).length > 0){
                const access_permission = JSON.parse(res.data.upsell_access_permission)
                // if(access_permission[0].value > 0){
                //     setSelectedValues(access_permission)
                // }else{
                //     setSelectedValues([])
                // }
                setInitialData({
                    upsell_name: res.data.upsell_name,
                    upsell_file_name: res.data.upsell_file_name,
                    upsell_slug: res.data.upsell_slug, 
                    selectedOptions: access_permission, 
                    mode : 'update'
                })
            }else{
                setInitialData({
                    upsell_name: res.data.upsell_name,
                    upsell_file_name: res.data.upsell_file_name,
                    upsell_slug: res.data.upsell_slug, 
                    selectedOptions: [], 
                    mode : 'update'
                })
            }

            setErros({});
            handleShow();
        })
    }

    const handleNewField = (value) => (console.log(value));
    // useEffect(() => {
    //     console.log(selectedValues) 
    // },[selectedValues])

    const navigate = useNavigate();

    function update(data) {
            handleClose();
            const formdata = new FormData();
            formdata.append('upsell_name', data.upsell_name);
            formdata.append('upsell_slug', data.upsell_slug);
            formdata.append('upsell_file_name', data.upsell_file_name);
            formdata.append('initial_product_id', JSON.stringify(data.selectedOptions));
            axios.post(`api/updateUpsell/${Id}`, formdata).then(res => {
                if (res.data.status === true) {
                    handleClose();
                    Swal.fire({
                        title: 'Success',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    // window.location.reload();
                    getList();
                }
                else {
                    Swal.fire({
                        title: 'Warning',
                        html: res.data.message,
                        icon: 'warning'
                    })
                }
            }).catch(err => {
                console.log(err.response)
                //window.location.reload();
                Swal.fire({
                    title: 'Warning',
                    html: err.response.data.message,
                    icon: 'warning'
                })

            });
    }

    useEffect(() => {
        const result = List.filter(country => {
            return country.upsell_name.toLowerCase().match(search.toLowerCase());
        })
        setsearchTable(result);
    }, [search]);

    return (
        <div>
            <div >
                <div className='modal'>


                    <Modal show={show} onHide={handleClose}>
                    <Formik
                        enableReinitialize = {true}
                        initialValues={initialdata}
                        validationSchema={Yup.object({
                            upsell_name: Yup.string().required('Upsell Name is Required'),
                            upsell_file_name: Yup.string().required('Upsell File Name is Required'),
                            upsell_slug: Yup.string().required('Upsell Slug is Required'),
                            selectedOptions: Yup.array()
                            .min(1, 'Please select at least one initial product')
                            .of(
                                Yup.object().shape({
                                label: Yup.string().required(),
                                value: Yup.string().required(),
                                })
                            ),
                            
                        })}

                        onSubmit={(values,{ setSubmitting , resetForm }) => {
                            setSubmitting (true);
                            console.log(values)
                            if(values.mode == 'add'){
                                submit(values);
                            }else{
                                update(values);
                            }
                            resetForm(initialdata);
                            
                        }}
                    >
                        {(values) => (
                            <Form className='row col-md-12'>
                            <Modal.Header closeButton>
                                <Modal.Title>Upsell Page</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-md-12'>
                                        <div className='card'>
                                            <div className='card-body' style={{ minHeight: 'auto', overflowY: 'scroll' }}>
                                            
                                            
                                                {/* <form > */}
                                                    <div className='form-group md-3'>
                                                        <label>Page Name </label>
                                                        <Field type="text" name="upsell_name" className="form-control" />
                                                        <CustomErrorMsg name="upsell_name" />
                                                    </div>
                                                    <div className='form-group md-3'>
                                                        <div className="headerCus  col-md-12 mt-4">
                                                            <div className='row align-items-center' >
                                                                <div className='col-md-2' style={{ textAlign: 'left' }}>
                                                                    Slug
                                                                </div>
                                                                <div className='col-md-10'>
                                                                    <Field name="upsell_slug" type="text" className="form-control" onChangeCapture={e => {handelInput(e)} } />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <CustomErrorMsg name="upsell_slug" />

                                                    <div className='form-group md-3'>
                                                        <div className="headerCus  col-md-12 mt-4">
                                                            <div className='row align-items-center' >
                                                                <div className='col-md-12' style={{ textAlign: 'left' }}>
                                                                    URL: {BASE_URL}{slug}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='form-group md-3'>
                                                        <label>Select Upsell Page </label>
                                                        <Field as="select" type="" name="upsell_file_name" className="form-control mm-select-list">
                                                            <option value="0">Select Upsell</option>
                                                            <option value="sleep-formula">Sleep Formula</option>
                                                            <option value="total-cleanse-formula">Total Cleanse Formula</option>
                                                            <option value="double-offer">Double Offer</option>
                                                            <option value="blue-forrest-goddess-eye-cream">Blue Forrest Goddess Eye Cream</option>
                                                            <option value="neobod-upsell">Neobod Upsell</option>
                                                            <option value="healthy-living-product-total-men-health">Healthy Living Product’s Total Men’s Health</option>
                                                        </Field>
                                                    </div>
                                                    <CustomErrorMsg name="upsell_file_name" />

                                                    <Field name="selectedOptions">
                                                    {({ field: { value }, form: { setFieldValue } }) => (
                                                    <div className='form-group md-3'>
                                                        <label>Access Permission By Product</label>
                                                        <MultiSelect
                                                            options={getOption}
                                                            value={value}
                                                            onChange={(newValue) => {
                                                            setFieldValue('selectedOptions', newValue);
                                                            }}
                                                        />
                                                    </div>
                                                    )}
                                                    </Field>
                                                    <CustomErrorMsg name="selectedOptions" />
                                                    <div className='form-group md-3 d-none'>
                                                        <Button type="submit">Save!</Button>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit">
                                {Id ? "Update" : "Save"}
                            </Button>
                        </Modal.Footer>
                        </Form>
                        )}
                </Formik>
            </Modal>
        </div>

            </div>
            <div className='container'>
                <div className='col-md-12 pl-3'>
                    <div className="pageheader card mb-4">
                        <div className="card-body row align-items-center">
                            <div className="page-name col-md-3">Upsell List</div>
                            <div className="buttun_div  col-md-9">  <Button variant="primary" onClick={createModal}>
                                Create Upsell
                            </Button>
                            </div>
                        </div>
                    </div>
                    <h1 className="display-6"></h1>
                    <div className='card '>
                        <div className='card-body '>
                            <div className='datatable '>
                                <DataTable
                                    noDataComponent="Upsell table is empty" //or your component
                                    columns={columns}
                                    data={searchTable}
                                    progressPending={pending}
                                    pagination
                                    fixedHeader
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text" placeholder="search" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                                    }
                                    subHeaderAlign="right"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssignementUpsell