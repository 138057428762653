import { update } from 'lodash';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import useStore from '../../store';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

function Navbar() {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const afterLogout = useStore((state) => state.afterLogout)
    const logoutSubmit = e => {
        e.preventDefault();
        localStorage.removeItem('authToken');
        localStorage.removeItem('bearer');
        localStorage.removeItem('permission');
        localStorage.removeItem('loggedInAs');
        afterLogout()
        Swal.fire({
            title: 'Logout!',
            html: 'Logout Successful',
            icon: 'success'
        })
        navigate("/admin");
    }
    return (
        <>
            <nav className="sb-topnav navbar navbar-expand py-0 navbar-dark navbar-custom shadow" >
                <Link className="navbar-brand ps-3" to="/admin/dashboard">Wholesale Health Club</Link>
                <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!"><i className="fas fa-bars"></i></button>
                {/* <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
                    <li className="nav-item dropdown">
                        <Link className="nav-link dropdown-toggle" id="navbarDropdown" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-user fa-fw"></i></Link>
                        <ul className="dropdown-menu dropdown-menu-end show" aria-labelledby="navbarDropdown">
                            <li><Link className="dropdown-item" to="#">Profile</Link></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><Link className="dropdown-item" onClick={logoutSubmit} to="#">Logout</Link></li>
                        </ul>
                    </li>
                </ul> */}
                <div className="dropdown_button_wrapper">
                    <DropdownButton id="dropdown-basic-button" title="">
                        <Dropdown.Item to="#">Profile</Dropdown.Item>
                        <Dropdown.Item to="#" onClick={logoutSubmit} >Logout</Dropdown.Item>
                    </DropdownButton>
                </div>
                
            </nav>
        </>
    )
}

export default Navbar