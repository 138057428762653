import React, { useState, useEffect } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom"; // v6
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faDeleteLeft, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons'
import CheckPermission from '../frontend/auth/CheckPermission';


function ProductReview() {
    CheckPermission()
    const [Instance, setInstance] = useState({
        brand_name: ""
    });

    const [show, setShow] = useState(false);
    const [List, setList] = useState([]);
    const [pending, setPending] = useState(true);

    const [errors, setErros] = useState({});
    const [isPSubmit, setIsPSubmit] = useState(false);
    const [Details, setDetails] = useState([]);
    const [Id, setId] = useState(false);
    const [search, setSearch] = useState("");
    const [searchData, setsearchData] = useState([]);

    const handleClose = () => {
        setShow(false)
        setsearchData('')
    }
    const handleShow = () => setShow(true);

    const validate = (Instance) => {
        const errors = {};
        console.warn(Instance.brand_name);
        if (!Instance.brand_name) {
            errors.brand_name = "Must Provide a brand name";
        }
        return errors;
    }

    const handelInput = (e) => {
        e.persist();
        if (Id == false) {
            setErros(validate(Instance));
        }
        setIsPSubmit(true);
        setInstance({ ...Instance, [e.target.name]: e.target.value })

    }

    async function getList() {
        axios.get(`api/listProductReviews/all`).then(res => {
            // console.warn(res.data.data);
            setList(res.data.data);
            setSearch(res.data.data)
            setPending(false);
        })

    }
    const columns = [
        {
            name: "Name",
            selector: row => row.user_name
        },
        {
            name: "Email",
            selector: row => row.user_email
        },
        {
            name: "Product",
            selector: row => row.product_name
        },
        {
            name: "Rating",
            selector: row => (
                <span>{row.rating} {row.rating > 1?'Stars':'Star'}</span>
            )
        },
        {
            name: "Comment",
            selector: row => (
                <span>{row.review==null?'-----':row.review}</span>
            )
        },
        {
            name: "Date",
            cell: row => (
                <span>{row.created_at.split("T")[0]}</span>
            )
        },
        {
            name: "Status",
            cell: row => (
                <span className={row.is_approved == 1 ?'text-success':row.is_approved == 0 ?'text-info':'text-danger'}>{row.is_approved==1?'Approved':row.is_approved==0?'Pending':'Not Approved'}</span>
            )
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <button title='Clict to approved' className={'btn btn-success'} onClick={() => { approvedReview(row.id) }} ><FontAwesomeIcon icon={faThumbsUp} /></button>

                    <button title='Clict to disapproved' className={'btn btn-danger'} onClick={() => { disApprovedReview(row.id) }} ><FontAwesomeIcon icon={faThumbsDown} /></button>
                </>
            )
        }
    ]
    useEffect(() => {
        getList()
    }, []);


    function createModal() {
        setDetails([]);
        setId(false);
        setErros({});
        handleShow();
    }

    function approvedReview(id) {
        Swal.fire({
            title: 'Are you sure you want to approved this review?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, approved it!',
            showCloseButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`api/approvedProductReviews/${id}`).then(res => {

                    Swal.fire({
                        title: 'Approved',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    // window.location.reload();
                    getList();
                })
            }
        })
    }

    function disApprovedReview(id) {
        Swal.fire({
            title: 'Are you sure you want to disapproved this review?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, disapproved it!',
            showCloseButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`api/cancelApprovedProductReviews/${id}`).then(res => {

                    Swal.fire({
                        title: 'Disapproved',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    // window.location.reload();
                    getList();
                })
            }
        })
    }


    function showSingleData(id) {
        //console.warn(id);
        setId(id);
        axios.get(`api/detailsBrand/${id}`).then(res => {
            setDetails(res.data);
            setErros({});
            handleShow();
        })
    }

    const navigate = useNavigate();

    useEffect(() => {
        const result = List.filter(country => {
            return country.product_name.toLowerCase().match(searchData.toLowerCase());
        })
        setSearch(result);
    }, [searchData]);

    return (
        <div>
            <div className='container'>
                <div className='col-md-12 pl-3'>
                    <div className="pageheader card mb-4">
                        <div className="card-body row align-items-center">
                            <div className="page-name col-md-3">Product Review List</div>
                            <div className="buttun_div  col-md-9">
                            </div>
                        </div>
                    </div>
                    <h1 className="display-6"></h1>
                    <div className='card '>
                        <div className='card-body '>
                            <div className='datatable '>
                                <DataTable
                                    noDataComponent="Product Review table is empty" //or your component
                                    columns={columns}
                                    data={search}
                                    progressPending={pending}
                                    pagination
                                    fixedHeader
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text" placeholder="search" value={searchData} onChange={(e) => { setsearchData(e.target.value) }} />
                                    }
                                    subHeaderAlign="right"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductReview