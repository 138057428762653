import React, { useEffect, useRef, useState } from "react";
import { Link, useParams }  from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../frontend/CustomErrorMsg";
import '../../assests/Lander/assets/css/facecream-landing.css';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import {BASE_URL,stateList,GetCardType} from '../Config';
import { Helmet } from 'react-helmet';
// Images 
import Logo from '../../assests/Lander/assets/images/face-cream-compressed/logo-whiteBor.png';
import mainGrabberDesktop1 from '../../assests/Lander/assets/images/face-cream-compressed/main-grabber-desktop-1.png';
import mainGrabberMobile1 from '../../assests/Lander/assets/images/face-cream-compressed/main-grabber-mobile-1.png';
import sealsTop from '../../assests/Lander/assets/images/face-cream-compressed/seals-top.png';
import formArrow from '../../assests/Lander/assets/images/face-cream-compressed/form-arrow.png';
import rushMyOrder from '../../assests/Lander/assets/images/face-cream-compressed/rush-my-order.png';
import stars from '../../assests/Lander/assets/images/face-cream-compressed/stars-2.png';
import product from '../../assests/Lander/assets/images/face-cream-compressed/image.png';
import secureicons from '../../assests/Lander/assets/images/face-cream-compressed/secureicons.jpg';
import featureProduct from '../../assests/Lander/assets/images/face-cream-compressed/feature-product.png';
import featureProductMob from '../../assests/Lander/assets/images/face-cream-compressed/feature-product-m.png';
import theresults from '../../assests/Lander/assets/images/face-cream-compressed/theresults.jpg';
import theproof from '../../assests/Lander/assets/images/face-cream-compressed/theproof.png';
import actionBtn from '../../assests/Lander/assets/images/face-cream-compressed/the-proof-call-to-action.png';
import skinbeforeafter from '../../assests/Lander/assets/images/face-cream-compressed/skinbeforeafter.jpg';
import results from '../../assests/Lander/assets/images/face-cream-compressed/results.jpg';
import featureBrand from '../../assests/Lander/assets/images/face-cream-compressed/feature-brand.png';
import seals3 from '../../assests/Lander/assets/images/face-cream-compressed/3-seals.jpg';
import whatdoyouget from '../../assests/Lander/assets/images/face-cream-compressed/whatdoyouget.jpg';
import fbLogo from '../../assests/Lander/assets/images/face-cream-compressed/fb-logo.jpg';
import allnatural from '../../assests/Lander/assets/images/face-cream-compressed/allnatural.png';
import fbPosts from '../../assests/Lander/assets/images/face-cream-compressed/SKN-Renew-FB-Posts.jpg';
import footerCTA from '../../assests/Lander/assets/images/face-cream-compressed/footer-cta.png';
import Copyright from "../Copyright";
import Footer from "../../layouts/frontend/FooterV2";

function Skincare2pTemplate(){
    const ref = useRef(null);
    const [loadershow, setloadershow] = useState(false);
    const location = useLocation();
    const currentYear = new Date().getFullYear();
    let params = location.search;
    const options = stateList;
    const [states, setStates] = useState([]);
    let URL = window.location.href;
    let urlSlug = URL.substring(URL.lastIndexOf('/') + 0);
    const params2 = useParams();

    const handleCountryChange = (event) => {
        const country_id = event.target.value;
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    const handleCountryChange2 = (country_id) => {
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    useEffect(()=>{
        handleCountryChange2('239');
    },[params2.slug]);

    const scrollToCustom = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    function confirmSubmit(values) {
        setloadershow(true);
        const formdata = new FormData();
        let campaign_id = 23;
        formdata.append('firstName',values.firstName);
        formdata.append('lastName',values.lastName);
        formdata.append('email',values.email);
        formdata.append('phone',values.phone);
        formdata.append('tag1','skin-prospect');
        formdata.append('shippingAddress',values.shippingAddress1);
        formdata.append('shippingCity',values.shippingCity);
        formdata.append('shippingState',values.shippingState);
        formdata.append('shippingZip',values.shippingZip);
        formdata.append('shippingCountry',values.country === '239' ? 'US' : 'CA');
        formdata.append('campaign_id',campaign_id);

        let affid = new URLSearchParams(location.search).get('affid');
        let sid = new URLSearchParams(location.search).get('sid');
        let s1 = new URLSearchParams(location.search).get('s1');
        let s2 = new URLSearchParams(location.search).get('s2');
        let s3 = new URLSearchParams(location.search).get('s3');
        let click_id = new URLSearchParams(location.search).get('click_id');
        let notes = new URLSearchParams(location.search).get('notes');

        formdata.append('affid', affid);
        formdata.append('sid', sid);
        formdata.append('s1', s1);
        formdata.append('s2', s2);
        formdata.append('s3', s3);
        formdata.append('click_id', click_id);
        formdata.append('notes', notes);
        
        //console.log(Object.fromEntries(formdata));
        axios.post(`api/maropost_tag_add`, formdata).then(res => {
            if (res.data.status === true) {
                axios.post(`api/create-prospect`, formdata).then(result => {
                    if(result.data.status === true) {
                        localStorage.setItem('prospectId',result.data.prospectId);
                        window.location.href = '/' + localStorage.getItem('c_s')+params;
                    }
                })
            }
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        });
    }

    return(
        <>
            <div className={loadershow ? 'loading' : 'd-none'}></div>
            <main className="faceCream-landing-page">
                {/* Top Sec Begin  */}
                <div className="topSec">
                    <div className="topAnnoncment text-center">
                        <div className="container">
                            <b>ATTENTION:</b>&nbsp;Due to high demand from recent media coverage we have limited stock.
                            <div>As of we currently have product in-stock and will ship within 24 hours of purchase.</div>
                        </div>
                    </div>
                    <div className="contactInfo">
                        <div className="container">
                        <p className="text-center">100% Money Back Guarantee that it Works!</p>
                        </div>
                    </div>
                </div>
                {/* Top Section End  */}
                {/* Banner Begin  */}
                <section className="headerbg">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-8 nopad">
                                <div className="bannerLeft">
                                    <img src={Logo} alt="logo" className="Top_Logo" />
                                    <img src={product} alt="product" className="product Step1_Product" />
                                    <div className="bannerTextImg">
                                        <img src={mainGrabberDesktop1} className="img-fluid desktop deskimg grabber-img" />
                                        <img src={mainGrabberMobile1} className="img-fluid mobile center-block" />
                                    </div>
                                    <div className="viraltext">
                                        <img src={stars} width="170" height="37" className="img-fluid center-block star" alt="" /> <br />
                                        “Find out why Blue Forrest Hydrating Face Cream is going viral”
                                    </div>
                                    <div className="viraltextm">CLAIM YOUR FREE BOTTLE!</div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-4 nopad">
                                <div className="formSeals">
                                    <img src={sealsTop} width="330" height="108" alt="" className="center-block img-fluid" />
                                </div>
                                <a name="ordernow" id="ordernow"></a>
                                <div className="formBox">
                                    <div className="formTop">
                                        Where do we<br />
                                        Send your Bottle? <img src={formArrow} className="desktop" height="415" alt="" />
                                    </div>
                                    <div className="stripe" ref={ref}>EXCLUSIVE CLINICAL FORMULA AVAILABLE NOW</div>
                                    <Formik
                                        initialValues={{
                                            firstName: '',
                                            lastName: '',
                                            shippingAddress1: '',
                                            shippingZip: '',
                                            shippingCity: '',
                                            shippingState: '',
                                            email: '',
                                            phone: '',
                                            country:'239'
                                        }}
                                        validationSchema={Yup.object({
                                            firstName: Yup.string().required('First name is required'),
                                            lastName: Yup.string().required('Last name is required'),
                                            shippingAddress1: Yup.string().required('Address is required'),
                                            shippingZip: Yup.string().when('country', {
                                                is: '239',
                                                then: Yup.string()
                                                  .required('Zip code is required')
                                                  .matches(/^\d{5}$/, 'Zip code must be exactly 5 digits number'),
                                                otherwise: Yup.string().when('country', {
                                                  is: '41',
                                                  then: Yup.string()
                                                    .required('Zip code is required')
                                                    .matches(/^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/, 'Zip code must contain only letters and numbers')
                                                    .min(6, 'Zip code should contain at least 6 characters')
                                                    .max(6, 'Zip code should contain at most 6 characters'),
                                                }),
                                            }),
                                            shippingCity: Yup.string().required('City is required'),
                                            shippingState: Yup.string().required('State is required'),
                                            email: Yup.string().email("Email is required").max(255).required("Please enter a valid email"),
                                            phone: Yup.string().required('Phone no is required')
                                            .matches(/^[0-9]+$/, "Please enter only number!")
                                            .min(10, 'Please enter a valid contact number!')
                                            .max(10, 'Please enter a valid contact number!'),
                                            country: Yup.string().required('Country is required')
                                        })}
                                        onSubmit={(values) => {
                                            localStorage.setItem('firstName', values.firstName);
                                            localStorage.setItem('lastName', values.lastName);
                                            localStorage.setItem('shippingAddress1', values.shippingAddress1);
                                            localStorage.setItem('shippingZip', values.shippingZip);
                                            localStorage.setItem('shippingState', values.shippingState);
                                            localStorage.setItem('shippingCity', values.shippingCity);
                                            localStorage.setItem('phone', values.phone);
                                            localStorage.setItem('email', values.email);
                                            localStorage.setItem('country', (values.country === '239') ? 'US' : 'CA');
                                            confirmSubmit(values);
                                        }}
                                    >
                                    {(formProps) => (
                                        <Form name="prospect_form1" className="prospectForm" id="OrderForm">
                                            <div className="formBody">
                                                <div className="fields">
                                                    <Field type="text" name="firstName" className="form-control" placeholder="First Name*" />
                                                    <CustomErrorMsg name="firstName" />
                                                </div>
                                                <div className="fields">
                                                    <Field type="text" name="lastName" className="form-control" placeholder="Last Name*" />
                                                    <CustomErrorMsg name="lastName" />
                                                </div>
                                                <div className="fields">
                                                    <Field type="text" name="shippingAddress1" className="form-control" placeholder="Address*" />
                                                    <CustomErrorMsg name="shippingAddress1" />
                                                </div>
                                                <div className="fields">
                                                    <Field type="text" maxLength="6" name="shippingZip" className="form-control" placeholder="Zip*" />
                                                    <CustomErrorMsg name="shippingZip" />                                            
                                                </div>
                                                <div className="fields">
                                                    <Field type="text" name="shippingCity" className="form-control" placeholder="City*" />
                                                    <CustomErrorMsg name="shippingCity" />
                                                </div>
                                                <div className="fields">
                                                    <Field
                                                        name="country"
                                                        as="select"
                                                        placeholder="Select an option*"
                                                        className="form-control validate[required]"
                                                        onChange={(event) => {
                                                            formProps.handleChange(event);
                                                            handleCountryChange(event);
                                                        }}
                                                    >
                                                        <option value="239" selected="selected">United States</option>
                                                        <option value="41" >Canada</option>
                                                    </Field>
                                                    <CustomErrorMsg name="country" />
                                                </div>
                                                <div className="fields">
                                                    <Field
                                                        name="shippingState"
                                                        as="select"
                                                        placeholder="Select an option*"
                                                        className="form-control validate[required]"
                                                    >
                                                        <option value="" >Select an option</option>
                                                        {states.map((state) => (
                                                            <option key={state.id} value={state.abbreviation}>
                                                                {state.state}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                    <CustomErrorMsg name="shippingState" />
                                                </div>
                                                <div className="fields">
                                                    <Field type="email" name="email" className="form-control" placeholder="Email*" />
                                                    <CustomErrorMsg name="email" />
                                                </div>
                                                <div className="fields">
                                                    <Field type="text" name="phone" className="form-control" placeholder="Phone*" maxLength={10} />
                                                    <CustomErrorMsg name="phone" />
                                                </div>
                                                <div id="RushOrderFacecream">
                                                    <button type="submit"><input type="image" src={rushMyOrder} className="img-fluid center-block" id="noexit" /></button>
                                                    <div className="center secTxt">
                                                        <span> <i className="fa fa-lock"></i> 256 bit secure form</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                    </Formik>
                                </div>
                                <img src={secureicons} width="354" height="51" className="center-block img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* Banner End  */}
                {/* <!-- Section Begin  --> */}
                <section id="section1" className="revolutionary-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="revLeft">
                                    <h2>Revolutionary Break-through!</h2>
                                    <p className="highlight">Why does it have <strong>Scientists</strong>, <strong>Doctors</strong> and <strong>Celebrities</strong> Buzzing?</p>
                                    <br />
                                    <p>
                                        <strong><span className="maroon underline">The most talked about age reversal and wrinkle reducing formula is finally here!</span></strong> The Collagen and Retinol breakthrough that has the media in a frenzy! Blue Forrest
                                        Hydrating Face Cream that contains the key that activates age reversal and can reverse the signs of aging visibly in just a short period of time.
                                    </p>
                                    <p>
                                        <strong><span className="text-underline">Blue Forrest Hydrating Face Cream is here to stay because of the unsurmountable success people are having turning back the hands of time!</span></strong>
                                    </p>
                                    <br />
                                    <img src={sealsTop} width="330" height="108" className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="revRight">
                                    <img src={product} alt="product" className="product Step1_Product-col-sm-5 device-desktop-page-index-flow-skin-v3-step-1-img-2 " />
                                    <img src={featureProduct} className="img-fluid pull-right desktop d-none d-md-block" alt="" />
                                    <img src={featureProductMob} width="528" height="444" className="center-block img-fluid d-md-none" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Section End  --> */}
                {/* <!-- Section Begin  --> */}
                <div className="theproofD">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 ms-auto">
                                <div className="theproofD-inner">
                                    <img src={product} alt="product" className="product Step1_Product-viral device-desktop-page-index-flow-skin-v3-step-1-img-3" />
                                    <img src={theproof} className="img-fluid center-block the-proof" alt="ketopia ketosis" />
                                    <h1>Join the Thousands who are already Seeing Results.</h1>
                                    <img src={theresults} width="551" height="439" className="img-fluid center-block mb-4" alt="Testimonials" /> <br />
                                    <p className="text-center">Join the thousands already enjoying the incredible age defying effects of Blue Forrest Hydrating Face Cream!</p>
                                </div>                            
                            </div>
                            <div className="col-sm-12 call_to_action"><img src={actionBtn} className="toForm img-fluid center-block" alt="order ketopia" /></div>
                        </div>
                    </div>
                    {/* <!--mobile--> */}
                    <div className="theproofM">
                        <div className="container"></div>
                    </div>
                </div>          
                {/* <!-- Section End  --> */}
                {/* <!-- How Does it work  --> */}
                <div className="how_it_work">
                    <div className="container">
                        <a name="science" id="science"></a>
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h1 className="mont60">HOW DOES IT WORK?</h1>
                                <span className="os28 lighter dark">
                                    Blue Forrest Hydrating Face Cream helps you acheive Visibly Younger Skin <span className="blue">faster than any other product</span> with a Powerfully Advanced Collagen and Retinol Age Defying Formula
                                </span>
                                <div className="space">&nbsp;</div>
                            </div>
                            <div className="col-sm-6">
                                <img src={results} className="img-fluid mb-4 result_img" alt="ketosis diet body burns carbs instead of fat" /><br />
                                <h2>WHY YOUR SKIN CARE PRODUCTS FAIL?</h2>
                                <p className="content">
                                    Seventy five percent of our skin is comprised of water and collagen. Our skin is exposed to harsh UVA and UVB radiation resulting in age spots, fine lines, and wrinkles. As we age, our bodies produce less and less collagen,
                                    leading to the formation of wrinkles and fine lines.
                                </p>
                                <h4 className="super">
                                    The Problem?
                                </h4>
                                <p className="content">Most anti-aging products use fragments of hydrolyzed collagen containing molecules too large for the skin with conventional formulas resulting in less than desireable results.</p>
                            </div>
                            <div className="col-sm-6">
                                <img src={skinbeforeafter} width="410" height="259" className="img-fluid" alt="" /><br />
                                <h2>HOW <span className="orange text-uppercase">Blue Forrest Hydrating Face Cream </span> WORKS?</h2>
                                <p className="content">
                                    Blue Forrest Hydrating Face Cream’s breakthrough formula delivers whole collagen molecules to the skin. The peptide-rich wrinkle cream is applied to the skin, rebuilding and rejuvenating the skin.
                                </p>
                                <h4 className="super">
                                    The Solution:
                                </h4>
                                <p className="content">
                                    Blue Forrest Hydrating Face Cream is your secret to radiant, beautiful skin that looks years younger. Don’t endure the physical pain and expense of costly procedures and surgeries. Blue Forrest Hydrating Face Cream works
                                    naturally to help replenish your skin’s moisture, firming its appearance and restoring your natural glow to reveal a younger-looking you.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- How Does it work End --> */}
                <div className="visibleResult">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h1 className="os33 lighter dark">Visible Results in 1 Week has been difficult to achieve...</h1>
                                <span className="os33 orange super montbold">UNTIL NOW</span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ketpoia-ketosis-banner Begin  */}
                <div className="ketpoia-ketosis-banner">
                    <div className="container">
                        <h2>Stunning visual results are waiting for you.</h2>
                        <div className="ketoImg-wrapper">                            
                            <div className="product">
                                <img src={product} alt="product" className="Step1_Product-ketpoia  device-desktop-page-index-flow-skin-v3-step-1-img-4" />
                                <img src={featureBrand} className="center-block img-fluid flowerImg" alt="Ketopia Logo" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* ketpoia-ketosis-banner End  */}
                {/* <!-- What You Get  --> */}
                <div className="whatYouGet">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <img src={seals3} width="299" height="115" className="center-block" alt="non gmo" />
                                <h1 className="mont60">WHAT DO YOU GET?</h1>
                                <span className="os28 lighter dark"> The Aloe &amp; Chamomile Cream that is <span className="orange">Sweeping the Nation</span>! </span>
                                <div className="space">&nbsp;</div>
                            </div>
                            <div className="col-sm-8 mx-auto posRelative">
                                <div className="whatYouGet_img_grp">
                                    <img src={whatdoyouget} className="img-fluid center-block whatYouGet-model" alt="ketopia ketosis ketonx drink" />
                                    <div className="whatYouGet_img_grp_inner">
                                        <img src={product} alt="product" className="" />
                                        <img src={product} alt="product" className="" />
                                        <img src={product} alt="product" className="" />
                                    </div>                                    
                                </div>
                                {/* <img src={whatdoyouget} className="img-fluid center-block whatYouGet-model" alt="ketopia ketosis ketonx drink" />
                                <img src={product} alt="product" className="Step1_Product-col-sm-offset-2-01 device-desktop-page-index-flow-skin-v3-step-1-img-5" />
                                <img src={product} alt="product" className="Step1_Product-col-sm-offset-2-02 device-desktop-page-index-flow-skin-v3-step-1-img-6" />
                                <img src={product} alt="product" className="Step1_Product-col-sm-offset-2-03 device-desktop-page-index-flow-skin-v3-step-1-img-7" /> */}
                                <h1 className="productTitle">Blue Forrest Hydrating Face Cream</h1>
                                <h3 className="productSub">Turn back the harsh effects of time! Visible results within as little as 7 days!</h3>
                                <p className="content">
                                    Blue Forrest Hydrating Face Cream is your secret to radiant, beautiful skin that looks years younger. Don’t endure the physical pain and expense of costly procedures and surgeries. Blue Forrest Hydrating Face Cream works
                                    naturally to help replenish your skin’s moisture, firming its appearance and restoring your natural glow to reveal a younger-looking you.
                                </p>
                                <p className="content">
                                    The boost in collagen and elastin helps retain the skin's dermal structure which results in reduction of wrinkles and fine lines. Active ingredients in Blue Forrest Hydrating Face Cream facilitate trapping moisture, which in
                                    turn hydrates the skin and prevents cracking. <br />
                                    <br />
                                    Finally the beautiful, healthy, and confident face you deserve with our unique Collagen + Retinol Age Defying Cream. Ideal for all skin types, Blue Forrest Hydrating Face Cream is a dynamic and powerful anti aging product
                                    that will give you noticeable results within 7 days!<br />
                                    <br />
                                </p>
                                <ul className="text-lists">
                                    <li>Firm &amp; Tighten Skin *</li>
                                    <li>Reduce Wrinkles &amp; Fine Lines *</li>
                                    <li>Reduce Signs of Aging *</li>
                                    <li>Brighten Skin's Appearance *</li>
                                    <li>Eliminates the Look of Dark Circles *</li>
                                    <li>Enhances Skin Hydration *</li>
                                    <li>Counter the Effects of Stress *</li>
                                </ul>
                                <p></p>
                            </div>
                            <div className="space">&nbsp;</div>
                            <div className="col-sm-6 mx-auto">
                                <div className="space">&nbsp;</div>
                                <div className="space">&nbsp;</div>                            
                                <div className="toForm text-center">
                                    <span className="c2abtn" onClick={scrollToCustom}>ORDER NOW!</span>
                                </div>                            
                                <div className="space">&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- What You Get End  --> */}
                <div className="stayingKetosis">
                    <div className="container">
                    <div className="row mob-center">
                        <h3>ACHEIVING BEAUTIFUL</h3>
                        <h2>YOUNGER SKIN</h2>
                        <h3>IS SIMPLE</h3>
                    </div>
                    </div>
                </div>
                {/* <!-- How To Use  --> */}
                <div className="ketopiainfo">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 ketopiainfo">
                                <h2>HOW TO USE Blue Forrest Hydrating Face Cream</h2>
                                <p><strong> Blue Forrest Hydrating Face Cream is a one-of-a-kind product designed to help you see visible results within just 7 days!</strong></p>
                                <p>
                                    Blue Forrest Hydrating Face Cream is a twice daily age defying cream that allows the face to begin reducing the appearance of age such as wrinkles, fine lines, spots and signs of stress in just a short time. Featuring a
                                    special blend of ingredients, Blue Forrest Hydrating Face Cream is a safe and simple way to turn back the hands of time.
                                </p>
                                <p>
                                    Blue Forrest Hydrating Face Cream is extremely easy to use and experience fast noticeable results with.
                                </p>
                                <ul className="text-lists">
                                    <li>Wash Face with clean water in the morning and before bed.</li>
                                    <li>Apply a dime sized portion of Blue Forrest Hydrating Face Cream to your palm.</li>
                                    <li>
                                        Gently rub Blue Forrest Hydrating Face Cream into your skin where ever you want results.                                
                                    </li>
                                </ul>
                                <h2>TIPS FOR SUCCESS</h2>
                                <p>Before you begin, you are encouraged to take a "before" photo and an inventory of your current aging signs.</p>
                                <p>
                                    Be sure to get enough deep sleep and drink lots of water. Eat foods high in vitamin A. Be sun safe and keep your face out of the sun. Lower your stress levels and load up on antioxidants. Also don't forget your neck and
                                    chest. These are commonly missed areas when using an anti-aging regimine.<br />
                                
                                </p>
                                <h2>GET READY FOR A NEW YOU!</h2>
                                <p>We know you will fall in love with the results of Blue Forrest Hydrating Face Cream. We want you to be 100% satisfied with your purchase so we offer a 100% Money Back Satisfaction Guarantee.</p>
                                <p>
                                    We rarely have product returns unlike most competitors due to the fact that people see noticeable results in just a short period of time and generally come back to buy more. We hope you enjoy the most exciting new Skin
                                    Regimine availalbe today!
                                </p>
                            </div>
                            <div className="col-md-4 posRelative">
                                <div className="productImg">
                                    <img src={product} alt="product" className="Step1_Product-col-sm-4 device-desktop-page-index-flow-skin-v3-step-1-img-8" />
                                    <img src={featureBrand} width="382" height="353" className="img-fluid center-block flowerImg" alt="ketonx drink" />
                                </div>
                                <p className="small text-center">
                                    Blue Forrest Hydrating Face Cream is a daily regimine that helps you reduce wrinkles and the signs of aging.
                                </p>
                            </div>
                            <div className="col-sm-12">
                                <span className="space">&nbsp;</span>
                                <div className="text-center">
                                    <div className="toForm">
                                        <span className="c2abtn" onClick={scrollToCustom}>GET IT NOW</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- How To Use End  --> */}
                <div className="allnatural">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 mx-auto">
                                <img src={allnatural} className="center-block img-fluid" alt="" />
                            </div>                            
                        </div>
                    </div>
                </div>
                {/* <!-- Testimonial Section  --> */}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h1 className="mont50 centered">WHAT OTHERS ARE SAYING:</h1>
                            <span className="os28 lighter dark">Blue Forrest Hydrating Face Cream is all over the Internet and the results <span className="orange">are Astonishing</span>! </span><br />
                            <div>
                                <div className="text-center">
                                    <img src={fbLogo} width="263" height="120" alt="" />
                                </div>
                            </div>
                            <div className="space">&nbsp;</div>
                        </div>
                        <div className="col-md-6 mx-auto">
                            <div className="text-center">
                                <img src={fbPosts} className="img-fluid" width="481" height="1025" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="space">&nbsp;</div>
                        </div>
                    </div>
                </div>
                {/* <!-- Testimonial Section End  --> */}
                <div className="footerC2a">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-lg-7 ms-auto">
                                <div className="posRelative">
                                    <img src={Logo} alt="logo" className="footer-logo mx-auto d-block" />
                                    <div className="footerProductWrapper">
                                        <img src={product} alt="product" className="Step1_Product-footer-01 img-fluid" />
                                        <img src={product} alt="product" className="Step1_Product-footer-02 img-fluid" />
                                        <img src={product} alt="product" className="Step1_Product-footer-03 img-fluid" />
                                    </div>
                                    <div className="text-center">
                                        <span onClick={scrollToCustom} className="toForm text-center claim"><img src={footerCTA} width="680" className="img-fluid center-block" height="771" alt="" /></span>
                                    </div>
                                </div>
                            </div>                        
                        </div>
                    </div>
                </div>
                <Copyright/>
            </main>
        </>
    )
}


export default Skincare2pTemplate