import React, { useState } from "react";
import { Link, useLocation }  from 'react-router-dom';
import axios from "axios";
import Swal from "sweetalert2";
import Copyright from "../Copyright";
import useStore from '../../store';
import AccessDeniedPage from "../AccessDeniedPage";
// Images 
import NeoMeditation from '../../assests/upsell/images/Neo_Meditation_V2.png'
import checkBtn from '../../assests/upsell/images/complete-checkout-btn.png'
import textPic from '../../assests/upsell/images/text_pic.png'
import visaUpsell from '../../assests/upsell/images/visa-upsell.png'

function NeobodUpsell() {
    const [disable,setDisable] = useState(false);
    const [loadershow, setloadershow] = useState(false);
    const currentYear = new Date().getFullYear();
    const location = useLocation();
    let params = location.search;
    const isLogin = useStore((state) => state.isLogin);

    function goToConfirmation(){
        setloadershow(true);
        sessionStorage.removeItem('yp');
        window.location.href = '/'+localStorage.getItem('con_s')+params;
    }

    function placeNeobodUpsell(){
        setDisable(true);
        setloadershow(true);
        const formdata = new FormData();
        formdata.append('billingFirstName', localStorage.getItem('firstName'));
        formdata.append('billingLastName', localStorage.getItem('lastName'));
        formdata.append('billingAddress1', localStorage.getItem('shippingAddress1'));
        formdata.append('billingCity', localStorage.getItem('shippingCity'));
        formdata.append('billingState', localStorage.getItem('shippingState'));
        formdata.append('billingZip', localStorage.getItem('shippingZip'));
        formdata.append('billingCountry', 'US');
        formdata.append('phone', localStorage.getItem('phone'));
        formdata.append('email', localStorage.getItem('email'));
        formdata.append('campaignId', localStorage.getItem('neobodCid'));
        formdata.append('product_id', localStorage.getItem('neobodPId'));
        formdata.append('affid', localStorage.getItem('affid'));
        formdata.append('sid', localStorage.getItem('sid'));
        formdata.append('s1', localStorage.getItem('s1'));
        formdata.append('s2', localStorage.getItem('s2'));
        formdata.append('s3', localStorage.getItem('s3'));
        formdata.append('click_id', localStorage.getItem('click_id'));
        formdata.append('notes', localStorage.getItem('notes'));
        formdata.append('yp', sessionStorage.getItem('yp'));
        //console.log(Object.fromEntries(formdata));
        axios.post(`api/place_neobod_upsell_order`, formdata).then(res => {
            //console.log(res.data);
            if(res.data.response_code === 100){
                sessionStorage.removeItem('yp');
                localStorage.setItem('neobodOrderId',res.data.order_id);
                localStorage.setItem('neobodOrderStatus',res.data.resp_msg);
                //setloadershow(false);
                window.location.href = '/' + localStorage.getItem('con_s')+params;
            } else {
                setDisable(false);
                setloadershow(false);
                Swal.fire({
                    title: 'Order Decline',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33'
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.location.href = '/dashboard';
                    }
                })
                
            } 
        })
    }
    return (
        <>
            { isLogin === 1 ?
            <>
            <div className={loadershow ? 'loading' : 'd-none'}></div><div className={loadershow ? 'd-none' : 'card funnel_card'}></div>
            <div className="faceCreamUpsell-3">
                <div className="container">
                    <div className="medImage">
                        <img src={NeoMeditation} className="img-fluid" />
                    </div>
                    <div className="fcUpsell3-btn text-center">
                        <Link to="" disabled={(disable)?'disabled':''} onClick={()=>{placeNeobodUpsell()}}><img src={checkBtn} className="img-fluid" /></Link>
                    </div>
                    <div className="text-center bottom-nothanks">
                        <button onClick={goToConfirmation}  className="btn btn-sm upsell p-3"><span className="cross text-danger">X</span> No thanks! I don't want Neobod today.</button>
                    </div>
                    
                </div>
                
            </div>
            {/* <!-- comnt area  --> */}
            {/* <div className="comnt_area2">
                <div className="container">
                    <div className="connection_text">
                        <span><img src={textPic} alt="" /></span>
                        <span><img src={visaUpsell} alt="" /></span>
                    </div>
                    <div className="footer-link-area mt-3">
                        <div className="text-center">
                            <p>
                                Copyright ©
                                {" "+currentYear+" "}
                                Wholesale Health Club. All Rights Reserved.
                            </p>

                            <ul className="terms-links">
                                <li>
                                    <Link to="#" onClick={(event) => {
                                        event.preventDefault();
                                        window.open('/privacy-policy', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                    }}>Privacy Policy |&nbsp;</Link>
                                </li>
                                <li>
                                    <Link to="#" onClick={(event) => {
                                        event.preventDefault();
                                        window.open('/term-and-condition', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                    }}>Terms and Conditions |</Link>
                                </li>
                                <li><Link to="/contact-us">&nbsp;Contact Us</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <!-- comnt area End --> */}
            <Copyright/>
            </>
            : <AccessDeniedPage/> }
        </>
        )
    }
    
    export default NeobodUpsell