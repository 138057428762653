import React from 'react'
import { Link } from 'react-router-dom';
import Copyright from '../Copyright';



function Citations() {

    return (
        <>
            <div className='infoPages term-and-cond'>
                <div className='container'>
                    <div className='breadcrumbs_nav text-center  mb-4'>
                        <Link to='/'>HOME</Link>
                        <Link to='/citations' className='lastElem'>CITATIONS</Link>
                    </div>
                    <div className="row">
                        <div className="col-md-10 col-lg-8 mx-auto">
                            <ul className="cita-ul">
                                <li><span className="num">[1]</span> Up to 80% off compared to products like this: <a href="https://get.skinnyfit.com/shopping/super-youth" target="_blank">https://get.skinnyfit.com/shopping/super-youth</a></li>
                                <li><span className="num">[2]</span> Some are higher: <a href="https://athleticgreens.com/products/ultimate-daily-greens-powder/us" target="_blank">https://athleticgreens.com/products/ultimate-daily-greens-powder/us</a></li>
                                <li><span className="num">[3]</span> <a href="https://mimicnews.com/multivitamins-shortened-duration-and-severity-of-illness" target="_blank">https://mimicnews.com/multivitamins-shortened-duration-and-severity-of-illness</a></li>
                                <li><span className="num">[4]</span> <a href="https://pubmed.ncbi.nlm.nih.gov/17298695/" target="_blank">https://pubmed.ncbi.nlm.nih.gov/17298695/</a></li>
                                <li><span className="num">[5]</span> <a href="https://pubmed.ncbi.nlm.nih.gov/12349913/" target="_blank">https://pubmed.ncbi.nlm.nih.gov/12349913/</a></li>
                                <li><span className="num">[6]</span> <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5707683/">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5707683/</a></li>
                                <li><span className="num">[7]</span> <a href="https://www.health.harvard.edu/heart-health/magnesium-helps-the-heart-keep-its-mettle" target="_blank">https://www.health.harvard.edu/heart-health/magnesium-helps-the-heart-keep-its-mettle</a></li>
                                <li><span className="num">[8]</span> <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4273450/" target="_blank">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4273450/</a></li>
                                <li><span className="num">[9]</span> <a href="https://pubmed.ncbi.nlm.nih.gov/24470182/" target="_blank">https://pubmed.ncbi.nlm.nih.gov/24470182/</a></li>
                                <li><span className="num">[10]</span> <a href="https://www.mdpi.com/2072-6643/12/4/1181" target="_blank">https://www.mdpi.com/2072-6643/12/4/1181</a></li>
                                <li><span className="num">[11]</span> <a href="https://datafiniti.co/supplement-pricing/" target="_blank">https://datafiniti.co/supplement-pricing/</a></li>
                                <li><span className="num">[12]</span> This vitamin C skin care costs $110: <a href="https://truebotanicals.com/products/pure-radiance-oil-renew" target="_blank">https://truebotanicals.com/products/pure-radiance-oil-renew</a></li>
                                <li><span className="num">[13]</span> This protein costs $119 per month: <a href="https://tusolwellness.com/shop/organic-plant-protein-superfood-smoothie-mix-single/" target="_blank">https://tusolwellness.com/shop/organic-plant-protein-superfood-smoothie-mix-single/</a></li>
                                <li><span className="num">[14]</span> Example of $80 skin care: <a href="https://www.honest.com/beauty-products/skincare/complete-calm-kit/B0052SSK0000S.html" target="_blank">https://www.honest.com/beauty-products/skincare/complete-calm-kit/B0052SSK0000S.html</a></li>
                                <li><span className="num">[15]</span> Example of $80 collagen: <a href="https://get.skinnyfit.com/shopping/super-youth" target="_blank">https://get.skinnyfit.com/shopping/super-youth</a></li>
                                <li><span className="num">[16]</span> Example of $80 greens: <a href="https://www.welleco.com/products/welleco-super-elixir-greens " target="_blank">https://www.welleco.com/products/welleco-super-elixir-greens </a></li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Citations