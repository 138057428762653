import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Spinner } from 'react-bootstrap';
import { Link, useParams, useNavigate } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar'
import Slider from "react-slick";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import Swal from 'sweetalert2';
import useStore from '../../store';
import {BASE_URL} from '../Config';
import Copyright from '../Copyright';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../frontend/CustomErrorMsg";
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import Pagination from "./Pagination";
import { CartState } from '../../context/Context';
// import Pagination2 from 'react-bootstrap/Pagination';
// Images 
import mainLogo from '../../assests/new/frontend/images/ghc-logo.png';
import homeIcon from '../../assests/new/frontend/images/home-icon.png';
import shopBag from '../../assests/new/frontend/images/shop-bag.png';
import search from '../../assests/new/frontend/images/search_hires.png';
import fbLogo from '../../assests/new/frontend/images/facebook-logo-white.png';
import linkedLogo from '../../assests/new/frontend/images/linkedin-logo-white.png';
import insta from '../../assests/new/frontend/images/instagram-logo-white.png';
import twitter from '../../assests/new/frontend/images/twitter-logo-white.png';
import star from '../../assests/new/frontend/images/rating-star.png'
import sliderImg1 from '../../assests/new/frontend/images/productSliderImg-1.jpg'
import sliderImg2 from '../../assests/new/frontend/images/productBenefit.jpg'
import sliderImg3 from '../../assests/new/frontend/images/productSliderImg-3.jpg'
import sliderImg4 from '../../assests/new/frontend/images/productSliderImg-4.jpg'
import brainHealth from '../../assests/new/frontend/images/brain-running-brain-health.jpg'
import cartImage from '../../assests/new/frontend/images/cart-sm.png'
import leftCret from '../../assests/new/frontend/images/left-caret.png'
import rightCaret from '../../assests/new/frontend/images/right-caret.png'
import brainRunning from '../../assests/new/frontend/images/brain-running.jpg'
import brainOutline from '../../assests/new/frontend/images/brainOutline.png'
import NoImage from '../../assests/admin/assets/img/nImage.jpg';
import onestar from '../../assests/frontend/assets/images/1-star.png';
import twostar from '../../assests/frontend/assets/images/2-star.png';
import threestar from '../../assests/frontend/assets/images/3-star.png';
import fourstar from '../../assests/frontend/assets/images/4-star.png';
import fivestar from '../../assests/frontend/assets/images/5-star.png';

function SingleProduct2(){
    const { state: { cart }, dispatch } = CartState();
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const params = useParams();
    const [navIsActive, setNavIsActive] = useState(false);
    const [is_membership, setIsMembership] = useState('');
    const ref = useRef(null);
    const [show, setShow] = useState(false);
    const [displayOff, setDisplayOff] = useState(false);
    let [counter, setCounter] = useState(cart.length);
    const [loadershow, setloadershow] = useState(false);
    const [productId, setProductId] = useState('');
    const disableAddToCart = () => {
        setDisplayOff(true);
    }


    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const navBtnClickHandle = event => {
        setNavIsActive(current => !current);
    };
    const [productData, setproductData] = useState(false);
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    let imgURL = BASE_URL+"whc-custom/storage/app/public/";
     
    

    const incrementCounter = () =>  {
        {productData && cart.map((prod) => {
            if (prod.id === productData[0].id) {
                setCounter(parseInt(prod.qty) + 1);
                dispatch({
                    type: "CHANGE_QTY",
                    payload: {
                        id: productData[0].id,
                        qty: parseInt(prod.qty) + 1,
                    },
                });
            }
        })}
    }

    const decrementCounter = () => {
        {productData && cart.map((prod) => {
            if (prod.id === productData[0].id) {
                if (parseInt(prod.qty) > 1) {
                    setCounter(parseInt(prod.qty) - 1);
                    dispatch({
                        type: "CHANGE_QTY",
                        payload: {
                            id: productData[0].id,
                            qty: parseInt(prod.qty) - 1,
                        },
                    });
                }
            }
        })}
    };

    let P_ID = params.slug;
    let P_Slug = params.slug;
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState('');
    const isLogin = useStore((state) => state.isLogin);
    const isMember = useStore((state) => state.isMember);

    const fetchProductData = (id,P_Slug) => {
        setloadershow(true);
        if(localStorage.getItem('userEmal')){
            axios.get(`api/check_user_membership/${localStorage.getItem('userEmal')}`).then(result => {
                if(parseInt(result.data) === 1){
                    setIsMembership(1);
                } else {
                    setIsMembership(0);
                }
            });
        }
        axios.post(`api/searchProductDetailsByID`,{'slug':P_Slug, 'id': id}).then(res => {
            setproductData(res.data.data);
            setProductId(res.data.data[0].product_id);
            setloadershow(false);
            }).catch(err => {
                console.log(err.response)
            }
        )
    };

    useEffect(()=>{
        window.scrollTo(0, 0);
        axios.get(`api/fetchReview?product_id=${productId}&page=${currentPage}`).then(res => {
            setData(res.data.data);
            setLastPage(res.data.last_page);
        });
    },[productId, currentPage]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    useEffect(() => {
        fetchProductData(P_ID,P_Slug);
    }, [P_ID,P_Slug]);

    function capitalizeFirstLetter(str) {
        const words = str.split(' ');
        const capitalizedWords = words.map(word => {
            if (word.length > 0) {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            } else {
                return '';
            }
        });
        const capitalizedString = capitalizedWords.join(' ');
      
        return capitalizedString;
    }

    function addToCartAccessControl(message,isLoggedIn){
        Swal.fire({
            title: message,
            showCancelButton: true,
            showConfirmButton: isLoggedIn,
            confirmButtonText: 'Login',
            cancelButtonText: "Register",
            confirmButtonColor: '#7066e0',
            cancelButtonColor: '#18aebf',
            showCloseButton: true,

        }).then((result) => {
            if (result.isConfirmed) {
                navigate("/login");
            }
            if (result.dismiss === 'cancel') {
                navigate("/create-membership");
            }
        })
    }

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => setShow(true);

    return(
        <>
            <main className={NoImage} ref={ref}>
                <div className={loadershow ? 'loading' : 'd-none'}></div>
                <section className="nwProductDetail-main">
                    <div className="container">
                        <div className="row nwPro_row">
                            {/* Left Col Begin  */}
                            <div className="col-md-5 col-lg-4 nwPro_col">
                                <div className="nwProDetails-slider">
                                    <Slider className="nwSliderMain" arrows={false} asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
                                        <div className="nwSliderImg" onClick={handleShow}>
                                            <img src={productData ? imgURL+productData[0].image : null} className="img-fluid"/>
                                        </div>
                                        <div className="nwSliderImg" onClick={handleShow}>
                                            <img src={productData ? imgURL+productData[0].label_image : null} className="img-fluid" />
                                        </div>
                                        <div className="nwSliderImg" onClick={handleShow}>
                                            <img src={productData ? imgURL+productData[0].slider_image1 : null} className="img-fluid" />
                                        </div>
                                        <div className="nwSliderImg" onClick={handleShow}>
                                            <img src={productData ? imgURL+productData[0].slider_image2 : null} className="img-fluid" />
                                        </div>
                                        <div className="nwSliderImg" onClick={handleShow}>
                                            <img src={productData ? imgURL+productData[0].slider_image3 : null} className="img-fluid" />
                                        </div>
                                        <div className="nwSliderImg" onClick={handleShow}>
                                            <img src={productData ? imgURL+productData[0].slider_image4 : null} className="img-fluid" />
                                        </div>
                                    </Slider>                                    
                                    <Slider
                                        asNavFor={nav1}
                                        ref={(slider2) => setNav2(slider2)}
                                        slidesToShow={4}
                                        swipeToSlide={true}
                                        focusOnSelect={true}
                                        arrows={true}
                                        className="nwSliderNav"
                                    >
                                        <div className="nwNavImg">
                                            <img src={productData ? imgURL+productData[0].image : null} className="img-fluid" />
                                        </div>
                                        <div className="nwNavImg">
                                            <img src={productData ? imgURL+productData[0].label_image : null} className="img-fluid" />
                                        </div>
                                        <div className="nwNavImg">
                                            <img src={productData ? imgURL+productData[0].slider_image1 : null} className="img-fluid" />
                                        </div>
                                        <div className="nwNavImg">
                                            <img src={productData ? imgURL+productData[0].slider_image2 : null} className="img-fluid" />
                                        </div>
                                        <div className="nwNavImg">
                                            <img src={productData ? imgURL+productData[0].slider_image3 : null} className="img-fluid" />
                                        </div>
                                        <div className="nwNavImg">
                                            <img src={productData ? imgURL+productData[0].slider_image4 : null} className="img-fluid" />
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                            {/* Left Col End  */}
                            {/* Right Col Begin  */}
                            <div className="col-md-7 col-lg-8 nwPro_col">
                                <div className="absFigure"><img src={productData ? imgURL+productData[0].background_image : null} className="img-fluid" /></div>
                                <div className="nwProductDetail-right">
                                    <div className="nwDetailsDiv">
                                        <h2 className="nwTitle">{productData ? capitalizeFirstLetter(productData[0].name) : ''}:</h2>
                                        <div className="nwRating d-flex align-items-center">
                                            <span className="stars"><img src={star} className="img-fluid" /></span> <span className="rvNum">{ data.length > 0 ? data.length === 1 ? data.length+' Review' : data.length+' Reviews':''}</span>
                                        </div>
                                        <div className="nwDivider w-100 my-3"></div>
                                        <div className="nwDescription row">
                                            <div className="col-md-12 col-lg-8">
                                                <div className="descCont">
                                                    <div dangerouslySetInnerHTML={{ __html: productData ? productData[0].details : 'No Details Found' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-lg-4">
                                                <figure className="nwDetailFig">
                                                    {productData && productData[0].description_image1 ?
                                                        <img src={imgURL+productData[0].description_image1}></img>
                                                    :
                                                    <img src={NoImage}></img>
                                                    }
                                                </figure>
                                            </div>
                                        </div>
                                        <div className="nwDivider w-100 my-3"></div>
                                        <div className="nwProInformation d-flex align-items-center justify-content-between">
                                            <span className="numOfPack">{productData ? productData[0].jar : ''}</span>
                                            <span className="numOfMed">{productData ? productData[0].capsules : ''}</span>
                                            <span className="wight">{productData ? productData[0].mg : ''}</span>
                                        </div>
                                        <div className="nwDivider w-100 my-3"></div>
                                    </div>
                                    <div className="nwProductAction d-flex align-items-center">
                                        <div className='quatitySel'>
                                            {productData && cart.map((prod) => {
                                                if (prod.id === productData[0].id) {
                                                    return (
                                                        <>
                                                            <div className="nwQuantityInput me-2 me-lg-4">
                                                                <button className="qtBtn qtBtn_dec" onClick={decrementCounter}><img src={leftCret} /></button>
                                                                <input className="qtInput" name="qty" type="text" value={prod.qty} disabled onChange={e => dispatch({
                                                                    type: "CHANGE_QTY",
                                                                    payload: {
                                                                        id: prod.id,
                                                                        qty: e.target.value
                                                                    },
                                                                })} />
                                                                <button className="qtBtn qtBtn_inc" onClick={incrementCounter}><img src={rightCaret} /></button>
                                                            </div>
                                                        </>
                                                    )
                                                }

                                            })}
                                        </div>
                                        
                                        {cart.length === 0 && productData && cart.map((prod) => {
                                            if (prod.id === productData[0].id) {
                                                return (
                                                    <button onClick={() => {
                                                        
                                                        /*dispatch({
                                                            type: "CHANGE_QTY",
                                                            payload: {
                                                                id: productData[0].id,
                                                                qty: Number(prod.qty) + 1
                                                            },
                                                        })*/

                                                    }} className={displayOff ? 'btn nwCart_btn d-none' : 'btn nwCart_btn'}><img src={cartImage} className="img-fluid" /> Add To Cart </button>
                                                )
                                            }

                                        })}
                                        {productData && cart.some(pro => pro.id === productData[0].id) ? null : (isLogin===1) ?<button onClick={() => {
                                        if (isLogin == 0) {
                                            addToCartAccessControl('Login OR, Create an Account',true);
                                        }
                                        else {
                                            if(isMember === 0){
                                                addToCartAccessControl('Please purchase membership to get this item',false);
                                            } else {
                                                if(is_membership === 0){
                                                    addToCartAccessControl('Please purchase membership to get this item',false);
                                                } else {
                                                    dispatch({
                                                        type: 'ADD_TO_CART',
                                                        payload: productData[0]
                                                    })
                                                    disableAddToCart()
                                                }
                                            }
                                        }
                                        }} className='btn nwCart_btn me-2 me-lg-5'>Add To Cart</button>:''}

                                        <Link to="" className="btn nwCart_btn me-2 me-lg-5"><img src={cartImage} className="img-fluid" /> Add to Cart</Link>

                                        <div className="priceInput">${productData ? productData[0].product_price : ''}</div>
                                    </div>
                                </div>
                            </div>
                            {/* Right Col End  */}
                        </div>
                    </div>
                </section>

                <section className="nwProductDetail-tab">
                    <div className="container">
                        <Tabs
                            defaultActiveKey="details"
                            id="uncontrolled-tab-example"
                            className="nwTab"
                        >
                            <Tab eventKey="details" title="Details">
                                <div className="nwTabContainer">
                                    <div className="row">
                                        <div className="col-md-8 col-lg-9">
                                            <div className="detailsCont">
                                                <div dangerouslySetInnerHTML={{ __html: productData ? productData[0].product_details : 'No Data Available' }} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <div className="">
                                                <img src={productData ? imgURL+productData[0].description_image2 : ''} className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nwDivider  my-3"></div>
                                </div>
                            </Tab>
                            <Tab eventKey="ingredients" title="Ingredients">
                                <div className="nwTabContainer">
                                    <div className="nwTitleDiv">
                                        <p className="nwTitle">{productData ? capitalizeFirstLetter(productData[0].name) : ''}:</p>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-md-8 col-lg-6 mx-auto">
                                            <div className="supplementChart">
                                                <Table className="suppTable"  bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center" colSpan={2}>Supplement Facts</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center"><strong>Ingredients</strong></td>
                                                            <td className="text-center"><strong>Amount Per Serving</strong></td>
                                                        </tr>
                                                        {productData && productData[0].supplement_facts ? JSON.parse(productData[0].supplement_facts).map((items)=>(
                                                            <tr>
                                                                <td className="text-center">{items.ingredients}</td>
                                                                <td className="text-center">{items.per_serve}</td>
                                                            </tr>
                                                        )): <tr>
                                                                <td className="text-center">No Data Available</td>
                                                                <td className="text-center">No Data Available</td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <strong>Other Ingredients:</strong> {productData ? productData[0].ingredients: ''}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <strong>Contains Allergen:</strong>  {productData ? productData[0].contains_allergen: ''}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="usage" title="Usage">
                                <div className="nwTabContainer">
                                    <div className="usageCont text-center row">
                                        <div className="col-md-12 col-lg-10 mx-auto">
                                            <div dangerouslySetInnerHTML={{ __html: productData ? productData[0].product_usage : '' }} />
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="reviews" title="Reviews">
                                <div className="nwTabContainer">
                                    
                                    
                                    {/* <div class="nwDivider  my-5"></div> */}
                                    <div className="nwTitleDiv">
                                        <p className="nwTitle">Customer Reviews:</p>
                                    </div>
                                    <div className="nwReviewCont row">
                                        <div className="col-md-12 col-lg-10 mx-auto">
                                            {data.length > 0 ? data.map((item) => (
                                                <>
                                                    <div className="nwReview-div text-center">
                                                        <div className="nwReview-1 row mb-md-3 mb-lg-4">
                                                            <div className="col-md-6 col-lg-5">
                                                                <p><strong> {item.review}</strong></p>
                                                            </div>
                                                            <div className="col-md-6 col-lg-7">
                                                                <p className="nwRvTxt">{item.comments}</p>
                                                            </div>
                                                        </div>
                                                        <div className="nwReview-2 row">
                                                            <div className="col-md-6 col-lg-5">
                                                                <div className="rvRating">
                                                                    {item.rating === 5 ?
                                                                        <img src={fivestar} className="" />
                                                                    : item.rating === 4 ?
                                                                        <img src={fourstar} className="" />
                                                                    : item.rating === 3 ?
                                                                        <img src={threestar} className="" />
                                                                    : item.rating === 2 ?
                                                                        <img src={twostar} className="" />
                                                                    : item.rating === 1 ?
                                                                        <img src={onestar} className="" />
                                                                    : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-lg-7">
                                                                <p className="ratingBy"><strong>By {item.name} {moment(item.review_date).format('M/D/Y')}<br/>{item.is_approved === 1 ? "Verfied Purchased" : ""}</strong></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )) : <div className="no-review">No review available</div>}
                                        </div>
                                        <div className="nwReview-div">
                                            {data.length > 0 ?
                                            <Pagination
                                                currentPage={currentPage}
                                                totalPages={lastPage}
                                                onPageChange={handlePageChange}
                                            />
                                            :''}
                                        </div>
                                        <div className="col-md-4 col-lg-3"></div>
                                    </div>

                                    <div>
                                        <Formik
                                            enableReinitialize = {true}
                                            initialValues={{
                                                name:'',
                                                email:'',
                                                subject:'',
                                                comments:'',
                                                stars:''
                                            }}
                                            validationSchema={Yup.object({
                                                name: Yup.string().required('Name is required'),
                                                email: Yup.string().required('Email is required').email('Invalid email format'),
                                                subject: Yup.string().required('Headline is required'),
                                                comments: Yup.string().required('Comments is required'),
                                                stars: Yup.string().required('Please provide review on the product'),
                                            })}

                                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                                setSubmitting(true);
                                                //alert(JSON.stringify(values, null, 2));
                                                const formdata = new FormData();
                                                formdata.append('name', values.name);
                                                formdata.append('email', values.email);
                                                formdata.append('review', values.subject);
                                                formdata.append('comments', values.comments);
                                                formdata.append('rating', values.stars);
                                                if(localStorage.getItem('userId')){
                                                    formdata.append('user_id', localStorage.getItem('userId'));
                                                }
                                                formdata.append('product_id',productId);
                                                //console.log(Object.fromEntries(formdata));
                                                axios.post(`api/saveReview`, formdata).then(res => {
                                                    //console.log(res.data);
                                                    resetForm();
                                                    Swal.fire({
                                                        title: 'Success',
                                                        html: res.data.message,
                                                        icon: 'success',
                                                        showConfirmButton: false,
                                                        timer: 1000
                                                    })
                                                }).catch(err => {
                                                    //console.log(err.response)
                                                    Swal.fire({
                                                        title: 'Warning',
                                                        html: err.response.data.message,
                                                        icon: 'warning'
                                                    })
                                                });
                                                
                                            }}
                                        >
                                            <Form className="review-form">
                                                <div className="add-rev">
                                                    <h3>Add a Review :</h3>
                                                </div>
                                                    <Field type="text" name="name" className="form-control" placeholder="Name:"></Field>
                                                    <CustomErrorMsg name="name" />
                                                    <Field type="email" name="email" className="form-control" placeholder="Email:"></Field>
                                                    <CustomErrorMsg name="email" />
                                                    <Field type="text" name="subject" className="form-control" placeholder="Headline:"></Field>
                                                    <CustomErrorMsg name="subject" />
                                                <div className="star-rev my-3">
                                                    <h6><strong>Your Rating</strong></h6>
                                                    <div className="custom-rating">
                                                        <label>
                                                            <Field type="radio" name="stars" value="1" />
                                                            <span className="icon">★</span>
                                                        </label>
                                                        <label>
                                                            <Field type="radio" name="stars" value="2" />
                                                            <span className="icon">★</span>
                                                            <span className="icon">★</span>
                                                        </label>
                                                        <label>
                                                            <Field type="radio" name="stars" value="3" />
                                                            <span className="icon">★</span>
                                                            <span className="icon">★</span>
                                                            <span className="icon">★</span>
                                                        </label>
                                                        <label>
                                                            <Field type="radio" name="stars" value="4" />
                                                            <span className="icon">★</span>
                                                            <span className="icon">★</span>
                                                            <span className="icon">★</span>
                                                            <span className="icon">★</span>
                                                        </label>
                                                        <label>
                                                            <Field type="radio" name="stars" value="5" />
                                                            <span className="icon">★</span>
                                                            <span className="icon">★</span>
                                                            <span className="icon">★</span>
                                                            <span className="icon">★</span>
                                                            <span className="icon">★</span>
                                                        </label>
                                                    </div>
                                                    <CustomErrorMsg name="stars" />
                                                </div>
                                                    <Field as="textarea" type="text" name="comments" className="form-control comment-area" rows="6" placeholder="Message:"></Field>
                                                    <CustomErrorMsg name="comments" />
                                                <div className="rev-sub">
                                                    <button type="submit">Submit Your Review</button>
                                                </div>
                                            </Form>
                                        </Formik>
                                    </div>

                                </div>
                            </Tab>
                        </Tabs>                        
                    </div>
                </section>
            </main>
            <a id="bt-button" onClick={handleClick}><img src={homeIcon} className="img-fluid" alt="" /></a>
            <div className='modal'>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        {/* <Modal.Title>Image</Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body>
                        <div className='container'>
                            <div className='row justify-content-center'>
                                <div className='col-md-12 product-modal'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <Slider arrows={true} asNavFor={nav2} infinite={true} ref={(slider1) => setNav1(slider1)}>
                                                <div className="nwSliderImg">
                                                    <img src={productData ? imgURL+productData[0].image : null} className="img-fluid"/>
                                                </div>
                                                <div className="nwSliderImg">
                                                    <img src={productData ? imgURL+productData[0].label_image : null} className="img-fluid" />
                                                </div>
                                                <div className="nwSliderImg">
                                                    <img src={productData ? imgURL+productData[0].slider_image1 : null} className="img-fluid" />
                                                </div>
                                                <div className="nwSliderImg">
                                                    <img src={productData ? imgURL+productData[0].slider_image2 : null} className="img-fluid" />
                                                </div>
                                                <div className="nwSliderImg">
                                                    <img src={productData ? imgURL+productData[0].slider_image3 : null} className="img-fluid" />
                                                </div>
                                                <div className="nwSliderImg">
                                                    <img src={productData ? imgURL+productData[0].slider_image4 : null} className="img-fluid" />
                                                </div>
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
}
        
export default SingleProduct2;