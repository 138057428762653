import React, { useState, useEffect } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom"; // v6
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faDeleteLeft } from '@fortawesome/free-solid-svg-icons'
import CheckPermission from '../frontend/auth/CheckPermission';


function AssignementTemplate() {
    CheckPermission()
    const [templateInstance, setTemplateInstance] = useState({
        template_name: "",
        template_file_name: ""
    });

    const [show, setShow] = useState(false);
    const [templateList, settemplateList] = useState([]);
    const [pending, setPending] = useState(true);

    const [errors, setErros] = useState({});
    const [isPSubmit, setIsPSubmit] = useState(false);
    const [templateDetails, settemplateDetails] = useState([]);
    const [Id, setId] = useState(false);
    const [search, setSearch] = useState("");
    const [searchTemplate, setsearchTemplate] = useState([]);

    const handleClose = () => {
        setShow(false)
        setSearch('')
    }
    const handleShow = () => setShow(true);

    const validate = (templateInstance) => {
        const errors = {};
        // console.warn(categoryInstance.template_name);
        if (!templateInstance.template_name) {
            errors.template_name = "Must Provide a template name";
        }

        if (!templateInstance.template_file_name) {
            errors.template_file_name = "Must select a template file name";
        }
        return errors;
    }

    const handelInput = (e) => {
        e.persist();
        if (Id == false) {
            setErros(validate(templateInstance));
        }
        setIsPSubmit(true);
        setTemplateInstance({ ...templateInstance, [e.target.name]: e.target.value })

    }

    async function getTemplateList() {
        axios.get(`api/listTemplate`).then(res => {
            // console.warn(res.data.data);
            settemplateList(res.data.data);
            setsearchTemplate(res.data.data)
            setPending(false);
        })

    }
    const columns = [
        {
            name: "Template Name",
            selector: row => row.template_name
        },
        {
            name: "Template File Name",
            cell: row => (
                <span>{row.template_file_name}</span>
            )
        },
        {
            name: "Created",
            cell: row => (
                <span>{row.created_at.split("T")[0]}</span>
            )
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <button style={{ marginRight: 1 + 'em' }} className='btn btn-primary' onClick={() => { showSingleData(row.id) }} ><FontAwesomeIcon icon={faGear} /></button>
                    <button className='btn btn-danger' onClick={() => { deleteTemplate(row.id) }} ><FontAwesomeIcon icon={faDeleteLeft} /></button>
                </>
            )
        }
    ]
    useEffect(() => {
        getTemplateList()
    }, []);


    function createTemplateModal() {
        settemplateDetails([]);
        setId(false);
        setErros({});
        handleShow();
    }

    const submitTemplate = (e) => {
        e.preventDefault();
        setErros(validate(templateInstance));
        if (Object.keys(errors).length === 0 && isPSubmit) {
            handleClose();
            const formdata = new FormData();
            formdata.append('template_name', templateInstance.template_name);
            formdata.append('template_file_name', templateInstance.template_file_name);
            axios.post(`api/createTemplate`, formdata).then(res => {
                if (res.data.status === true) {
                    handleClose();
                    Swal.fire({
                        title: 'Success',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    getTemplateList();
                    //window.location.reload();
                }
            }).catch(err => {
                console.log(err.response)
                Swal.fire({
                    title: 'Warning',
                    html: err.response.data.message,
                    icon: 'warning'
                })

            });

        }
    }

    function deleteTemplate(id) {
        Swal.fire({
            title: 'Are you sure you want to delete this Template?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showCloseButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`api/deleteTemplate/${id}`).then(res => {

                    Swal.fire({
                        title: 'Deleted',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    // window.location.reload();
                    getTemplateList();
                })
            }
        })
    }

    function showSingleData(id) {
        //console.warn(id);
        setId(id);
        axios.get(`api/detailsTemplate/${id}`).then(res => {
            settemplateDetails(res.data);
            setErros({});
            handleShow();
        })
    }

    const navigate = useNavigate();

    function updateTemplate() {
        setErros(validate(templateInstance));
        if (Object.keys(errors).length === 0) {
            handleClose();
            const formdata = new FormData();
            formdata.append('template_name', templateInstance.template_name != "" ? templateInstance.template_name : templateDetails.template_name);
            formdata.append('template_file_name', templateInstance.template_file_name != "" ? templateInstance.template_file_name : templateDetails.template_file_name);
            axios.post(`api/updateTemplate/${Id}`, formdata).then(res => {
                if (res.data.status === true) {
                    handleClose();
                    Swal.fire({
                        title: 'Success',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    // window.location.reload();
                    getTemplateList();
                }
            }).catch(err => {
                console.log(err.response)
                //window.location.reload();
                Swal.fire({
                    title: 'Warning',
                    html: err.response.data.message,
                    icon: 'warning'
                })

            });
        }
    }

    useEffect(() => {
        const result = templateList.filter(country => {
            return country.template_name.toLowerCase().match(search.toLowerCase());
        })
        setsearchTemplate(result);
    }, [search]);

    return (
        <div>
            <div >
                <div className='modal'>


                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Template</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-md-12'>
                                        <div className='card'>
                                            <div className='card-body'>
                                                <form className='row col-md-9'>
                                                    <div className='form-group md-3'>
                                                        <label>Template Name </label>
                                                        <input type="" name="template_name" className="form-control" defaultValue={templateDetails.template_name} onChange={handelInput}></input>
                                                        {errors.template_name && <p class="text-danger">{errors.template_name}</p>}
                                                    </div>
                                                    <div className='form-group md-3'>
                                                        <label>Select Template </label>
                                                        <select type="" name="template_file_name" className="form-control" defaultValue={templateDetails.template_file_name} onChange={handelInput}>
                                                            <option value="0">Select Template</option>
                                                            <option value="Template-1">Template-1</option>
                                                            <option value="Template-2">Template-2</option>
                                                            <option value="Template-3">Template-3</option>
                                                            <option value="Template-4">Template-4</option>
                                                        </select>
                                                    </div>
                                                    {errors.template_file_name && <p class="text-danger">{errors.template_file_name}</p>}
                                                    <div className='form-group md-3 d-none'>
                                                        <Button type="submit">Save!</Button>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={Id ? updateTemplate : submitTemplate}>
                                {Id ? "Update" : "Save"}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>

            </div>
            <div className='container'>
                <div className='col-md-12 pl-3'>
                    <div className="pageheader card mb-4">
                        <div className="card-body row align-items-center">
                            <div className="page-name col-md-3">Template List</div>
                            <div className="buttun_div  col-md-9">  <Button variant="primary" onClick={createTemplateModal}>
                                Create Template
                            </Button>
                            </div>
                        </div>
                    </div>
                    <h1 className="display-6"></h1>
                    <div className='card '>
                        <div className='card-body '>
                            <div className='datatable '>
                                <DataTable
                                    noDataComponent="Template table is empty" //or your component
                                    columns={columns}
                                    data={searchTemplate}
                                    progressPending={pending}
                                    pagination
                                    fixedHeader
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text" placeholder="search" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                                    }
                                    subHeaderAlign="right"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssignementTemplate