import React, { useState, useEffect } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom"; // v6
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faDeleteLeft, faSignIn } from '@fortawesome/free-solid-svg-icons'
import { MultiSelect } from "react-multi-select-component";
import CheckPermission from '../frontend/auth/CheckPermission';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../frontend/CustomErrorMsg";
import useStore from '../../store';
import { Tooltip as ReactTooltip } from "react-tooltip";


function Adduser() {
    CheckPermission()
    const [userInstance, setUserInstance] = useState({
        uname: "",
        uemail: "",
        upassword: "",
        utype: "0"
    });
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [userList, setUserList] = useState([]);
    const [pending, setPending] = useState(true);

    const [errors, setErros] = useState({});
    const [isPSubmit, setIsPSubmit] = useState(false);
    const [userDetails, setUserDetails] = useState([]);
    const [userId, setUserId] = useState(false);
    const [search, setSearch] = useState("");
    const [searchUser, setSearchUser] = useState([]);
    const [permissionShow, SetPermissionShow] = useState('form-group md-3 d-none')
    const [Dpassword, setDpassword] = useState("")
    const [loadershow, setloadershow] = useState(false)
    const afterLogin = useStore((state) => state.afterLogin);
    const [page, setPage] = useState(1);
    //const [countPerPage, setCountPerPage] = useState(10);
    const countPerPage = 10;
    const [details, setDetails] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    var options = [
        { label: "Order", value: "orders" },
        { label: "Customer", value: "customers" },
        { label: "Product", value: "Product" },
        { label: "Page Setup", value: "pagesetup" },
        { label: "Funnel Flow Setup", value: "funnelflowsetup" },
        { label: "CRM Integration", value: "crminstance" },
        { label: "User", value: "users" },
    ];

    const [selected, setSelected] = useState([]);

    const handleClose = () => {
        setShow(false)
        setSearch('')
    }
    const handleShow = () => setShow(true);

    const validate = (userInstance) => {
        const errors = {};
        // console.warn(userInstance.utype);
        if (!userInstance.uname) {
            errors.uname = "Must Provide a Name";
        }
        if (!userInstance.uemail) {
            errors.uemail = "Must Provide an Email";
        }
        if (userInstance.utype == '0') {
            errors.utype = "Must Select an User type";
        }
        if (!userInstance.upassword) {
            errors.upassword = "Must Provide a Password";
        }
        if (userInstance.utype == 2) {
            if (selected.length == 0) {
                errors.uper = "Must Set a Permission";
            }
        }

        return errors;
    }
    const [userUpdateHandle, setUserUpdateHandle] = useState({
        uname: "",
        uemail: "",
        upassword: "",
        utype: "0"
    });
    const handelInput = (e) => {
        // e.persist();
        // if (userId == false) {
        //     setErros(validate(userInstance));
        // }
        // setIsPSubmit(true);
        // setUserInstance({ ...userInstance, [e.target.name]: e.target.value })
        // setUserUpdateHandle({ ...userUpdateHandle, [e.target.name]: e.target.value })
        if (e.target.name == 'utype') {
            if (e.target.value == 2) {
                SetPermissionShow("form-group md-3")
            }
            else {
                SetPermissionShow("form-group md-3 d-none")
            }
        }
    }

    /*async function getUserList() {
        axios.get(`api/listuser`).then(res => {
            // console.warn(res.data.data);
            setUserList(res.data);
            setSearchUser(res.data)
            setPending(false);
        })

    }*/
    const getUserList = () => {
        axios.get(`/api/listuser?page=${page}&per_page=${countPerPage}`).then(res => {
            //console.log(res.data);
            setDetails(res.data);
            setUserList(res.data.data);
            setSearchUser(res.data)
            setPending(false);
        }).catch(err => {
            setUserList({});
        });
    }

    const handleSearch = (e) => {
        const searchValue = e.target.value;
        setSearchTerm(searchValue);
        if(searchValue.length > 3){
            //console.log(searchValue.length);
            axios.get(`/api/search-user?searchItems=${searchValue}`)
                .then(res => {
                    setDetails(res.data); // Assuming res.data contains total count and search results
                    setUserList(res.data); // Assuming res.data.data contains search results
                    setPending(false);
                })
            .catch(error => {
                //console.error('Error fetching search results:', error);
                setDetails({ total: 0, data: [] });
                setUserList([]);
                setPending(false);
            });
        } else {
            //console.log(searchValue.length);
            getUserList();
        }
    };

    
    /*async function getUserList() {
        try {
            // Initial Axios call
            const firstResponse = await axios.get(`api/listuser`);
            setUserList(firstResponse.data);
            setSearchUser(firstResponse.data);
            setPending(false);
    
            // After 10 seconds, make the second Axios call
            setTimeout(async () => {
                try {
                    const secondResponse = await axios.get(`api/list-next-set-user`);
                    // Merge the data with the previous setUserList
                    setUserList(prevList => [...prevList, ...secondResponse.data]);
                } catch (error) {
                    console.error('Error fetching second set of data:', error);
                }
            }, 10000); // 10 seconds delay
        } catch (error) {
            console.error('Error fetching initial set of data:', error);
        }
    }*/

    async function loginAsCustomer(id){
        setloadershow(true);
        axios.get(`api/get-user-details-by-id/${id}`).then(res => {
            if(res.data.status === "success"){
                localStorage.setItem('loggedInAs','admin');
                localStorage.setItem('userId',res.data.data.user_id);
                afterLogin('All', 1, res.data.data.user_id, res.data.data, res.data.data.is_member);
                setloadershow(false);
                //navigate("/dashboard");
                window.open("/dashboard", "_blank");
            } else {
                setloadershow(false);
                Swal.fire({
                    title: 'Warning',
                    html: res.data.message,
                    icon: 'warning',
                    showConfirmButton: true,
                })
            }
        })
    }

    const columns = [
        {
            name: "Name",
            selector: row => row.name
        },
        {
            name: "Email",
            cell: row => (
                <span>{row.email}</span>
            )
        },
        {
            name: "User Type",
            cell: row => (
                <span>{row.user_type == 1 ? 'Admin' : row.user_type == 2 ? 'Support' : 'User'}</span>
            )
        },
        {
            name: 'Created at',
            selector: row => row.created_at.split("T")[0],
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <button className='btn btn-primary' onClick={() => loginAsCustomer(row.id)} data-tooltip-id="my-tooltip-1">
                        <FontAwesomeIcon icon={faSignIn} />
                    </button>
                    <button style={{ marginRight: 1 + 'em' }} className='btn btn-primary' onClick={() => { showSingleData(row.id) }} ><FontAwesomeIcon icon={faGear} /></button>
                    <button className='btn btn-danger' onClick={() => { deleteUser(row.id) }} ><FontAwesomeIcon icon={faDeleteLeft} /></button>
                </>
            )
        }
    ]

    useEffect(() => {
        //console.log('useEffect');
        getUserList()
    }, [page]);


    function createUserModal() {
        setSelected([]);
        SetPermissionShow("form-group md-3 d-none");
        setUserDetails([]);
        setUserId(false);
        setErros({});
        setDpassword("");
        handleShow();
    }

    const submitUser = (data) => {
        // e.preventDefault();
        // setErros(validate(userInstance));

        // if (Object.keys(errors).length === 0 && isPSubmit) {
        //     handleClose();
            const formdata = new FormData();
            formdata.append('name', data.uname);
            formdata.append('email', data.uemail);
            formdata.append('password', data.upassword);
            formdata.append('user_type', data.utype);
            formdata.append('acoount_permission', JSON.stringify(selected));
            axios.post(`api/createuser`, formdata).then(res => {
                if (res.data.status === true) {
                    handleClose();
                    Swal.fire({
                        title: 'Success',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    getUserList();
                    //window.location.reload();
                }
            }).catch(err => {
                console.log(err.response)
                Swal.fire({
                    title: 'Warning',
                    html: err.response.data.message,
                    icon: 'warning'
                })

            });

        // }
    }

    function deleteUser(id) {
        Swal.fire({
            title: 'Are you sure you want to delete this User?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showCloseButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`api/deleteuser/${id}`).then(res => {

                    Swal.fire({
                        title: 'Deleted',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    // window.location.reload();
                    getUserList();
                })
            }
        })
    }

    function showSingleData(id) {
        //console.warn(id);
        setIsPSubmit(false);
        setUserInstance({
            uname: "",
            uemail: "",
            upassword: "",
            utype: "0"
        });
        setUserUpdateHandle({
            uname: "up",
            uemail: "up",
            upassword: "up",
            utype: "-1"
        });
        SetPermissionShow("form-group md-3 d-none");
        setSelected([])
        setUserId(id);
        setDpassword("*************")
        axios.get(`api/detailsuser/${id}`).then(res => {
            if (res.data.user_type == 2) {
                var pc = JSON.parse(res.data.acoount_permission);
                setSelected(pc);
                SetPermissionShow("form-group md-3");
            }
            setUserDetails(res.data);
            setErros({});
            handleShow();
        })
    }

    const updateUser = (data) => {

        // const errors = {};
        // // console.warn(userInstance.utype);
        // if (!userInstance.uname && userUpdateHandle.uname != 'up') {
        //     errors.uname = "Must Provide a Name";
        // }
        // if (!userInstance.uemail && userUpdateHandle.uemail != 'up') {
        //     errors.uemail = "Must Provide an Email";
        // }
        // if (userInstance.utype == '0' && userUpdateHandle.utype != '-1') {
        //     errors.utype = "Must Select an User type";
        // }
        // if (!userInstance.upassword && userUpdateHandle.upassword != 'up') {
        //     errors.upassword = "Must Provide a Password";
        // }
        // if (userInstance.utype == 2 || userDetails.user_type == 2) {
        //     // console.warn(selected);

        //     if (selected.length == 0) {
        //         // console.warn(selected);
        //         errors.uper = "Must Set a Permission";
        //         setIsPSubmit(true);
        //         setErros(errors)
        //         console.warn(isPSubmit, Object.keys(errors).length);

        //     }
        // }
        // if (isPSubmit) {
        //     setErros(errors)
        // }

        // if (Object.keys(errors).length > 0 && isPSubmit || ((userInstance.utype == 2 || userDetails.user_type == 2) && selected.length == 0)) {
        //     return false;
        // }

        const formdata = new FormData();
        formdata.append('name', data.uname ? data.uname : userDetails.name);
        formdata.append('email', data.uemail ? data.uemail : userDetails.email);
        if (data.upassword != Dpassword) {
            formdata.append('password', data.upassword != Dpassword ? data.upassword : userDetails.password);
        }

        formdata.append('user_type', data.utype != "0" ? data.utype : userDetails.user_type);
        formdata.append('acoount_permission', JSON.stringify(selected));
        axios.post(`api/updateuser/${userId}`, formdata).then(res => {
            handleClose();
            if (res.data.status === true) {
                handleClose();
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                // window.location.reload();
                getUserList();
            }
        }).catch(err => {
            console.log(err.response)
            //window.location.reload();
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })

        });

    }

    /*useEffect(() => {
        const result = userList.filter(country => {
            return country.email.toLowerCase().match(search.toLowerCase());
        })
        setSearchUser(result);
    }, [search]);*/

    return (
        <>
        <ReactTooltip
            id="my-tooltip-1"
            place="top"
            content="Login As Customer"
        />
        <div>
            <div >
                <div className={loadershow ? 'loading' : 'd-none'}></div>
                <div className='modal'>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>User</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-md-12'>
                                        <div className='card'>
                                            <div className='card-body'>
                                            <Formik
                                                    enableReinitialize = {true}
                                                    initialValues={{
                                                        uname: userDetails.name,
                                                        uemail: userDetails.email,
                                                        upassword: Dpassword,
                                                        utype: userDetails.user_type,
                                                    }}

                                                    validationSchema={Yup.object({
                                                        uname: Yup.string().required('user name is required'),
                                                        uemail: Yup.string().required('user email is required'),
                                                        upassword: Yup.string().required('user password is required'),
                                                        utype: Yup.string().required('Please select a user type'),
                                                    })}

                                                    onSubmit={(values, { setSubmitting }) => {
                                                        setSubmitting(true);
                                                        //alert(JSON.stringify(values, null, 2));
                                                        {(userId) ? updateUser(values) : submitUser(values); }
                                                    }}
                                                >
                                                <Form className='row col-md-9' onChange={handelInput}>
                                                    <div className='form-group md-3'>
                                                        <label>Name</label>
                                                        <Field type="" name="uname" className="form-control" />
                                                        <CustomErrorMsg name="uname" />
                                                        
                                                    </div>
                                                    <div className='form-group md-3'>
                                                        <label>Email </label>
                                                        <Field type="" name="uemail" className="form-control" />
                                                        <CustomErrorMsg name="uemail" />
                                                        
                                                    </div>
                                                    <div className='form-group md-3'>
                                                        <label> Password</label>
                                                        <Field type="" name="upassword" className="form-control"  />
                                                        <CustomErrorMsg name="upassword" />
                                                        
                                                    </div>
                                                    <div className='form-group md-3'>
                                                        <label> User Type </label>
                                                        <Field as="select" name="utype" className="form-control">
                                                            <option value=''>Select User Type</option>
                                                            <option value={1} key={1}>Admin</option>
                                                            <option value={2} key={2}>Support</option>
                                                            <option value={3} key={3}>User</option>
                                                        </Field>
                                                        <CustomErrorMsg name="utype" />
                                                        
                                                    </div>

                                                    <div className={permissionShow}>
                                                        <label> Set Permission</label>
                                                        <MultiSelect
                                                            options={options}
                                                            value={selected}
                                                            onChange={setSelected}
                                                            labelledBy="Select"
                                                        />
                                                        {errors.uper && <p class="text-danger">{errors.uper}</p>}
                                                    </div>


                                                    {/* <div className='form-group md-3'>
                                                        <Button type="submit">{userId ? "Update" : "Save"}</Button>
                                                    </div> */}
                                                </Form>
                                                </Formik>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary d-none" >
                                {userId ? "Update" : "Save"}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>

            </div>
            <div className='container'>
                <div className='col-md-12 pl-3'>
                    <div className="pageheader card mb-4">
                        <div className="card-body row align-items-center">
                            <div className="page-name col-md-3">User List</div>
                            <div className="buttun_div  col-md-9">  <Button variant="primary" onClick={createUserModal}>
                                Create User
                            </Button>
                            </div>
                        </div>
                    </div>
                    <h1 className="display-6"></h1>
                    <div className='card '>
                        <div className='card-body '>
                            <div className='datatable '>
                                {/* <DataTable
                                    noDataComponent="No data found" //or your component
                                    columns={columns}
                                    data={searchUser}
                                    progressPending={pending}
                                    pagination
                                    fixedHeader
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text" placeholder="search" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                                    }
                                    subHeaderAlign="right"
                                /> */}
                                <DataTable
                                    noDataComponent="No data found"
                                    columns={columns}
                                    data={userList}
                                    highlightOnHover
                                    progressPending={pending}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={details.total}
                                    paginationPerPage={countPerPage}
                                    paginationComponentOptions={{
                                        noRowsPerPage: true
                                    }}
                                    fixedHeader
                                    subHeader
                                    subHeaderComponent={
                                        // <input type="text" placeholder="search" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                                        <input type="text" placeholder="search" value={searchTerm} onChange={handleSearch} />
                                    }
                                    onChangePage={page => setPage(page)}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default Adduser