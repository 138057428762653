import React, { useState, useEffect } from 'react'
import Template from '../funneltemplates/Template-1'



function AddCheckoutFunnel() {
    return (
        <div className="container funnel-container py-4">
            <div className='card mb-4'>
                <div className='card-body'>
                    <div className="row align-items-center">
                        <div className="page-name col-md-3 mb-2 mb-md-0">Add Checkout Page</div>
                        <div className="headerCus  col-md-9">
                            {/* <div className="text-right">
                            <button className='btn btn-success w-25'>Save</button>
                        </div> */}

                        </div>
                    </div>
                </div>
            </div>
            <div className="card funnel_card">
                <div className="card-body p-0">

                    <Template />

                </div>
            </div>








        </div>

    )
}

export default AddCheckoutFunnel