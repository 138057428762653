import React, { useState, useEffect } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom"; // v6
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faDeleteLeft } from '@fortawesome/free-solid-svg-icons';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../frontend/CustomErrorMsg";
import {BASE_URL} from '../Config';

function Survey(){
    const [show, setShow] = useState(false);
    const [List, setList] = useState([]);
    const [pending, setPending] = useState(true);
    const [Details, setDetails] = useState([]);
    const [Id, setId] = useState(false);
    const [search, setSearch] = useState("");
    const [searchData, setsearchData] = useState([]);
    const [option, setOption] = useState([]);

    const handleShow = () => setShow(true);

    const handleClose = () => {
        setShow(false)
        setsearchData('')
    }

    function createModal() {
        setDetails([]);
        setId(false);
        handleShow();
    }

    function getSurveyQuestionList() {
        setPending(true);
        axios.get(`api/getSurveyQuestionlist`).then(res => {
            setList(res.data.data);
            setSearch(res.data.data);
            setPending(false);
        }).catch(err => {
            //console.log(err.response)
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }

    function showSingleData(id) {
        axios.get(`api/getSurveyQuestionById/${id}`).then(res => {
            setDetails({
                question_title:res.data.question_title,
                question_type:res.data.question_type,
                question_type_value:JSON.parse(res.data.question_type_value)
            });
            setId(id);
            handleShow();
        })
    }

    function deleteSurveyQuestion(id) {
        Swal.fire({
            title: 'Are you sure you want to delete this survey question?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showCloseButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`api/deleteSurveyQuestion/${id}`).then(res => {
                    Swal.fire({
                        title: 'Deleted',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    getSurveyQuestionList();
                })
            }
        })
    }

    useEffect(() => {
        getSurveyQuestionList();
    }, []);

    useEffect(() => {
        const result = List && List?.filter(question => {
            return question.question_title.toLowerCase().match(searchData.toLowerCase());
        })
        setSearch(result);
    }, [searchData]);

    const columns = [
        {
            name: "Question Title",
            selector: row => row.question_title
        },
        {
            name: "Question Type",
            selector: row => row.question_type
        },
        {
            name: "Question Type Value",
            selector: row => (row.option_value != "") ? row.option_value : "-"
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <button style={{ marginRight: 1 + 'em' }} className='btn btn-primary' onClick={() => { showSingleData(row.id) }}><FontAwesomeIcon icon={faGear} /></button>
                    <button className='btn btn-danger' onClick={() => { deleteSurveyQuestion(row.id) }} ><FontAwesomeIcon icon={faDeleteLeft} /></button>
                </>
            )
        }
    ]

    const formSubmit = (formData) => {
        const formdata = new FormData();
        formdata.append('question_title', formData.question_title);
        formdata.append('question_type', formData.question_type);
        formdata.append('option', JSON.stringify(formData.options));
        axios.post(`api/createSurveyQuestion`, formdata).then(res => {
            if (res.data.status === true) {
                handleClose();
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                getSurveyQuestionList();
            }
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        });
    }

    const formUpdate = (formData) => {
        const formdata = new FormData();
        formdata.append('question_title', formData.question_title);
        formdata.append('question_type', formData.question_type);
        formdata.append('option', JSON.stringify(formData.options));
        axios.post(`api/updateQuestionSurvey/${Id}`, formdata).then(res => {
            if (res.data.status === true) {
                handleClose();
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                getSurveyQuestionList();
            }
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        });
    }

    return (
        <div>
            <div >
                <div className='modal'>
                    <Modal show={show} onHide={handleClose} className='custom-modal'>
                        <Modal.Header closeButton>
                            <Modal.Title>Survey Questions</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-md-12'>
                                        <div className='card'>
                                            <div className='card-body' style={{minHeight:'auto',overflowY:'hidden'}}>
                                                <Formik
                                                    enableReinitialize = {true}
                                                    initialValues={{
                                                        question_title: Details.question_title,
                                                        question_type: Details.question_type,
                                                        options: Details.question_type_value && Details.question_type_value.length > 0 ? Details.question_type_value : ['']

                                                    }}

                                                    validationSchema={Yup.object({
                                                        question_title: Yup.string().required('Question title is required'),
                                                        //question_type: Yup.string().required('Question type is required'),
                                                        options: Yup.array().when('question_type', {
                                                            is: 'multioption',
                                                            then: Yup.array()
                                                              .of(Yup.string().trim().required('Option cannot be blank'))
                                                              .min(1, 'At least one option is required'),
                                                            otherwise: Yup.array(), // No validation for other question types
                                                        }),
                                                    })}

                                                    onSubmit={(values, { setSubmitting }) => {
                                                        setSubmitting(true);
                                                        //alert(JSON.stringify(values, null, 2));
                                                        {(Id) ? formUpdate(values) : formSubmit(values); }

                                                    }}
                                                >
                                                {(formProps) => (
                                                    <Form className='row col-md-12'>
                                                        <div className='form-group md-3'>
                                                            <label> Question Title </label>
                                                            <Field type="text" name="question_title" className="form-control"></Field>
                                                            <CustomErrorMsg name="question_title" />
                                                        </div>
                                                        <div className="form-group md-3">
                                                            <label>Question Type:</label>
                                                            <Field
                                                                name="question_type"
                                                                as="select"
                                                                className="form-control"
                                                                onChange={(event) => {
                                                                    formProps.handleChange(event);
                                                                    formProps.setFieldValue('question_type', event.target.value);
                                
                                                                }}
                                                            >
                                                                <option value="Text" >Text</option>
                                                                <option value="Multi-option">Multi-option</option>
                                                            </Field>
                                                            <CustomErrorMsg name="question_type" />
                                                            <div className="accept-icon"></div>
                                                        </div>
                                                        {formProps.values.question_type === 'Multi-option' && (
                                                        <div className='form-group md-3'>
                                                            <label>Options:</label>
                                                            {formProps.values.options.map((option, index) => (
                                                                
                                                            <div key={index} className="option-input sur-add">
                                                                <Field
                                                                type="text"
                                                                name={`options[${index}]`}
                                                                className="form-control"
                                                                />
                                                                {index > 0 && (
                                                                <button className='cross'
                                                                    type="button"
                                                                    onClick={() => {
                                                                    const newOptions = [...formProps.values.options];
                                                                    newOptions.splice(index, 1);
                                                                    formProps.setFieldValue('options', newOptions);
                                                                    }}
                                                                >
                                                                    &#x2715; {/* Unicode for the multiplication sign (cross) */}
                                                                </button>
                                                                )}
                                                                {index === formProps.values.options.length - 1 && (
                                                                <button className='suram-btn btn btn-primary'
                                                                    type="button"
                                                                    onClick={() => formProps.setFieldValue('options', [...formProps.values.options, ''])}
                                                                >
                                                                    Add More
                                                                </button>
                                                                )}
                                                                <CustomErrorMsg name="`options[${index}]`" />
                                                            </div>
                                                            ))}
                                                        </div>
                                                        )}
                                                        <div className='form-group md-3 text-end'>
                                                            <button type="submit" className='btn submitBtn btn-primary'>Save!</button>
                                                        </div>
                                                    </Form>
                                                )} 
                                                </Formik>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
            <div className='container'>
                <div className='col-md-12 pl-3'>
                    <div className="pageheader card mb-4">
                        <div className="card-body row align-items-center">
                            <div className="page-name col-md-3">Survey Question List</div>
                            <div className="buttun_div  col-md-9">  <Button variant="primary" onClick={createModal}>
                                Create Survey Question
                            </Button>
                            </div>
                        </div>
                    </div>
                    <h1 className="display-6"></h1>
                    <div className='card '>
                        <div className='card-body '>
                            <div className='datatable '>
                                <DataTable
                                    noDataComponent="Survey question table is empty" //or your component
                                    columns={columns}
                                    data={search}
                                    progressPending={pending}
                                    pagination
                                    fixedHeader
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text" placeholder="search" value={searchData} onChange={(e) => { setsearchData(e.target.value) }} />
                                    }
                                    subHeaderAlign="right"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Survey;