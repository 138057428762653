import { Formik, Field, Form } from 'formik';
import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import '../../assests/Lander/assets/css/acvgummies2-landing.css';

// Images 
import acvTwoLogo from '../../assests/Lander/assets/images/acvgummies2/logo.png';
import acvTwoLogoWhite from '../../assests/Lander/assets/images/acvgummies2/logo-white.png';
import badgeHighQuality from '../../assests/Lander/assets/images/acvgummies2/badge-high-quality.png';
import badge1 from '../../assests/Lander/assets/images/acvgummies2/badge-100-satisfaction.png';
import badge2 from '../../assests/Lander/assets/images/acvgummies2/badge-100-natural.png';
import badge3 from '../../assests/Lander/assets/images/acvgummies2/badge-bhb-100-pure.png';
import bannerBottle from '../../assests/Lander/assets/images/acvgummies2/top-banner-bottle-smg.png';
import leftDownArraow from '../../assests/Lander/assets/images/acvgummies2/vector-left-down-arrow.png';
import securityIcon from '../../assests/Lander/assets/images/acvgummies2/security-icons-1.png';
import logoGroup from '../../assests/Lander/assets/images/acvgummies2/logo-group-desktop.png';
import logoGroupMob from '../../assests/Lander/assets/images/acvgummies2/logo-group-mobile.png';
import woman1 from '../../assests/Lander/assets/images/acvgummies2/women-1.png';
import product2 from '../../assests/Lander/assets/images/acvgummies2/product2.png';
import step1 from '../../assests/Lander/assets/images/acvgummies2/step-1.png';
import step2 from '../../assests/Lander/assets/images/acvgummies2/step-2.png';
import step3 from '../../assests/Lander/assets/images/acvgummies2/step-3.png';
import woman2 from '../../assests/Lander/assets/images/acvgummies2/women-2.png';
import testImg1 from '../../assests/Lander/assets/images/acvgummies2/testimonial-1.png';
import testImg2 from '../../assests/Lander/assets/images/acvgummies2/testimonial-2.png';
import testImg3 from '../../assests/Lander/assets/images/acvgummies2/testimonial-3.png';
import securityIcons from '../../assests/Lander/assets/images/acvgummies2/security-icons-1.png';
import manWoman from '../../assests/Lander/assets/images/acvgummies2/men-women.png';
import nowAvailable from '../../assests/Lander/assets/images/acvgummies2/now-available.png';
import securityIcons2 from '../../assests/Lander/assets/images/acvgummies2/security-icons.png';
import popupImg from '../../assests/Lander/assets/images/acvgummies2/popup.gif';
import upArrow from '../../assests/Lander/assets/images/up-arrow-white.png';
import CustomErrorMsg from "../frontend/CustomErrorMsg";
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';
import { CountryList } from '../Config';

function Acvgummies2Template(){
    const [states, setStates] = useState([]);
    const [loadershow, setloadershow] = useState(false);
    const location = useLocation();
    const [minutes, setMinutes] = useState(9);
    const [seconds, setSeconds] = useState(59);
    const currentDate = getCurrentDate();
    let params = location.search;
    const ref = useRef(null);
    const navigate = useNavigate();
    const currentYear = new Date().getFullYear();
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
              breakpoint: 992,
              settings: { slidesToShow: 1, slidesToScroll: 1, infinite: true }
            }
            
        ]
    };

    function getDeviceType() {
        let width = window.innerWidth;
        let height = window.innerHeight;
    
        // Define breakpoints for different device types
        let isMobile = width <= 700;  // Mobile devices
        let isTablet = width > 700 && width <= 1024; // Tablets
        let isDesktop = width > 1024; // Desktops
    
        if (isMobile) {
            return 'mobile';
        } else if (isTablet) {
            return 'tablet';
        } else if (isDesktop) {
            return 'desktop';
        }
    }

    let deviceType = getDeviceType();

    function handleClick(){
        setloadershow(true);
        navigate(window.location.href = '/' + localStorage.getItem('c_s')+params);
    }

    const DisplayingErrorMessagesSchema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        shippingAddress1: Yup.string().required('Address is required'),
        shippingZip: Yup.string().when('country', {
            is: 'US',
            then: Yup.string()
              .required('Zip code is required')
              .matches(/^\d{5}$/, 'Zip code must be exactly 5 digits number'),
            otherwise: Yup.string().when('country', {
              is: 'CA',
              then: Yup.string()
                .required('Zip code is required')
                .matches(/^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/, 'Zip code must contain only letters and numbers')
                .min(6, 'Zip code should contain at least 6 characters')
                .max(6, 'Zip code should contain at most 6 characters'),
            }),
        }),
        shippingCity: Yup.string().required('City is required'),
        shippingState: Yup.string().required('State is required'),
        email: Yup.string().email("Email is required").max(255).required("Please enter a valid email"),
        phone: Yup.string().required('Phone no is required')
            .matches(/^[0-9]+$/, "Please enter only number!")
            .min(10, 'Please enter a valid contact number!')
            .max(10, 'Please enter a valid contact number!'),
        country: Yup.string().required('Country is required')
    });

    function getCurrentDate() {
        const date = new Date();
        const month = date.getMonth();
        const day = date.getDate();
        const year = date.getFullYear();
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const monthName = months[month];
        return `${monthName} ${day}, ${year}`;
    }

    useEffect(()=>{
        handleCountryChange('239');
    },[]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [minutes, seconds]);
    
    const handleCountryChange = (country_id) => {
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    function confirmSubmit(values) {
        let campaign_id = '44';
        setloadershow(true);
        const formdata = new FormData();
        formdata.append('firstName',values.firstName);
        formdata.append('lastName',values.lastName);
        formdata.append('email',values.email);
        formdata.append('phone',values.phone);
        formdata.append('tag1','male-prospect');
        formdata.append('shippingAddress',values.shippingAddress1);
        formdata.append('shippingCity',values.shippingCity);
        formdata.append('shippingCountry',values.country);
        formdata.append('shippingState',values.shippingState);
        formdata.append('shippingZip',values.shippingZip);
        formdata.append('campaign_id',campaign_id);
        formdata.append('tag1','diet');
        formdata.append('tag2','diet-prospect');

        let affid = new URLSearchParams(location.search).get('affid');
        let sid = new URLSearchParams(location.search).get('sid');
        let s1 = new URLSearchParams(location.search).get('s1');
        let s2 = new URLSearchParams(location.search).get('s2');
        let s3 = new URLSearchParams(location.search).get('s3');
        let click_id = new URLSearchParams(location.search).get('click_id');
        let notes = new URLSearchParams(location.search).get('notes');

        localStorage.setItem('affid',affid);
        localStorage.setItem('sid',sid);
        localStorage.setItem('s1',s1);
        localStorage.setItem('s2',s2);
        localStorage.setItem('s3',s3);
        localStorage.setItem('click_id',click_id);
        localStorage.setItem('notes',notes);

        formdata.append('affid', affid);
        formdata.append('sid', sid);
        formdata.append('s1', s1);
        formdata.append('s2', s2);
        formdata.append('s3', s3);
        formdata.append('click_id', click_id);
        formdata.append('notes', notes);
        
        //console.log(Object.fromEntries(formdata));

        axios.post(`api/maropost_tag_add`, formdata).then(res => {
            if (res.data.status === true) {
                axios.post(`api/create-prospect`, formdata).then(result => {
                    if(result.data.status === true) {
                        localStorage.setItem('prospectId',result.data.prospectId);
                        window.location.href = '/' + localStorage.getItem('c_s')+params;
                    }
                })
            }
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        });
    }

    const handleClickRef = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    

    return(
        <>
            <div className={loadershow ? 'loading' : 'd-none'}></div>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="acvgummies2LanderPage">
                {/* Header  */}
                <header className="acv2Header py-2">
                    <div className="container top-bar">
                        <div className="row">
                            <div className="col-12">
                                <div className="header-warning text-center">
                                    Warning: Due to extremely high media demand, there is limited supply of Tru Body ACV Keto Gummies in stock as of&nbsp; 
                                    <span id="currentDate" className="medium-font color-yellow"> {currentDate}</span> <span className="medium-font color-yellow">HURRY!</span>
                                    <span id="clockdiv" className="medium-font color-yellow">
                                        <span className="minutes"> {minutes}</span>:<span className="seconds">{seconds}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {/* Header End */}
                <main className='acv2Main' ref={ref}>
                    <div className={loadershow ? 'loading' : 'd-none'}></div>
                    {/* Section 1  */}
                    <section id="section-1" className="acv2Section1 section py-5 bg-topbanner">
                        <div className="container position-relative">
                            <div className="row align-items-center">
                                <div className="col-12 topbanner-left position-relative mb-lg-0 mb-5">
                                    <div className="d-flex">
                                        <div className="col-12 col-md-8 pe-md-2">
                                            <Link to="/" className="logo-wrap image-wrap mb-4">
                                                <img src={acvTwoLogoWhite} className="logo" alt="" />
                                            </Link>
                                            <h1 className="color-white extrabold-font mb-0">Melt FAT <br /><span className="color-green">FAST</span></h1>
                                            <h3 className="color-yellow medium-font mb-0">Without Excercise</h3>
                                            <div className="heading-divider"></div>
                                            <h4 className="color-white mb-3">Powerful New Formula Triggers <br />Fat-Burning Ketosis!
                                            </h4>
                                            <div className="topBanner-part2 d-flex">
                                                <div className="col-7">
                                                    <ul className="list-unstyled color-white">
                                                        <li>Burn Fat for Energy not Carbs</li>
                                                        <li>Release Fat Stores</li>
                                                        <li>Increase Energy Naturally!</li>
                                                        <li>Love the Way You Feel!</li>
                                                    </ul>
                                                    <div className="image-wrap badgeMob mobile_show ">
                                                        <img src={badgeHighQuality} className='img-fluid m-auto w-75' alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-5 col-md-4">
                                                    <div className="image-wrap badgeDesktop ">
                                                        <img src={badgeHighQuality} alt="" />
                                                    </div>
                                                    <div className="bannerProd-mob image-wrap mobile_show">
                                                        <img src={product2} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="topBanner-part3 d-flex gap-2">
                                                <div className="col-2 image-wrap">
                                                    <img src={badge1} alt="" />
                                                </div>
                                                <div className="col-2 image-wrap">
                                                    <img src={badge2} alt="" />
                                                </div>
                                                <div className="col-2 image-wrap">
                                                    <img src={badge3} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 d-md-flex align-items-end ps-2 desktop_show">                                
                                            <div className="image-wrap top-banner-bottle">
                                                <img src={bannerBottle} width="755" height="741" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 topbanner-right position-relative">
                                    <div className="form-block">
                                        <div className="text-center topbanner-bottle-top-text mb-0">
                                            <h4 className="color-white">Get My <span className="color-red">Free Bottle!</span></h4>
                                            <p className="small color-white">Voted #1 Keto Product in USA</p>
                                        </div>
                                        <div className="form-wrap bg-yellow">
                                            <h5 className="form-title text-uppercase color-dark-green bold-font">Step 1 - tell us where To Send your bottle
                                            </h5>
                                            <Formik
                                                initialValues={{
                                                    firstName: '',
                                                    lastName: '',
                                                    shippingAddress1: '',
                                                    shippingZip: '',
                                                    shippingCity: '',
                                                    shippingState: '',
                                                    email: '',
                                                    phone: '',
                                                    country:'US'
                                                }}
                                                validationSchema={DisplayingErrorMessagesSchema}
                                                onSubmit={values => {
                                                    localStorage.setItem('firstName', values.firstName);
                                                    localStorage.setItem('lastName', values.lastName);
                                                    localStorage.setItem('shippingAddress1', values.shippingAddress1);
                                                    localStorage.setItem('shippingZip', values.shippingZip);
                                                    localStorage.setItem('shippingState', values.shippingState);
                                                    localStorage.setItem('shippingCity', values.shippingCity);
                                                    localStorage.setItem('phone', values.phone);
                                                    localStorage.setItem('email', values.email);
                                                    localStorage.setItem('country',values.country);
                                                    confirmSubmit(values);
                                                }}
                                            >
                                            {({ errors, touched, setFieldValue }) => (
                                                <Form className='acv2Form mb-4'>
                                                    <div className="acv2-form-holder mb-3">
                                                        <Field 
                                                            name="firstName" 
                                                            className="form-control"
                                                            placeholder="First Name*"
                                                        />
                                                        {touched.firstName && !errors.firstName && <div className="accept-icon"/>}
                                                        <CustomErrorMsg name="firstName" />
                                                    </div>
                                                    <div className="acv2-form-holder mb-3">
                                                        <Field 
                                                            name="lastName" 
                                                            className="form-control" 
                                                            placeholder="Last Name*"
                                                        />
                                                        {touched.lastName && !errors.lastName && <div className="accept-icon"></div>}
                                                        <CustomErrorMsg name="lastName" />
                                                    </div>
                                                    <div className="acv2-form-holder mb-3">
                                                        <Field 
                                                            name="shippingAddress1" 
                                                            className="form-control" 
                                                            placeholder="Address*"
                                                        />
                                                        {touched.shippingAddress1 && !errors.shippingAddress1 && <div className="accept-icon"></div>}
                                                        <CustomErrorMsg name="shippingAddress1" />
                                                    </div>
                                                    <div className="acv2-form-holder mb-3">
                                                        <Field 
                                                            name="shippingCity" 
                                                            className="form-control" 
                                                            placeholder="City*"
                                                        />
                                                        {touched.shippingCity && !errors.shippingCity && <div className="accept-icon"/>}
                                                        <CustomErrorMsg name="shippingCity" />
                                                    </div>
                                                    <div className="acv2-form-holder mb-3">
                                                        <Field
                                                            name="country"
                                                            as="select"
                                                            placeholder="Select an option*"
                                                            className="form-control validate[required]"
                                                            onChange={(event) => {
                                                                setFieldValue('country', event.target.value);
                                                                setFieldValue("shippingState", '');
                                                                
                                                                handleCountryChange(event.target[event.target.selectedIndex].getAttribute('data-id')); 
                                                                // Call the custom function here if needed
                                                                }}
                                                            >
                                                            {CountryList.map((country) => (
                                                            <option key={country.id} data-id={country.id} value={country.short_name}>
                                                             {country.country}
                                                            </option>
                                                             ))}
                                                        </Field>
                                                        <CustomErrorMsg name="country" />
                                                    </div>
                                                    <div className="acv2-form-holder mb-3">
                                                        <Field
                                                            name="shippingState"
                                                            as="select"
                                                            placeholder="Select an option*"
                                                            className="form-control"
                                                            
                                                        >
                                                            <option value="" >Select state</option>
                                                            {states.map((state) => (
                                                                <option key={state.id} value={state.abbreviation}>
                                                                    {state.state}
                                                                </option>
                                                            ))}
                                                        </Field>
                                                        <CustomErrorMsg name="shippingState" />
                                                    </div>
                                                    <div className="acv2-form-holder mb-3">
                                                        <Field 
                                                            name="shippingZip" 
                                                            className="form-control" 
                                                            placeholder="Zip Code*"
                                                            maxLength="6"
                                                        />
                                                        {touched.shippingZip && !errors.shippingZip && <div className="accept-icon"></div>}
                                                        <CustomErrorMsg name="shippingZip" />
                                                    </div>                                                    
                                                    <div className="acv2-form-holder mb-3">
                                                        <Field 
                                                            type="tel" 
                                                            name="phone" 
                                                            className="form-control" 
                                                            placeholder="Phone*"  
                                                        />
                                                        {touched.phone && !errors.phone && <div className="accept-icon"/>}
                                                        <CustomErrorMsg name="phone" />
                                                    </div>
                                                    <div className="acv2-form-holder mb-3">
                                                        <Field 
                                                            type="email" 
                                                            name="email" 
                                                            className="form-control" 
                                                            placeholder="Email*"
                                                        />
                                                        {touched.email && !errors.email && <div className="accept-icon"/>}
                                                        <CustomErrorMsg name="email" />
                                                    </div>
                                                    <button className="btn acv2Btn ac2Btn-full mt-4" type="submit" data-uitype="submit_button">Rush My Order</button>
                                                </Form>
                                            )}
                                            </Formik>
                                                
                                            <div className="acvSecurity-logo image-wrap mx-auto">
                                                <img src={securityIcon} alt="" className="" />
                                            </div>
                                        </div>
                                        <div className="form-vector image-wrap position-absolute d-none d-lg-block">
                                            <img src={leftDownArraow} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Section 1 End  */}


                    {/* Section 2 Begin  */}
                    <section id="section-2" className="acv2Section2 section py-5 bg-image-1">
                        <div className="container">
                            <div className="row mb-3 mw-1140px logoGrp">
                                <div className="col-12">
                                    <div className="image-wrap ">
                                        <img src={logoGroup} className="d-none d-lg-block" alt="" />
                                        <img src={logoGroupMob} className="d-block d-lg-none" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mw-1140px align-items-center">                    
                                <div className="col-12 col-xl-5 col-lg-6 mb-4 mb-lg-0">
                                    <div className="d-flex align-items-end womanProduct-img">
                                        <div className="image-wrap px-2 woman1">
                                            <img src={woman1} width="160" height="543" alt="" />
                                        </div>
                                        <div className="image-wrap px-2 mb-6 primaryBottle">
                                            <img src={product2} width="182" height="336" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-7 col-lg-6">                        
                                    <h3 className="color-dark-green extrabold-font mb-0">Why is Trubody Supplement</h3>
                                    <h3 className="color-green extrabold-font mb-4">So popular Now?</h3>
                                    <p>A recent study published by the Diabetes, Obesity, and Metabolism Journal found that Keto supported burning fat for energy instead of carbohydrates greatly increasing weight loss and energy. Many have called Keto the "Holy Grail" of weight loss for good reason - IT WORKS.</p>
                                    <p>It is important to note that the Keto with 100% BHB (Beta-Hydroxybutyrate) used in the study was the real deal and Keto exceeds the studies product potency using proprietary methods.</p>
                                    <p>Bottom Line: It Works and it's Better for your Health!</p>
                                    {(deviceType === 'mobile') ? 
                                        <div className="btn-wrap">
                                            <Link to="javascript:void(0)" onClick={handleClick} className="btn acv2Btn">Rush My Order</Link>
                                        </div>
                                    :
                                        <div className="btn-wrap">
                                            <Link to="javascript:void(0)" onClick={handleClickRef} className="btn acv2Btn">Rush My Order</Link>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Section 2 Begin  */}


                    {/* Section 3 Begin  */}
                    <section id="section-3" className="acv2Section3 section bg-image-2 py-5">
                        <div className="container">
                            <div className="row mb-5">
                                <div className="col-12">
                                    <div className="text-wrap">
                                        <h3 className="color-dark-green extrabold-font">How to use Tru Body ACV Keto Gummies</h3>
                                        <h3 className="mb-0 color-green extrabold-font">To Get Results</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-4">
                                    <div className="image-wrap mb-3">
                                        <img src={step1} width="356" height="185" alt="" />
                                    </div>
                                    <h4 className="color-green">Step 1</h4>
                                    <h5 className="text-uppercase bold-font mb-4 color-dark-green body-font">Instant Fat Burn</h5>
                                    <p>Tru Body ACV Keto Gummies works to release stored fat, by helping your body burn fat for energy instead of carbs. Advanced Ketones are behind this miracle product that helps you lose up to 5 lbs in the first week.</p>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="image-wrap mb-3">
                                        <img src={step2} width="356" height="185" alt="" />
                                    </div>
                                    <h4 className="text-uppercase color-green">Step 2</h4>
                                    <h5 className="text-uppercase bold-font mb-4 color-dark-green body-font">Accelerated Fat Burn</h5>
                                    <p>During the first month of use, Tru Body ACV Keto Gummies with BHB produces accelerated Fat Burn, which results in expected weight loss of up to 20 lbs. You will notice a drastic change in a very short period of time!</p>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="image-wrap mb-3">
                                        <img src={step3} width="356" height="185" alt="" />
                                    </div>
                                    <h4 className="text-uppercase color-green">Step 3</h4>
                                    <h5 className="text-uppercase bold-font mb-4 color-dark-green body-font">Transform Your Body</h5>
                                    <p>With your weight loss goals achieved, continue to take Tru Body ACV Keto Gummies for 3-5 months as to stabilize your appetite, as well as to maintain and transform your new, slim body.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    {(deviceType === 'mobile') ? 
                                        <div className="btn-wrap">
                                            <Link to="javascript:void(0)" onClick={handleClick} className="btn acv2Btn">Rush My Order</Link>
                                        </div>
                                    :
                                    <div className="btn-wrap">
                                        <Link to="javascript:void(0)" onClick={handleClickRef} className="btn acv2Btn">Rush My Order</Link>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Section 3 End  */}


                    {/* Section 4 Begin  */}
                    <section id="section-4" className="acv2Section4 section py-5">
                        <div className="container position-relative">
                            <div className="row align-items-center">
                                <div className="col-12 col-lg-6 mb-4 mb-lg-0">
                                    <h3 className="color-dark-green extrabold-font">The science of</h3>
                                    <h3 className="color-green extrabold-font">Ketosis<span className="h5 extrabold-font">(ketovex)</span></h3>
                                    <p>Ketosis is the state where your body is actually burning fat for energy instead of carbs. Ketosis is extremely hard to obtain on your own and takes weeks to accomplish. Tru Body ACV Keto Gummies actually helps your body achieve ketosis fast and helps you burn fat for energy instead of carbs!</p>
                                    <ul className="list-unstyled">
                                        <li><span><strong>No More Stored Fat:</strong> Currently with the massive load of carbohydrates in our foods, our bodies are conditioned to burn carbs for energy instead of fat. Because it is an easier energy source for the body to use up.</span></li>
                                        <li><span><strong>Fat - The New Energy:</strong> Ketosis is the state where your body is actually burning fat for energy instead of carbs. Ketosis is extremely hard to obtain on your own and takes weeks to accomplish. Tru Body ACV Keto Gummies actually helps your body achieve ketosis fast and helps you burn fat for energy instead of carbs!</span></li>
                                        <li><span><strong>More Health Benefits:</strong> Tru Body ACV Keto Gummies BHB works almost instantly to help support ketosis in the body by Burning FAT for energy. Fat IS the body's ideal source of energy and when you are in ketosis you experience energy and mental clarity like never before and of course very rapid weight loss.</span></li>
                                    </ul>
                                </div>
                                <div className="col-12 col-lg-6 col-xl-4 ps-xl-4">
                                    <div className="image-wrap">
                                        <img src={woman2} width="344" height="491" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Section 4 End  */}


                    {/* Section 5 Begin  */}
                    <section id="section-5" className="acv2Section5 section bg-image-3 py-5 pb-8">
                        <div className="container">
                            <div className="row mb-5 mb-xl-8">
                                <div className="col-12">
                                    <div className="text-center">
                                        <h3 className="color-dark-green extrabold-font">Real Tru Body ACV Keto Gummies</h3>
                                        <h3 className="color-green extrabold-font">success stroies</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Slider className='acv2testimonials slick-slider' {...settings}>
                                        <div className='sliderOuter acv2Testimonial'>
                                            <div className="testimonial-content bg-testimonial">
                                                <p className="mb-0">"I've been hearing about Tru Body ACV Keto Gummies for a while now. My sister took
                                                    it and had some amazing success. I thought, if I could just lose
                                                    a few pounds I would feel better about myself. I was shocked to
                                                    say the least when I lost 20lbs in 30 days. Now I tell everyone
                                                    :)"</p>
                                            </div>
                                            <div className="d-flex align-items-end justify-content-between author-info-wrap">
                                                <div className="author-image image-wrap">
                                                    <img src={testImg1} alt="" />
                                                </div>
                                                <div className="author-name">
                                                    <p className="mb-0">- Isabella N</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='sliderOuter acv2Testimonial'>
                                            <div className="testimonial-content bg-testimonial">
                                                <p className="mb-0">"Tru Body ACV Keto Gummies is by far the best product I've used for ketosis. It
                                                works and it works well. I am the leanest I have ever been in my
                                                life. I had my body fat tested before and after and I went from
                                                a whopping 26% body fat down to 16% in 4 months. I owe a big
                                                thanks to Tru Body ACV Keto Gummies for this amazing
                                                experience."</p>
                                            </div>
                                            <div className="d-flex align-items-end justify-content-between author-info-wrap">
                                                <div className="author-image image-wrap">
                                                    <img src={testImg2} alt="" />
                                                </div>
                                                <div className="author-name">
                                                    <p className="mb-0">- Darin K</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='sliderOuter acv2Testimonial'>
                                            <div className="testimonial-content bg-testimonial">
                                                <p className="mb-0">"After watching video after video of keto success stories, I felt hopeful that I could actually start losing weight without anything too extreme. When I found Tru Body ACV Keto Gummies online and thought I'd give it a shot. I started losing weight, I though maybe it was a fluke at first. After my first 10 pounds, I cried. If you need something to work like me, you won't be let down"</p>
                                            </div>
                                            <div className="d-flex align-items-end justify-content-between author-info-wrap">
                                                <div className="author-image image-wrap">
                                                    <img src={testImg3} alt="" />
                                                </div>
                                                <div className="author-name">
                                                    <p className="mb-0">- Ashley R</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='sliderOuter acv2Testimonial'>
                                            <div className="testimonial-content bg-testimonial">
                                                <p className="mb-0">"I've been hearing about Tru Body ACV Keto Gummies for a while now. My sister took
                                                    it and had some amazing success. I thought, if I could just lose
                                                    a few pounds I would feel better about myself. I was shocked to
                                                    say the least when I lost 20lbs in 30 days. Now I tell everyone
                                                    :)"</p>
                                            </div>
                                            <div className="d-flex align-items-end justify-content-between author-info-wrap">
                                                <div className="author-image image-wrap">
                                                    <img src={testImg3} alt="" />
                                                </div>
                                                <div className="author-name">
                                                    <p className="mb-0">- Isabella N</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Section 5 End  */}
                    {/* Section 6 Begin  */}
                    <section id="section-6" className="acv2Section6 section bg-image-4 py-5">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-12 col-xl-5 col-lg-6 mb-lg-0 mb-5">
                                    <Link to="/" className="logo-wrap image-wrap mb-4">
                                        <img src={acvTwoLogo} alt="" />
                                    </Link>
                                    <h3 className="color-dark-green extrabold-font">The most powerful</h3>
                                    <h2 className="color-green extrabold-font text-uppercase mb-4">Full spectrum <br />Keto BHB Salts</h2>
                                    <div className="d-flex gap-2 mb-4">
                                        <div className="col-2 image-wrap">
                                            <img src={badge1} alt="" />
                                        </div>
                                        <div className="col-2 image-wrap">
                                            <img src={badge2} alt="" />
                                        </div>
                                        <div className="col-2 image-wrap">
                                            <img src={badge3} alt="" />
                                        </div>
                                    </div>
                                    {(deviceType === 'mobile') ? 
                                        <div className="btn-wrap mb-3">
                                            <Link 
                                                to="javascript:void(0)" 
                                                onClick={handleClick} 
                                                className="btn acv2Btn">Rush My Order!!
                                            </Link>
                                        </div>:
                                        <div className="btn-wrap mb-3">
                                            <Link to="javascript:void(0)" onClick={handleClickRef} className="btn acv2Btn">Rush My Order!!</Link>
                                        </div>
                                    }
                                    <div className="image-wrap securityIcons2">
                                        <img src={securityIcons2} alt="" />
                                    </div>
                                </div>
                                <div className="col-12 col-xl-7 col-lg-6">
                                    <div className="d-flex section6ImgBlock">
                                        <div className="col-4">
                                            <div className="image-wrap mb-5 mw-90">
                                                <img src={badgeHighQuality} className='highQualityBadge' alt="" />
                                            </div>
                                            <div className="image-wrap productImg">
                                                <img src={product2} alt="" />
                                            </div>                                
                                        </div>
                                        <div className="col-7 position-relative">
                                            <div className="image-wrap"><img src={manWoman} alt="" /></div>
                                            <div className="image-wrap position-absolute bottom-0"><img src={nowAvailable} className=" " alt="" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Section 6 End  */}
                </main>
                {/* Footer  */}
                <footer className="acv2Footer py-5">
                    <div className="container">
                        <div className="row mw-1000px">
                            <div className="col-12">
                                <div className="text-wrap text-center">
                                    <p className="color-dark-purple small">Tru Body ACV Keto Gummies is committed to maintaining the highest quality products and the utmost integrity in business practices. All products sold on this website are certified by Good Manufacturing Practices (GMP), which is the highest standard of testing in the supplement industry. Notice: The products and information found on this site are not intended to replace professional medical advice or treatment. These statements have not been evaluated by the Food and Drug Administration. These products are not intended to diagnose, treat, cure or prevent any disease. Individual results may vary.</p>
                                </div>
                                <div className="d-sm-flex d-block gap-4 justify-content-center text-center footer-links small mb-3">
                                    <Link to='#' onClick={(event) => {
                                        event.preventDefault();
                                        window.open('/term-and-condition', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                    }}>Terms And Conditions</Link> <span className="d-sm-block d-none">|</span>
                                    <Link to="#" onClick={(event) => {
                                        event.preventDefault();
                                        window.open('/privacy-policy', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                    }}>Privacy Policy</Link> <span className="d-sm-block d-none">|</span>
                                    <Link to="#" onClick={(event) => {
                                        event.preventDefault();
                                        window.open('/citations', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                    }}>Citations</Link> <span className="d-sm-block d-none">|</span>                     
                                    <Link to='/contact-us' className="ajax-popup-link"> Contact Us </Link>
                                </div>
                                <div className="acv2Copyright text-center color-dark-purple small">
                                    © {currentYear} Tru Body ACV Keto Gummies. All Right Reserved.
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                {/* Footer End  */}
                <div className="acv2BottomBar p-2 mobile_show">
                    <div className="form-block">
                        <div className="row">
                            <div className="col-4">
                                <img src={product2} alt="" className='productImg' />
                            </div>
                            <div className="col-8">
                                <h4 className="color-white">Get My <span className="color-red">Free Bottle!</span></h4>
                                <Link to="javascript:void(0)" onClick={handleClick} className="btn btn-full p-1 acv2Btn">Rush My Order</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="acv2-popover-exit" className='d-none'>
                    <div className="pop-uphead">
                        <div id="pop-box-exit" className="pop-box">
                            <div className="popupImage">
                                <Link to="/"><img src={popupImg} /></Link>
                            </div>
                            <div id="pop-terms-1">
                                <p><Link to="/">No Thanks.</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dScrollTop acv2ScrollTop d-md-none" onClick={handleClickRef}><img src={upArrow}></img></div>
            </div>
        </>
    )
}

export default Acvgummies2Template;