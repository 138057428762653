import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import referralIcon from '../../assests/frontend/assets/images/Platinum-membership-referral-graphic.png';
import goldMembership from '../../assests/frontend/assets/images/Gold-membership-supplement-graphic.png';
import betterHealthImage from '../../assests/frontend/assets/images/betterHealthImage.jpg';
import celebDietImage from '../../assests/frontend/assets/images/celebrityDietCookBook.jpg';
import youngerImage from '../../assests/frontend/assets/images/lookYoungerFeelYoungerBook.jpg';
import whcCoverImage from '../../assests/frontend/assets/images/10_wholesale_club_cover_final.png';
import sapphirePackage from '../../assests/frontend/assets/images/sapphire-pack.png';
import moneyBackImg from '../../assests/frontend/assets/images/icon-mb.png';
import { useLocation } from 'react-router-dom';
import platinumPackage from '../../assests/frontend/assets/images/platinum_package.png';
import footerGuarantee from '../../assests/frontend/assets/images/footer-guarantee-img.png';
import secureImg from '../../assests/frontend/assets/images/secure.jpg';

import Copyright from '../Copyright';

function OrderStep2() {
    const location = useLocation();
    let params = location.search;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section className="orderStep">
                <div className="container">
                    <div className="orderStepInner text-center">
                        <h1 className='orderStep-title'>Congratulations!</h1>
                        <div className="step-headerText">
                            <p>You've made a great decision for you and your family! <br />Get ready to live the healthy lifestyle you've been searching for! <br />
                                Your Product Will Be Reserved For <u>15 Minutes!</u>
                            </p>
                        </div>
                        <div className="order-step"><a href="#" id="redirectTo2">Complete Your Order (Step 1 of 2)</a></div>
                    </div>
                    <div calss="producttab">
                        <table name="tab" id="tab" className="step-table" width="100%" border="0" align="center" cellPadding={0} cellSpacing="0">
                            <tbody>
                                <tr>
                                    <th colSpan="2" className="pro-heading">Product</th>
                                    <th className='text-center'>Price</th>
                                </tr>
                                <tr>
                                    <td className="step-img"><img src={platinumPackage} id="plan_image" /></td>
                                    <td className="book-name">PLATINUM - Access To Member Site: Supplements + Skincare + Powders</td>
                                    <td className="book-price">$21.00</td>
                                </tr>
                                <tr>
                                    <td className="step-img"><img src={referralIcon} id="plan_image" /></td>
                                    <td className="book-name">1 REFERRAL GETS YOU 1 MONTH FREE<span>($21.00 Value)</span></td>
                                    <td className="book-price">$0.00</td>
                                </tr>
                                <tr>
                                    <td className="step-img"><img src={goldMembership} id="plan_image" /></td>
                                    <td className="book-name">FOR EVERY 5 BOTTLES PURCHASED GET ONE FREE <span>($15-$25 Value)</span></td>
                                    <td className="book-price">$0.00</td>
                                </tr>
                                <tr>
                                    <td className="step-img"><img src={betterHealthImage} id="plan_image" /></td>
                                    <td className="book-name">Better Health Overnight <span>($29.99 Value)</span></td>
                                    <td className="book-price">$0.00</td>
                                </tr>
                                <tr>
                                    <td className="step-img"><img src={celebDietImage} id="plan_image" /></td>
                                    <td className="book-name">Celebrity Diet Cook Book <span>($49.99 Value)</span></td>
                                    <td className="book-price">$0.00</td>
                                </tr>
                                <tr>
                                    <td className="step-img"><img src={youngerImage} id="plan_image" /></td>
                                    <td className="book-name">Look Younger Feel Younger <span>($29.99 Value)</span></td>
                                    <td className="book-price">$0.00</td>
                                </tr>
                                <tr>
                                    <td className="step-img"><img src={whcCoverImage} id="plan_image" /></td>
                                    <td className="book-name">Wholesale Health Club <span>($127.00 Value)</span></td>
                                    <td className="book-price">$0.00</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="table-bottom-text">
                        <p>Wholesale Health Club is a premium subscription service that provides supplemental products designed to show results or your money back! When you click the CHECKOUT button, you will receive a life changing product that you get to try out RISK FREE. Your 30-Days money back guarantee applies to any product you purchase in the Wholesale Health Club line! Click now to try Wholesale Health Club without risking ONE CENT!</p>
                        <h3>Your Price Today: <span className="price">$21.00</span></h3>
                    </div>
                    <div className="bottom-button text-center">
                        <Link to={`/membership-checkout${params}`} className="checkLink">
                            <button>CHECKOUT</button>
                            <img className="button-warranty" src={moneyBackImg} alt="Money Back Icon" />
                        </Link>
                    </div>
                </div>
            </section>

            <section className="step-footer">
                <div className="container">
                    <div className="row warranty-box">
                        <div className="col-md-5 col-xs-12 guarantee-text">100% NO RISK GUARANTEE!</div>
                        <div className="col-md-2 col-xs-12 guarantee-img"><img src={footerGuarantee} className="img-fluid" /></div>
                        <div className="col-md-5 col-xs-12 guarantee-rembr">Remember, you are never at risk! You're fully protected by our 30 days money back guarantee.</div>
                    </div>
                </div>
                <div className="footer-privacy-text">
                    For Your Privacy: <br />
                    All Orders Are Discreetly Billed
                </div>
                <div className="footer-secure"><img src={secureImg} className="img-fluid" /></div>
                <div className="footer-bottom-text">Your checkout is completely secure on a McAfee tested SSL order page.</div>
            </section>

        </>
    )
}

export default OrderStep2