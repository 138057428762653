import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2'
import { Link, useNavigate } from "react-router-dom"; // v6

import Product1 from '../../assests/admin/assets/img/payment_multi_btls_5.png';
import Product2 from '../../assests/admin/assets/img/payment_multi_btls_3.png';
import Product3 from '../../assests/admin/assets/img/payment_multi_btls_1.png';
import IconStisfaction from '../../assests/admin/assets/img/icon-satisfaction.png';
import IconDelivery from '../../assests/admin/assets/img/icon-delivery.png';
import PostalLogo from '../../assests/admin/assets/img/postal-logos.png';
import SecurityBadge1 from '../../assests/admin/assets/img/security-badge-1.png';
import SecurityBadge2 from '../../assests/admin/assets/img/security-badge-2.png';
import SecurityBadge3 from '../../assests/admin/assets/img/security-badge-3.png';
import SecurityBadge4 from '../../assests/admin/assets/img/security-badge-4.png';
import FormTop from '../../assests/admin/assets/img/payment_form_top_bg.png';
import PaymentCards from '../../assests/admin/assets/img/payment_multi_cards.png';
import PaymentButton from '../../assests/admin/assets/img/payment_button.png';
import paymentMultiSecure from '../../assests/admin/assets/img/payment_multi_secure.jpg';
import Guaranteed from '../../assests/admin/assets/img/guaranteed.png';
import Arrow2 from '../../assests/admin/assets/img/arrow2.png';
import privacyVerified from '../../assests/admin/assets/img/privacy-verified.jpg';
import CertifiedBy from '../../assests/admin/assets/img/certified-by.jpg';
import SecurityVerified from '../../assests/admin/assets/img/security-verified.jpg';
import BusinessVerified from '../../assests/admin/assets/img/business-verified.jpg';
import Visa from '../../assests/admin/assets/img/visa-mc-discover-new.png';
import PostalLogo2 from '../../assests/admin/assets/img/postal-logos_ZKYhlZD.png';
import OnlineLock from '../../assests/admin/assets/img/shop-online-lock.png';
import CheckPermission from '../frontend/auth/CheckPermission';
import CTE from "react-click-to-edit";
import {BASE_URL} from '../Config';
import Copyright from '../Copyright';



function TemplateEdit() {
    CheckPermission()
    let isDisabled = 'package-container';
    const navigate = useNavigate();
    const [products, setproducts] = useState([]);
    const [errors, setErros] = useState({});
    const [url, setURL] = useState();
    const [lander, setLander] = useState();
    const [show, setShow] = useState("0");

    const tmeChange = (e) => {
        setShow(e.target.value)
        console.log(show)
    }
    function getProductList() {
        axios.get(`api/listMappedProductForFunnel`).then(res => {

            setproducts(res.data.data);

        })
    }
    useEffect(() => { getProductList(); }, []);

    const [productImg1, setProductimg1] = useState();
    const [productImg2, setProductimg2] = useState();
    const [productImg3, setProductimg3] = useState();

    let display = "top-terms open"
    const [productName1, setproductName1] = useState('');
    const [productName2, setproductName2] = useState('');
    const [productName3, setproductName3] = useState('');

    const [productPrice1, setproductPrice1] = useState('');
    const [productPrice2, setproductPrice2] = useState('');
    const [productPrice3, setproductPrice3] = useState('');

    const [CheckoutFunnelInstance, setCheckoutFunnelInstance] = useState({
        funnel_name: "",
        template_id: "",
        lander_page: "",
        lander_page_url: "",
        first_product_label: "",
        first_product_shipping_id: "",
        first_product_id: "",
        first_product_name: "",
        first_product_retail: "",
        first_product_price: "",
        first_product_save: "",
        first_product_each: "",
        second_product_label: "",
        second_product_shipping_id: "",
        second_product_id: "",
        second_product_name: "",
        second_product_retail: "",
        second_product_price: "",
        second_product_save: "",
        second_product_each: "",
        third_product_label: "",
        third_product_shipping_id: "",
        third_product_id: "",
        third_product_name: "",
        third_product_retail: "",
        third_product_price: "",
        third_product_save: "",
        third_product_each: "",
        membership_add_on: "",
        funnel_url: "",
        lander_url: ""

    });
    const [updateCheckoutFunnelInstance, setupdateCheckoutFunnelInstance] = useState({
        funnel_name: "u",
        template_id: "u",
        lander_page: "u",
        lander_page_url: "u",
        first_product_label: "u",
        first_product_shipping_id: "u",
        first_product_id: "u",
        first_product_name: "u",
        first_product_retail: "u",
        first_product_price: "u",
        first_product_save: "u",
        first_product_each: "u",
        second_product_label: "u",
        second_product_shipping_id: "u",
        second_product_id: "u",
        second_product_name: "u",
        second_product_retail: "u",
        second_product_price: "u",
        second_product_save: "u",
        second_product_each: "u",
        third_product_label: "u",
        third_product_shipping_id: "u",
        third_product_id: "u",
        third_product_name: "u",
        third_product_retail: "u",
        third_product_price: "u",
        third_product_save: "u",
        third_product_each: "u",
        membership_add_on: "u",
        funnel_slug: "u",
        lander_url: "u"

    });
    const [checkoutFunnelData, setCheckoutFunnelData] = useState({})
    async function getCheckoutFunnelById() {
        const queryParams = new URLSearchParams(window.location.search);
        const checkoutFunnelId = queryParams.get('checkoutFunnelId');

        if (checkoutFunnelId != null) {
            await axios.get(`api/detailsCheckoutFunnel/${checkoutFunnelId}`).then(res => {

                setCheckoutFunnelData(res.data);
                setShow(res.data.template_id)
                // console.warn(checkoutFunnelData)

            })
        }
    }

    useEffect(() => { getCheckoutFunnelById(); }, []);
    const [landerPageUrl, setLanderPageUrl] = useState('')
    const [funnel_slug, setfunnel_slug] = useState('')

    function camelCase(str) {
        return str.split(' ').map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase()).join(' ');
    }

    const handelInput = (e) => {
        e.persist();
        var imageRURL = ''
        setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, [e.target.name]: e.target.value })
        setupdateCheckoutFunnelInstance({ ...updateCheckoutFunnelInstance, [e.target.name]: e.target.value })
        //console.warn(e.target.name,e.target.value,CheckoutFunnelInstance.lander_page)
        var urlPrefix = BASE_URL;
        if (e.target.name == 'lander_page') {
            // console.warn(e.target.value)
            var cUrl = ''
            if (e.target.value != 0 && e.target.value != 'Lander Page Not Selected') {
                var valueLander = e.target.value;
                var slugArrSplit = valueLander.split(' ')
                var slug = slugArrSplit.join('-');
                var cUrl = urlPrefix + slug;


            }
            if (e.target.value == 0) {
                setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['lander_page']: "Lander Page Not Selected" })
                setLanderPageUrl("Lander Page Not Selected")

            }
            setLanderPageUrl(cUrl)

        }
        if (e.target.name == 'funnel_url') {
            var valueLander = e.target.value.trim().toLowerCase();
            var slugArrSplit = valueLander.split(' ')
            var slug = slugArrSplit.join('-');
            setfunnel_slug(slug)
        }
        if (e.target.name == 'template_id') {
            if (e.target.value == 1) {
                isDisabled = 'package-container';

            }
            else {
                isDisabled = 'package-container is-disabled';
            }
        }

        if (e.target.name == 'first_product_id') {
            const index = e.target.selectedIndex;
            const el = e.target.childNodes[index]
            var pimg1 = imageRURL + el.getAttribute('data-img1');
            var name1 = camelCase(el.getAttribute('data-name1'));

            var price1 = el.getAttribute('data-price1');
            setproductPrice1(price1);
            if (e.target.value == 0) {
                pimg1 = el.getAttribute('data-img1');
            }

            setProductimg1(pimg1);
            setproductName1(name1);
        }
        if (e.target.name == 'membership_add_on') {
            var c = e.target.checked;
            if (c) {
                setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['membership_add_on']: "1" })
            }
            else {
                setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['membership_add_on']: "0" })
            }
        }
        if (e.target.name == 'second_product_id') {
            const index = e.target.selectedIndex;
            const el = e.target.childNodes[index]
            var pimg2 = imageRURL + el.getAttribute('data-img2');
            var name2 = camelCase(el.getAttribute('data-name2'));

            var price2 = el.getAttribute('data-price2');
            setproductPrice2(price2);

            if (e.target.value == 0) {
                pimg2 = el.getAttribute('data-img2');
            }
            setProductimg2(pimg2);
            setproductName2(name2);
        }

        if (e.target.name == 'third_product_id') {
            const index = e.target.selectedIndex;
            const el = e.target.childNodes[index]
            var pimg3 = imageRURL + el.getAttribute('data-img3');
            var name3 = camelCase(el.getAttribute('data-name3'));

            var price3 = el.getAttribute('data-price3');
            setproductPrice3(price3);

            if (e.target.value == 0) {
                pimg3 = el.getAttribute('data-img3');
            }
            setProductimg3(pimg3);
            setproductName3(name3);
        }

    }

    function fireSwal(msg) {
        Swal.fire({
            title: msg,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        })
    }
    const updateCheckoutFunnel = (e) => {

        const errors = {};


        if (!CheckoutFunnelInstance.funnel_name && updateCheckoutFunnelInstance.funnel_name != 'u') {
            errors.funnel_name = "Must provide a checkout funnel name";
            setErros(errors)
            fireSwal(errors.funnel_name)
            return false
        }

        if (!funnel_slug && updateCheckoutFunnelInstance.funnel_slug != 'u') {
            console.warn(funnel_slug, updateCheckoutFunnelInstance.funnel_slug)
            errors.funnel_slug = "Must provide a checkout funnel slug";
            setErros(errors)
            fireSwal(errors.funnel_slug)
            return false
        }

        // if (!CheckoutFunnelInstance.lander_page || CheckoutFunnelInstance.lander_page == 'Lander Page Not Selected') {
        //     console.log(CheckoutFunnelInstance.lander_page)
        //     errors.lander_page = "Must Select a Lander Page";
        //     setErros(errors)
        //     fireSwal(errors.lander_page)
        //     return false
        // }
        // if (!CheckoutFunnelInstance.lander_url) {
        //     errors.lander_url = "Must provide a Lander URL";
        //     setErros(errors)
        //     fireSwal(errors.lander_url)
        //     return false
        // }

        if (CheckoutFunnelInstance.first_product_id == 0 && updateCheckoutFunnelInstance.first_product_id != 'u') {
            errors.first_product_id = "Must Select a 1st product";
            setErros(errors)
            fireSwal(errors.first_product_id)
            return false
        }

        if (!CheckoutFunnelInstance.first_product_label && updateCheckoutFunnelInstance.first_product_label != 'u') {
            errors.first_product_label = "Must provide a 1st product label";
            setErros(errors)
            fireSwal(errors.first_product_label)
            return false
        }

        if (!CheckoutFunnelInstance.first_product_shipping_id && updateCheckoutFunnelInstance.first_product_shipping_id != 'u') {
            errors.first_product_shipping_id = "Must provide a 1st product shipping";
            setErros(errors)
            fireSwal(errors.first_product_shipping_id)
            return false
        }

        if (!CheckoutFunnelInstance.first_product_name && updateCheckoutFunnelInstance.first_product_name != 'u') {
            errors.first_product_name = "Must provide a 1st product name";
            setErros(errors)
            fireSwal(errors.first_product_name)
            return false
        }

        if (!CheckoutFunnelInstance.first_product_retail && updateCheckoutFunnelInstance.first_product_retail != 'u') {
            errors.first_product_retail = "Must provide a 1st product's retail price";
            setErros(errors)
            fireSwal(errors.first_product_retail && CheckoutFunnelInstance.funnel_name != 'u')
            return false
        }

        if (!CheckoutFunnelInstance.first_product_price && updateCheckoutFunnelInstance.first_product_price != 'u') {
            errors.first_product_price = "Must provide a 1st product's price";
            setErros(errors)
            fireSwal(errors.first_product_price)
            return false
        }

        if (!CheckoutFunnelInstance.first_product_save && updateCheckoutFunnelInstance.first_product_save != 'u') {
            errors.first_product_save = "Must provide a 1st product save";
            setErros(errors)
            fireSwal(errors.first_product_save)
            return false
        }

        if (!CheckoutFunnelInstance.first_product_each && updateCheckoutFunnelInstance.first_product_each != 'u') {
            errors.first_product_each = "Must provide a 1st each product price";
            setErros(errors)
            fireSwal(errors.first_product_each)
            return false
        }


        if (CheckoutFunnelInstance.second_product_id == 0 && updateCheckoutFunnelInstance.second_product_id != 'u') {
            errors.second_product_id = "Must Select a 2nd product";
            setErros(errors)
            fireSwal(errors.second_product_id)
            return false
        }

        if (!CheckoutFunnelInstance.second_product_label && updateCheckoutFunnelInstance.second_product_label != 'u') {
            errors.second_product_label = "Must provide a 2nd product label";
            setErros(errors)
            fireSwal(errors.second_product_label)
            return false
        }

        if (!CheckoutFunnelInstance.second_product_shipping_id && updateCheckoutFunnelInstance.second_product_shipping_id != 'u') {
            errors.second_product_shipping_id = "Must provide a 2nd product shipping";
            setErros(errors)
            fireSwal(errors.second_product_shipping_id)
            return false
        }

        if (!CheckoutFunnelInstance.second_product_name && updateCheckoutFunnelInstance.second_product_name != 'u') {
            errors.second_product_name = "Must provide a 2nd product name";
            setErros(errors)
            fireSwal(errors.second_product_name)
            return false
        }

        if (!CheckoutFunnelInstance.second_product_retail && updateCheckoutFunnelInstance.second_product_retail != 'u') {
            errors.second_product_retail = "Must provide a 2nd product's retail price";
            setErros(errors)
            fireSwal(errors.second_product_retail)
            return false
        }

        if (!CheckoutFunnelInstance.second_product_price && updateCheckoutFunnelInstance.second_product_price != 'u') {
            errors.second_product_price = "Must provide a 2nd product's price";
            setErros(errors)
            fireSwal(errors.second_product_price)
            return false
        }

        if (!CheckoutFunnelInstance.second_product_save && updateCheckoutFunnelInstance.second_product_save != 'u') {
            errors.second_product_save = "Must provide a 2nd product save";
            setErros(errors)
            fireSwal(errors.second_product_save)
            return false
        }

        if (!CheckoutFunnelInstance.second_product_each && updateCheckoutFunnelInstance.second_product_each != 'u') {
            errors.second_product_each = "Must provide a 2nd each product price";
            setErros(errors)
            fireSwal(errors.second_product_each)
            return false
        }



        if (CheckoutFunnelInstance.third_product_id == 0 && updateCheckoutFunnelInstance.third_product_id != 'u') {
            errors.third_product_id = "Must Select a 3rd product";
            setErros(errors)
            fireSwal(errors.third_product_id)
            return false
        }

        if (!CheckoutFunnelInstance.third_product_label && updateCheckoutFunnelInstance.third_product_label != 'u') {
            errors.third_product_label = "Must provide a 3rd product label";
            setErros(errors)
            fireSwal(errors.third_product_label)
            return false
        }

        if (!CheckoutFunnelInstance.third_product_shipping_id && updateCheckoutFunnelInstance.third_product_shipping_id != 'u') {
            errors.third_product_shipping_id = "Must provide a 3rd product shipping";
            setErros(errors)
            fireSwal(errors.third_product_shipping_id)
            return false
        }

        if (!CheckoutFunnelInstance.first_product_name && updateCheckoutFunnelInstance.first_product_name != 'u') {
            errors.third_product_name = "Must provide a 3rd product name";
            setErros(errors)
            fireSwal(errors.third_product_name)
            return false
        }

        if (!CheckoutFunnelInstance.third_product_retail && updateCheckoutFunnelInstance.funnel_name != 'u') {
            errors.third_product_retail = "Must provide a 3rd product's retail price";
            setErros(errors)
            fireSwal(errors.third_product_retail)
            return false
        }

        if (!CheckoutFunnelInstance.third_product_price && updateCheckoutFunnelInstance.third_product_price != 'u') {
            errors.third_product_price = "Must provide a 3rd product's price";
            setErros(errors)
            fireSwal(errors.third_product_price)
            return false
        }

        if (!CheckoutFunnelInstance.third_product_save && updateCheckoutFunnelInstance.third_product_save != 'u') {
            errors.third_product_save = "Must provide a 3rd product save";
            setErros(errors)
            fireSwal(errors.third_product_save)
            return false
        }

        if (!CheckoutFunnelInstance.third_product_each && updateCheckoutFunnelInstance.third_product_each != 'u') {
            errors.third_product_each = "Must provide a 3rd each product price";
            setErros(errors)
            fireSwal(errors.third_product_each)
            return false
        }



        const formdata = new FormData();
        formdata.append('funnel_name', CheckoutFunnelInstance.funnel_name ? CheckoutFunnelInstance.funnel_name : checkoutFunnelData.funnel_name);
        formdata.append('lander_page', CheckoutFunnelInstance.lander_page ? CheckoutFunnelInstance.lander_page : checkoutFunnelData.lander_page)
        formdata.append('lander_page_url', landerPageUrl ? landerPageUrl : checkoutFunnelData.lander_page_url);
        formdata.append('template_id', CheckoutFunnelInstance.template_id ? CheckoutFunnelInstance.template_id : checkoutFunnelData.template_id);
        formdata.append('funnel_slug', funnel_slug ? funnel_slug : checkoutFunnelData.funnel_slug);

        formdata.append('first_product_id', CheckoutFunnelInstance.first_product_id ? CheckoutFunnelInstance.first_product_id : checkoutFunnelData.first_product_id);
        formdata.append('first_product_label', CheckoutFunnelInstance.first_product_label ? CheckoutFunnelInstance.first_product_label : checkoutFunnelData.first_product_label);
        formdata.append('first_product_shipping_id', CheckoutFunnelInstance.first_product_shipping_id ? CheckoutFunnelInstance.first_product_shipping_id : checkoutFunnelData.first_product_shipping_id);
        formdata.append('first_product_name', productName1 ? productName1 : checkoutFunnelData.first_product_name);
        formdata.append('first_product_retail', CheckoutFunnelInstance.first_product_retail ? CheckoutFunnelInstance.first_product_retail : checkoutFunnelData.first_product_retail);
        formdata.append('first_product_price', productPrice1 ? productPrice1 : checkoutFunnelData.first_product_price);
        formdata.append('first_product_save', CheckoutFunnelInstance.first_product_save ? CheckoutFunnelInstance.first_product_save : checkoutFunnelData.first_product_save);
        formdata.append('first_product_each', CheckoutFunnelInstance.first_product_each ? CheckoutFunnelInstance.first_product_each : checkoutFunnelData.first_product_each);

        formdata.append('second_product_id', CheckoutFunnelInstance.second_product_id ? CheckoutFunnelInstance.second_product_id : checkoutFunnelData.second_product_id);
        formdata.append('second_product_label', CheckoutFunnelInstance.second_product_label ? CheckoutFunnelInstance.second_product_label : checkoutFunnelData.second_product_label);
        formdata.append('second_product_shipping_id', CheckoutFunnelInstance.second_product_shipping_id ? CheckoutFunnelInstance.second_product_shipping_id : checkoutFunnelData.second_product_shipping_id);
        formdata.append('second_product_name', productName2 ? productName2 : checkoutFunnelData.second_product_name);
        formdata.append('second_product_retail', CheckoutFunnelInstance.second_product_retail ? CheckoutFunnelInstance.second_product_retail : checkoutFunnelData.second_product_retail);
        formdata.append('second_product_price', productPrice2 ? productPrice2 : checkoutFunnelData.second_product_price);
        formdata.append('second_product_save', CheckoutFunnelInstance.second_product_save ? CheckoutFunnelInstance.second_product_save : checkoutFunnelData.second_product_save);
        formdata.append('second_product_each', CheckoutFunnelInstance.second_product_each ? CheckoutFunnelInstance.second_product_each : checkoutFunnelData.second_product_each);

        formdata.append('third_product_id', CheckoutFunnelInstance.third_product_id ? CheckoutFunnelInstance.third_product_id : checkoutFunnelData.third_product_id);
        formdata.append('third_product_label', CheckoutFunnelInstance.third_product_label ? CheckoutFunnelInstance.third_product_label : checkoutFunnelData.third_product_label);
        formdata.append('third_product_shipping_id', CheckoutFunnelInstance.third_product_shipping_id ? CheckoutFunnelInstance.third_product_shipping_id : checkoutFunnelData.third_product_shipping_id);
        formdata.append('third_product_name', productName3 ? productName3 : checkoutFunnelData.third_product_name);
        formdata.append('third_product_retail', CheckoutFunnelInstance.third_product_retail ? CheckoutFunnelInstance.third_product_retail : checkoutFunnelData.third_product_retail);
        formdata.append('third_product_price', productPrice3 ? productPrice3 : checkoutFunnelData.third_product_price);
        formdata.append('third_product_save', CheckoutFunnelInstance.third_product_save ? CheckoutFunnelInstance.third_product_save : checkoutFunnelData.third_product_save);
        formdata.append('third_product_each', CheckoutFunnelInstance.third_product_each ? CheckoutFunnelInstance.third_product_each : checkoutFunnelData.third_product_each);

        formdata.append('membership_add_on', CheckoutFunnelInstance.membership_add_on ? CheckoutFunnelInstance.membership_add_on : checkoutFunnelData.membership_add_on);
        const queryParamsa = new URLSearchParams(window.location.search);
        const checkoutFunnelIda = queryParamsa.get('checkoutFunnelId');
        axios.post(`api/updateCheckoutFunnel/${checkoutFunnelIda}`, formdata).then(res => {
            if (res.data.status === true) {
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                navigate('/admin/checkoutfunnelslist');
            }
            else {
                Swal.fire({
                    title: 'Warning',
                    html: res.data.message,
                    icon: 'warning'
                })
            }
        }).catch(err => {
            console.log(err.response)
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })

        });

    }



    return (
        <>
            {/* <CTE
                wrapperclassName="wrapper"
                textclassName="text"
                initialValue={"click"}
            /> */}
            <div className='cardBg-1 mb-4'>
                {Object.values(checkoutFunnelData).length !== 0 ? <div className='card m-4'>
                    <div className="card-header custom_card_hdr">
                        <span class="stepTitle">Checkout Funnel Name</span>
                    </div>
                    <div className='card-body'>
                        <div className="row align-items-center mb-4">
                            <div className="headerCus col-md-9">
                                <input type="" name="funnel_name" className="form-control" defaultValue={checkoutFunnelData.funnel_name} onChange={handelInput}>
                                </input>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="page-name col-md-3 mb-2 mb-md-0">Funnel URL/Slug: </div>
                            <div className="headerCus  col-md-9">
                                <div className='row align-items-center'>
                                    <div className='col-md-7 mb-2 mb-md-0' style={{ textAlign: 'left' }}>
                                       {BASE_URL} {funnel_slug ? funnel_slug : checkoutFunnelData.funnel_slug}
                                    </div>
                                    <div className='col-md-5'>
                                        <input type="" name="funnel_url" className="form-control" defaultValue={checkoutFunnelData.funnel_slug} onChange={handelInput}>

                                        </input>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div> : null}

                {Object.values(checkoutFunnelData).length !== 0 ? <div className='card m-4 d-none'>
                    <div className="card-header custom_card_hdr d-none">
                        <span class="stepTitle">Lander Page</span>
                    </div>
                    <div className='card-body'>
                        <div className="row align-items-center mb-4">
                            <div className="headerCus  col-md-9">
                                <select type="" name="lander_page" className="form-control" defaultValue={checkoutFunnelData.lander_page} onChange={handelInput}>
                                    <option value="0" >Select Lander Page</option>
                                    <option value="lander page-1">Lander Page 1</option>
                                    <option value="lander page-2">Lander Page 2</option>
                                </select>

                            </div>
                        </div>
                        <div className="row align-items-center">

                            {Object.values(checkoutFunnelData).length !== 0 ? <><div className="page-name col-md-3 mb-2 mb-md-0">Lander URL: </div>

                                <div className="headerCus  col-md-9">
                                    <div className='align-items-center l-2' style={{ textAlign: 'left' }}>
                                        {/* <div className='col-md-5'>
                                       BASE_URL 
                                    </div> */}

                                        {landerPageUrl ? landerPageUrl : checkoutFunnelData.lander_page_url}


                                    </div></div> </> : null}




                        </div>
                    </div>
                </div> : null}

                {Object.values(checkoutFunnelData).length !== 0 ? <div className='card m-4'>
                    <div className="card-header custom_card_hdr">
                        <span class="stepTitle">Select Template</span>
                    </div>
                    <div className='card-body'>
                        <div className="row align-items-center">
                            {/* <div className="page-name col-md-3 mb-2 mb-md-0">Select Template</div> */}
                            <div className="headerCus  col-md-9">

                                <select type="" name="template_id" className="form-control mb-4" defaultValue={checkoutFunnelData.template_id} onChange={tmeChange}>
                                    <option value="0">Chose Template</option>
                                    <option value="1" >Template 1</option>
                                    <option value="2" >Template 2</option>
                                    <option value="3" >Template 3</option>
                                    <option value="4" >Template 4</option>
                                    <option value="5" >Template 5</option>
                                </select>

                            </div>
                        </div>
                    </div>
                </div> : null}

            </div>
            {Object.values(checkoutFunnelData).length !== 0 ? <> <div className='checkoutMain'>     <div className='checkoutPageWrapper'>
                <div className='checkout-container container'>
                    <div className='innerLogo'>
                        <img src="" />
                    </div>
                    <div className='orderWrapper'>
                        {/* Left Section  */}
                        <div className='orderWrapper-left'>
                            <div className='newCheckout-wrapper'>

                                <div className={isDisabled}>

                                    {/* Package 1  selected_pack */}
                                    <select type="" name="first_product_id" className="form-control" defaultValue={checkoutFunnelData.first_product_id} onChange={handelInput}>
                                        <option value="0" data-img1={Product1} data-name1={"3 Month Neuro Tech IQ Pack"} data-price1={'99'}>Select First Product</option>

                                        {products.map(products => {
                                            return (
                                                <option value={products.id} key={products.id} data-img1={products.image} data-name1={products.name} data-price1={products.price}>
                                                    {products.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <div className='package1 plan-selection mt-3'>
                                        <div className='payProd'>
                                            <div className='payProd_header'>
                                                <p className='payProd_title'>
                                                    <CTE inputClass="wrapperText" textClass="textEdit" initialValue={checkoutFunnelData.first_product_label}
                                                        endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['first_product_label']: value }) }}
                                                    />
                                                </p>

                                                <span className='shipping_msg'><CTE textClass="textEdit" initialValue={checkoutFunnelData.first_product_shipping_id}
                                                    endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['first_product_shipping_id']: value }) }}
                                                /></span>
                                            </div>
                                            <div className='payProd_body'>
                                                <span className='radio'></span>
                                                <div className='product_figure'>
                                                    <img src={productImg1 ? productImg1 : BASE_URL+"whc-custom/storage/app/public/" + checkoutFunnelData[0].first_product_img} className="product_img img-fluid" />
                                                </div>
                                                <div className='product_cont'>
                                                    <p className='prod_name'>
                                                        {productName1 ? productName1 : checkoutFunnelData.first_product_name}
                                                    </p>
                                                    <p className='prod_offer_price'>
                                                        <span>Retail Price: <span className='text_red1'>$<CTE inputClass="wrapperTextdark" textClass="textEdit" initialValue={checkoutFunnelData.first_product_retail}
                                                            endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['first_product_retail']: value }) }}
                                                        /></span></span><br />
                                                        <span>Your Price: ${productPrice1 ? productPrice1 : checkoutFunnelData.first_product_price} – <strong>Save</strong>: $<CTE inputClass="wrapperTextdark" textClass="textEdit" initialValue={checkoutFunnelData.first_product_save}
                                                            endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['first_product_save']: value }) }}
                                                        /></span>
                                                    </p>
                                                    <p className='prod_actual_price'>
                                                        <span className='price'>$<CTE inputClass="wrapperTextdark" textClass="textEdit" initialValue={checkoutFunnelData.first_product_each}
                                                            endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['first_product_each']: value }) }}
                                                        /></span><em> each</em>
                                                    </p>
                                                    <p className='stock_status'>
                                                        In Stock. Current Sell-Out Risk: <span className="text_red1">HIGH</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Package 1 End  */}
                                    {/* Package 2  */}

                                    <select type="" name="second_product_id" className="form-control" defaultValue={checkoutFunnelData.second_product_id} onChange={handelInput}>
                                        <option value="0" data-img2={Product2} data-name2={"3 Month Neuro Tech IQ Pack"} data-price2={'99'}>Select Second Product</option>
                                        {products.map(products => {
                                            return (
                                                <option value={products.id} key={products.id} data-img2={products.image} data-name2={products.name} data-price2={products.price}>
                                                    {products.name}
                                                </option>
                                            );
                                        })}
                                    </select>

                                    <div className='package2 plan-selection mt-3'>
                                        <div className='payProd'>
                                            <div className='payProd_header'>
                                                <p className='payProd_title'>

                                                    <CTE inputClass="wrapperText" textClass="textEdit" initialValue={checkoutFunnelData.second_product_label}
                                                        endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['second_product_label']: value }) }}
                                                    /></p>
                                                <span className='shipping_msg'> <CTE inputClass="wrapperText" textClass="textEdit" initialValue={checkoutFunnelData.second_product_shipping_id}
                                                    endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['second_product_shipping_id']: value }) }}
                                                /></span>
                                            </div>
                                            <div className='payProd_body'>
                                                <span className='radio'></span>
                                                <div className='product_figure'>
                                                    <img src={productImg2 ? productImg2 : BASE_URL+"whc-custom/storage/app/public/" + checkoutFunnelData[0].second_product_img} className="product_img img-fluid" />
                                                </div>
                                                <div className='product_cont'>
                                                    <p className='prod_name'> {productName2 ? productName2 : checkoutFunnelData.second_product_name}</p>
                                                    <p className='prod_offer_price'>
                                                        <span>Retail Price: <span className='text_red1'>$<CTE inputClass="wrapperTextdark" textClass="textEdit" initialValue={checkoutFunnelData.second_product_retail}
                                                            endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['second_product_retail']: value }) }}
                                                        /></span></span><br />
                                                        <span>Your Price: ${productPrice2 ? productPrice2 : checkoutFunnelData.second_product_price} – <strong>Save</strong>: $<CTE inputClass="wrapperTextdark" textClass="textEdit" initialValue={checkoutFunnelData.second_product_save}
                                                            endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['second_product_save']: value }) }}
                                                        /></span>
                                                    </p>
                                                    <p className='prod_actual_price'>
                                                        <span className='price'>$<CTE inputClass="wrapperTextdark" textClass="textEdit" initialValue={checkoutFunnelData.second_product_each}
                                                            endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['second_product_each']: value }) }}
                                                        /></span><em> each</em>
                                                    </p>
                                                    <p className='stock_status'>
                                                        In Stock. Current Sell-Out Risk: <span className="text_red1">HIGH</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Package 2 End  */}
                                    {/* Package 3  */}

                                    <select type="" name="third_product_id" className="form-control" defaultValue={checkoutFunnelData.third_product_id} onChange={handelInput}>
                                        <option value="0" data-img3={Product3} data-name3={"3 Month Neuro Tech IQ Pack"} data-price3={'99'}>Select Third Product</option>
                                        {products.map(products => {
                                            return (
                                                <option value={products.id} key={products.id} data-img3={products.image} data-name3={products.name} data-price3={products.price}>
                                                    {products.name}
                                                </option>
                                            );
                                        })}
                                    </select>

                                    <div className='package3 plan-selection mt-3'>
                                        <div className='payProd'>
                                            <div className='payProd_header'>
                                                <p className='payProd_title'>

                                                    <CTE inputClass="wrapperText" textClass="textEdit" initialValue={checkoutFunnelData.third_product_label}
                                                        endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['third_product_label']: value }) }}
                                                    /></p>
                                                <span className='shipping_msg'> <CTE inputClass="wrapperText" textClass="textEdit" initialValue={checkoutFunnelData.third_product_shipping_id}
                                                    endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['third_product_shipping_id']: value }) }}
                                                /></span>
                                            </div>
                                            <div className='payProd_body'>
                                                <span className='radio'></span>
                                                <div className='product_figure'>
                                                    <img src={productImg3 ? productImg3 : BASE_URL+"whc-custom/storage/app/public/" + checkoutFunnelData[0].third_product_img} className="product_img img-fluid" />
                                                </div>
                                                <div className='product_cont'>
                                                    <p className='prod_name'> {productName3 ? productName3 : checkoutFunnelData.third_product_name}</p>
                                                    <p className='prod_offer_price'>
                                                        <span>Retail Price: <span className='text_red1'>$<CTE inputClass="wrapperTextdark" textClass="textEdit" initialValue={checkoutFunnelData.third_product_retail}
                                                            endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['third_product_retail']: value }) }}
                                                        /></span></span><br />
                                                        <span>Your Price: ${productPrice3 ? productPrice3 : checkoutFunnelData.third_product_price} – <strong>Save</strong>: $<CTE inputClass="wrapperTextdark" textClass="textEdit" initialValue={checkoutFunnelData.third_product_save}
                                                            endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['third_product_save']: value }) }}
                                                        /></span>
                                                    </p>
                                                    <p className='prod_actual_price'>
                                                        <span className='price'>$<CTE inputClass="wrapperTextdark" textClass="textEdit" initialValue={checkoutFunnelData.third_product_each}
                                                            endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['third_product_each']: value }) }}
                                                        /></span><em> each</em>
                                                    </p>
                                                    <p className='stock_status'>
                                                        In Stock. Current Sell-Out Risk: <span className="text_red1">HIGH</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Package 3 End  */}
                                </div>

                                {/* Order Delivery  */}
                                <div className='order-delivery'>
                                    <img src={IconDelivery} className="icon_delivery img-fluid" />
                                    <h3>USPS First-Class 2-3 Day Delivery</h3>
                                    <p>Parcels are shipped with insurance. Guaranteed Delivery.</p>
                                </div>
                                {/* Order Delivery end  */}


                                {/* Order counter Begin  */}
                                <div className="order-counter">
                                    <article className="clearfix">
                                        <div className="float_left">Shipping Type:</div>
                                        <div id="block2_shipping" className="float_right"><b>FREE</b> Priority Shipping</div>
                                        <div className="clear">&nbsp;</div>
                                    </article>
                                    <article className="clearfix">
                                        <div className="float_left">Shipping Price:</div>
                                        <div id="block2_price" className="float_right"><b>$0.00</b></div>
                                        <div className="clear">&nbsp;</div>
                                    </article>
                                    <article id="" className="clearfix ws_vip">
                                        <div className="float_left">Sapphire Membership:</div>
                                        <div id="block2_price" className="float_right"><b>$0.00</b></div>
                                        <div className="clear">&nbsp;</div>
                                    </article>
                                    <article className="clearfix">
                                        <div className="float_left">Your Total:</div>
                                        <div id="block2_total" className="float_right">$0.00</div>
                                        <div className="clear">&nbsp;</div>
                                    </article>
                                    <article className="last clearfix">
                                        <div id="block2_total2" className="float_left">Retail: $0.00</div>
                                        <div id="block2_save" className="float_right"><b>You Save: $0.00</b></div>
                                        <div className="clear">&nbsp;</div>
                                    </article>
                                </div>
                                {/* Order counter End  */}

                                {/* Gurantee Div  */}
                                <div className="order-satisfaction">
                                    <img src={IconStisfaction} alt="" className="img-fluid gurantee_img" />
                                    <h3>Satisfaction Guarantee!</h3>
                                    <p>We’re so confident that Neuro Tech IQ will work for you that we are offering a <b>30-Days Money Back
                                        Guarantee!</b> So, feel confident that you will get results, or your money back!</p>
                                </div>
                                {/* Gurantee Div End */}

                                {/* security 1 begin  */}
                                <div className="secu-1">
                                    <div className="row">
                                        <div className="col-md-9 mx-auto text-center">
                                            <div className="secImg-1">
                                                <img src={PostalLogo} alt="" />
                                            </div>
                                            <div className="arrive-date">Your order is due to arrive on <span className="orange">September 13 2022</span> .
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Security 1 end  */}

                                {/* Security 2 Begin  */}
                                <div className="secu-2">
                                    <div className="row">
                                        <div className="col-lg-9 mx-auto text-center">
                                            <div className="row secu-row">
                                                <div className="col-3">
                                                    <div className="secImg-2">
                                                        <img className="img-fluid" src={SecurityBadge1} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="secImg-2">
                                                        <img className="img-fluid" src={SecurityBadge2} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="secImg-2">
                                                        <img className="img-fluid" src={SecurityBadge3} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="secImg-2">
                                                        <img className="img-fluid" src={SecurityBadge4} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Security 2 End  */}
                            </div>
                        </div>
                        {/* Left section end  */}
                        {/* Right Section  */}
                        <div className='orderWrapper-right'>
                            <div className='formBox'>
                                <div className='formBox-hdr'>
                                    <img className="img-fluid" src={FormTop} alt="" />
                                </div>
                                <div className='formBox-body'>
                                    <div className='text-center payOption'>
                                        <p className="allCards mb-0"><strong>We Accept:</strong></p>
                                        <img src={PaymentCards} className="img-fluid" alt="" />
                                    </div>
                                    {/* Form Div  */}
                                    <div className='checkout-form-div'>
                                        <form className='row form_row'>
                                            <div className='col-md-12 form_col'>
                                                <input type="text" className='form-control' placeholder="Credit Card Number" disabled />
                                            </div>
                                            <div className='col-md-12 form_col'>
                                                <input type="password" className='form-control' placeholder="Security Code" disabled />
                                            </div>
                                            <div className='col-md-6 form_col'>
                                                <select className='form-control' disabled>
                                                    <option value="01">Month</option>
                                                    <option value="01">(01) Jan</option>
                                                    <option value="02">(02) Feb</option>
                                                    <option value="03">(03) Mar</option>
                                                    <option value="04">(04) Apr</option>
                                                    <option value="05">(05) May</option>
                                                    <option value="06">(06) Jun</option>
                                                    <option value="07">(07) Jul</option>
                                                    <option value="08">(08) Aug</option>
                                                    <option value="09">(09) Sep</option>
                                                    <option value="10">(10) Oct</option>
                                                    <option value="11">(11) Nov</option>
                                                    <option value="12">(12) Dec</option>
                                                </select>
                                            </div>
                                            <div className='col-md-6 form_col'>
                                                <select className='form-control' disabled>
                                                    <option value="2022">Year</option>
                                                    <option value="2022">2022</option>
                                                    <option value="2023">2023</option>
                                                    <option value="2024">2024</option>
                                                    <option value="2025">2025</option>
                                                    <option value="2026">2026</option>
                                                    <option value="2027">2027</option>
                                                    <option value="2028">2028</option>
                                                    <option value="2029">2029</option>
                                                    <option value="2030">2030</option>
                                                    <option value="2031">2031</option>
                                                    <option value="2032">2032</option>
                                                    <option value="2033">2033</option>
                                                </select>
                                            </div>
                                            <div className='col-md-12 form_col'>
                                                <button href='#' className='pay-btn text-center' disabled><img src={PaymentButton} alt="" className="img-fluid" /></button>
                                            </div>
                                        </form>
                                    </div>
                                    {/* form Div End  */}

                                    {/* Trans Begin  */}
                                    <div className='trans text-center'>
                                        <img src={paymentMultiSecure} alt="" className="img-fluid w-100" />
                                    </div>
                                    {/* Trans End  */}

                                    {/* Safe Checkout Gurantee Begin  */}
                                    <div className="w_item w_item_1 section-guranteed">
                                        <div className="w_inner">
                                            <div className="w_thumb">
                                                <figure>
                                                    <img className="img-fluid" alt="" src={Guaranteed} />
                                                </figure>
                                            </div>
                                            <div className="w_desc">
                                                <p>GUARANTEED <strong><span>SAFE</span></strong> CHECKOUT</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Safe Checkout Gurantee End  */}

                                    {/* Yellow Box Begin  */}
                                    <div className='col-12 row'>
                                        <div className='col-8'>
                                            <p>Sapphire Membership</p>
                                        </div>
                                        <div className='col-4'>
                                            <label class="switch">
                                                <input type="checkbox" defaultChecked={checkoutFunnelData.membership_add_on == 1 ? true : false} name="membership_add_on" onChange={handelInput}></input>
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className={CheckoutFunnelInstance.membership_add_on == 1 ? 'yellowbox mt-4' : checkoutFunnelData.membership_add_on == 1 && CheckoutFunnelInstance.membership_add_on == '' ? 'yellowbox mt-4' : 'd-none'}>
                                        <i>
                                            <img className="pulse" src={Arrow2} alt="" />
                                        </i>
                                        <div className="check_area">
                                            <input id="checkD" className="vip_shipping_desk" checked="checked" type="checkbox" />
                                            <label htmlFor="checkD">YES, I want FREE Shipping With Sapphire Membership!</label>
                                        </div>
                                    </div>
                                    {/* Yellow Box End  */}
                                    {/* Top Terms Begin  */}
                                    <div className={CheckoutFunnelInstance.membership_add_on == 1 ? 'top-terms open' : checkoutFunnelData.membership_add_on == 1 && CheckoutFunnelInstance.membership_add_on == '' ? 'top-terms open' : 'd-none'}>
                                        <h1 className="top-termsHdng">Sapphire Membership Terms</h1>
                                        <div className="top-trends-desc">
                                            <p>BIG SAVINGS are ahead! Sapphire Membership comes loaded with benefits. At Wholesalehealthclub we pride ourselves on offering customers the best deals on the products they love. Your Sapphire Membership takes that one step further.</p>
                                            <p>As a Sapphire Membership member we’ll send you a unique discount code each month to your inbox. Be sure to use this code at checkout to take advantage of the following benefits with your Sapphire Membership Today!:</p>
                                            <p>-FREE shipping on all orders!</p>
                                            <p>-Up to 80% off all of the great products you’ll find on wholesalehealthclub.com</p>
                                            <p>-No hassle, no questions asked replacement on any items.</p>
                                            <p>-Priority Processing – Have your orders placed in front of the line!</p>
                                            <p>-Get it all FREE for first 30 days!</p>
                                            <p>Some Notes About Your Membership:</p>
                                            <p>Remember – while your first month of Wholesalehealthclub Sapphire Membership is free of charge, you will be billed $12.77/ month to retain membership after your first month. If you wish to cancel your Sapphire Membership at any time, simply contact <br />our support using the below information: Support Email: <a to="mailto:support@wholesalehealthclub.com"> support@wholesalehealthclub.com </a></p>
                                        </div>
                                    </div>
                                    {/* Top Terms End  */}
                                </div>
                            </div>
                        </div>
                        {/* Right section end  */}
                    </div>
                </div>
            </div>


                {/* Security List Begin  */}
                <div className="securityIcon-sec">
                    <div className="checkout-container container securityIcon-container">
                        <ul className="order-icons-list">
                            <li>
                                <img src={privacyVerified} alt="privacy verified" width="86" />
                            </li>
                            <li>
                                <img src={CertifiedBy} alt="certified" width="86" />
                            </li>
                            <li>
                                <img src={SecurityVerified} alt="security verified" width="86" />
                            </li>
                            <li>
                                <img src={BusinessVerified} alt="business verified" width="85" />
                            </li>
                            <li>
                                <img src={PaymentCards} alt="Visa, Mastercard, Discover accepted" width="160" height="36" />
                            </li>
                            <li>
                                <img src={PostalLogo2} alt="united states" width="299" />
                            </li>
                            <li>
                                <img src={OnlineLock} alt="shop online" width="187" />
                            </li>
                        </ul>
                    </div>
                </div>
                {/* Security List Begin  */}
            </div>

                {/* Footer  */}
                <Copyright/>
                <div className='card-body'>
                    <div className="text-center text-md-end">
                        <button className='btn btn-success w-25' onClick={updateCheckoutFunnel}>Update</button>
                    </div>
                </div>
                {/* footer End  */} </> : <><div className='checkoutPageWrapper mt-7 pt-3 pb-5'>
                    <div className='checkout-container container'> <div className='not-selected-msg'> Not Template Selected </div> </div></div></>}

        </>

    )
}

export default TemplateEdit