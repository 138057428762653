import  { useRef } from  'react';
import { Accordion } from 'react-bootstrap';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from 'react-helmet';

import '../../assests/Lander/assets/css/skincare6p.css';
// Images 
import skincareModel1 from '../../assests/Lander/assets/images/skincare6p/skincareModel-1.png';
import beforeAfterImg1 from '../../assests/Lander/assets/images/skincare6p/before-after-img1.jpg';
import beforeAfterImg2 from '../../assests/Lander/assets/images/skincare6p/before-after-img2.jpg';
import skincareModel2 from '../../assests/Lander/assets/images/skincare6p/skincareModelImg-2.jpg';
import neckImage from '../../assests/Lander/assets/images/skincare6p/neckImage.jpg';
import sadWoman from '../../assests/Lander/assets/images/skincare6p/sadWoman.jpg';
import neckAt62 from '../../assests/Lander/assets/images/skincare6p/neck-at-62.png';
import neckline from '../../assests/Lander/assets/images/skincare6p/neckline-img.png';
import skincareImg9 from '../../assests/Lander/assets/images/skincare6p/skincare-img-9.jpg';
import skincareImg10 from '../../assests/Lander/assets/images/skincare6p/skincare-img-10.jpg';
import skincareImg11 from '../../assests/Lander/assets/images/skincare6p/skincare-img-11.jpg';
import skincareImg12 from '../../assests/Lander/assets/images/skincare6p/skincare-img-12.jpg';
import skincareImg13 from '../../assests/Lander/assets/images/skincare6p/skincare-img-13.jpg';
import skincareImg14 from '../../assests/Lander/assets/images/skincare6p/skincare-img-14.jpg';
import skincareImg15 from '../../assests/Lander/assets/images/skincare6p/skincare-img-15.png';
import skincareImg16 from '../../assests/Lander/assets/images/skincare6p/skincare-img-16.jpg';
import skincareImg17 from '../../assests/Lander/assets/images/skincare6p/skincare-img-17.png';
import skincareImg18 from '../../assests/Lander/assets/images/skincare6p/skincare-img-18.png';
import skincareImg19 from '../../assests/Lander/assets/images/skincare6p/skincare-img-19.png';
import skincareImg20 from '../../assests/Lander/assets/images/skincare6p/skincare-img-20.png';
import skincareImg21 from '../../assests/Lander/assets/images/skincare6p/skincare-img-21.png';
import skincareImg22 from '../../assests/Lander/assets/images/skincare6p/skincare-img-22.png';
import skincareImg23 from '../../assests/Lander/assets/images/skincare6p/skincare-img-23.jpg';
import skincareImg24 from '../../assests/Lander/assets/images/skincare6p/skincare-img-24.png';
// import skincareImg25 from '../../assests/Lander/assets/images/skincare6p/skincare-img-25.png';
import skincareImg26 from '../../assests/Lander/assets/images/skincare6p/skincare-img-26.jpg';
import skincareImg27 from '../../assests/Lander/assets/images/skincare6p/skincare-img-27.jpg';
import skincareImg28 from '../../assests/Lander/assets/images/skincare6p/skincare-img-28.jpg';
import skincareImg29 from '../../assests/Lander/assets/images/skincare6p/skincare-img-29.jpg';
import skincareImg30 from '../../assests/Lander/assets/images/skincare6p/skincare-img-30.jpg';
import skincareImg31 from '../../assests/Lander/assets/images/skincare6p/skincare-img-31.png';
import skincareImg32 from '../../assests/Lander/assets/images/skincare6p/skincare-img-32.jpg';
import skincareImg33 from '../../assests/Lander/assets/images/skincare6p/skincare-img-33.jpg';
import skincareImg34 from '../../assests/Lander/assets/images/skincare6p/skincare-img-34.png';
import skincareImg35 from '../../assests/Lander/assets/images/skincare6p/skincare-img-35.jpg';
import skincareImg36 from '../../assests/Lander/assets/images/skincare6p/skincare-img-36.png';
import skincareImg37 from '../../assests/Lander/assets/images/skincare6p/skincare-img-37.png';
import skincareImg38 from '../../assests/Lander/assets/images/skincare6p/skincare-img-38.jpg';
import skincareImg39 from '../../assests/Lander/assets/images/skincare6p/skincare-img-39.png';
import skincareImg40 from '../../assests/Lander/assets/images/skincare6p/skincare-img-40.jpg';
import skincareImg41 from '../../assests/Lander/assets/images/skincare6p/skincare-img-41.png';
import skincareImg42 from '../../assests/Lander/assets/images/skincare6p/skincare-img-42.png';
import skincareImg43 from '../../assests/Lander/assets/images/skincare6p/skincare-img-43.png';
import skincareImg44 from '../../assests/Lander/assets/images/skincare6p/skincare-img-44.jpg';
import skincareImg45 from '../../assests/Lander/assets/images/skincare6p/skincare-img-45.jpg';
import skincareImg46 from '../../assests/Lander/assets/images/skincare6p/skincare-img-46.png';
import skincareImg47 from '../../assests/Lander/assets/images/skincare6p/skincare-img-47.png';
import skincareImg48 from '../../assests/Lander/assets/images/skincare6p/skincare-img-48.png';
import skincareImg49 from '../../assests/Lander/assets/images/skincare6p/skincare-img-49.jpg';
import skincareImg50 from '../../assests/Lander/assets/images/skincare6p/skincare-img-50.jpg';
import skincareImg51 from '../../assests/Lander/assets/images/skincare6p/skincare-img-51.jpg';
import skincareImg52 from '../../assests/Lander/assets/images/skincare6p/skincare-img-52.jpg';
import skincareImg53 from '../../assests/Lander/assets/images/skincare6p/skincare-img-53.jpg';
import skincareImg54 from '../../assests/Lander/assets/images/skincare6p/skincare-img-54.png';
import skincareImg55 from '../../assests/Lander/assets/images/skincare6p/skincare-img-55.png';
import skincareImg56 from '../../assests/Lander/assets/images/skincare6p/skincare-img-56.png';
import skincareImg57 from '../../assests/Lander/assets/images/skincare6p/skincare-img-57.png';
import skincareImg58 from '../../assests/Lander/assets/images/skincare6p/skincare-img-58.png';
import skincareImg59 from '../../assests/Lander/assets/images/skincare6p/skincare-img-59.jpg';
import skincareImg60 from '../../assests/Lander/assets/images/skincare6p/skincare-img-60.png';
import skincareImg61 from '../../assests/Lander/assets/images/skincare6p/skincare-img-61.png';
import skincareImg62 from '../../assests/Lander/assets/images/skincare6p/skincare-img-62.jpg';
import skincareImg63 from '../../assests/Lander/assets/images/skincare6p/skincare-img-63.jpg';
import skincareImg64 from '../../assests/Lander/assets/images/skincare6p/skincare-img-64.jpg';
import skincareImg65 from '../../assests/Lander/assets/images/skincare6p/skincare-img-65.jpg';
import skincare_mob_img2 from '../../assests/Lander/assets/images/skincare6p/skincare_mob_img2.jpg';
import skincareImg66 from '../../assests/Lander/assets/images/skincare6p/skincare-img-66.png';
import skincareImg67 from '../../assests/Lander/assets/images/skincare6p/skincare-img-67.png';
import skincareImg68 from '../../assests/Lander/assets/images/skincare6p/skincare-img-68.jpg';
import skincareImg69 from '../../assests/Lander/assets/images/skincare6p/skincare-img-69.jpg';
import skincareImg70 from '../../assests/Lander/assets/images/skincare6p/skincare-img-70.png';
import skincareImg71 from '../../assests/Lander/assets/images/skincare6p/skincare-img-71.jpg';
import skincareImg72 from '../../assests/Lander/assets/images/skincare6p/skincare-img-72.jpg';
import skincareImg73 from '../../assests/Lander/assets/images/skincare6p/skincare-img-73.jpg';
import skincareImg74 from '../../assests/Lander/assets/images/skincare6p/skincare-img-74.png';
import skincareImg75 from '../../assests/Lander/assets/images/skincare6p/skincare-img-75.jpg';
import skincareProduct1 from '../../assests/Lander/assets/images/skincare6p/skincare-product-1.png';
import skincareProduct2 from '../../assests/Lander/assets/images/skincare6p/skincare-product-2.png';
import skincareProduct3 from '../../assests/Lander/assets/images/skincare6p/skincare-product-3.png';
import truck_img from '../../assests/Lander/assets/images/skincare6p/truck.png';
import circle_tick from '../../assests/Lander/assets/images/skincare6p/circle_tick.png';
import card_img from '../../assests/Lander/assets/images/skincare6p/card_img.png';
import line from '../../assests/Lander/assets/images/skincare6p/line.png'; 
import doc_mob_img1 from '../../assests/Lander/assets/images/skincare6p/doc_mob_img1.jpg';
import checkProduct1 from '../../assests/Lander/assets/images/skincare6p/productImg-check.png';
import submitBtn from '../../assests/Lander/assets/images/d-pill/order-btn.png';
import nortonSecure from '../../assests/Lander/assets/images/skincare6p/norton-secure-logo.png';
import upArrow from '../../assests/Lander/assets/images/up-arrow-white.png';

import Copyright from '../Copyright';
import skincareMoneyBackImg from '../../assests/Lander/assets/images/skincare6p/money-back-30day.png';
import React, { useEffect } from 'react';
import { reach } from 'yup';




function Skincarebp(){
    const navigate = useNavigate();
    const location = useLocation();
    let params = location.search;
    let URL = window.location.href;
    const ref = useRef(null);

    useEffect(() => {
        localStorage.removeItem("pack");
    },[]);

    
    function handleClick() {
        navigate('/skincarebpLead'+params);
    }

    const handleClickRef = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };


    return(
        <>
            <Helmet>
                <script>
                    {`
                        !function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '1440871839866690');
                        fbq('track', 'PageView');
                        fbq('track', 'ViewContent');
                    `}
                </script>
                <noscript>
                    {`
                        <img
                            height="1"
                            width="1"
                            style={{display: 'none'}}
                            src="https://www.facebook.com/tr?id=1440871839866690&ev=PageView&noscript=1"
                        />
                    `}
                </noscript>
            </Helmet>
            <div className='skincare6pPage'  ref={ref}>
                <div id="ipa47" className="fk-row">
                    <div id="ilo1n" className="fk-col col container_sec text-center">
                        <div  className="fk-inner-row">
                            <div id="ie639" className="fk-inner-column montserrat-font text-center">
                                <div id="imz1z">
                                    <div id="ih23o" className="fk-headline montserrat-font mx-auto">
                                        <span  id="iohcw">For </span>Women
                                        <span  id="imd44"> That Want The Firm Neckline Of Their 20's…</span>
                                    </div>
                                </div>
                                <div id="i18m9">
                                    <div id="i5yga" className="fk-headline trirong-font">
                                        <span >How I Revived The <i>Sagging Skin
                                            </i> On My Neck To A  <span ><i><u> New Sculpted Neckline&nbsp; </u></i>
                                            </span>
                                        </span>
                                        <div >
                                            <span >By <i >Reducing 20 Years Of Aging </i> In Just Days Using the <i >“Platysma Renew"       </i> Secret
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="imzaq" className="fk-inner-column text-center">
                                <LazyLoadImage id="fkt-image-d3c-4af-88f" width={430} src={skincareModel1} alt="img1" className="fk-image-defaults mx-auto" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="ii3t8" className="fk-row">
                    <div id="ipcd6" className="fk-col col container_sec text-center">
                        <div id="imih6">
                            <div id="iz2xn" className="fk-headline trirong-font f32">
                                <span >Discover How Thousands of Aging Women Are Now Firming Up
                                    Their Necks, Reducing Saggy Skin And Making Their Necklines More Beautiful Than Ever
                                </span>
                            </div>
                        </div>
                        <div id="iph3s">
                            <div id="imlgg" className="fk-headline trirong-font f40">
                                <i >...just like these women…</i>
                            </div>
                        </div>
                        <div  className="fk-inner-row">
                            <div id="iwqlx" className="fk-inner-column text-center">
                                <LazyLoadImage id="fkt-image-190-599-9f2" src={beforeAfterImg1} className="fk-image-defaults mx-auto" />
                            </div>
                            <div id="iv2ew" className="fk-inner-column text-center">
                                <LazyLoadImage id="fkt-image-a8e-2ab-b8b" src={beforeAfterImg2} className="fk-image-defaults mx-auto" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="ip7nu" className="fk-row">
                    <div id="i1vjh" className="fk-col col container_sec text-center">
                        <div id="i9uepr">
                            <div id="i0hgu" className="fk-inner-row">
                                <div id="ijhyi" className="fk-inner-column text-center">
                                    <LazyLoadImage id="fkt-image-570-a85-8b9" width={460} alt="img4" src={skincareModel2} className="fk-image-defaults mx-auto" />
                                </div>
                                <div id="iz58g" className="fk-inner-column text-center">
                                    <div id="i2qqh">
                                        <div id="itu9a" className="fk-headline trirong-font">My name's Heather Adams…</div>
                                    </div>
                                    <div id="ityxf" className="albert-font para">
                                        <div>And at 62, I thought that my “Turkey Neck” was something I just had to live with.
                                        </div>
                                        <div id="i5jaa">Everyone told me that having a sagging neckline was just part of the aging process, and that there was nothing that I could do about it, and I should just accept it.</div>
                                        <div>So, for the last 15 years, I'd used every  wardrobe trick in the book to camouflage my neckline. </div>
                                        <div>See, for my face I could always… </div>
                                        <div id="icgel">…hide fine lines with primer and foundation… </div>
                                        <div>…hide the dark circles under my eyes with a concealer…</div>
                                        <div>…hide forehead lines with Botox…</div>
                                    </div>
                                </div>
                            </div>
                            <div id="i0d94" className="albert-font para">But the <b>only way </b> to <b>hide the sagging neck </b> under my chin was to wear turtlenecks or scarves.&nbsp; <br /></div>
                            <div id="iejar" className="albert-font para">And that’s exactly what I
                                did beginning in my late 40’s when my neckline first began sagging, up until I turned 62 –
                                <b  id="it6pp" className="yell-1strip">and that’s when I discovered this tightening, lifting and firming breakthrough that naturally</b> <b  className="yell-2strip"> restored my neck.</b></div>
                            <div  className="fk-inner-row">
                                <div id="i3ikd" className="fk-inner-column text-center">
                                    <div id="iciam" className="albert-font para">
                                        <div id="itctv">The reason I was able to stop was
                                            because I discovered the “
                                            <b>Platysma Renew
                                            </b>" method which allowed me to transform my neckline from years of sagging into a firm
                                            and sculpted neck…
                                        </div>
                                        <div>…and in the next few minutes I’m going to share
                                            with you this little known breakthrough that allowed me to firm and tighten my neck and
                                            chest, to reveal a beautifully contoured neckline.
                                        </div>
                                        <div>Because whether you're in the early stages of
                                            "turkey neck", or if you've been struggling with a saggy neckline for years…
                                        </div>
                                        <div id="i7dzy">…you’ll see for yourself how this
                                            instantly “beautifies and lifts” the area where most eyes are eventually drawn to -
                                            <b>the neck area.
                                            </b>
                                        </div>
                                    </div>
                                </div>
                                <div id="ic9rx" className="fk-inner-column text-center">
                                    <LazyLoadImage id="fkt-image-3b7-787-890" alt="img5" src={neckImage} className="fk-image-defaults mx-auto" />
                                </div>
                            </div>
                            <div id="itvdb" className="albert-font para">
                                <div>I used to blame my skin type, my genetics, and even my mother…</div>
                                <div>…but my jowls and crepey chest skin had nothing to do with any of those reasons.</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="imxkl" className="fk-row">
                    <div id="i9vos-2" className="fk-col col container_sec text-center">
                        <div id="ivgrmo">
                            <div id="i5k34b" className="fk-headline trirong-font f40">This Helped Me Regain the Firmness and Tightness in my Neck that I Thought I’d Never Have Again.</div>
                        </div>
                        <div id="iey6d" className="fk-inner-row">
                            <div id="iqjimg" className="fk-inner-column text-center">
                                <LazyLoadImage src={sadWoman}  alt="img6" id="fkt-image-c58-999-b60" className="fk-image-defaults mob-view mx-auto" />
                                <div id="i7tjw6" className="albert-font para">
                                    <div>…and this works regardless of how old you are, whether you're in your 30’s or 70’s, you can have a firmer and smoother neckline.
                                    </div>
                                    <div>And once you learn how easy it is to firm-up the sagging skin around your neck and décolletage, then you’ll never have to worry again about….
                                    </div>
                                    <div>…resorting to a creative wardrobe to conceal a drooping neckline…</div>
                                    <div>…or the fear of wearing low-cut or v-neck tops that shows a crinkled upper chest.… </div>
                                    <div>…or being caught in embarrassing situations because of a saggy neckline… like I used to be. </div>
                                    <div>And if I hadn’t experienced that awkward moment with
                                        my daughter and seen that humiliating picture… then I never would have discovered the
                                        “Platysma Renew” method...
                                    </div>
                                    <div>… and it was that “confidence-crushing” photo…
                                    </div>
                                </div>
                            </div>
                            <div id="ildauz" className="fk-inner-column">
                                <LazyLoadImage id="fkt-image-4d0-7a6-8af" alt="img6" src={sadWoman} className="fk-image-defaults desktop-view" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="i8cdec" className="fk-row">
                    <div id="idf67a" className="fk-col col container_sec text-center">
                        <div id="if80py">
                            <div id="i2x85m" className="fk-headline trirong-font f40">That ultimately led me to an
                                incredible discovery that removed the sagging skin around my neck… and could help YOU do the same in
                                as little as just <i>7 minutes</i> from now
                            </div>
                        </div>
                        <div id="i4dubh">
                            <div id="i1b03d" className="fk-headline trirong-font f36">
                                <i>“Why did you HIDE my NECKLINE?!”</i>
                            </div>
                        </div>
                        <div  className="fk-inner-row">
                            <div id="i3htvt" className="fk-inner-column text-center">
                                <LazyLoadImage src={neckline} alt="img7" id="fkt-image-fca-0ad-932" className="fk-image-defaults  mob-view mx-auto" />
                                <div id="i0egq8" className="albert-font para">
                                    <div>…that’s what I said under my breath to her as I tried to keep my composure.</div>
                                    <div>I was visiting my daughter Tina to catch up after her wedding to hear all about her honeymoon… </div>
                                    <div>…and then I saw “the picture”, on full display atop
                                        Tina and Troy's mantle, revealing my worst fears, because my secret, which I thought I hid
                                        well, was now out in the open for everyone to see..
                                    </div>
                                    <div>Tina had “modified” the picture that was now staring back at me.</div>
                                    <div>And just a few months prior, things had already begun to get awkward…</div>
                                    <div>Tina had been glowing with anticipation for her wedding day…</div>
                                    <div>…but I was dreading it.</div>
                                    <div>I was trapped in a feeling of despair that was inescapable.</div>
                                    <div>See…</div>
                                    <div>...it wasn't because of the groom, Troy was an amazing match for Tina...</div>
                                    <div>...it wasn't because of Troy’s parents, they were kind, generous and true "Southern Gems"...</div>
                                    <div>...and it wasn't because I was transitioning from being the central mother figure to merely a bystander...</div>
                                </div>
                            </div>
                            <div id="i6zvri" className="fk-inner-column">
                                <LazyLoadImage id="fkt-image-e87-ea5-bfd" alt="img7" src={neckline} className="fk-image-defaults  desktop-view" />
                            </div>
                        </div>
                        <div id="iq4zig" className="albert-font para">
                            <div>I dreaded that day because I knew what I’d be asked to do, and I was scared to death… </div>
                            <div>… of the family wedding photo shoot. </div>
                            <div>My stomach was in perpetual knots just knowing I’d be standing alongside the bridal party…</div>
                            <div>And there was no way out. </div>
                            <div>Honestly, I never thought I was any more vain than the next woman... </div>
                            <div id="i02o4s">…but, I always paid extra attention to my appearance because I know “
                                <i >how we look
                                </i>” matters - both to me and to others, and that’s why I felt extra sensitive about my… neckline.
                            </div>
                        </div>
                        <div  className="fk-inner-row">
                            <div id="im72db" className="fk-inner-column text-center">
                                <div id="imhqb5" className="albert-font para">
                                    <div id="iqikk0x">
                                        <span cllass="helvetica-font" id="isx6xo"><b>Over the years, my physical appearance had changed…</b></span>
                                    </div>
                                    <div id="iqikk0x-2">
                                        <span cllass="helvetica-font">…it was subtle at first,
                                            and it kind of sneaked up on me, so I hardly noticed when it began.
                                        </span>
                                    </div>
                                    <div id="iqikk0x-3">
                                        <span cllass="helvetica-font" id="iqjirw"><span>But as the years passed, changes began to be more noticeable… </span></span>
                                    </div>
                                    <div id="iqikk0x-3-2">
                                        <span cllass="helvetica-font" id="i7gmw7"><b
                                                >…the lines on my face had gotten deeper…
                                            </b></span>
                                    </div>
                                    <div id="iqikk0x-4">
                                        <span cllass="helvetica-font" id="i3iib6"><b
                                                >…the skin around my jawline began drooping…
                                            </b></span>
                                    </div>
                                    <div id="iqikk0x-4-2">
                                        <span cllass="helvetica-font" id="iy201k"><b
                                                >…and the skin around my neck began sagging.
                                            </b></span>
                                    </div>
                                    <div id="iqikk0x-5">
                                        <span cllass="helvetica-font">So when Tina and Troy
                                            announced their wedding, I was excited for them, but felt a wave of anxiety flow over me
                                            because of how I looked.
                                        </span>
                                    </div>
                                    <div id="iqikk0x-5-2">
                                        <span cllass="helvetica-font">Was I being vain? Yes, I
                                            won’t deny it…
                                        </span>
                                    </div>
                                    <div id="iqikk0x-6">
                                        <span cllass="helvetica-font">But let me explain.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div id="it4g3a" className="fk-inner-column">
                                <LazyLoadImage id="fkt-image-d63-8a2-896"  alt="img8" src={neckAt62} className="fk-image-defaults" />
                            </div>
                        </div>
                        <div id="i37l9t" className="albert-font para">
                            <div>Truthfully, I’d become very self-conscious about the jowls
                                and sagging neckline I'd developed.
                            </div>
                            <div>And my friends would always tell me that it wasn't so bad,
                                that it was "hardly noticeable" or that I was making too big of a deal about it.
                            </div>
                            <div id="inn98m">But I knew better.</div>
                            <div id="ifajuh">And while they were just being good friends,  <b>my jowls made me look years older than I actually was, which made me feel insecure and less confident.</b>
                            </div>
                        </div>
                        <div  className="fk-inner-row">
                            <div id="iintud" className="fk-inner-column text-center">
                                <LazyLoadImage src={skincareImg9} alt="img9" id="fkt-image-e59-191-8f1" className="fk-image-defaults mob-view mx-auto" />
                                <div id="i8qr8v" className="albert-font para">
                                    <div>In fact, I've struggled with signs of premature aging since my 30’s… </div>
                                    <div>…when I first saw the consequences of the sun-bathing I’d done… </div>
                                    <div>…and started noticing lines forming around my eyes…
                                    </div>
                                    <div>…my skin had not yet lost its elasticity, but it
                                        certainly needed more moisturizing…
                                    </div>
                                    <div>And as I reached my 40’s…
                                    </div>
                                    <div>…I saw those same lines around my eyes begin to
                                        deepen…
                                    </div>
                                    <div>…while noticing the skin on my face start to lose its
                                        firmness…
                                    </div>
                                </div>
                            </div>
                            <div id="iwb1ad" className="fk-inner-column text-center">
                                <LazyLoadImage id="fkt-image-a95-a99-9b9" alt="img9" src={skincareImg9} className="fk-image-defaults desktop-view mx-auto" />
                            </div>
                        </div>
                        <div id="i804to" className="albert-font para">
                            <div>…and I had my first encounter with “diet-face” after losing weight…</div>
                            <div>Then when the 50’s came…</div>
                        </div>
                        <div  className="fk-inner-row">
                            <div id="ivztff" className="fk-inner-column text-center">
                                <LazyLoadImage  src={skincareImg10} alt="img10" id="fkt-image-ef5-fb7-a2b" className="fk-image-defaults mob-view mx-auto" />
                                <div id="ijjim1" className="albert-font para">
                                    <div>…the skin on my face was really losing its elasticity and began drooping… </div>
                                    <div>…sagging skin settled into areas of my face that made me look tired and worn out… </div>
                                    <div>…and my neck’s skin had seemed to have surrendered to gravity… the sculpted neckline I once had appeared to be lost forever.
                                    </div>
                                    <div id="io053x"><b>That’s when I learned all about "Turkey-Neck"...or “Tech-Neck”
                                        </b> as some refer to what happens from looking down at our phones and devices throughout
                                        the day.
                                    </div>
                                    <div>See, it was my drooping skin, particularly below the chin, spanning the neck and upper chest, that became the source of my terrible anxiety.
                                    </div>
                                    <div id="imy55g"><b>Until…&nbsp; </b></div>
                                </div>
                            </div>
                            <div id="ihttam" className="fk-inner-column text-center">
                                <LazyLoadImage id="fkt-image-1c5-c8b-baf" alt="img10" src={skincareImg10} className="fk-image-defaults  desktop-view mx-auto" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="i532y7" className="fk-row">
                    <div id="ivfgfr" className="fk-col col container_sec text-center">
                        <div id="iclzqj" className="albert-font para">
                            <div id="iu7lcm"><span  id="i3mg0q"><b>…I discovered this breakthrough…</b></span></div>
                        </div>
                        <div id="ipf1ua" className="albert-font para">
                            <div id="ilwijf">…because once I found out how to instantly  achieve a firmer and more sculpted neckline, I felt rejuvenated, like a more youthful and radiant version of myself because…
                            </div>
                        </div>
                        <div id="i0jwqg" className="albert-font para">
                            <div id="isvujy">…I could now wear any type of blouses and v-necks that I wanted to, without worrying about any sagging neck skin…</div>
                        </div>
                        <div id="ixo14u" className="albert-font para">
                            <div id="inay81">…my skin hard firmed up and tightened giving my  jawline a more contoured look… </div>
                        </div>
                        <div id="ifqv5s" className="albert-font para">
                            <div id="ipv8st">…and my self-esteem returned because of my new  firmer neckline. </div>
                        </div>
                        <div id="igu8xa" className="albert-font para">
                            <div id="id4amg"><span  id="ihk6ny">But before I found this breakthrough…</span></div>
                            <div id="itrabf">…my face was well on its way to becoming  something I feared most…</div>
                        </div>
                    </div>
                </div>

                <div id="ieu82m" className="fk-row">
                    <div id="i9e8x4" className="fk-col col container_sec text-center">
                        <div id="idl7oy">
                            <div id="ib94vj" className="fk-headline trirong-font f40">I always knew that one day I’d look like my mother,
                                <br />&nbsp;I just never thought it would happen so soon…
                            </div>
                            <div id="i6jiix" className="albert-font para"><span id="i23jpg">…And just the thought of that made me feel guilty, because as a
                                    little girl, I remember being complimented, “you look just like your mother!”, and that made me
                                    feel so proud…</span></div>
                            <div  className="fk-inner-row">
                                <div id="ichvfj" className="fk-inner-column text-center">
                                    <div id="i9iqsk" className="para albert-font">
                                        <div id="iybq5s">
                                            <LazyLoadImage src={skincareImg11} alt="img11"  id="fkt-image-0b5-19d-a5b" className="fk-image-defaults mob-view mx-auto" />
                                            <span id="itf2b9">But then I grew up, got older, and saw the transition begin to take shape…</span>
                                        </div>
                                        <div id="i7zm3s">…I’d often find myself checking the
                                            firmness of the skin under my chin… “lifting” the skin with the back of my hand, trying
                                            to measure how much worse it had gotten.
                                        </div>
                                        <div id="ianhal"><b>And so I did my best to try and hide my sagging neckline…</b></div>
                                        <div id="i5fu4a">I mean, I tried every type of moisturizing and skin-firming technique and product I could find...</div>
                                        <div id="in3nk1">…and I kept running into dead ends.</div>
                                    </div>
                                </div>
                                <div id="i2vguz" className="fk-inner-column text-center">
                                    <LazyLoadImage id="fkt-image-dd9-ea9-ba9" alt="img11" src={skincareImg11} className="fk-image-defaults  desktop-view mx-auto" />
                                </div>
                            </div>
                            <div id="i7goae" className="para albert-font">
                                <div id="iqc45l"><span  id="iody2h">I’d
                                        even considered “minor” cosmetic procedures to tighten and firm-up the skin around the chin
                                        and neck…</span></div>
                                <div id="i4okub">…but that was too expensive and required
                                    multiple visits at over $1,000 per “procedure”!
                                </div>
                                <div id="isb9xh"><b>And
                                        before I found this…
                                    </b></div>
                                <div id="ibii7u"><b>…I
                                        used to think the “jowls” around my neck and chin were something I’d have forever…
                                    </b></div>
                                <div id="i2lumh">…and be destined to wear scarves to cover up,
                                    no matter the season…
                                </div>
                                <div id="it8dhh">…or wait for the inevitable glance at my neck
                                    (which was&nbsp;not meant to admire my necklace!)…
                                </div>
                                <div id="icim08">…or accept the role I wasn’t ready for –
                                    looking more and more like my mother.
                                </div>
                            </div>
                            <div  className="fk-inner-row">
                                <div id="ijbw45" className="fk-inner-column">
                                    <div id="iqxsfd" className="para albert-font">
                                        <div id="ijjt14">
                                            <LazyLoadImage src={skincareImg12} alt="img12" id="fkt-image-2ff-695-af5" className="fk-image-defaults mob-view mx-auto" />
                                            <span id="ie8h6t">And as much as I searched for a solution, NOTHING WORKED…</span>
                                        </div>
                                        <div id="i4et8k"><b 
                                                id="i79g5t"> Until I found <u>the breakthrough that THOUSANDS of WOMEN are now using
                                                </u> to fight back against sagging necks, jowls and the dreaded “turkey neck”…
                                            </b></div>
                                        <div id="izimyb">Which is why, if you’ve been
                                            struggling with sagging skin around your neck…
                                        </div>
                                        <div id="i0ydby">…like loss of elasticity and firmness
                                            around the neckline and “crinkly” thin skin on your upper chest… then this letter may be
                                            the most important thing you read today.
                                        </div>
                                    </div>
                                </div>
                                <div id="ij5vfk" className="fk-inner-column text-center">
                                    <LazyLoadImage id="fkt-image-265-6b6-a5d" alt="img12" src={skincareImg12} className="fk-image-defaults desktop-view" />
                                </div>
                            </div>
                            <div id="i3cy54" className="albert-font para">
                                <div id="intlkr"><span 
                                        id="icocmh">Because I’m about to share with you the surprising reason why so many women over
                                        40 start getting “turkey neck”… and why you don’t have to settle for saggy skin on the neck
                                        any longer…</span></div>
                                <div id="ieur3e">And in just a moment…
                                </div>
                                <div id="iwvgqz"><b 
                                        >…you’ll see how the “Platysma Renew" secret will restore your neck from
                                        sagging skin into a contoured neck, allowing you to have a beautiful neckline just like you
                                        had in your 20’s…
                                    </b></div>
                            </div>
                            <div id="ihe7nw">
                                <div id="ivsugg" className="albert-font para">
                                    <div id="idwo8g"><span id="iuzc4j">Like Julie A. who at 57 and after giving birth to three amazing children, says she</span></div>
                                </div>
                                <div id="i2x8ws" className="albert-font para">
                                    <div id="i4a779">"Often struggled with sagging skin around
                                        her chin", now she's told she looks years younger when she meets people. "They can't believe
                                        my real age!"
                                    </div>
                                </div>
                                <LazyLoadImage id="fkt-image-ebd-987-9c6" alt="img13" src={skincareImg13} className="fk-image-defaults mx-auto" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="ine8rz" className="fk-row">
                    <div id="i2x7fl" className="fk-col col container_sec text-center">
                        <div id="il8dd4">
                            <div id="icpaka" className="fk-headline trirong-font f40">The Shocking Wedding Photo
                                &amp; My Fight Against the
                                <br /> <i >'Saggy Neck Curse'</i> That Followed...
                            </div>
                        </div>
                        <div  className="fk-inner-row">
                            <div id="iiyduf" className="fk-inner-column text-center">
                                <div id="i5xili" className="albert-font para">
                                    <div id="iu55xg">
                                        <LazyLoadImage src={skincareImg14} alt="img14" id="fkt-image-b20-58a-abf" className="fk-image-defaults  mob-view mx-auto" />
                                        <span id="i3s71p">But I was painfully reminded of my neckline…</span>
                                    </div>
                                    <div id="ik23yn">Because Tina had removed all evidence of
                                        my sagging neck from the wedding photos by covering my neck with a… scarf!
                                    </div>
                                    <div id="if5hed">My secret wasn’t just out… EVERYONE was
                                        aware of my sagging neck!
                                    </div>
                                    <div id="ih7zjl"><b>My neckline wasn’t going to get better on its own and time
                                            certainly wasn’t on my side…
                                        </b></div>
                                    <div id="ix47t2">And I feared that…
                                    </div>
                                    <div id="itzctj">…gravity would accelerate the neck
                                        sagging…
                                    </div>
                                    <div id="iin361">…the loose neck skin would get to a point
                                        where even scarves couldn’t hide it…
                                    </div>
                                    <div id="iwlzo5"><b>…and the flabby skin around my neck and jawline would just get
                                            worse.
                                        </b></div>
                                </div>
                            </div>
                            <div id="ici8qt" className="fk-inner-column text-center">
                                <LazyLoadImage id="fkt-image-64c-39b-828" alt="img14" src={skincareImg14} className="fk-image-defaults desktop-view" />
                            </div>
                        </div>
                        <div id="ivyxmz" className="albert-font para">
                            <div id="iazw0a"><span  id="iznwvi">I couldn’t undue the wedding photo, but I could choose to try and find a solution for my saggy neck.</span> </div>
                            <div id="iq6qiu"><b>So I decided to take matters into my own hands and find out how I could firm up my neck…</b></div>
                            <div id="izfhgy"><span ><i>…and the discovery I finally made allowed me to
                                        transform my neckline into a beautiful contoured neck without…</i></span></div>
                        </div>
                    </div>
                </div>

                <div id="iusvwc" className="fk-row">
                    <div id="ik139y" className="fk-col col container_sec text-center">
                        <div id="icorlw" className="fk-inner-row">
                            <div id="ii8xxr" className="fk-inner-column">
                                <LazyLoadImage src={skincareImg15} alt="img15"  id="fkt-image-c8f-6ba-8eb" className="fk-image-defaults  mob-view mx-auto" />
                                <div id="ifvg4j" className="albert-font para">
                                    <div id="iydkgh"><b>…without having to spend THOUSANDS of dollars on expensive cosmetics…</b></div>
                                    <div id="iga5p5">And while I loved my mother dearly, I was  terrified I’d suffer from the same “saggy neck gene” that she had… and be destined to have
                                        to live with my skin drooping for the rest of my life.
                                    </div>
                                    <div id="ink7u3">But what I found wasn’t linked to my mother at all…
                                    </div>
                                    <div id="i7mgb2"><b>…because the root cause of sagging neck skin had nothing to do with what I thought it did…
                                        </b></div>
                                    <div id="ican3h"><b>In fact… </b></div>
                                </div>
                            </div>
                            <div id="i6kz83" className="fk-inner-column text-center">
                                <LazyLoadImage id="fkt-image-a52-087-a37" alt="img15" src={skincareImg15} className="fk-image-defaults desktop-view mx-auto" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="iuwedq" className="fk-row">
                    <div id="i9qkd2" className="fk-col col container_sec text-center">
                        <div id="i8yspe">
                            <div id="iu9rbi">
                                <div id="iw7mn7" className="fk-headline trirong-font f40">Scientists From US Universities Recently Discovered
                                    That Sagging, Droopy And Crepey Neck Skin Isn’t Necessarily Caused By Genetics, Sun Damage or
                                    Age As We've Been Led To Believe…
                                </div>
                            </div>
                            <div id="i5lb6f" className="fk-row">
                                <div id="i0nmtg" className="fk-col col text-center">
                                    <div id="icb8r7" className="albert-font para">
                                        <div id="ilbb9j"><span 
                                                id="ic52yo">Rather it has to do with what scientists refer to as the “Derma-Dormant
                                                Clog” problem which affects how blood flow is distributed to the neck’s skin and
                                                muscles.</span></div>
                                        <div id="iaen2q">And in a few seconds you’ll discover
                                            how the simple “Platysma Renew” Method “unclogs” the neck skin so you can have a firmer
                                            and more youthful looking neck…
                                        </div>
                                        <div id="i1kgj1">…the same neckline that you had in your 20’s.</div>
                                    </div>
                                    <div  className="fk-inner-row">
                                        <div id="iigsbs" className="fk-inner-column text-center">
                                            <LazyLoadImage  alt="img17" src={skincareImg17} id="fkt-image-59f-5a9-a1a" className="fk-image-defaults mx-auto" />
                                            <div id="i9s0w9" className="albert-font para">Just like 57 year old Cindy…
                                            </div>
                                        </div>
                                        <div id="i3ssrj" className="fk-inner-column text-center">
                                            <LazyLoadImage alt="img18" src={skincareImg18} id="fkt-image-ba8-0aa-a13" className="fk-image-defaults mx-auto" />
                                            <div id="ipocyj" className="albert-font para">Like Tricia, <br />at age 61</div>
                                        </div>
                                        <div id="iss84s" className="fk-inner-column text-center">
                                            <LazyLoadImage alt="img19" src={skincareImg19} id="fkt-image-30a-195-946" className="fk-image-defaults mx-auto" />
                                            <div id="iq5lqb" className="albert-font para">…or like Mary who’s 64 years old.</div>
                                        </div>
                                    </div>
                                </div>
                                <div id="iouc1z" className="fk-col col">
                                    <LazyLoadImage id="fkt-image-c89-cbe-9ec" alt="img16" src={skincareImg16} className="fk-image-defaults mx-auto" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="i6gayp" className="fk-row">
                    <div id="i5uoii" className="fk-col col container_sec text-center">
                        <div id="i9lybj">
                            <div id="iq6as3" className="fk-headline trirong-font f40">...In fact, all you have to do is this
                                <i ><span id="ip6lbj3" className="cc-rte-styled">one simple
                                        thing</span></i><br />&nbsp;that will allow you to
                                firm-up your sagging neckline…
                            </div>
                            <div id="ix4rr93" className="fk-headline trirong-font f40"><span id="i7rdnch"
                                    className="cc-rte-styled">*DISCLAIMER*</span>
                                <div>The following statements are backed by decades of
                                    peer-reviewed research. Read on to learn more...
                                </div>
                            </div>
                        </div>
                        <div id="ir30ib" className="fk-inner-row">
                            <div id="i4gvtk" className="fk-inner-column text-center">
                                <div id="ivq7a6" className="albert-font para">
                                    <div id="icqxq9"><span  id="ihpnp7"><b id="iudurs"><i id="ircadk">It's the same technique that's now used by some of the biggest names
                                                </i><u  id="ijep6h">in Hollywood… </u></b></span></div>
                                    <div id="ihmlpo">
                                        And that’s where my journey took me that led me to this discovery…
                                    </div>
                                    <div id="izn596">
                                        Los Angeles…
                                        <b>where I experienced for myself how to restore my
                                            neck’s skin back to its original firmness.
                                        </b>
                                    </div>
                                    <div id="ipwoxo">
                                        My search took me to a cosmetic convention in Los Angeles, and that’s when I first met Lilly
                                        and her nephew Kai.
                                    </div>
                                    <div id="i3sf0w">
                                        We met during a lunch break and, being similar in age, Me and Lilly hit it off. Her skin
                                        looked amazing!&nbsp;<b>I would never have guessed her age (early 60’s) and
                                            she could easily pass for her thirties…
                                        </b>
                                    </div>
                                </div>
                            </div>
                            <div id="ifq0bj" className="fk-inner-column text-center">
                                <LazyLoadImage id="fkt-image-847-e99-aca" src={doc_mob_img1} alt="" className="fk-image-defaults mx-auto" />
                            </div>
                        </div>
                        <div  className="fk-inner-row">
                            <div id="inaadd" className="fk-inner-column">
                                <div id="ic282j" className="albert-font para">
                                    <div id="i9z9vp"><span 
                                            id="iuvpjm">Lilly was there as her nephew Kai’s guest…</span></div>
                                    <div id="ipsn02">Kai was a budding researcher and scientist at a major cosmetic company, but was looking for a change because he wasn’t being
                                        fulfilled at his current job.
                                    </div>
                                    <div id="i1apxs">As he told me,
                                        <i >"I thought I'd be at the forefront of anti-aging
                                            science, but I got stuck doing more admin work instead of my true passion: research."
                                        </i>
                                    </div>
                                    <div id="izshks">And since his heart was truly in research, he dedicated most of his free time studying anti-aging causes and aid.
                                    </div>
                                    <div id="iapco6">I shared my story with them because, well, I had nothing to lose… </div>
                                    <div id="icolan">…frankly, I felt like this must have been “fate” meeting them both… </div>
                                    <div id="itn90h">And to sit down with an anti-aging
                                        researcher to finally get answers about my sagging neck was something I had to take
                                        advantage of.
                                    </div>
                                    <div id="i51a0k"><b>But what Kai described next was both <i id="i90zkt">shocking and eye-opening… </i></b>
                                    </div>
                                    <div id="ihtgnn">Kai continued saying how…</div>
                                </div>
                            </div>
                            <div id="it6q1y" className="fk-inner-column text-center">
                                <LazyLoadImage id="fkt-image-204-ab3-a4f" alt="img20" src={skincareImg20} className="fk-image-defaults mx-auto" />
                            </div>
                        </div>
                        <div id="is9ca6" className="albert-font para">
                            <div id="imlb3e"><span  id="i4isf7">…the
                                    reason that women experienced sagging in their neck starting in their 30’s and 40’s and then
                                    accelerating in their 50’s and 60’s, was because of this unique underlying problem… <b>and some women are more susceptible than others to
                                        getting it…
                                    </b></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="if3x62" className="fk-row">
                    <div id="i5zuks" className="fk-col col container_sec text-center">
                        <div id="imoh31">
                            <div id="ic1px4">
                                <div id="irossv" className="fk-headline trirong-font f40">…If You Have Loose Skin
                                    Around Your Neckline
                                    <br />&nbsp;Chances Are You Might Be One Of These Women
                                </div>
                            </div>
                            <div id="iau57g" className="fk-row">
                                <div id="i4wjbi" className="fk-col col text-center">
                                    <div id="i1tjeh" className="albert-font para">
                                        <div id="iy8fqf"><span 
                                                id="ivveyg">See, science has known that causes of <b id="imyxtp"><i>facial
                                                    </i></b> wrinkles come from: collagen loss, elastin loss, hormonal changes, and
                                                prolonged sun exposure…</span></div>
                                        <div id="icopsh">But even though women tend to develop
                                            a sagging neck around the same time facial wrinkles begin, the causes of each are quite
                                            different.
                                        </div>
                                    </div>
                                    <div id="iau4vl" className="albert-font para"><b
                                            id="iucqgx">The reason the neck sags, droops, and
                                            has a “fatty” look has nothing to do with the reasons why you get facial wrinkles,
                                            because…
                                        </b></div>
                                </div>
                                <div id="impnld" className="fk-col col text-center">
                                    <div id="ij9vsf">
                                        <div id="ih2nbh" className="fk-headline trirong-font f30">Aging Changes in
                                            Skin caused by Collagen Loss, Elastin Loss, Prolonged Sun Exposure&nbsp;
                                        </div>
                                    </div>
                                    <LazyLoadImage id="fkt-image-c00-a85-8bc" alt="img21" src={skincareImg21} className="fk-image-defaults" />
                                </div>
                            </div>
                            <div id="ixtlln">
                                <div id="inc2y2">
                                    <span  id="i6d1vi" className="albert-font">Because the muscle-to-skin balance has changed, and it’s not only because of lower estrogen levels…</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="ix5ddf" className="fk-row">
                    <div id="ih8sz8" className="fk-col col container_sec text-center">
                        <div id="iqh7if">
                            <div id="ionj9t" className="fk-headline trirong-font f40">I thought it was because of
                                Menopause<br />until I found out about this…
                            </div>
                        </div>
                        <div  className="fk-inner-row">
                            <div id="ixi3q9" className="fk-inner-column text-center">
                                <div id="i7cswh" className="albert-font para">
                                    <div id="iputh1y">Then Kai shared a surprising revelation …</div>
                                    <div id="isq7ur-2">Your face has over 40
                                        “micro-muscles”, which when you think about it makes sense, because your face has to do
                                        everything from chewing food to showing different emotions…
                                    </div>
                                    <div id="i28xzoz">…and each of those
                                        muscles are attached to facial skin. So they act as “mini-motors” helping blood flow to the
                                        skin.
                                    </div>
                                    <div id="isq7ur-3-2">And this is important because <b>healthy skin thrives on good blood flow. </b>
                                    </div>
                                    <div id="iel1f6g">But the neck, on the
                                        other hand, has one primary muscle, called the Platysma, that sits behind the thick skin of
                                        the neck...
                                    </div>
                                    <div id="isq7ur-4">…and since this muscle
                                        isn’t as active, blood flow to the neck takes a back seat when compared to the face…
                                    </div>
                                    <div id="isq7ur-3-3"><b>…and that’s why lesser blood flow limits the natural rejuvenation
                                            of the skin on the neck…
                                        </b></div>
                                    <div id="isq7ur-5">But what’s more is…
                                    </div>
                                </div>
                            </div>
                            <div id="isha95" className="fk-inner-column">
                                <LazyLoadImage id="fkt-image-19e-1b5-a99" alt="img22" src={skincareImg22} className="fk-image-defaults" />
                            </div>
                        </div>
                        <div id="iqemmm" className="albert-font para">
                            <div id="ircdvh"><span  id="ipdyng">The neck’s
                                    skin and Platysma muscle can “grow apart” over time, causing the skin to separate from the
                                    muscle to create that “sagging” look in the neck – and this can be a noticeable problem for many
                                    women…</span></div>
                            <div id="i1mtup">Let me explain…
                            </div>
                            <div id="ippojh">Your skin has “connecting bands” called Septa,
                                and these bands attach themselves to the platysma muscle…
                            </div>
                            <div id="ipkj6j">So the Septa basically fastens the neck skin to the Platysma muscle. </div>
                            <div id="ihp6rk"><b>But if the Septa weakens, it loses connection to the muscle, which leads to sagging and drooping skin.</b></div>
                        </div>
                    </div>
                </div>

                <div id="iprdle" className="fk-row">
                    <div id="io2j69" className="fk-col col container_sec text-center">
                        <div id="iqcq5r" className="fk-inner-row">
                            <div id="ieeb0t" className="fk-inner-column text-center">
                                <div id="ik7ukk" className="albert-font para">
                                    <div id="irxng7">
                                        <LazyLoadImage src={skincareImg23} alt="img23"  id="fkt-image-aad-ab4-9bc" className="fk-image-defaults  mob-view " />
                                        <span id="icnzyv">On top of that…</span>
                                    </div>
                                    <div id="i4wuwk"><b>As women age their estrogen levels decrease, and that can lead to a
                                            “fatty neck” look...
                                        </b></div>
                                    <div id="itez8g">Because one of the things that estrogen
                                        does is distribute “healthy fat” proportionately, and that healthy fat is what gives a
                                        “plump and youthful” definition to the face…
                                    </div>
                                    <div id="izq238"><b>But with less estrogen, that same fat ends up just accumulating in
                                            areas of the neck, giving the appearance of having a “fatty neck”.
                                        </b></div>
                                </div>
                                <div id="ijbbzm" className="albert-font">
                                    <b  id="i06yq8">
                                        <i >And this is why you need a <u>UNIQUE METHOD </u> for your <br /> 
                                            <u>SAGGING NECK</u> and <u> JOWLS </u> than what you use on your face…
                                        </i>
                                    </b>
                                </div>
                            </div>
                            <div id="inwqht" className="fk-inner-column text-center">
                                <LazyLoadImage id="fkt-image-a5b-a83-a33" title="" src={skincareImg23} alt="img23" className="fk-image-defaults  desktop-view mx-auto" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="imim41" className="fk-row">
                    <div id="ig3ops" className="fk-col col container_sec text-center">
                        <div id="in1h1j">
                            <div id="i9pw1m" className="albert-font para">What Kai described made a lot of sense to me, so I asked him:</div>
                            <div id="iloeqn">
                                <div id="izq2vs" className="fk-headline trirong-font f40">
                                    <i >“How can women treat a saggy neckline?”</i>
                                </div>
                            </div>
                            <div id="ity3zt" className="fk-row">
                                <div id="ipvnzx" className="fk-col col text-center">
                                    <div id="i2ppwa" className="albert-font para">
                                        <div id="ic04bh">
                                            <span id="ii2lwp">Then Kai’s mood seemed to change…</span>
                                        </div>
                                        <div id="iprqsy"><i 
                                                id="ifglgc"><b>“Women can firm-up their skin, because science has developed a NEW and INNOVATIVE method”&nbsp; 
                                                </b></i>he said.
                                        </div>
                                        <div id="i69g4i">That’s amazing! I thought, <i >“So how can I try this for myself”</i></div>
                                        <div id="ibyokg">And that’s when things took a turn…</div>
                                        <div id="iw9ku3">Kai folded his arms, and leaning back in his chair, explained that…</div>
                                        <div id="iaou53">He’d identified specific ingredients and compounds that could firm, moisturize, lift, and tighten the skin…
                                        </div>
                                        <div id="iir13d">…and so he thought he had the perfect “next big product” idea for his cosmetic company…</div>
                                    </div>
                                </div>
                                <div id="if51u1" className="fk-col col text-center">
                                    <LazyLoadImage id="fkt-image-327-da6-b16" alt="" src={skincareImg24} className="fk-image-defaults  desktop-view mx-auto" />
                                    <LazyLoadImage title="" src={skincareImg24} alt="" id="fkt-image-426-787-a69" className="fk-image-defaults  mob-view mx-auto" />
                                </div>
                            </div>
                            <div id="i017t6" className="albert-font para">
                                <div id="i22r98"><span  id="iv4xys">But
                                        when he brought his idea to management, the response he got back was less than positive, in
                                        fact, his proposal was rejected.</span></div>
                            </div>
                            <div id="i7lk3l" className="albert-font para">
                                <div id="i67z9b"><span  id="ixavpk">“Off
                                        the record”, he was told, the new product would jeopardize the company’s bottom line,
                                        because <b>once women saw the incredible effects, they
                                            wouldn’t have the need to buy anything else
                                        </b> – the amazing results would overshadow the other products.</span></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="il9ml7" className="fk-row">
                    <div id="ihrgza" className="fk-col col container_sec text-center">
                        <div id="igwzar" className="albert-font para">
                            <div id="id2rn9">
                                <span  id="idu4cm">So my first thought was to just buy all the products that were on Kai’s ingredients list, but as he
                                    pointed out, that would mean spending hundreds, even a thousand dollars or more just for a month's supply…</span>
                            </div>
                            <div id="ii58fk">Instead, Kai told me he had a better idea.</div>
                            <div id="inyer7"><i >"How about lunch tomorrow? There's something special I'd like to share with you." </i></div>
                            <div id="i9duqn">I smiled and agreed, <i >“I’ll see you then!” </i>. </div>
                            <div id="i8vihj">The time and place was set for tomorrow, and that night back in my hotel room, I couldn’t help but let my mind wander, what if Kai was right?</div>
                        </div>
                    </div>
                </div>

                <div id="ifmf5j" className="fk-row">
                    <div id="ia12n3" className="fk-col col container_sec text-center">
                        <div  className="fk-inner-row">
                            <div id="i9pjde" className="fk-inner-column text-center">
                                <LazyLoadImage src={skincareImg26} href="" alt="img26" id="fkt-image-36e-484-b35" className="fk-image-defaults  mob-view mx-auto" />
                                <div id="ipx0y7" className="albert-font para">
                                    <div id="iydfzz">…What if Kai really discovered a way to firm-up a sagging skin?
                                    </div>
                                    <div id="il7axb-2">…What if I could have a tighter and more contoured neck?
                                    </div>
                                    <div id="ia7imn">…What if I could lift and rejuvenate the skin along my jawline?</div>
                                    <div id="ifokeb">The next day I got to the restaurant 20 minutes early, got seated and waited…</div>
                                    <div id="iqbpmv">…and I wore a scarf over my neck as I often did to hide the drooping skin on it.</div>
                                    <div id="i07lwl">The anticipation inside me grew until Kai and Lily walked in. </div>
                                    <div id="ipjwvk">And as the menus were given to us, Kai placed a small jar on the table…</div>
                                    <div id="im1bj2"><b id="ioyneu">“
                                        <i id="iq1l57">This” </i></b> he said, <b  data-highlightable="1" id="i13r2f"><i id="i7enrg"> “is what I call Platysma Renew”</i></b>.
                                    </div>
                                    <div id="ih4wdu">Both Kai and Lily sat back and smiled…
                                    </div>
                                </div>
                            </div>
                            <div id="igyib1" className="fk-inner-column text-center">
                                <LazyLoadImage id="fkt-image-ed7-f8f-8eb" width="285" alt="img26" src={skincareImg26} className="fk-image-defaults  desktop-view mx-auto" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="iy88zi" className="fk-row">
                    <div id="iw7gxw" className="fk-col col container_sec text-center">
                        <div id="it61yk" className="albert-font para">
                            <div id="i3nvql"><span  id="iwfn2y">…Because <b>this was the same custom formulation that Lily and
                                        other women had been using that gave them a rejuvenated look and beautifully contoured neck
                                        and jawlines
                                    </b>.</span></div>
                            
                            <div id="iw6whl">Kai explained how he got motivation for his idea
                                after reading a comprehensive study… They’d developed a “compound stack” of elements that
                                <i >“helped reverse aging… within a week, by rejuvenating old
                                    cells within muscles and tissues.”
                                </i>
                            </div>
                            
                            <div id="im7gz6">And it got him thinking, what if…
                            </div>
                            <div id="i4yg2h">What if he could apply that same concept to aging
                                skin, because although anti-aging science was advancing much faster than anyone expected…
                            </div>
                            <div id="iead1i">…it just wasn’t fast enough for women struggling
                                with sagging necklines, jowls, and “crepey” skin, because the product didn’t exist…
                                <i >yet</i>.
                            </div>
                        </div>
                    </div>
                </div>

                <div id="ivj3yo" className="fk-row">
                    <div id="i91u5s" className="fk-col col container_sec text-center">
                        <div  className="fk-inner-row">
                            <div id="i0yzqh" className="fk-inner-column">
                                <div id="i6y2wc" className="albert-font para">
                                    <div id="inqx32">
                                        <LazyLoadImage src={skincareImg27} alt="img27" id="fkt-image-876-cb0-910" className="fk-image-defaults mob-view mx-auto " />
                                        <span id="in2935">The idea of a “revolutionary cream”, helping thousands
                                            of women restore their neck’s skin back to a firmer and smoother neckline, was something
                                            he’d be energized to develop.</span>
                                    </div>
                                    <div id="i8kz0h">Because, he believed, <i>“if I could combine the correct elements into one
                                            formulation I might be able to rejuvenate the skin and have a solution that could firm
                                            up and even “sculpt” the jawline and neck. </i>
                                    </div>
                                    <div id="ic2bdq">So he began looking for compounds and ingredients that could help sagging and drooping skin. </div>
                                    <div id="ismrlh">And that led him to three primary compounds, the “Super Trio” as he referred to them. </div>
                                </div>
                            </div>
                            <div id="i605zm" className="fk-inner-column">
                                <LazyLoadImage id="fkt-image-fd7-9b7-a0f" width="590" alt="img27" src={skincareImg27} className="fk-image-defaults desktop-view mx-auto" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="idao5a" className="fk-row">
                    <div id="if0tq7" className="fk-col col container_sec text-center">
                        <div id="iore3h">
                            <div id="im3baj" className="fk-row">
                                <div id="i62zzi" className="fk-col col text-center">
                                    <div id="i2qhkq">
                                        <div id="i75k7x" className="fk-headline trirong-font f36">
                                            <div id="iegfq2">
                                                <span  id="ihqkm7">Matrixyl 3000™</span>
                                            </div>
                                        </div>
                                    </div>
                                    <LazyLoadImage src={skincareImg28} alt="img28" id="fkt-image-804-985-b3c" className="fk-image-defaults  mob-view mx-auto " />
                                    <div id="i2dfth" className="albert-font para">
                                        <div id="iafowi"><span id="iar238">First, was Matrixyl 3000™, a set of peptides called “palmitoyl tripeptide-1” and “palmitoyl tetrapeptide-7”, <b id="i8ptiy"><i id="imuw86"> which together, boosted collagen levels to help elasticity </i> <u id="ikuhvt"> and firmness </u>.
                                            </b></span>
                                        </div>
                                        <div id="ir15oc">These two peptides are known to <i id="idgq9l">“reverse aging, as well as damage caused by exposure to the sun”.
                                            </i> A study in the Journal of Cosmetic Dermatology reported a stunningly&nbsp;
                                            <b  data-highlightable="1" 
                                                id="ic2vty"><u  data-highlightable="1" 
                                                    id="icbf42"><i  
                                                        data-highlightable="1" id="iguc71" >75% of the women
                                                        reported “significant decrease in the
                                                    </i><i
                                                        id="i2cect">
                                                        number, depth, and volume of wrinkles”
                                                    </i></u></b> and also “Skin hydration, elasticity, and firmness increased”.
                                            
                                        </div>
                                    </div>
                                </div>
                                <div id="i6boyw" className="fk-col col text-center">
                                    <LazyLoadImage id="fkt-image-7ba-d8e-be9" alt="img28" src={skincareImg28} className="fk-image-defaults  desktop-view mx-auto" />
                                </div>
                            </div>
                            <div id="itqhmj" className="albert-font para">
                                <div id="ieas11"><span  id="iznhvf">Once
                                        he identified the ingredients to firm up the skin and to increase its elasticity, he needed
                                        to add a moisturizer…</span></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="ik7zwr" className="fk-row">
                    <div className="fk-col col container_sec text-center">
                        <div id="iclp5n">
                            <div id="i49ec2" className="fk-row">
                                <div id="ikcl1j" className="fk-col col">
                                    <div id="iljy6a">
                                        <div id="ivk01m" className="fk-headline trirong-font f36">
                                            <div id="il2sm2">Saccharide Isomerate</div>
                                        </div>
                                    </div>
                                    <LazyLoadImage src={skincareImg29} alt="img29" id="fkt-image-14e-580-9e3" className="fk-image-defaults  mob-view mx-auto " />
                                    <div id="i2jsqb" className="albert-font para">
                                        <div id="iq9day">Kai chose
                                            “Saccharide Isomerate”, for its moisturizing effect that’s so robust it’s known as “the
                                            water magnet” by Dermatologists, and when paired with the two peptides above, stimulated
                                            even more collagen creation.&nbsp;
                                            <b><u>A recent </u><i ><u id="i8vb5f">study even boasted that within just weeks
                                                        of use, “women's </u></i><i  id="i6cwlw"> facial skin had become firmer and less wrinkled”.
                                                    </i></b>
                                        </div>
                                        <div id="il68du-2">And then the final
                                            thing he needed was the compound to reduce wrinkles and repair the skin…
                                        </div>
                                        <div id="i06c3n">…and that’s when he added the next amazing ingredient…</div>
                                    </div>
                                </div>
                                <div id="iqf9yz" className="fk-col col">
                                    <LazyLoadImage alt="img29" src={skincareImg29} id="fkt-image-5c5-b85-ba8" className="fk-image-defaults  desktop-view mx-auto" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="i2uygn" className="fk-row">
                    <div className="fk-col col container_sec text-center">
                        <div id="iusqef">
                            <div id="i0s7el" className="fk-row">
                                <div id="iss7pc" className="fk-col col">
                                    <div id="i3b4wk">
                                        <div id="idwkzk" className="fk-headline trirong-font f36">
                                            <div id="iji23r">Retinyl Palmitate</div>
                                        </div>
                                    </div>
                                    <LazyLoadImage src={skincareImg30} alt="img30" id="fkt-image-92b-882-95f" className="fk-image-defaults  mob-view mx-auto " />
                                    <div id="i45lzh" className="albert-font para">
                                        <div 
                                            id="ietm49">And “Retinyl Palmitate <b id="izoz1d"><u >”helps to accelerate “skin cell</u> <u>turnover” to create more skin stretchiness, reduce wrinkles
                                                </u><u >and repair uneven skin texture. And  research shows it’s </u><i id="i7kuk5">“linked with a thicker skin and improvement in the
                                                </i><i id="ikutli">appearance of fullness in the skin”. </i></b>
                                        </div>
                                        <div id="ietm49-2">Excited with this first formulation he tested it… but it didn’t work. </div>
                                        <div id="ietm49-3">So he returned to his research and dug deeper... </div>
                                    </div>
                                </div>
                                <div id="iw6rl2" className="fk-col col text-center">
                                    <LazyLoadImage alt="img30" src={skincareImg30} id="fkt-image-c1f-28c-b33" className="fk-image-defaults  desktop-view mx-auto" />
                                </div>
                            </div>
                            <div id="iafh6o" className="albert-font para">
                                <div id="ivsbw7"><span  id="iwn9dk">And then it dawned on him and Kai realized what was missing…</span></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="i1c9aq" className="fk-row">
                    <div id="iww2st" className="fk-col col container_sec text-center">
                        <div id="i1m3zi" className="albert-font para">
                            <div id="ixewfe"><span  id="iq9tvc">The only thing that was missing was the <i >application and absorption
                                    </i> into the skin, because the absorption has to reach the depth of the thick layers of the
                                    neck’s skin to begin to rejuvenate.</span></div>
                            <div id="iv3fry"><i >“You can try it for yourself”, Kai said…”pinch your face skin and then pinch your neck skin, you’ll
                                    notice the difference in thickness”…
                                </i></div>
                            <div id="irkk35">If he could figure out how to get the absorption deeper into the thick neck skin, then the three compounds would work in harmony…</div>
                        </div>
                    </div>
                </div>

                <div id="ich4j1" className="fk-row">
                    <div id="ixfle7" className="fk-col col container_sec text-center">
                        <div  className="fk-inner-row">
                            <div id="itddkm" className="fk-inner-column text-center">
                                <div id="if1t3s" className="albert-font para">
                                    <div id="iaoqss">
                                        <LazyLoadImage src={skincareImg31} id="fkt-image-368-883-aa9" className="fk-image-defaults  mob-view mx-auto " />
                                        <span id="imych1">And that’s when Kai remembered reading about the ancient uses of a plant called Salvia Sclarea (the Clary plant) and its medicinal
                                            properties…</span>
                                    </div>
                                    <div id="i7pwgr">…and how the plant's oil was used for its anti-inflammatory and absorption-enhancing properties…
                                    </div>
                                    <div id="icfpek">…so when he finally combined Clary oil, <b> that’s when he saw the magic happen.</b></div>
                                </div>
                            </div>
                            <div id="iztcdh-2" className="fk-inner-column text-center">
                                <LazyLoadImage id="fkt-image-0b9-ab7-9df" src={skincareImg31} alt=""  className="fk-image-defaults  desktop-view mx-auto" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="iyelug" className="fk-row">
                    <div id="iimxjn" className="fk-col col container_sec text-center">
                        <div id="iwu2tm">
                            <div id="i0rigw" className="albert-font para">
                                <div id="ijaxn8"><span id="isuezz">Kimberly W. from Wisconsin, told me she hasn't had as many men checking her out like this since she was in her 20's...&nbsp;</span></div>
                            </div>
                            <div id="iqpbwo" className="albert-font para">
                                <div id="iaxo5t"><span id="ieiqfl"><i >"I notice more heads turn when I go to my favorite coffee shop and I laugh to myself
                                            thinking if they only knew!" </i></span>
                                </div>
                            </div>
                            <LazyLoadImage id="fkt-image-d13-892-a9a" alt="img32" src={skincareImg32} className="fk-image-defaults mx-auto" />
                        </div>
                    </div>
                </div>

                <div id="iutbl9" className="fk-row">
                    <div id="icm8d5" className="fk-col col container_sec text-center">
                        <div id="idb2xs">
                            <div id="ip2jw7" className="albert-font para">
                                <div id="ihlsn4"><span  id="izbxda"><b><i>“Try Platysma Renew for yourself,” </i> Kai said, handing the small jar of his formulation to me.
                                        </b></span></div>
                                <div id="iuyoc3">I thanked Kai and Lily and practically
                                    <i >ran&nbsp; </i><i>out of the restaurant!</i>
                                </div>
                                <div id="irds8l">I didn’t wait till I got back to my hotel room, I was so excited that once I got to my car, I pulled off my scarf, and gently massaged the
                                    cream into my neck and under my chin…
                                </div>
                                <div id="iov8es">…between you and me, I can still remember
                                    that exciting moment, looking in the car's visor mirror. The cream felt smooth, luxurious, and I
                                    knew it was different than anything I’d tried before!
                                </div>
                                <div id="i8lsvg"><b>And
                                        immediately my neck’s skin felt softer, rejuvenated, with an instant sensation of subtle
                                        firmness… I couldn’t believe it!
                                    </b>
                                </div>
                            </div>

                            <div id="iek47l" className="fk-row">
                                <div id="ifavsg" className="fk-col col text-center">
                                    <div id="i8krmy" className="albert-font para">
                                        <div id="i255ay">
                                            <LazyLoadImage src={skincareImg33} alt="img33" id="fkt-image-3e4-c8d-97b" className="fk-image-defaults mob-view " />
                                            <span  id="isn9zb"><b>The next  day though, is when I began to notice change… </b></span>
                                        </div>
                                        <div id="ibj5yv">Because when I woke up the next morning, I paused at the vanity and looked at my reflection…
                                        </div>
                                        <div id="ik3nwl">…and then I walked closer to the mirror, and at first thought it was my imagination, so I then leaned in further to look
                                            at my neckline.
                                        </div>
                                        <div id="i5vvml">And overnight, my neckline was smoother, firmer and even my jawline appeared enhanced!
                                        </div>
                                        <div id="illmok">Then as the days went by, my neckline completely improved… </div>
                                        <div id="imgau4"><b><i >It was like my neck was aging in reverse, like this </i><u >cream was a miracle!</u></b></div>
                                    </div>
                                </div>
                                <div id="iiv9ho" className="fk-col col">
                                    <LazyLoadImage id="fkt-image-492-e9f-a67" alt="img33" src={skincareImg33}  className="fk-image-defaults  desktop-view mx-auto" />
                                </div>
                            </div>
                            <div id="ih7bkj" className="albert-font para">
                                <div id="ia0bhe"><span  id="is9p03">And it
                                        only got better from there…</span></div>
                                <div id="izxirc">By the end of the first week the fine lines  on my neck were fading, the overall skin tone became more even and radiant, and
                                    <b> the sagging skin on my neck was beginning to tighten and firm up…</b> I was feeling a new sense of confidence seeing the change happening!
                                </div>
                                <div id="iop9h7">And then at the end of the first month <b>I saw a significant reduction of neck lines and creases. My jawline appeared more contoured and defined.
                                    </b> My neck skin was firmer and more elastic, almost springy to the touch… and I began feeling empowered and eagerly waited for more dramatic changes!
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div id="i37739" className="fk-row">
                    <div id="ii4rqf" className="fk-col col container_sec">
                        <div  className="fk-inner-row">
                            <div id="ijbmjh" className="fk-inner-column text-center">
                                <div id="icvk6q" className="albert-font para">
                                    <div id="ibenoq">
                                        <LazyLoadImage src={skincareImg34}  alt="img34" id="fkt-image-32a-1a3-b59" className="fk-image-defaults  mob-view mx-auto " />
                                        <span id="ik30pi">And after the third month, the scarf was history… and
                                            instead, I was showing off my new necklace!</span>
                                    </div>
                                    <div id="ivpc7t">I’d finally boxed up my scarves because the transformation was incredible!</div>
                                    <div id="inx19m"><b>I looked 20 years younger and felt empowered, like I’d taken
                                            control of time itself - writing my own storyline of age and beauty!
                                        </b></div>
                                    <div id="i7pq2k">But I never expected the joy and
                                        excitement that was to follow…
                                    </div>
                                    <div id="imeccj"><b>…because my self-confidence increased at the same time of my beauty transformation.</b></div>
                                </div>
                            </div>
                            <div id="iag56a" className="fk-inner-column text-center">
                                <LazyLoadImage id="fkt-image-fcb-285-a4a" alt="img34" src={skincareImg34}  className="fk-image-defaults  desktop-view mx-auto" />
                            </div>
                        </div>
                    </div>
                </div>


                <div id="ikq5ch" className="fk-row">
                    <div id="i7pxdr" className="fk-col col container_sec text-center">
                        <div id="i4ce8l" className="albert-font para">
                            <div id="ig5f8k">And every
                                new moment seemed to be filled with positivity… I’d signed up for pickleball at the community
                                center, I began sleeping better, and I even noticed younger men glancing at me like they did when I
                                was in my 30’s!
                            </div>
                            <div id="ig5f8k-2">But the
                                best part is what happened next.
                            </div>
                            <div id="ig5f8k-3">I met
                                my daughter Tina for coffee, and as I approached her sitting at a table in the corner, I saw her
                                eyes widened…
                                <i  id="igt63n">“Mom,
                                    why aren’t you wearing your scarf… wait, Mom did you have work done on your neck!?”
                                </i>...
                            </div>
                            <div id="ig5f8k-3-2"><i
                                    id="izls78">“NO!”
                                </i> I laughed…
                                <i  id="i94w41">“it’s
                                    Kai and Lily's Platysma Renew”
                                </i>! And then went on to share details of my discovery.
                            </div>
                            <div id="ig5f8k-4">And it
                                didn’t stop there…
                            </div>
                            <div id="ig5f8k-4-2">
                                Because when I tell people my real age, they can’t believe it.
                            </div>
                            <div id="ig5f8k-5"><b
                                    id="ii71wi">I
                                    often get told I look 10 or 20 years younger.
                                </b>.
                            </div>
                            <div id="ig5f8k-5-2">
                                Amazing right? But the crazy thing is, it’s not just me who’s had these incredible results.
                            </div>
                            <div id="ig5f8k-6">Because
                                Kai and Lily have helped countless other women enjoy firmer, smoother, and more radiant looking neck
                                skin…
                            </div>
                            <div id="ig5f8k-6-2">
                                ...and become the envy of their friends!
                            </div>
                        </div>
                    </div>
                </div>

                <div id="it2k6yr" className="fk-row">
                    <div id="ij1nxyh" className="fk-col col container_sec text-center">
                        <div id="irtyzom">
                            <div id="in3o2nr" className="albert-font para">
                                <div id="istx1oh"
                                    className="gjs-selected">Beth M. at 55 shared with me how her confidence is through the moon
                                    now...&nbsp;
                                </div>
                            </div>
                            <div id="ivg21rk" className="albert-font para">
                                <div  data-highlightable="1"  id="i3pr8kh" className="gjs-selected">
                                    <i >"I feel so blessed, I look amazing and my neck is firm
                                        and my profile is more contoured"... "I never thought I'd get these but I did, thank you!"
                                    </i></div>
                            </div>
                            <LazyLoadImage  alt="img35" src={skincareImg35} id="fkt-image-5a2-986-937" className="fk-image-defaults mx-auto" />
                        </div>
                    </div>
                </div>

                <div id="if86e2" className="fk-row">
                    <div id="ic8tak" className="fk-col col container_sec text-center">
                        <div id="ishhlk">
                            <div id="ibaoc4" className="fk-row">
                                <div id="ihw3jq" className="fk-col col text-center">
                                    <LazyLoadImage id="fkt-image-fac-290-93b"  alt="img36" src={skincareImg36}  className="fk-image-defaults mx-auto" />
                                </div>
                                <div id="i8pexd" className="fk-col col">
                                    <div id="ir08wk" className="albert-font para">
                                        <div id="iosxod"><i  id="im7log">I
                                                had almost lost hope and then I discovered Kai's "Platysma Renew" breakthrough and
                                                my neck has firmed up and looks absolutely amazing!
                                            </i>
                                        </div>
                                    </div>
                                    <div id="if41fk" className="albert-font para">
                                        <div id="i7395h"><span id="i8qdkl"><b id="ixeltq">Diana, 66, <span  id="iue4o5">California  </span></b></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="ixez24" className="fk-row">
                    <div className="fk-col col container_sec text-center">
                        <div id="i9orxa">
                            <div id="ihr9wc" className="fk-row">
                                <div id="iqyfp1" className="fk-col col text-center">
                                    <LazyLoadImage title="" target="_self" src={skincareImg37}   alt="img37"  id="fkt-image-897-3a6-a39" className="fk-image-defaults mx-auto" />
                                </div>
                                <div id="ibzq92" className="fk-col col text-center">
                                    <div id="i9i4cg" className="albert-font para">
                                        <div id="irggrr"><i  id="i1598k">I
                                                used to have to cover-up using scarves or turtlenecks, I feel liberated now and my
                                                confidence is back! Thank you!
                                            </i></div>
                                    </div>
                                    <div id="i1tprv" className="albert-font para">
                                        <div id="iiq91k"><span id="iejgq2"><b id="i0qvwk">Brenda, 62, <span  id="iwymos">New York</span></b></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="i7u51t" className="fk-row">
                    <div id="i1qfcb" className="fk-col col container_sec text-center">
                        <div  className="fk-inner-row">
                            <div id="ifu5x1" className="fk-inner-column text-center">
                                <div id="ifpxrn" className="albert-font para">
                                    <div id="izroxo">
                                        <LazyLoadImage src={skincareImg38} alt="img38"  id="fkt-image-7b7-da8-8aa" className="fk-image-defaults  mob-view mx-auto" />
                                        <span  id="ih76u1">I know everything so far sounds amazing right? Well,
                                            it’s about to get better!</span></div>
                                    <div id="ixjxfx">This neck treatment is completely safe for all women – because it has <u>zero</u> side effects.</div>
                                    <div id="i2wa9a"><b>And as you’ll see in just a moment, it’s very affordable… and can
                                            work for any woman as well.
                                        </b></div>
                                    <div id="i086kj">And that's important because…
                                    </div>
                                    <div id="ihuf4k">…I know what it's like to be in an
                                        embarrassing situation and feeling stuck like there’s nothing you can do about it…
                                    </div>
                                    <div id="imbj2i">…but after discovering this “wonder
                                        treatment” and knowing that living with sagging skin can be a thing of the past, you should
                                        have access to this too.
                                    </div>
                                </div>
                            </div>

                            <div d="ickvo7" className="fk-inner-column text-center">
                                <LazyLoadImage id="fkt-image-84a-7b8-8eb"  alt="img38" src={skincareImg38}  className="fk-image-defaults  desktop-view mx-auto" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="iavjkyp" className="fk-row text-center">
                    <div id="i732l9z" className="fk-col col container_sec text-center">
                        <div id="ipdtfkb">
                            <div id="iws26qp" className="albert-font f30"><b>These clinical studies proves how amazing this treatment really is</b></div>
                        </div>
                    </div>
                </div>

                <div id="icfz6b" className="fk-row">
                    <div id="ixvolfv" className="fk-col col container_sec text-center">
                        <div id="iomgp1c" className="albert-font para">
                            <div id="iyt1eki"><span  id="i22h3qf"><b
                                        >We now have solid evidence demonstrating the
                                        undeniable success of this breakthrough…
                                    </b></span></div>
                            <div id="imqf6t2">A recent study published by the National Library
                                of Medicine revealed that
                                <i >“wrinkles improved significantly at day 30… and chin
                                    texture improved significantly” and a whopping <b id="iliwjup" className='ps-2'><u id="iqt7d7h">“64% of women reported improved facial
                                        </u><u 
                                            id="i9o0wg2">skin texture and 73% reported improved skin hydration”.
                                        </u></b></i>
                            </div>
                            <div id="ihy3ayn">Just think, as you look each day in the mirror,
                                watching the skin on your neck get firmer and your jawline becoming more defined…
                            </div>
                        </div>
                    </div>
                </div>

                <div id="idswazu" className="fk-row">
                    <div id="i04rjd8" className="fk-col col container_sec text-center">
                        <div  className="fk-inner-row">
                            <div id="i12d1mk" className="fk-inner-column">
                                <div id="i2p2587" className="albert-font para">
                                    <div id="ifs5xdr">
                                        <LazyLoadImage src={skincareImg39} alt="" id="fkt-image-3e2-981-b03" className="fk-image-defaults  mob-view mx-auto" />
                                        <span id="ijzq4kh">…then you’ll be filled with a sense of new self-worth
                                            and empowerment for the first time in a long time…</span>
                                    </div>
                                    <div id="imzob3k">…as you finally realize that the sagging
                                        skin is gone – and replaced with a firmer and more contoured neckline!
                                    </div>
                                    <div id="iw7qkgf"><b>Just keep reading and I’ll show you how to enjoy results like this
                                            in as little as a few days from now.
                                        </b></div>
                                    <div id="ipcw5c3">But first, check out what Harvard Health
                                        had to say…
                                    </div>
                                    <div id="ihlljsp">A recent article published in Harvard
                                        Health found the super trio ingredients to
                                        <i >“reduce fine lines and wrinkles by increasing the
                                            production of collagen”... and further “fading age spots and softening rough patches of  skin” occurred.</i>
                                    </div>
                                </div>
                            </div>
                            <div id="in7i9hg" className="fk-inner-column">
                                <LazyLoadImage id="fkt-image-ad7-b8a-b65" src={skincareImg39} alt="" className="fk-image-defaults  desktop-view mx-auto" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="iskm7w2" className="fk-row">
                    <div id="i545lt7" className="fk-col col container_sec text-center">
                        <div id="i7njbf6" className="albert-font para">
                            <div id="ibpz7hh"><span  id="i5b1frd">What
                                    this boils down to is that this formula is a game-changer for women like us, offering us a
                                    chance to firm-up our necklines and rejuvenate our skin by turning back the clock 10, 15, and
                                    even 20 years.</span></div>
                            <div id="ikhx3pt">Just look at these incredible beauty
                                transformations…
                            </div>
                        </div>
                    </div>
                </div>

                <div id="ismuhn" className="fk-row">
                    <div id="iwk90h" className="fk-col col container_sec">
                        <div id="iacp94">
                            <div id="ifqfn2" className="albert-font para">
                                <div id="ijr3mf"
                                    className="gjs-selected">There's 64 year old Angie W. who after only a few weeks of using the neck
                                    cream said
                                </div>
                            </div>
                            <div id="ip9nn9" className="albert-font para">
                                <div  data-highlightable="1"  id="idzp3t" className="gjs-selected">
                                    <i  id="iltmd7">"My husband can't believe the
                                        difference"... "and my friends say I look at least ten years younger, and it's only been a
                                        few weeks!"
                                    </i></div>
                            </div>
                            <LazyLoadImage alt="img40" src={skincareImg40} id="fkt-image-b83-09f-a16" className="fk-image-defaults mx-auto" />
                        </div>
                    </div>
                </div>

                <div id="idmvsil" className="fk-row">
                    <div id="i2f054o" className="fk-col col container_sec text-center">
                        <div id="ishtn3n" className="albert-font para">
                            <div id="i15lzox"><span  id="idx30gb">Now
                                    you’ve seen the difference with your own eyes!</span></div>
                            <div id="i64vktx"><b>All
                                    these women have turned back the clock from 10, 15, to even 20 years… and their family and
                                    friends are simply in awe of the changes (and a bit jealous too!).
                                </b></div>
                        </div>
                    </div>
                </div>

                <div id="isj34vu" className="fk-row">
                    <div id="imh630h" className="fk-col col container_sec">
                        <div  className="fk-inner-row">
                            <div id="i8xa32k" className="fk-inner-column">
                                <div id="i9mtxb4" className="albert-font para">
                                    <div id="ivtapgx">
                                        <LazyLoadImage src={skincareImg41} alt="img41" id="fkt-image-8c6-bb7-93b" className="fk-image-defaults  mob-view " />
                                        <span  id="i5gpceu">To put it simply, this discovery has changed my life.
                                            I've tossed my scarves and changed out my wardrobe, and now I wear low cut tops I once
                                            thought were out of the question.</span></div>
                                    <div id="ib0yqok"><b>I’ve tried everything else that’s on the market
                                        </b>– spending thousands of dollars, and every time ending in disappointment…
                                    </div>
                                    <div id="i1vajhg"><b>…but THIS discovery changed everything for me
                                        </b>, because before I found this, I even considered one of those “neck lift” surgeries…
                                    </div>
                                    <div id="i4qg3en"><b>But thankfully I never had to try any cosmetic procedures on my
                                            neck because of this breakthrough.
                                        </b></div>
                                </div>
                            </div>
                            <div id="inaeu8i" className="fk-inner-column">
                                <LazyLoadImage id="fkt-image-145-99d-9aa" alt="img41" src={skincareImg41}  className="fk-image-defaults  desktop-view mx-auto" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="i8lmp74" className="fk-row">
                    <div className="fk-col col container_sec">
                        <div id="ivi0aw1">
                            <div id="ih5hagr" className="fk-row">
                                <div id="iyy3ja8" className="fk-col col text-center">
                                    <LazyLoadImage  alt="img42" src={skincareImg42} id="fkt-image-71f-8b3-937" className="fk-image-defaults mx-auto" />
                                </div>
                                <div id="idyamse" className="fk-col col text-center">
                                    <div id="irfo50g" className="albert-font para">
                                        <div  data-highlightable="1"  id="ijwdlxl"
                                            className="gjs-selected"><i >This works! After just a
                                                few weeks my neck looks years younger and I've only just begun using this!
                                            </i>
                                        </div>
                                    </div>
                                    <div id="i688ykt" className="albert-font para">
                                        <div id="i3jhv9h">
                                            <span><span><b>Susan C, 63,</b> <span >Delaware</span><br /></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="i8o1z2z" className="fk-row">
                    <div className="fk-col col container_sec text-center">
                        <div id="il5m1kb">
                            <div id="id38le1" className="fk-row">
                                <div id="ii1yumx" className="fk-col col text-center">
                                    <LazyLoadImage alt="img43" src={skincareImg43} id="fkt-image-d86-688-b77" className="fk-image-defaults mx-auto" />
                                </div>
                                <div id="icyade3" className="fk-col col">
                                    <div id="iy9ua1p" className="albert-font para">
                                        <div id="i3djhro"><i id="i0z45k2">My neck is firmer and my confidence is back!,&nbsp; </i></div>
                                    </div>
                                    <div id="ix44rpz" className="albert-font para">
                                        <div id="iq78uz5">
                                            <span><span ><b>Jennifer A, 67, </b> <span >Illinois</span> <br /></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="i39crzp" className="fk-row">
                    <div id="i9sj4vi" className="fk-col col container_sec text-center">
                        <div id="idgtecj">
                            <div id="iai1rvi" className="albert-font">
                                <div id="icmoa2k"
                                    className="gjs-selected">Barbara M. who at 55 is often compared to a 35-year-old Jane Seymour...
                                    she credits her neck transformation for the compliments!
                                </div>
                            </div>
                            <LazyLoadImage alt="img44" src={skincareImg44} id="fkt-image-4a9-586-a85" className="fk-image-defaults mx-auto" />
                        </div>
                    </div>
                </div>

                <div id="iknt0j2" className="fk-row">
                    <div id="iljxv1j" className="fk-col col container_sec text-center">
                        <div id="ig6mlc5" className="albert-font para">
                            <div id="ike93v4"><span  id="ib1wl0i">Kai has
                                    helped not just me, but so many other women as well.</span></div>
                            <div id="imtflps">And even though they each had amazing results,
                                he knew he had limited resources to distribute Platysma Renew… and that’s where I stepped in to help
                                him.
                            </div>
                            <div id="i03ilgd">See, Kai’s passion is R&amp;D… and because his
                                formulation helped so many women regain self-confidence by improving their neck’s skin, introducing
                                it to more women could help fund what he loved to do: research.
                            </div>
                            <div id="iwblpcj">So I suggested he could “do both”… continue with
                                his research, and help women's skin by sharing the Platysma Renew formulation with even more women.
                            </div>
                            <div id="iytlyet">And he really liked what he was hearing…
                            </div>
                        </div>
                    </div>
                </div>


                <div id="i0bfhmn" className="fk-row">
                    <div id="i1uxkn3" className="fk-col col container_sec text-center">
                        <div className="fk-inner-row">
                            <div id="i7wp7mu" className="fk-inner-column">
                                <div id="iugvm1j" className="albert-font para">
                                    <div id="igsd79k">
                                        <LazyLoadImage title="" target="_self" src={skincareImg45} alt="img45" id="fkt-image-40c-7b5-a7e" className="fk-image-defaults  mob-view mx-auto " />
                                        <span id="i22l82g">And so I asked Kai how he felt about working with me
                                            and then selecting a manufacturer to produce Platysma Renew? Because with my business
                                            background and his breakthrough we could accomplish both.</span>
                                    </div>
                                    <div id="i6hcrxi">He loved the idea! …but on one  condition…
                                    </div>
                                    <div id="ia2qtck">He wanted to create a product that held
                                        nothing back in the fight against aging and sagging skin…
                                        <b>so that women, with any skin type, who used the
                                            cream could experience firmer, healthier, and more youthful neck skin.
                                        </b>
                                    </div>
                                    <div id="iuhf325">So whether you’re just beginning to see
                                        signs of a saggy neckline, droopy skin, and your jawline is beginning to show jowls…
                                    </div>
                                </div>
                            </div>
                            <div id="izr2zdm" className="fk-inner-column">
                                <LazyLoadImage id="fkt-image-1e8-499-823"  alt="img45" src={skincareImg45} className="fk-image-defaults  desktop-view" />
                            </div>
                        </div>
                    </div>
                </div>


                <div id="ibv29fi" className="fk-row">
                    <div id="i9rovym" className="fk-col col container_sec text-center">
                        <div id="ibg0esm" className="albert-font para">
                            <div id="ik4ojvj"><span  id="ikcb024">…or if
                                    you’ve been struggling for years with sagging skin, and “turkey neck” has made you have to cover
                                    up with scarves and turtlenecks like I used to have to do…</span></div>
                            <div id="i4yyru1">…the good news is – you don’t have to settle for
                                that tired and worn-out look anymore.
                            </div>
                            <div id="i29kem3"><b>And best of all, we’ve made it <i>affordable</i></b> <i  id="itcogj5"> for any woman that wants to <span><i>try it </i></span></i>, because we don’t want price to come between you and rejuvenating your
                                neckline into a more youthful and contoured look.
                            </div>
                        </div>
                    </div>
                </div>

                <div id="ig3tpga" className="fk-row fk-lazy">
                    <div id="ih7umdh" className="fk-col col container_sec text-center">
                        <div id="iiitkz9">
                            <div id="idpnru7" className="fk-headline trirong-font f60">Unveiling the Secret to
                                <br />
                                <i > Your Youthful Transformation...                            </i>
                            </div>
                            <LazyLoadImage id="fkt-image-fbe-a83-ab2" alt="img46" src={skincareImg46} className="fk-image-defaults" />
                        </div>
                    </div>
                </div>

                <div id="i03jqq3" className="fk-row">
                    <div id="igwqtop" className="fk-col col container_sec text-center">
                        <div  className="fk-inner-row">
                            <div id="iewal0i" className="fk-inner-column">
                                <div id="idl3cth" className="albert-font para">
                                    <div id="iiyjy8t">
                                        <LazyLoadImage src={skincareImg47} alt="img47" id="fkt-image-29b-a97-956" className="fk-image-defaults  mob-view mx-auto" />
                                        <span id="ijunlql">So now I’m excited to give you the full details on it
                                            right now:</span>
                                    </div>
                                    <div id="ia9v975"><b>Nectar Of The Gods is everything you need to firm up the loose skin on
                                            your neckline..
                                        </b></div>
                                    <div id="ih6c77j">It’s the only product in the world
                                        designed with Kai’s Platysma Renew breakthrough.
                                    </div>
                                    <div id="iej65qk">Professionally manufactured in a renown,
                                        secure GMP certified facility, which meets the highest standards in product quality and
                                        safety…
                                    </div>
                                    <div id="in9viel"><b><i 
                                                >Nectar Of The Gods simply works in ways that nothing else does.
                                            </i></b></div>
                                    <div id="idf6vue">It works so well in fact, that in just
                                        days you’ll see your neck skin firm-up and your jawline become more defined, revealing a new
                                        contoured smooth and healthy neckline.
                                    </div>
                                </div>
                            </div>
                            <div id="ib5gano" className="fk-inner-column">
                                <LazyLoadImage id="fkt-image-e70-598-8e4"  alt="img47" src={skincareImg47} className="fk-image-defaults  desktop-view mx-auto" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="i2lzq4a" className="fk-row">
                    <div id="idptzd7" className="fk-col col container_sec text-center">
                        <div id="i93zjhv" className="albert-font para">
                            <div id="icejreq"><span  id="ijdzrca">And
                                    you'll feel a silkier texture on your neck as the skin naturally repairs and rejuvenates.</span>
                            </div>
                            <div id="i5po5el"><b>It
                                    doesn’t matter how old you are because Nectar Of The Gods works for any age and for any skin type…
                                </b> and what you’ve tried before doesn’t matter either, because <b><u >bottomline this will work for you.</u></b>
                            </div>
                            <div id="if6n92v">Because after only a few days, you'll
                                immediately sense your skin becoming more revitalized and tighter.
                            </div>
                            <div id="ionnwk3">Then you’ll see the neck's skin texture
                                even-out, and you’ll notice your profile becoming more defined…
                            </div>
                        </div>
                    </div>
                </div>


                <div id="idh3o5p" className="fk-row">
                    <div id="issddjj" className="fk-col col container_sec text-center">
                        <div  className="fk-inner-row">
                            <div id="i3k64gj" className="fk-inner-column">
                                <div id="ihul5mx" className="albert-font para">
                                    <div id="iy62656">
                                        <LazyLoadImage src={skincareImg48} alt="img48"  id="fkt-image-e63-3bd-85d" className="fk-image-defaults  mob-view mx-auto" />
                                        <span 
                                            id="ihq5fuf">And then with every day that passes you’ll literally
                                            see your neckline look years younger, revealing a beautiful contoured look.</span></div>
                                    <div id="i7o5ue6"><b>And believe me, when your neck and jawline firm-up and smoothen
                                            out, you’ll start to feel younger as well!
                                        </b></div>
                                    <div id="i2ogd7h">And once you feel younger, every day
                                        will become a new experience filled with joy and happiness because your “new look” will have
                                        such a big impact on how you’ll feel.
                                    </div>
                                    <div id="ih8hcvq"><b>It’s really that transformative!
                                        </b></div>
                                    <div id="it7j1wk">And when it comes to selecting all the
                                        ingredients that make Nectar Of The Gods so effective and powerful…
                                    </div>
                                </div>
                            </div>
                            <div id="iu79pa6" className="fk-inner-column">
                                <LazyLoadImage id="fkt-image-594-7ab-b0d" alt="img48" src={skincareImg48} className="fk-image-defaults  desktop-view mx-auto" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="i2ssbzl" className="fk-row">
                    <div id="iv36dbf" className="fk-col col container_sec text-center">
                        <div id="i2nbjna" className="albert-font para">
                            <div id="i4c353j"><span  id="ica859l"><b>
                                We’ve sourced only the highest quality ingredients
                                    from around the world and selected only the purest and premium elements for our
                                    formulation…so you can feel confident that you're treating your skin with the absolute best.
                                </b></span>
                            </div>
                            <div id="itcki1q">And these ingredients have been rigorously
                                tested, have been through clinical trials, and researched by some of the most respected universities
                                across the United States.
                            </div>
                            <div id="i8dk4bq"><b>And
                                    we’ve made Nectar Of The Gods affordable, as you’ll see in a moment, so that any woman can try it
                                    for themselves.
                                </b>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="ihg6u6n" className="fk-row">
                    <div id="ikk7ndr" className="fk-col col container_sec text-center">
                        <div id="ing8brl">
                            <div id="ih9jakb" className="fk-headline trirong-font f36">With Nectar Of The Gods you can
                                have:
                            </div>
                        </div>
                        <div id="iuce8pl">
                            <div id="iascp9z" className="fk-row">
                                <div id="itau5fe" className="fk-col col text-center">
                                    <LazyLoadImage id="fkt-image-84b-cbb-aa8" alt="img49" src={skincareImg49} className="fk-image-defaults mx-auto" />
                                    <div id="iircmok" className="albert-font para">A firmer, more
                                        taut and
                                        <br />smoother neckline…
                                    </div>
                                </div>
                                <div id="if5ho4f" className="fk-col col">
                                    <LazyLoadImage id="fkt-image-1c8-3b0-baa" alt="img50" src={skincareImg50} className="fk-image-defaults mx-auto" />
                                    <div id="icl7lpn" className="albert-font para">Healthier, more
                                        elastic, and springier neck skin…
                                    </div>
                                </div>
                                <div id="il7ypz9" className="fk-col col">
                                    <LazyLoadImage id="fkt-image-a5d-ab5-8d3" alt="" src={skincareImg51} className="fk-image-defaults mx-auto" />
                                    <div id="ikjcjtk" className="albert-font para">A sculpted jawline… </div>
                                </div>
                            </div>
                        </div>
                        <div id="ii0kjtb">
                            <div id="iwz061o" className="fk-row">
                                <div id="ihr9jvj" className="fk-col col">
                                    <LazyLoadImage  alt="img52"  src={skincareImg52} id="fkt-image-cbf-6be-9c2" className="fk-image-defaults mx-auto" />
                                    <div id="iw9xmb2" className="albert-font para">An elegant contoured neck… </div>
                                </div>
                                <div id="i58gthi" className="fk-col col">
                                    <LazyLoadImage  href="" alt="img53" src={skincareImg53} id="fkt-image-9e6-082-a1b" className="fk-image-defaults mx-auto" />
                                    <div id="iebgq5g" className="albert-font para">And a firmer,
                                        wrinkle-free, and beautiful décolletage (upper chest).
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="i5spduv" className="fk-row">
                    <div id="i6thzcr" className="fk-col col container_sec text-center">
                        <div id="im98jpz" className="albert-font para">
                            <div id="i2f20qr"><span  id="ilw45s7"><b
                                        >WITHOUT the high-end price tag.
                                    </b></span></div>
                            <div id="icpcczh">And the best part is you can have all this
                                within just days of use…so that your friends and family notice the change… and, don’t be surprised
                                if you get compliments or even the occasional glance from younger men…
                            </div>
                            <div id="i3lqwui">Because It won’t be just you noticing your new
                                beautifully contoured neckline… <b>they’ll say you look 10, 15, even 20 years younger and want
                                    to know your secret!
                                </b>
                            </div>
                            <div id="ins595g">And it’s so easy… just gently massage Nector
                                Of The Gods into your neck, jawline, and upper chest area once a day and then let it work…
                            </div>
                            <div id="iuy0o9r">…it won’t take long for you to join so many
                                other women now raving about Nectar Of The Gods and their incredible results!
                            </div>
                            <div id="ijnd0zw">But still, here’s a <b>special surprise for you that makes Nectar Of The Gods even BETTER </b> at rejuvenating and firming-up your neckline…</div>
                        </div>
                    </div>
                </div>

                <div id="i6xzqi8" className="fk-row">
                    <div id="ird2vfl" className="fk-col col container_sec text-center">
                        <div id="iex03ii">
                            <div id="i95amuh" className="fk-headline trirong-font f40">The Platysma Renew breakthrough has the foundation of the <i >“Super Trio”
                                </i> anti-aging and neck firming compounds <u>plus</u> an additional SEVEN more firming, smoothing, and anti-wrinkle ingredients
                            </div>
                            <div id="ieq5fgr" className="albert-font para">
                                <div id="inoifuu"><span  id="irv31qn">We wanted to create the ultimate neck-firming and lifting formulation, and Kai was eager to
                                        make his Platysma Renew even MORE powerful and effective…</span></div>
                                <div id="ia6xmsf">…and when you see the results of his new and
                                    improved advanced formulation, you’ll be blown away!
                                </div>
                                <div id="idv46t6">So when Kai added these additional ingredients…</div>
                                <div id="im72xg3"><u>Women began to see even faster results!  </u></div>
                            </div>
                        </div>
                        <div id="ixb8erk">
                        </div>
                    </div>
                </div>

                <div id="ifmughs" className="fk-row">
                    <div    id="ie8ispi" className="fk-col col container_sec text-center">
                        <div  className="fk-inner-row">
                            <div id="im0chaf" className="fk-inner-column text-center">
                                <div id="ib91trl" className="albert-font para">
                                    <div id="ikvypaz">
                                        <div id="indw4fl">
                                            <div id="ig8dpgy" className="fk-headline trirong-font f40">Because
                                                individually, science proves their effectiveness, but when combined, they work in
                                                harmony to lift and firm the neck’s skin…
                                            </div>
                                            <LazyLoadImage src={skincareImg54} alt="img54" id="fkt-image-b5f-1b6-996" className="fk-image-defaults  mob-view mx-auto" />
                                        </div><span  id="ic4lrq8">I can’t wait any longer to
                                            tell you about these <b>additional ingredients
                                            </b> and how amazing they are, so if you let me, I’ll show you them right now…</span>
                                    </div>
                                    <div id="ixo71g5">The first one is <b>Hydrolyzed Soy Protein.</b></div>
                                </div>
                            </div>
                            <div id="iisu5vl" className="fk-inner-column text-center">
                                <LazyLoadImage id="fkt-image-fa9-1b5-8da" alt="img54" src={skincareImg54} className="fk-image-defaults  desktop-view mx-auto" /></div>
                        </div>
                    </div>
                </div>

                <div id="idbj1ia" className="fk-row">
                    <div className="fk-col col container_sec text-center">
                        <div id="i4nlbzy">
                            <div id="i0hgzi6" className="fk-row">
                                <div id="iz6y33e" className="fk-col col">
                                    <LazyLoadImage alt="img55" src={skincareImg55} id="fkt-image-55f-485-8ad" className="fk-image-defaults mx-auto" />
                                </div>
                                <div id="ivy9ryg" className="fk-col col text-center">
                                    <div id="idm2flm" className="albert-font para">
                                        <div id="is1bf2g"><i 
                                                id="izhsm08">I’m 56 and a mother of two. After losing 40 pounds, I noticed extra
                                                skin around my neck area. I was able to firm up the skin using Platysma Renew...
                                            </i>
                                        </div>
                                    </div>
                                    <div id="i5nlwzf" className="albert-font para">
                                        <div id="iq78uz5"><span><span ><b>Samantha J …56 year old mother of two.</b></span></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div id="ie0wior" className="fk-row text-center">
                    <div className="fk-col col container_sec">
                        <div id="i58r8im">
                            <div id="il6tmhd" className="fk-row">
                                <div id="id1m7hs" className="fk-col col">
                                    <LazyLoadImage alt="img56" src={skincareImg56} id="fkt-image-653-8b1-ac5" className="fk-image-defaults mx-auto" />
                                </div>
                                <div id="iti48j7" className="fk-col col">
                                    <div id="isamab6" className="albert-font para">
                                        <div id="itr2gfl">
                                            <i id="i6p8j36">I am a 67-year-old who found myself staying indoors and avoiding going
                                                out. Now, I call myself a "social butterfly" and am busier than ever in my
                                                community...
                                            </i>
                                        </div>
                                    </div>
                                    <div id="ig6zoly" className="albert-font para">
                                        <div id="iq78uz5-2"><span><span><b>Jackie Z …the “social butterfly” at age 67.</b></span></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="i1899gp" className="fk-row">
                    <div  className="fk-col col container_sec text-center">
                        <div id="iyrjcsh">
                            <div id="ix4pdlf" className="fk-row">
                                <div id="ipn1tkf" className="fk-col col">
                                    <LazyLoadImage alt="img57" src={skincareImg57} id="fkt-image-a61-3b6-933" className="fk-image-defaults mx-auto" />
                                </div>
                                <div id="ih230ph" className="fk-col col">
                                    <div id="ic8tl9o" className="albert-font para">
                                        <div id="iuz0njg">
                                            <i id="irkubw5">At 58, I struggled with "turkey neck" for years. I tried everything
                                                available, but the only product that brought me success was Kai's Platysma Renew...
                                            </i>
                                        </div>
                                    </div>
                                    <div id="i1t9jm9" className="albert-font para">
                                        <div id="iq78uz5-3"><span><span><b>Jenna D …at 58  finally got rid of “turkey neck”.</b></span></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="iiqe7dx" className="fk-row">
                    <div id="iahon1d" className="fk-col col container_sec text-center">
                        <div  className="fk-inner-row">
                            <div id="ioxb5e9" className="fk-inner-column">
                                <div id="ip8n5so" className="albert-font para">
                                    <div id="iwwsvuw">
                                        <LazyLoadImage src={skincareImg58} alt="img58" id="fkt-image-b4a-091-af3" className="fk-image-defaults  mob-view mx-auto " />
                                        <span id="ivav9q2">And this isn’t just any protein, it acts as a guardian of your skin's elasticity...</span>
                                    </div>
                                    <div id="i6nrx0l">First, it
                                        <b><i>promotes collagen and elastin and also reduces fine lines… </i></b> This special protein has a natural tightening and firming effect to keep your  neck skin smooth and firm.
                                    </div>
                                    <div id="iqnu808">And what's more is, it’s also a rich source of <i >Isoflavones
                                        </i>.
                                    </div>
                                    <div id="iimqv6f">See, Isoflavones act like estrogen, so
                                        when estrogen levels drop from hormonal changes like menopause, they can help fill in the
                                        gap.
                                    </div>
                                    <div id="ibjnuq1">In fact a recent study proves just how effective they are… 
                                        <b id="iyx7lnm"><i  data-highlightable="1" id="ilm3394"><u id="iuq69nr">a staggering</u></i> <u id="i6jypi4"><i id="ic9hbqg">86.2% of women had an increase in collagen in their skin </i></u></b><i > after Isoflavone treatment                 </i>.
                                        In other words, it’s incredibly effective in firming, restoring
                                        elasticity, and rejuvenating the skin for women as they age!
                                    </div>
                                    <div id="inob0k8">Hydrolyzed Soy Protein isn’t just good
                                        for tightening and firming your skin, it’s also good for soothing the skin because of its
                                        anti-inflammatory properties.
                                    </div>
                                </div>
                            </div>
                            <div id="iwj19pz" className="fk-inner-column">
                                <LazyLoadImage id="fkt-image-a89-7b8-9b3" alt="img58" src={skincareImg58} className="fk-image-defaults  desktop-view mx-auto" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="idz534t" className="fk-row">
                    <div id="i2vgovj" className="fk-col col container_sec text-center">
                        <div id="imudxji">
                            <div id="iw9tsi4" className="albert-font para">
                                <div id="idgbhi9"
                                    className="gjs-selected">Sheila L. a 56 mother of two... who after losing 40 pounds suddenly had
                                    extra skin around her neck area, and then was able to firm up the skin using Platysma Renew...
                                </div>
                            </div>
                            <LazyLoadImage alt="img59" src={skincareImg59} id="fkt-image-b00-4a4-ab6" className="fk-image-defaults mx-auto" />
                        </div>
                    </div>
                </div>
                
                <div id="ixuzybd" className="fk-row">
                    <div id="iq97zn4" className="fk-col col container_sec text-center">
                        <div id="i2767qh">
                            <div id="ionf0ku" className="albert-font para">
                                <div id="iqdvcwg"><span  id="ib9l9op">Kai
                                        knew that hydrolyzed soy protein was just the beginning, and he wanted to include an
                                        antioxidant for a layer of skin protection and that’s why he added a unique form of Vitamin
                                        E.</span></div>
                            </div>
                            <div id="i2alkmh" className="fk-row">
                                <div id="i9n9txa" className="fk-col col">
                                    <div id="ijgbnn1" className="albert-font para">
                                        <div id="ibwbnlt">
                                            <LazyLoadImage src={skincareImg60} alt="img60" id="fkt-image-f6e-283-adc" className="fk-image-defaults  mob-view mx-auto " />
                                            <span id="iaiagmh"><b> Tocopheryl Acetate (Vitamin E)</b></span>
                                        </div>
                                        <div id="i6v1olt">The next ingredient Kai  <b>Tocopheryl Acetate (Vitamin E)&nbsp;
                                            </b>is cherished for its powerful antioxidant properties…
                                        </div>
                                        <div id="i8bbqyo">…Because it helps shield the skin
                                            from free radicals that can cause DNA damage, premature aging, and even skin cancer…
                                        </div>
                                        <div id="ielstw9">By penetrating deep into the skin,
                                            this Vitamin E not only helps protect your skin from damage due to ultraviolet rays,
                                            cigarette smoke, and air pollution, but also <b><i>promotes the regeneration of</i><i>healthy skin cells.</i></b>
                                        </div>
                                    </div>
                                </div>
                                <div id="ibdhb13" className="fk-col col">
                                    <LazyLoadImage id="fkt-image-98c-dac-930" alt="img60" src={skincareImg60} className="fk-image-defaults  desktop-view mx-auto" />
                                </div>
                            </div>
                            <div id="ir6nsqe" className="albert-font para">
                                <div id="iefuzfh"><span  id="ib668vg">Put
                                        simply, it acts as your skin's protective barrier by warding off environmental threats while
                                        revitalizing your skin from the inside out.</span></div>
                                <div id="ikzym6f">This next ingredient is packed with natural
                                    properties which made it an obvious choice to include…
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div id="icjpj94" className="fk-row">
                    <div id="igo1jsw" className="fk-col col container_sec">
                        <div id="i3xdqj6" className="fk-inner-row">
                            <div id="ildya7o" className="fk-inner-column">
                                <div id="iyy3vko" className="albert-font para">
                                    <div id="i5k2my7">
                                        <LazyLoadImage title="" target="_self" src={skincareImg61}  alt="img61" id="fkt-image-611-980-95c" className="fk-image-defaults  mob-view mx-auto " />
                                        <span id="i1kao6k"><b>Butyrospermum Parkii (Shea) Butter </b></span>
                                    </div>
                                    <div id="ix7cn9y">Shea butter, which comes from the shea
                                        tree and is native to Africa, is known for its natural moisturizing, anti-aging, and
                                        antioxidant properties.
                                    </div>
                                    <div id="i97bz4l">You may have heard that Shea butter is
                                        used for sunburns or rashes, but research has found that it also helps stimulate the
                                        production of collagen to create younger-looking skin.
                                    </div>
                                    <div id="ifzwaup">Also <b><i>rich in vitamins A and E, it hydrates to create a supple, soft, and</i><i >radiant complexion.</i></b>   </div>
                                    <div id="i67dbre">And then these next to amazing primary ingredients…</div>
                                </div>
                            </div>
                            <div id="ieo9dgl" className="fk-inner-column">
                                <LazyLoadImage id="fkt-image-8ee-bb5-a9f" alt="img61" src={skincareImg61} className="fk-image-defaults  desktop-view mx-auto" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="iooi99j" className="fk-row">
                    <div id="ioyi12l" className="fk-col col container_sec text-center">
                        <div id="itlu1cj">
                            <div id="i8k4aig">
                                <div id="ie1av8i" className="fk-headline trirong-font f40">The next two ingredients
                                    work with <i >EACH OTHER</i> <br />to become even more effective at lifting and
                                    <br />firming your neck skin
                                </div>
                                <div id="inkvrlg" className="fk-row">
                                    <div id="i1e24zg" className="fk-col col text-center">
                                        <div id="i5lq08h" className="albert-font para">
                                            <div id="i2xu0xz">
                                                <LazyLoadImage src={skincareImg62} alt="img62" id="fkt-image-452-ca1-a12" className="fk-image-defaults  mob-view mx-auto " />
                                                <span id="igstc5m">The first nutrient is <b>Serenoa Serrulata (Saw Palmetto)
                                                    </b>. An extract from the berries of the palm tree called saw palmetto, native
                                                    to the West Indies…</span></div>
                                            <div id="ij9ezyi"><span 
                                                    id="i6w5cz6">Besides balancing the skin's oiliness and helping the skin maintain
                                                    a natural complexion…
                                                </span></div>
                                            <div id="ig74axs"><span 
                                                    id="ioj649v">…when combined with the next nutrient a synergistic effect emerges
                                                    to enhance the health of your skin.
                                                </span></div>
                                            <div id="iohdnj9"><span 
                                                    id="itqnmmw">Because <b>Prunus Amygdalus
                                                        Dulcis (Sweet Almond) Oil,
                                                    </b> the second ingredient, is rich in Vitamin E, monounsaturated fatty acids,
                                                    proteins, potassium, and zinc...
                                                </span></div>
                                            <div id="iyx9mgs"><span 
                                                    id="iofe5gm">Making it on its own an excellent moisturizer…&nbsp;</span></div>
                                        </div>
                                    </div>
                                    <div id="iy00xwn" className="fk-col col">
                                        <LazyLoadImage id="fkt-image-b4a-cb4-9b0"  alt="img62" src={skincareImg62} className="fk-image-defaults  desktop-view mx-auto" />
                                    </div>
                                </div>
                            </div>
                            <div id="iw8yixv" className="albert-font para">
                                <div id="i2wbqkt"><span  id="i8uupgp">…but
                                        when these two nutrients are combined, a union is formed to deeply moisturize and uphold the
                                        skin's elasticity… firming and hydrating the neck's skin.</span></div>
                            </div>
                            <div id="i3yu8ap">
                                <div id="i39nqxl" className="albert-font para">
                                    <div id="i0ta8if"
                                        className="gjs-selected">There's Mary S. who despite being 61, was mistaken for being her 32
                                        year old son's sister.
                                    </div>
                                </div>
                                <LazyLoadImage alt="img63" src={skincareImg63} id="fkt-image-2b4-388-a74" className="fk-image-defaults mx-auto" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="ibd2dyk" className="fk-row">
                    <div id="inti0yl" className="fk-col col container_sec text-center">
                        <div id="itpmh2h">
                            <div id="ibtty52" className="fk-headline trirong-font f40">It started with the <i >“Super Trio” </i> ingredients of the
                                <br />Platysma Renew breakthrough, now here’s the final highlighted nutrients
                            </div>
                            <div id="i4xtzrw" className="albert-font para">
                                <div id="i2tgpsl"><span  id="i69nyle">Kai
                                        added these ingredients specifically to give Nectar Of The Gods its final boost to give you
                                        beautiful, firmer and smoother neck skin.</span></div>
                            </div>
                        </div>
                        <div  className="fk-inner-row">
                            <div id="iaoqw6h" className="fk-inner-column">
                                <div id="if92c6j">
                                    <div id="id7mjjh" className="fk-headline albert-font f30">
                                        <div id="i7jk85i">
                                            <span  id="iaundc6">Theobroma cacao Seed
                                                Butter</span>
                                        </div>
                                    </div>
                                </div>
                                <LazyLoadImage title="" target="_self" src={skincareImg64} alt="img64" id="fkt-image-da5-c9f-85a" className="fk-image-defaults  mob-view mx-auto " />
                                <div id="imvm4uk" className="albert-font para">
                                    <div id="i1xha6b"><span 
                                            id="i7pw0zt"><b>Theobroma cacao Seed Butter
                                            </b>, commonly known as cacao butter and when used on the skin, helps to soften and
                                            smooth… and also has skin healing abilities…</span></div>
                                    <div id="iy5zvbk">…because of its fatty acids, it's been
                                        known to heal dry skin while improving skin elasticity…
                                    </div>
                                    <div id="ijlxiof">…and this is important because of the
                                        tendency for the neck skin to dry out faster than the skin on your face.
                                    </div>
                                </div>
                            </div>
                            <div id="i6g6fok" className="fk-inner-column text-center">
                                <LazyLoadImage id="fkt-image-b65-191-af3" alt="img64" src={skincareImg64} className="fk-image-defaults  desktop-view mx-auto" />
                            </div>
                        </div>
                        <div id="itbl7ek" className="albert-font para">
                            <div id="i6lh6md"><span  id="ilh6iti">This
                                    nutrient “locks in” moisture yet also protects the skin from harmful toxins, harsh winds, and
                                    even certain harmful bacteria… essentially acting as a defensive layer for the neck’s
                                    skin.</span></div>
                        </div>
                    </div>
                </div>
                
                <div id="i06b18l" className="fk-row">
                    <div id="i1dk50o" className="fk-col col container_sec text-center">
                        <div  className="fk-inner-row">
                            <div id="iuq0fl9" className="fk-inner-column">
                                <div id="ilfrsu3" className="fk-headline albert-font f30">
                                    <div id="i48f3vv">
                                        <span  id="itciafl">Cocos Nucifera (Coconut) Oil.</span>
                                    </div>
                                </div>
                                <LazyLoadImage src={skincareImg65} alt="img65" id="fkt-image-3bd-6a4-8cd" className="fk-image-defaults  mob-view mx-auto " />
                                <div id="i1z4pq8" className="albert-font para">
                                    <div id="i8q6g6n">
                                        <span id="iafy0t6">Then there’s the final ingredient <b>Cocos Nucifera (Coconut) Oil.</b></span>
                                    </div>
                                    <div id="is7iskh"><span 
                                            id="i57sdas">An excellent moisturizer on its own because of its rich fatty acids, this
                                            nutrient also contains lauric acid…
                                        </span>
                                    </div>
                                    <div id="i4qjulk"><span 
                                            id="ixd6ctg">…and what’s amazing about that is it means an added layer of protection
                                            because of its antibacterial and antifungal properties which fights against harmful
                                            microorganisms.</span>
                                    </div>
                                </div>
                            </div>
                            <div id="izy8ecq">
                            </div>
                            <div id="icd7aua" className="fk-inner-column">
                                <LazyLoadImage id="fkt-image-5be-e8f-88c"  alt="img65" src={skincareImg65} className="fk-image-defaults  desktop-view mx-auto" />
                            </div>
                        </div>
                        <div id="i1cztk8" className="albert-font para">
                            <div id="il6v97m"><span  id="iejnfhc">But
                                    perhaps the most important is its restorative properties which promote healing… because as your
                                    skin tightens and firms up, this nutrient aids in healing and rejuvenating the skin to a vibrant
                                    and healthy complexion.</span></div>
                        </div>
                    </div>
                </div>

                <div id="i4xusp3" className="fk-row fk-lazy">
                    <div id="it3sqnx" className="fk-col col container_sec text-center">
                        <div id="ifwv2tl">
                            <div id="iocyxv3" className="fk-headline trirong-font f40">With The Platysma Derma Breakthrough, The <i >“Super Trio”</i> <br />Primary Ingredients, And The Seven Other Potent <br />Nutrients You’ve Seen,
                            </div>
                            <div id="ijqe4ls" className="fk-headline trirong-font f40">You Can Finally Have A
                                Firmer More Beautiful Neckline
                                <br />In As Little As 7 Days
                            </div>
                        </div>
                        <div  className="fk-inner-row">
                            <div id="i7cd2k9" className="fk-inner-column text-center">
                                <div id="i6061un" className="albert-font para">
                                    <div id="ivixvnb">So to summarize…
                                    </div>
                                    <div id="i82czbw-2"><b>The cause of loose neck skin, jowls and sagging neck… is because
                                            the blood flow to the neck skin gets “clogged” as we age…
                                        </b></div>
                                    <div id="ivjofxf">… and the neck's skin “grows apart” from
                                        the neck muscle called the Platysma, causing that “fatty-look” to appear under the chin…
                                    </div>
                                    <div id="iook8yc"><b>And Kai’s Platysma Renew “unclogs” the necks skin with the “Super
                                            Trio” compound plus seven other crucial nutrients your necks skin needs to…
                                        </b></div>
                                    <div id="i7g94yl">…firm-up sagging skin, remove droopy
                                        jowls, and smoothen-out the skin along the jawline to give it a more detailed and defined
                                        look..
                                    </div>
                                    <div id="igad9ij"><b>And once your neck's skin has regained its firmness, tightness, and
                                            smoother texture, you’ll experience a boost in self-esteem, feel empowered, and have a
                                            brighter outlook.
                                        </b></div>
                                    <div id="i69q64g">Now…
                                    </div>
                                    <div id="itia6qr">You’ve heard my personal story…
                                    </div>
                                    <div id="i0paz9l">… you’ve seen first-hand how hundreds of
                                        women are already raving about the Platysma Renew breakthrough…
                                    </div>
                                    <div id="iwom6j2">… and you’ve seen the proven-science
                                        behind every ingredient inside Nectar Of The Gods…
                                    </div>
                                </div>
                            </div>
                            <div id="i3ggrv6" className="fk-inner-column text-center">
                                <LazyLoadImage id="fkt-image-84c-c9e-9a0" src={skincare_mob_img2} alt="" className="fk-image-defaults mx-auto" />
                            </div>
                        </div>
                        <div  className="fk-inner-row">
                            <div id="inuu83h" className="fk-inner-column text-center">
                                <div id="iooywmh" className="albert-font para">
                                    <div id="inl26as"><span 
                                            id="i3l6ebn"><b>So here’s something to think
                                                about…
                                            </b></span></div>
                                    <div id="ij6onyj">30 days from today two things will
                                        happen…
                                    </div>
                                    <div id="i42pa38">…You’ll either be 30 days older with the
                                        same neckline that’s only getting worse, or you’ll be 30 days older starting to look 5, 10
                                        and even 15 years younger.
                                    </div>
                                    <div id="ih7wcug"><b><i 
                                                >What will you see when you look in the mirror a month from now?
                                            </i></b></div>
                                    <div id="icghlvi">I’m about to share the reality of aging,
                                        because it would break my heart to see you miss out on this opportunity.
                                    </div>
                                </div>
                            </div>
                            <div id="iraemdm" className="fk-inner-column">
                                <LazyLoadImage id="fkt-image-cd3-895-88c" alt="img66" src={skincareImg66} className="fk-image-defaults mx-auto" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="iso9n8c" className="fk-row">
                    <div id="iy6awxz" className="fk-col col container_sec text-center">
                        <div id="i7v6t4h">
                            <div id="iqkgiey" className="albert-font para">
                                <div id="i5zus2k"><span  id="i5tpw0p">The
                                        reality is, if you don’t get your hands on Nectar Of The Gods today nothing will change. In
                                        fact, you’ll keep looking older and more tired with each day that goes by.</span></div>
                                <div id="ibbc8x7">New sagging and drooping will appear, and in
                                    places you’ve never noticed it happening before…
                                </div>
                                <div id="ipwqz1x">The skin under your chin and on your neck
                                    will sag just a little more each morning…
                                </div>
                            </div>
                            <div id="imltf9i" className="fk-row">
                                <div id="ijwhlqx" className="fk-col col text-center">
                                    <LazyLoadImage src={skincareImg67} alt="img67" id="fkt-image-431-993-af3" className="fk-image-defaults  mob-view mx-auto " />
                                    <div id="ioedsk4" className="albert-font para">
                                        <div id="icxoa2q"><span 
                                                id="ii103nv">And you’ll become less and less visible to other people…</span></div>
                                        <div id="irg40k5"><u 
                                                >But, it doesn’t need to be like this.
                                            </u></div>
                                        <div id="ixexs0s"><b 
                                                >Because with Kai’s help, you can turn back the clock and
                                                reverse 20 years of aging in your neckline in as little as 7 days. And “WOW” your
                                                friends with a neck restored back to its prime.
                                            </b></div>
                                        <div id="ib5uw82">It’s all because of Kai’s Platysma
                                            Renew breakthrough, the “Super Trio” ingredient blend and the seven other potent
                                            nutrients inside Nectar Of The Gods.
                                        </div>
                                        <div id="i44bo6z">And if you’re like most women who
                                            discover Nectar Of The Gods…
                                        </div>
                                        <div id="ijuq4ft">You’ll want to know how to get your
                                            hands on as many jars, as fast as possible…
                                        </div>
                                        <div id="ik8iupf"><b 
                                                >So here’s some great news:
                                            </b></div>
                                        <div id="im2hxui">Because you’ve proven you’re
                                            committed to firming up your neckline by reading this far,
                                            <b>the next batch has been reserved for readers of
                                                this letter.
                                            </b>
                                        </div>
                                    </div>
                                </div>
                                <div id="inf2ovu" className="fk-col col">
                                    <LazyLoadImage id="fkt-image-f60-793-8e3" src={skincareImg67} alt="img67" className="fk-image-defaults  desktop-view mx-auto" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="i772ixg" className="fk-row">
                    <div id="imudz56" className="fk-col col container_sec text-center">
                        <div id="ixwn2vw">
                            <div id="iahncfh" className="fk-headline trirong-font f40">The next 300 jars of <i >Nectar Of The Gods </i>
                                <br />are reserved for readers of this letter…
                                <br />… but you must act before they run <i>out of stock!</i>
                            </div>
                        </div>

                        <div id="iyi3d55" className="albert-font para">
                            <div id="iri170b"><span  id="i6haf3l">Please
                                    understand, there’s only a set number of jars that can be shipped out in any single week due to
                                    manufacturing constraints…</span></div>
                            <div id="i17hviz">…and because Nectar Of The Gods is produced in small
                                batches to ensure the highest quality, it can also mean months between batches…
                            </div>
                            <div id="i7cnpx9">In fact, because it’s so powerful and
                                sought-after, demand often outpaces supply. Sometimes stock runs out and women are forced to wait
                                until the next batch is produced.
                            </div>
                        </div>

                        <div  className="fk-inner-row">
                            <div id="ii0e8rr" className="fk-inner-column text-center">
                                <div id="ish3rh3" className="albert-font para">
                                    <div id="isfjybr">
                                        <LazyLoadImage src={skincareImg68} alt="img68" className="fk-image-defaults  mob-view mx-auto " />
                                        <span id="iwj1i39"><b>Now I don’t want you to miss out. </b></span>
                                    </div>
                                    <div id="ipiq94n"><b><i>So we’ve reserved the next batch for women who order through this page.</i></b></div>
                                </div>
                            </div>
                            <div id="in8574l" className="fk-inner-column">
                                <LazyLoadImage id="fkt-image-d8c-59d-888" title="" src={skincareImg68} alt="img68" className="fk-image-defaults  desktop-view mx-auto" />
                            </div>
                        </div>

                        <div  className="fk-inner-row">
                            <div id="ioqdnzo" className="fk-inner-column">
                                <div id="i4ppbag" className="albert-font para">
                                    <div id="iebfpi1">
                                        <LazyLoadImage src={skincareImg69} alt="img69" id="fkt-image-668-88f-99d" className="fk-image-defaults  mob-view mx-auto " />
                                        <span  id="i7wb0i5"><b>As long as you’re one of the first 300 women who order today, you’ll receive your jar of Nector Of The Gods.
                                            </b></span>
                                    </div>
                                    <div id="i6vy16a">…and not only that, but the cost of
                                        shipping right to your doorstep is covered too. You don’t pay anything for delivery!
                                    </div>
                                    <div id="ifx28n3"><b>And even though we’re holding your Jar of Nectar Of The Gods,
                                            <i >the only thing we can’t do is hold it for long.
                                            </i></b></div>
                                    <div id="ivlxzxw">The first time we did this, we sold out
                                        within a week.
                                    </div>
                                    <div id="is1ucbe">So if you’re someone who’s suffering
                                        from sagging neck skin, and if you’re someone whose skin is losing its elasticity, and if
                                        you’re someone who’s ready to get their self-confidence back…
                                    </div>
                                </div>
                            </div>
                            <div id="i1fdfdx" className="fk-inner-column text-center">
                                <LazyLoadImage id="fkt-image-dea-6a0-8d1" alt="img69" src={skincareImg69} className="fk-image-defaults  desktop-view mx-auto" />
                            </div>
                        </div>

                        <div id="iczqaxv" className="albert-font para">
                            <div id="ik4ludq"><span  id="i75d39g">…then you’ve already made the decision, otherwise you wouldn’t have read this far.</span></div>
                            <div id="ixyh797">So let’s take everything we’ve just said about
                                how you’ll firm-up your neckline, and how you’ll eliminate your sagging skin, and how you’ll
                                rejuvenate your complexion…
                                <b>and let's make it a reality for you.</b>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="inakwn8" className="fk-row">
                    <div id="isyj5cw" className="fk-col col container_sec text-center">
                        <div id="imuk2yq">
                            <div id="iarbh4l">
                                <div id="i6nk1gt" className="fk-headline trirong-font f40">Let’s Make It A Reality
                                    That You’ll Reverse At Least
                                    <br />10 Years Of Aging In Your Neckline, Or You Won’t
                                    <br />Pay A Penny!
                                </div>
                            </div>
                            <div id="imzeark" className="fk-row">
                                <div id="i8ipaa2" className="fk-col col text-center">
                                    <LazyLoadImage src={skincareImg70} alt="img70"  id="fkt-image-744-aa2-b7a" className="fk-image-defaults  mob-view mx-auto " />
                                    <div id="igz881x" className="albert-font para">
                                        <div id="ilaj1li"><span id="ilf0ajv">And all that starts as soon as you get your 1st jar so you can start  applying it.</span></div>
                                        <div id="iyupd4u">We’ll get your order of Nectar Of The Gods in the mail as soon as you reserve your jar.
                                        </div>
                                        <div id="iki9gdu">It usually takes 4 - 5 days to
                                            arrive.
                                        </div>
                                        <div id="ijv3wy5">And when the delivery man knocks on
                                            your door, I’m sure you’ll want to tear open the package and try it immediately!
                                        </div>
                                        <div id="ioxsbn4">So, go ahead.
                                        </div>
                                    </div>
                                </div>
                                <div id="imorn3t" className="fk-col col">
                                    <LazyLoadImage id="fkt-image-029-d99-a15" alt="img70" src={skincareImg70} className="fk-image-defaults  desktop-view mx-auto" />
                                </div>
                            </div>
                            <div id="i8ggxgj" className="albert-font para">
                                <div id="i7nkj5f"><span 
                                        id="iogbaow">Apply it to your neck and under your chin. And then apply it every
                                        morning.</span></div>
                                <div id="i8oo1lk"><b 
                                        >You’ll notice a difference with each day that goes by.
                                    </b></div>
                                <div id="iib1ljg"><b 
                                        >You’ll start to see the sagging skin on your neck firm up...
                                    </b></div>
                                <div id="ifxv5yi"><b 
                                        >Your loose skin will feel like it’s being erased in real-time. And your
                                        neck will look as radiant and contoured as it did when you were in your 20's.
                                    </b></div>
                                <div id="ic9bs53">Within the first month, people will be
                                    telling you that you’ve never looked so good.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="ixagynk" className="fk-row">
                    <div id="iskxqz4" className="fk-col col container_sec text-center">
                        <div  className="fk-inner-row">
                            <div id="iabc038" className="fk-inner-column">
                                <div id="i23c82n" className="albert-font para">
                                    <div id="icqxrci">
                                        <LazyLoadImage src={skincareImg71} id="fkt-image-1b3-aa3-9b7" className="fk-image-defaults  mob-view mx-auto " /><span 
                                            id="ieltqdo">And I’m sure you’ll agree, it’s something you’ll never
                                            want to go without again.</span></div>
                                    <div id="iyf6q9j"><b>Because once you notice how much firmer your neckline has become,
                                            you’ll only wish you’d discovered this sooner…
                                        </b></div>
                                    <div id="i6faxnq">Yet, if you’re not 100% amazed…
                                    </div>
                                    <div id="ih0ppu8">If family and friends AREN’T telling you
                                        how amazing you look…
                                    </div>
                                    <div id="i1jaz8h">Then you have the promise of a full,
                                        prompt refund at any point within your first 30 days of using Nectar Of The Gods.
                                    </div>
                                </div>
                            </div>
                            <div id="inj2pe9" className="fk-inner-column text-center">
                                <LazyLoadImage id="fkt-image-8d4-28f-b8e" alt="img71" src={skincareImg71} className="fk-image-defaults  desktop-view mx-auto" />
                            </div>
                        </div>
                        <div id="im2hrri" className="albert-font para">
                            <div id="ia47g3c">
                                <span  id="i3uwkvw">You won’t be asked why. <b  id="iveqksy"><u>Simply put, you ONLY pay if you’re totally convinced it works for you.</u></b></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="ipvo506" className="fk-row">
                    <div id="imtm3pb" className="fk-col col container_sec text-center">
                        <div id="irxrmzk">
                            <div id="isqp4y5" className="fk-headline trirong-font f40">Complete Your Order Today <br/>and Get <i><u>2 Incredible Gifts!</u></i> + FREE SHIPPING</div>
                            <div id="ie336mc">
                                <div id="iloq17v" className="fk-row">
                                    <div id="i84gl0z" className="fk-col col text-center">
                                        <LazyLoadImage id="fkt-image-d66-6bd-ae6" width="272" alt="img72" src={skincareImg72}  className="fk-image-defaults mx-auto" />
                                    </div>
                                    <div id="iiudv7v" className="fk-col col text-center">
                                        <div id="iojnsab">
                                            <div id="ize3qof" className="fk-headline trirong-font f28">
                                                <div id="ixz24n9">
                                                    <span  id="ir7tq47">Facial Massage For Relaxation &amp; Stress Relief</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="izg1kz7" className="albert-font para">
                                            <div id="iz1pn53"><span 
                                                    id="i61761r">Experience the transformative power of facial massage as a practice
                                                    that promotes deep relaxation, releases tension, and nurtures your overall well
                                                    being...
                                                </span></div>
                                            <div id="inhwcug-2"><span 
                                                    id="iy01stf">Enjoy reading through this 35 page eBook that delves deep into
                                                    relaxation techniques... you'll learn how to amplify your relaxation,
                                                    aromatherapy and meditation techniques, self-care daily practices, creating the
                                                    ideal tranquil environment in any situation and more...</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="i4lvl01">
                                <div id="ib52ijf" className="fk-row">
                                    <div id="ijms2of" className="fk-col col text-center">
                                        <LazyLoadImage alt="img73" src={skincareImg73}  id="fkt-image-9f7-6b2-aa7" className="fk-image-defaults mx-auto" />
                                    </div>
                                    <div id="i8v2j3a" className="fk-col col">
                                        <div id="ig3rnsn">
                                            <div id="i3rncwj" className="fk-headline trirong-font f28">
                                                <div id="iheff06">Aging Backwards: Simple Habits to Slow the Aging Process
                                                </div>
                                            </div>
                                        </div>
                                        <div id="i2nwsds" className="albert-font para">
                                            <div id="ipdonwb"><span 
                                                    id="iwqfecz">Embark on a journey to ageless beauty and vitality. Dive deep into
                                                    the science of aging, the magic of nutrition, and the rejuvenating power of
                                                    sleep.
                                                </span></div>
                                            <div id="itfue4u-2"><span 
                                                    id="itpfn96">Explore the profound mind-body connection, harness the wonders of
                                                    nature, and navigate modern technology for wellness. Your comprehensive guide to
                                                    radiant health and graceful aging awaits.</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="ivm5g4a" className="albert-font para">
                            <div id="iw8fmuw"><span  id="i3pbsde">Sounds
                                    good? Then <b>place your order of Nectar Of The Gods by
                                        clicking the button below.
                                    </b> You’ll be taken to an order page to review your order.</span>
                            </div>
                        </div>
                    </div>
                </div>



                {/* Order Section Begin  */}
                <div className="fk-row productCardSection">
                    <div id="if2nctw" className="fk-col col container_sec text-center">
                        <div  className="fk-inner-row">
                        </div>
                        <div  className="fk-inner-row">
                            <div id="i8qd2wh" className="fk-inner-column">
                                <div id="i3mwoam" className="bottle_card bottle-one">
                                    <div id="icrs70c" className="bottle-header">
                                        <div id="ilj5uik">
                                            <div id="i3j3esh" className="fk-headline trirong-font f30">
                                                <div id="ikrqyq9">SAMPLER PACKAGE</div>
                                            </div>
                                        </div>
                                        <hr className="fk-line horizontal-line-default proCardHr" />
                                        <div id="i6x5coh">
                                            <div id="inqvwjk" className="fk-headline trirong-font f24">
                                                <div id="i6icoyr">BUY 1 BOTTLE</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="iatrl2e" className="bottle-inner">
                                        <div id="iinmq2h" className="fk-headline barlow f80">
                                            <div id="inx877h-3">$69.00</div>
                                        </div>
                                        <div id="i1yp98t" className="albert-font f26">
                                            <div id="iedlami">PER JAR</div>
                                        </div>
                                        <div id="iim95yi" className="barlow save-price f30"><i id="iz6gmhr">SAVE $110.00</i></div>
                                        <LazyLoadImage id="fkt-image-68d-a80-829" alt="pro1" src={skincareProduct1} className="fk-image-defaults pro-img" />
                                        <div id="ism777b" className="barlow regular-txt f30">
                                            <div id="ir20079">REGULARLY <u>$159.00</u></div>
                                        </div>
                                        <div id="id6af3z" className="barlow f30">
                                            <div id="i67e6jv">TOTAL : $69.00</div>
                                        </div>
                                        <div id="i4v5kdd">
                                            <div id="ieq16fg" className="fk-row">
                                                <div id="i2xekr3" className="fk-col col text-center">
                                                    <LazyLoadImage src={truck_img} className="truck_img" />
                                                </div>
                                                <div id="i7yyej8" className="fk-col col text-center">
                                                    <div id="ix39362" className="albert-font">FREE SHIPPING </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="i9kvwat">
                                            <div id="ibuz7sf" className="fk-row">
                                                <div id="i3gi30p" className="fk-col col text-center">
                                                    <LazyLoadImage  src={circle_tick} alt="circle-tick-img" className="circle_tick" />
                                                </div>
                                                <div id="icl6gtg" className="fk-col col text-center">
                                                    <div id="iiuubfg" className="albert-font">30 DAYS MONEY BACK GUARANTEE</div>
                                                </div>
                                            </div>
                                        </div>
                                        <button data-id="fkt-button-2e2-c93-b67" title="B1 Add to cart - Bottles 1"  className="btn btn-primary btn-one-style btn-icon cart_btn" onClick={handleClick}>Add to Cart</button>
                                        {/* <Link to="/skincare6pLead" data-id="fkt-button-2e2-c93-b67" title="B1 Add to cart - Bottles 1"  className="btn btn-primary btn-one-style btn-icon cart_btn" >Add to Cart</Link> */}
                                        <LazyLoadImage src={card_img} alt="card-img" className="card_img" />
                                    </div>
                                </div>
                            </div>
                            <div id="ibkr7of" className="fk-inner-column text-center">
                                <div id="iile783" className="bottle_card bottle-two">
                                    <div id="i7h4w46" className="bottle-header">
                                        <div id="ie6uf1j">
                                            <div id="idlfkxj" className="fk-headline trirong-font f30">
                                                <div id="ikrqyq9">MOST POPULAR</div>
                                            </div>
                                        </div>
                                        <hr className="fk-line horizontal-line-default proCardHr" />
                                        <div id="is0nev8">
                                            <div id="iu0iuof" className="fk-headline trirong-font f24">
                                                <div id="i6icoyr">BUY 2 GET 1 FREE*</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="i5f1uph" className="bottle-inner">
                                        <div id="icymt5q" className="fk-headline barlow f80">
                                            <div id="inx877h-4">$33.66</div>
                                        </div>
                                        <div id="iktrwr3" className="albert-font f26">
                                            <div id="iqlyctw">PER JAR</div>
                                        </div>
                                        <div id="ie64cpt" className="barlow save-price f30">
                                            <i id="i4ahddk">SAVE $170.00</i>
                                        </div>
                                        <LazyLoadImage alt="pro2" src={skincareProduct2} id="fkt-image-8a2-19b-b33" className="fk-image-defaults pro-img" />
                                        <div id="i11bz4g" className="barlow regular-txt f30">
                                            <div id="i4xve0n">REGULARLY <u  id="i7f9v6j">$269.00 </u></div>
                                        </div>
                                        <div id="i4939u3" className="barlow f30">
                                            <div id="iltj5xk">TOTAL : $99.00</div>
                                        </div>
                                        <div id="i7c8d8e">
                                            <div id="ipqyu6m" className="fk-row">
                                                <div id="ik0raf6" className="fk-col col text-center">
                                                    <LazyLoadImage src={truck_img} className="truck_img" />
                                                </div>
                                                <div id="ixpop4l" className="fk-col col text-center">
                                                    <div id="i8nc55s" className="albert-font">FREE SHIPPING</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="itzcp6d">
                                            <div id="iv5qd2t" className="fk-row">
                                                <div id="ikikubd" className="fk-col col">
                                                    <LazyLoadImage  src={circle_tick} alt="circle-tick-img" className="circle_tick" />
                                                </div>
                                                <div id="iqc9aji" className="fk-col col">
                                                    <div id="i55pmjb" className="albert-font">30 DAYS MONEY BACK GUARANTEE</div>
                                                </div>
                                            </div>
                                        </div>
                                        <button title="B1 Add to cart - Bottles 2" type="button" className="btn btn-primary btn-one-style btn-icon cart_btn" onClick={handleClick}>Add to Cart</button>
                                        <LazyLoadImage src={card_img} alt="card-img" className="card_img" />
                                    </div>
                                </div>
                            </div>
                            <div id="iwuyanp-4" className="fk-inner-column text-center">
                                <div id="ius1cyp-2" className="bottle_card bottle-three">
                                    <div id="i12rtus-2" className="bottle-header">
                                        <div id="ibwe428-2">
                                            <div id="inxfmtl-2" className="fk-headline trirong-font f30">
                                                <div id="ikrqyq9">BEST SELLER</div>
                                            </div>
                                        </div>
                                        <hr className="fk-line horizontal-line-default proCardHr" />
                                        <div id="ihb48y2-2">
                                            <div id="iu3lwn7-2" className="fk-headline trirong-font f24">
                                                <div id="i6icoyr">BUY 3 GET 2 FREE*</div>
                                            </div>
                                        </div>
                                    </div>

                                
                                    <div id="iq4k4dd-2" className="bottle-inner">
                                        <div id="id7ru86-2" className="fk-headline barlow f80">
                                            <div id="inx877h-2">$29.60</div>
                                        </div>
                                        <div id="isvwb27-2" className="albert-font f26">
                                            <div id="iwqbh6x-2">PER JAR</div>
                                        </div>
                                        <div id="i1sfifa-2" className="barlow save-price f30"><i id="i183zkk-2">SAVE $300.00</i></div>
                                        <LazyLoadImage alt="pro3" src={skincareProduct3} id="fkt-image-2df-99e-b67-2" className="fk-image-defaults pro-img" />
                                        <div id="i9bo2xe-2" className="barlow regular-txt f30">
                                            <div id="i7285i8-2">REGULARLY <u  id="ious40g-2">$449.00 </u></div>
                                        </div>
                                        <div id="ie4tpfh-2" className="barlow f30">
                                            <div id="iqdy8rs-2">TOTAL : $149.00</div>
                                        </div>
                                        <div id="ih9c7dc-2">
                                            <div className="fk-row p-0">
                                                <div id="izcgf3k-2" className="fk-col col">
                                                    <LazyLoadImage src={truck_img} className="truck_img" />
                                                </div>
                                                <div id="i2qfibz-2" className="fk-col col text-center">
                                                    <div id="ighalsw-2" className="albert-font">
                                                        FREE SHIPPING
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="i40yn9d-2">
                                            <div id="ivpsj51-2" className="fk-row">
                                                <div id="i29p0uf-2" className="fk-col col">
                                                    <LazyLoadImage  src={circle_tick} alt="circle-tick-img" className="circle_tick" />
                                                </div>
                                                <div id="id0gq6t-2" className="fk-col col text-center">
                                                    <div id="ik1jx0o-2" className="albert-font">
                                                        30 DAYS MONEY BACK GUARANTEE
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button title="B1 Add to cart - Bottles 2" type="button" className="btn btn-primary btn-one-style btn-icon cart_btn" onClick={handleClick}>Add to Cart</button>
                                        <LazyLoadImage src={card_img} alt="card-img" className="card_img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Order Section End  */}


                <div id="ikk70i4" className="fk-row">
                    <div id="iwhgvgs" className="fk-col col container_sec text-center">
                        <div id="iyjqryp" className="fk-lazy">
                            <div id="i3utvbh">
                                <div id="ijp66d6" className="fk-headline trirong-font f40">You risk nothing by
                                    trying Nectar Of The Gods today,
                                    <br />yet you could look better than ever before!
                                </div>
                            </div>
                            <LazyLoadImage id="fkt-image-a2b-a92-92a" title="" target="_self" src={line} alt="line-img"  lass="fk-image-defaults" />
                            <div id="ihdwbc8" className="fk-row">
                                <div id="i8knh9x" className="fk-col col text-center">
                                    <div id="ireyo96" className="albert-font para">
                                        <div id="ityebtg">
                                            <LazyLoadImage src={skincareImg74} alt="img74" id="fkt-image-647-faa-91f" className="fk-image-defaults  mob-view mx-auto " />
                                            <span id="ihl8nt7">Of course, I’m convinced you’re going to see
                                                life-changing results in 30 days.</span></div>
                                        <div id="i4qczyk">In fact, I’m living proof of it!</div>
                                        <div id="iardp2b">Yet, the best results come from longer use… that’s when your results go from great to INCREDIBLE.
                                        </div>
                                        <div id="is0wk0r">See, after 3 months, people will begin to mistake you for a woman half your age.</div>
                                    </div>
                                </div>
                                <div id="ik6u14f" className="fk-col col">
                                    <LazyLoadImage id="fkt-image-5ff-eb3-805" alt="img74" src={skincareMoneyBackImg} className="fk-image-defaults  desktop-view mx-auto" />
                                </div>
                            </div>
                            <div id="im3ztkf" className="albert-font para">
                                <div id="ilttxby"><span  id="iaj17uq">Your
                                        friends will BEG you to share your jar of Nectar Of The Gods with them. In fact, they’ll pay you
                                        any price!</span></div>
                                <div id="iks2u6m">And after a few months, don’t be surprised
                                    if a younger man tries to chat you up. Of course, I’d never date a younger man, but it’s a nice
                                    compliment!
                                </div>
                                <div id="i3b837l">Bottomline is, the longer you use this cream
                                    the better your results and the more firmer and sculpted neckline you’ll have.
                                </div>
                                <div id="i8w2v5x">And we’ve made it as easy as possible to get
                                    the full, neck-lifting and firming effects from Nectar Of The Gods.
                                </div>
                                <div id="ilvjupf">When you order today, you have the choice of
                                    a 1-month, 3-months or 5-months supply.
                                </div>
                                <div id="ir0x1gs">Of course, you’re welcome to choose any
                                    option, though the biggest discount is by securing your 5-months supply.
                                </div>
                                <div id="iw562tz">That’s because I want you to experience the
                                    BEST results possible -- just like hundreds of American women are doing right now.
                                </div>
                                <div id="iz7n7zi">Once again, shipping is FREE.
                                </div>
                                <div id="il8ngfu">And you’re covered by a 30-days
                                    money-back-guarantee. So even at the end of a 5-months supply, you’re able to get a full refund
                                    if you’re not thrilled with your new youthful looking neckline.
                                </div>
                                <div id="izk4tkw">Click the “ORDER NOW” button below to be
                                    taken to a secure checkout page.
                                </div>
                                <div id="izpd8ru">You can have your radiant youthful and
                                    firmer neckline back and become the envy of your friends for the cost of an inexpensive lunch.
                                    So why not?
                                </div>
                                <div id="il3ikg8">I urge you: claim one of the 300 jars available today.
                                </div>
                                <div id="ino6bel">Click the “ORDER NOW” button below
                                    immediately.
                                </div>
                                <div id="ihr80ru">I can’t wait to hear your success story,,
                                </div>
                            </div>
                            <div className="text-start">
                                <LazyLoadImage id="fkt-image-6b9-2bd-b49" alt="img75" src={skincareImg75} className="fk-image-defaults" />
                            </div>
                        </div>
                    </div>
                </div>


                {/* Order Section Begin  */}
                <div className="fk-row productCardSection">
                    <div id="if2nctw" className="fk-col col container_sec text-center">
                        <div  className="fk-inner-row">
                        </div>
                        <div  className="fk-inner-row">
                            <div id="i8qd2wh" className="fk-inner-column">
                                <div id="i3mwoam" className="bottle_card bottle-one">
                                    <div id="icrs70c" className="bottle-header">
                                        <div id="ilj5uik">
                                            <div id="i3j3esh" className="fk-headline trirong-font f30">
                                                <div id="ikrqyq9">SAMPLER PACKAGE</div>
                                            </div>
                                        </div>
                                        <hr className="fk-line horizontal-line-default proCardHr" />
                                        <div id="i6x5coh">
                                            <div id="inqvwjk" className="fk-headline trirong-font f24">
                                                <div id="i6icoyr">BUY 1 BOTTLE</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="iatrl2e" className="bottle-inner">
                                        <div id="iinmq2h" className="fk-headline barlow f80">
                                            <div id="inx877h-3">$69</div>
                                        </div>
                                        <div id="i1yp98t" className="albert-font f26">
                                            <div id="iedlami">PER JAR</div>
                                        </div>
                                        <div id="iim95yi" className="barlow save-price f30"><i id="iz6gmhr">SAVE $110.00</i></div>
                                        <LazyLoadImage id="fkt-image-68d-a80-829" alt="pro1" src={skincareProduct1} className="fk-image-defaults pro-img" />
                                        <div id="ism777b" className="barlow regular-txt f30">
                                            <div id="ir20079">REGULARLY <u>$159.00</u></div>
                                        </div>
                                        <div id="id6af3z" className="barlow f30">
                                            <div id="i67e6jv">TOTAL : $69</div>
                                        </div>
                                        <div id="i4v5kdd">
                                            <div id="ieq16fg" className="fk-row">
                                                <div id="i2xekr3" className="fk-col col text-center">
                                                    <LazyLoadImage src={truck_img} className="truck_img" />
                                                </div>
                                                <div id="i7yyej8" className="fk-col col text-center">
                                                    <div id="ix39362" className="albert-font">FREE SHIPPING </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="i9kvwat">
                                            <div id="ibuz7sf" className="fk-row">
                                                <div id="i3gi30p" className="fk-col col text-center">
                                                    <LazyLoadImage  src={circle_tick} alt="circle-tick-img" className="circle_tick" />
                                                </div>
                                                <div id="icl6gtg" className="fk-col col text-center">
                                                    <div id="iiuubfg" className="albert-font">30 DAYS MONEY BACK GUARANTEE</div>
                                                </div>
                                            </div>
                                        </div>
                                        <button data-id="fkt-button-2e2-c93-b67" title="B1 Add to cart - Bottles 1"  className="btn btn-primary btn-one-style btn-icon cart_btn" onClick={handleClick}>Add to Cart</button>
                                        <LazyLoadImage src={card_img} alt="card-img" className="card_img" />
                                    </div>
                                </div>
                            </div>
                            <div id="ibkr7of" className="fk-inner-column text-center">
                                <div id="iile783" className="bottle_card bottle-two">
                                    <div id="i7h4w46" className="bottle-header">
                                        <div id="ie6uf1j">
                                            <div id="idlfkxj" className="fk-headline trirong-font f30">
                                                <div id="ikrqyq9">MOST POPULAR</div>
                                            </div>
                                        </div>
                                        <hr className="fk-line horizontal-line-default proCardHr" />
                                        <div id="is0nev8">
                                            <div id="iu0iuof" className="fk-headline trirong-font f24">
                                                <div id="i6icoyr">BUY 2 GET 1 FREE*</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="i5f1uph" className="bottle-inner">
                                        <div id="icymt5q" className="fk-headline barlow f80">
                                            <div id="inx877h-4">$33.66</div>
                                        </div>
                                        <div id="iktrwr3" className="albert-font f26">
                                            <div id="iqlyctw">PER JAR</div>
                                        </div>
                                        <div id="ie64cpt" className="barlow save-price f30">
                                            <i id="i4ahddk">SAVE $170.00</i>
                                        </div>
                                        <LazyLoadImage alt="pro2" src={skincareProduct2} id="fkt-image-8a2-19b-b33" className="fk-image-defaults pro-img" />
                                        <div id="i11bz4g" className="barlow regular-txt f30">
                                            <div id="i4xve0n">REGULARLY <u  id="i7f9v6j">$269.00 </u></div>
                                        </div>
                                        <div id="i4939u3" className="barlow f30">
                                            <div id="iltj5xk">TOTAL : $99.00</div>
                                        </div>
                                        <div id="i7c8d8e">
                                            <div id="ipqyu6m" className="fk-row">
                                                <div id="ik0raf6" className="fk-col col text-center">
                                                    <LazyLoadImage src={truck_img} className="truck_img" />
                                                </div>
                                                <div id="ixpop4l" className="fk-col col text-center">
                                                    <div id="i8nc55s" className="albert-font">FREE SHIPPING</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="itzcp6d">
                                            <div id="iv5qd2t" className="fk-row">
                                                <div id="ikikubd" className="fk-col col">
                                                    <LazyLoadImage  src={circle_tick} alt="circle-tick-img" className="circle_tick" />
                                                </div>
                                                <div id="iqc9aji" className="fk-col col">
                                                    <div id="i55pmjb" className="albert-font">30 DAYS MONEY BACK GUARANTEE</div>
                                                </div>
                                            </div>
                                        </div>
                                        <button title="B1 Add to cart - Bottles 2" type="button" className="btn btn-primary btn-one-style btn-icon cart_btn" onClick={handleClick}>Add to Cart</button>
                                        <LazyLoadImage src={card_img} alt="card-img" className="card_img" />
                                    </div>
                                </div>
                            </div>
                            <div id="iwuyanp-4" className="fk-inner-column text-center">
                                <div id="ius1cyp-2" className="bottle_card bottle-three">
                                    <div id="i12rtus-2" className="bottle-header">
                                        <div id="ibwe428-2">
                                            <div id="inxfmtl-2" className="fk-headline trirong-font f30">
                                                <div id="ikrqyq9">BEST SELLER</div>
                                            </div>
                                        </div>
                                        <hr className="fk-line horizontal-line-default proCardHr" />
                                        <div id="ihb48y2-2">
                                            <div id="iu3lwn7-2" className="fk-headline trirong-font f24">
                                                <div id="i6icoyr">BUY 3 GET 2 FREE*</div>
                                            </div>
                                        </div>
                                    </div>
                            
                                    <div id="iq4k4dd-2" className="bottle-inner">
                                        <div id="id7ru86-2" className="fk-headline barlow f80">
                                            <div id="inx877h-2">$29.60</div>
                                        </div>
                                        <div id="isvwb27-2" className="albert-font f26">
                                            <div id="iwqbh6x-2">PER JAR</div>
                                        </div>
                                        <div id="i1sfifa-2" className="barlow save-price f30"><i id="i183zkk-2">SAVE $300.00</i></div>
                                        <LazyLoadImage alt="pro3" src={skincareProduct3} id="fkt-image-2df-99e-b67-2" className="fk-image-defaults pro-img" />
                                        <div id="i9bo2xe-2" className="barlow regular-txt f30">
                                            <div id="i7285i8-2">REGULARLY <u  id="ious40g-2">$449.00 </u></div>
                                        </div>
                                        <div id="ie4tpfh-2" className="barlow f30">
                                            <div id="iqdy8rs-2">TOTAL : $149.00</div>
                                        </div>
                                        <div id="ih9c7dc-2">
                                            <div className="fk-row p-0">
                                                <div id="izcgf3k-2" className="fk-col col">
                                                    <LazyLoadImage src={truck_img} className="truck_img" />
                                                </div>
                                                <div id="i2qfibz-2" className="fk-col col text-center">
                                                    <div id="ighalsw-2" className="albert-font">
                                                        FREE SHIPPING
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="i40yn9d-2">
                                            <div id="ivpsj51-2" className="fk-row">
                                                <div id="i29p0uf-2" className="fk-col col">
                                                    <LazyLoadImage  src={circle_tick} alt="circle-tick-img" className="circle_tick" />
                                                </div>
                                                <div id="id0gq6t-2" className="fk-col col text-center">
                                                    <div id="ik1jx0o-2" className="albert-font">
                                                        30 DAYS MONEY BACK GUARANTEE
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button title="B1 Add to cart - Bottles 2" type="button" className="btn btn-primary btn-one-style btn-icon cart_btn" onClick={handleClick}>Add to Cart</button>
                                        <LazyLoadImage src={card_img} alt="card-img" className="card_img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Order Section End  */}


                <div id="i0lfirg" className="fk-row skincare6pAcc">
                    <div id="ijlj6jy" className="fk-col col container_sec text-center">
                        <div id="ilnaw9f">
                            <div id="i38ji4j" className="fk-headline trirong-font f40">Frequently Asked Question</div>
                        </div>

                        <div className='accordianWrapper'>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What’s “Platysma Renew” again?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>It’s the breakthrough led by the “super trio” of ingredients that turned a groundbreaking Harvard discovery into the most effective neck firming solution in the world.
                                        First, it helps to “unclog” and deliver active ingredients into the thick skin of the neck with an advanced absorption process that rejuvenates and tightens the neck's skin to give it a more sculpted and defined look.
                                        </p>
                                        <p>And second, the “singular” Platysma muscle that sits behind the skin of the neck, over time can “grow apart” from the skin because the Septa (small connecting bands that attaches skin to muscle) weakens over time, and along with less estrogen as women age, can result in a “fatty” (turkey neck) look on the neck. The primary ingredients in Platysma Renew help to firm and tighten that skin.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>How long will it take to get results?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>After just a few minutes you’ll notice a subtle tautness in your neck and jawline. And then fine lines will begin to fade away. And your skin will feel soft, yet firm.				  </p>
                                        <p>Of course, using Nectar Of The Gods every single day is the best way to achieve your defined, firm and contoured neckline look.
                                        </p>
                                        <p>And every time they see you, your friends and family will be begging for your secret…</p>
                                        <p>And you won’t go a week without being mistaken for a woman half your age!</p>
                                        <p>This is why having just one jar of Nectar Of The Gods is good, but having a 3-months supply is even BETTER…</p>
                                        <p>The team at Nectar Of The Gods knows how much you’re going to love your new, defined, firm, and contoured look…</p>
                                        <p>Which is why they have put together a special bundle package for Nectar Of The Gods customers only…</p>
                                        <p>That’ll help you SAVE BIG when you stock up today…</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Will it work for me?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>YES! Nectar Of The Gods contains ingredients scientifically-proven to work on anyone. Whether you’re 30 or 70. No matter your skin type or your ethnicity.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>What if it doesn’t work for me, is there a refund promise?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>I understand you might be skeptical. I mean, the results I’ve shown you today are truly incredible. But I promise you, Nectar Of The Gods will work for YOU!<br />
                                        Yet, I understand you’ll only be convinced once you’ve tried it and seen the difference for yourself. So I want you to feel totally comfortable that you’re not risking a penny when you order today.</p>
                                        <p>That’s why we have what we think is the “Best Guarantee in the World”, which is a full 30-days money-back-guarantee.</p>
                                        <p>It’s simple: you can try Nectar Of The Gods and if you're not totally thrilled, simply call or email the team and they’ll return every penny you pay today.</p>
                                        <p>You don’t need to provide a reason why, you’ll just get an easy, hassle-free refund of the full price you pay today.</p>
                                        
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>How can I order my jar of Nectar Of The Gods?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>It’s easy! Just click the “ORDER NOW” button below.<br />
                                            You’ll be taken to the secure order page. It takes just two minutes to complete.</p>
                                        <p>Once you’re finished, the Nectar Of The Gods team will get your jar into the mail as soon as possible. You’ll have it in your hands within 3 - 5 days.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>            
                        </div>
                    </div>
                </div>

                {/* Advertorial  */}
                <div className='advertorailDiv text-center my-3 px-4 d-flex justify-content-center'>
                    <h4><b className='text-lg'>Advertorial</b></h4>
                </div> 
            <div className="skincare6pScrollTop" onClick={handleClickRef}><img src={upArrow}></img></div>
            <div className='skin6pFooer__callToAction'>
                <div className='callToAction__inner'>
                    <div className='callToAction__img'>
                        <img src={checkProduct1} alt='' className='fCallToAct__img' />  
                    </div>                    
                    <div className='callToAction__const'>
                        <Link to="javascript:void(0)" id="go-next" onClick={handleClick}>
                            <img alt="" className="btn pulse" src={submitBtn} />
                        </Link>
                        <div className='nortonSecure'>
                            <img src={nortonSecure} alt='' className='' />
                        </div>
                    </div>                          
                </div> 
            </div> 
            </div>
            {/* Footer  */}
            <Copyright/>

            
        </>
    )
}


export default Skincarebp;