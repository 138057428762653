import React, { createContext, useContext,useReducer } from 'react'

import { cartReducer, sidebarReducer, navbarReducer } from './Reducer';
const Cart = createContext();
const Sidebar = createContext();
const Navbar = createContext();

function Context({ children }) {

    const [state, dispatch] = useReducer(cartReducer, {
        cart: [],
    })

    const [sidebarState, sidebarDispatch] = useReducer(sidebarReducer, {
        sidebarOpen: false,
    });

    const [navbarState, navbarDispatch] = useReducer(navbarReducer, {
        activeTab: false,
    });

    return (
          <Cart.Provider value={{ state, dispatch }}>
          <Sidebar.Provider value={{ sidebarState, sidebarDispatch }}>
              <Navbar.Provider value={{ navbarState, navbarDispatch }}>
                  {children}
              </Navbar.Provider>
          </Sidebar.Provider>

      </Cart.Provider>
    )
}

export default Context

export const CartState = () => {
    return useContext(Cart)
}

export const useSidebar = () => {
    return useContext(Sidebar)
}

export const useNavbar = () => {
    return useContext(Navbar)
}