import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';



import betterHealthImage from '../../assests/frontend/assets/images/betterHealthImage.jpg';
import brainPuzzle from '../../assests/frontend/assets/images/NoPicturePuzzle_Puzzles-300x188.webp';
import celebrityDiteBook from '../../assests/frontend/assets/images/celebrityDietCookBook.jpg';
import balancedDietCocept from '../../assests/frontend/assets/images/balanced-diet-concept-300x225.jpg';
import exerciseNewsletters from '../../assests/frontend/assets/images/workout-equipment-for-professional-bodybuilder-300x200.jpg';
import lifeStyleNewsletters from '../../assests/frontend/assets/images/happy-mature-people-couple-exercising-300x200.jpg';
import lookYounger from '../../assests/frontend/assets/images/lookYoungerFeelYoungerBook.jpg';
import clubCover from '../../assests/frontend/assets/images/10_wholesale_club_cover_final.png';
import {BASE_URL} from '../Config';
import useStore from '../../store';
import Copyright from "../Copyright";
import AccessDeniedPage from "../AccessDeniedPage";
import Sidebar2 from "../../layouts/frontend/Sidebar2";


function BonusPage2() {
    const [bonus, setBonus] = useState([]);
    let imgURL = BASE_URL+"whc-custom/storage/app/public/";
    const isLogin = useStore((state) => state.isLogin);
    const isMember = useStore((state) => state.isMember);
    const navigate = useNavigate();
    const [is_membership, setIsMembership] = useState('');
    let userEmail = useStore((state) => state.userDetails.email);

    function findBonusList() {
        axios.get(`api/bonus_list_frontend`).then(res => {

            let BonusItems = Object.entries(res.data.data).map(([key, value]) => ({ key, value }));
            setBonus(BonusItems);

        }).catch(err => {
            //console.log(err.response)
            Swal.fire({
                title: 'Warning',
                html: err,
                icon: 'warning'
            })
        });
    }

    useEffect(() => {
        findBonusList();
        axios.get(`api/check_user_membership/${userEmail}`).then(result => {
            console.log(result.data);
            if(parseInt(result.data) === 1){
                setIsMembership(1);
            } else {
                setIsMembership(0);
            }
        });
    }, []);

    return (
        <>
            {((isLogin === 1) && (isMember === 1)) ?
                is_membership === 1 ?
                    <section className="bonusPage">
                        <div className='container-fluid'>
                            {/* Row Begins  */}
                            <div className="row newShopList_row">
                                <Sidebar2/>
                                {/* Left Col Begin  */}
                                <div className="col-md-8 col_right">
                                    <div>
                                        {/* <div className="breadcumNav-div text-center">
                                            <Link to="/" className="breadcrumbs_nav_link">Home</Link> <span>&#062;</span> <Link to="/bonus"  className="breadcrumbs_nav_link last_elt">BONUSES</Link> 
                                        </div> */}
                                        {/* Row : Begin  */}
                                        <div className="row prodList-row bonusProdLists">
                                            {/* Col : Begin  */}
                                            {bonus ? bonus.map((item, index) => (
                                                (parseInt(item.key) === 1) ?
                                                    item.value.items.map((subitems) => (
                                                        <div className="col-lg-4 col-md-6 prodList-col">
                                                            <div className="product_display">
                                                                <div className="product_img">
                                                                    <img src={imgURL + subitems.image} className="img-fluid" alt="" />
                                                                </div>
                                                                <div className="content-block">
                                                                    <div className="title-block-bonus text-center">
                                                                        <h6 className="text-center">{subitems.name}</h6>
                                                                    </div>
                                                                    <a href={imgURL + subitems.doc} target='_blank' rel='noopener noreferrer' className="prod_btn">Download PDF</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    : (
                                                        <div className="col-lg-4 col-md-6 prodList-col">
                                                            <div className="product_display">
                                                                <div className="product_img">
                                                                    <img src={imgURL + item.value.category_image} className="img-fluid" alt="" />
                                                                </div>
                                                                <div className="content-block">
                                                                    <div className="title-block-bonus text-center">
                                                                        <h6 className="text-center">{item.value.category_name}</h6>
                                                                    </div>
                                                                    <Link to={'/bonus/' + item.key} rel='noopener noreferrer' className="prod_btn">{item.value.category_name}</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                            ) : null
                                            }

                                            {/* Col End  */}
                                        </div>
                                        {/* Row End  */}
                                    </div>
                                </div>
                                {/* Left Col End  */}
                            </div>
                            {/* Row Ends  */}
                        </div>
                    </section>
                :  <AccessDeniedPage/>
            : ((isLogin === 1) && (isMember === 0)) ?  <AccessDeniedPage/> : ''}
        </>
    )
}


export default BonusPage2