import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';
import CustomErrorMsg from "../frontend/CustomErrorMsg";
import '../../assests/Lander/assets/css/dickPillHome.css';
import primary from '../../assests/Lander/assets/images/d-pill/home/primary.png';
import fromTop from '../../assests/Lander/assets/images/d-pill/home/frm-top.png';
import rushBtn from '../../assests/Lander/assets/images/d-pill/home/submit-btn.png';
import sliderimg3 from '../../assests/Lander/assets/images/d-pill/home/sliderimg3.png';
import sliderimg4 from '../../assests/Lander/assets/images/d-pill/home/sliderimg4.png';
import star from '../../assests/Lander/assets/images/d-pill/home/star.png';
import lock from '../../assests/Lander/assets/images/d-pill/home/lock.png';
import frmBtm from '../../assests/Lander/assets/images/d-pill/home/frm-btm.png';
import Copyright from '../Copyright';
import { CountryList } from '../Config';

//css
import style from '../../assests/Lander/assets/css/GethardppDesktop.module.css';

function Get1hardppDesktop(){
    const ref = useRef(null);
    const [states, setStates] = useState([]);
    const [firstNameEntered, setFirstNameEntered] = useState(false);
    const [lastNameEntered, setLastNameEntered] = useState(false);
    const [addressEntered, setAddressEntered] = useState(false);
    const [cityEntered, setCityEntered] = useState(false);
    const [countryEntered, setCountryEntered] = useState(false);
    const [stateEntered, setStateEntered] = useState(false);
    const [zipEntered, setZipEntered] = useState(false);
    const [phoneEntered,setPhoneEntered] = useState(false);
    const [emailEntered, setEmailEntered] = useState(false);
    const [loadershow, setloadershow] = useState(false);
    const location = useLocation();
    let params = location.search;

    const DisplayingErrorMessagesSchema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        shippingAddress1: Yup.string().required('Address is required'),
        shippingZip: Yup.string().when('country', {
            is: 'US',
            then: Yup.string()
              .required('Zip code is required')
              .matches(/^\d{5}$/, 'Zip code must be exactly 5 digits number'),
            otherwise: Yup.string().when('country', {
              is: 'CA',
              then: Yup.string()
                .required('Zip code is required')
                .matches(/^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/, 'Zip code must contain only letters and numbers')
                .min(6, 'Zip code should contain at least 6 characters')
                .max(6, 'Zip code should contain at most 6 characters'),
            }),
        }),
        shippingCity: Yup.string().required('City is required'),
        shippingState: Yup.string().required('State is required'),
        email: Yup.string().email("Email is required").max(255).required("Please enter a valid email"),
        phone: Yup.string().required('Phone no is required')
            .matches(/^[0-9]+$/, "Please enter only number!")
            .min(10, 'Please enter a valid contact number!')
            .max(10, 'Please enter a valid contact number!'),
        country: Yup.string().required('Country is required')
    });

    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(()=>{
        handleCountryChange('239');
    },[]);
    
    const handleCountryChange = (country_id) => {
       
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    function confirmSubmit(values) {
        let campaign_id = '59';
        setloadershow(true);
        const formdata = new FormData();
        formdata.append('firstName',values.firstName);
        formdata.append('lastName',values.lastName);
        formdata.append('email',values.email);
        formdata.append('phone',values.phone);
        formdata.append('tag1','male-prospect');
        formdata.append('shippingAddress',values.shippingAddress1);
        formdata.append('shippingCity',values.shippingCity);
        formdata.append('shippingCountry',values.country);
        formdata.append('shippingState',values.shippingState);
        formdata.append('shippingZip',values.shippingZip);
        formdata.append('campaign_id',campaign_id);

        let affid = new URLSearchParams(location.search).get('affid');
        let sid = new URLSearchParams(location.search).get('sid');
        let s1 = new URLSearchParams(location.search).get('s1');
        let s2 = new URLSearchParams(location.search).get('s2');
        let s3 = new URLSearchParams(location.search).get('s3');
        let click_id = new URLSearchParams(location.search).get('click_id');
        let notes = new URLSearchParams(location.search).get('notes');

        localStorage.setItem('affid',affid);
        localStorage.setItem('sid',sid);
        localStorage.setItem('s1',s1);
        localStorage.setItem('s2',s2);
        localStorage.setItem('s3',s3);
        localStorage.setItem('click_id',click_id);
        localStorage.setItem('notes',notes);

        formdata.append('affid', affid);
        formdata.append('sid', sid);
        formdata.append('s1', s1);
        formdata.append('s2', s2);
        formdata.append('s3', s3);
        formdata.append('click_id', click_id);
        formdata.append('notes', notes);
        
        //console.log(Object.fromEntries(formdata));

        axios.post(`api/maropost_tag_add`, formdata).then(res => {
            if (res.data.status === true) {
                axios.post(`api/create-prospect`, formdata).then(result => {
                    if(result.data.status === true) {
                        localStorage.setItem('prospectId',result.data.prospectId);
                        window.location.href = '/' + localStorage.getItem('c_s')+params;
                    }
                })
            }
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        });
    }

    return(
        <>
            <head>
                <meta name="viewport" content="width=1350,user-scalable=0" />
                <meta name="robots" content="noindex, nofollow" />
            </head>
            <div className={loadershow ? 'loading' : 'd-none'}></div>
            <main className={style.dPillHome}>
                {/* 'Top Bar ' */}
                <div className={style.tophdr} id="header">
                    <div className={style.contentWrap}>
                        <p className={style.hdrtxt}><span>WARNING:</span> Due to extremely high media demand, there is limited supply of Alpha Drive Rx Gummies in stock as of today</p>
                        <p></p>
                    </div>
                </div>

                {/* Section 1  */}
                <div id="section1" className={style.section1}>
                    <div className={style.sec1inner}>
                        <div className={style.contentWrap}>
                            <div className={`${style.pro_pack_5_3} align-self-center`}>
                                <img src={primary} className={`${style.product_l} img-fluid`} alt="" />
                                <img src={primary} className={`${style.product_r} img-fluid`} alt="" />
                                <img src={primary} className={`${style.product_c} img-fluid`} alt="" />
                            </div>
                            <div className={style.lft_content}>
                                <i className={`${style.sprite} ${style.sprite_logo} ${style.s1_logo}`}></i> <i className={`${style.sprite} ${style.sprite_top_tagline} ${style.s1_tagline}`}></i>
                                <p className={style.s1hding}>Medical Strength Male Enhancement</p>
                                <p className={style.s1hding2}>Get Maximum
                                <br /> <span>Sexual Benefits</span> 
                                </p>
                                <div className={style.doctor}> <i className={`${style.sprite1} ${style.sprite_s1no_pres} ${style.s1no_pres}`}></i> </div>
                                <i className={`${style.sprite} ${style.sprite_arrow} ${style.s1_arrow}`}></i>
                                <i className={`${style.sprite} ${style.sprite_us_seal} ${style.s1seal}`}></i>
                                <ul className={style.s1list}>
                                <li><span>Bigger &amp; Long-Lasting Erections </span>
                                    <br /> Maximum pleasure &amp; intensified orgasms
                                </li>
                                <li><span>Surge In Sex Drive &amp; Energy </span>
                                    <br /> Ramps up stamina &amp; staying power
                                </li>
                                <li><span>Increased Sexual Confidence </span>
                                    <br /> Experience vitality &amp; peak performance
                                </li>
                                </ul>
                                <i className={`${style.sprite1} ${style.sprite_as_seen} ${style.as_seen}`}></i> 
                            </div>
                            <div className={style.rgt_frm} ref={ref}>
                                {/* <!-- start form --> */}
                                <div className={style.frm_top}><img width="307" height="134" alt="" src={fromTop} /> </div>
                                <div className={style.form_position}>
                                <Formik
                                    initialValues={{
                                        firstName: '',
                                        lastName: '',
                                        shippingAddress1: '',
                                        shippingZip: '',
                                        shippingCity: '',
                                        shippingState: '',
                                        email: '',
                                        phone: '',
                                        country:'US'
                                    }}
                                    validationSchema={DisplayingErrorMessagesSchema}
                                    onSubmit={values => {
                                        localStorage.setItem('firstName', values.firstName);
                                        localStorage.setItem('lastName', values.lastName);
                                        localStorage.setItem('shippingAddress1', values.shippingAddress1);
                                        localStorage.setItem('shippingZip', values.shippingZip);
                                        localStorage.setItem('shippingState', values.shippingState);
                                        localStorage.setItem('shippingCity', values.shippingCity);
                                        localStorage.setItem('phone', values.phone);
                                        localStorage.setItem('email', values.email);
                                        localStorage.setItem('country', values.country);
                                        confirmSubmit(values);
                                    }}
                                    >
                                    {({ errors, touched, setFieldValue }) => (
                                        <Form className={`${style.dPillForm} ${style.dPillForm_error2}`}>
                                            <div className={style.form_outter}>
                                                <div className={style.form_grp}>
                                                    <label className={style.form_label}>First Name:</label>
                                                    <Field 
                                                        name="firstName" 
                                                        className={style.form_control}
                                                        placeholder="First Name*"
                                                    />
                                                    {touched.firstName && !errors.firstName && <div className={style.accept_icon}/>}
                                                </div>
                                                <CustomErrorMsg className={style.errMsg2} name="firstName" />
                                            </div>
                                            <div className={style.form_outter}>
                                                <div className={style.form_grp}>
                                                    <label className={style.form_label}>Last Name:</label>
                                                    <Field 
                                                        name="lastName" 
                                                        className={style.form_control} 
                                                        placeholder="Last Name*"
                                                    />
                                                    {touched.lastName && !errors.lastName && <div className={style.accept_icon}></div>}
                                                </div>
                                                <CustomErrorMsg className={style.errMsg2} name="lastName" />
                                            </div>
                                            <div className={style.form_outter}>
                                                <div className={style.form_grp}>
                                                    <label className={style.form_label}>Address:</label>
                                                    <Field 
                                                        name="shippingAddress1" 
                                                        className={style.form_control} 
                                                        placeholder="Address*"
                                                    />
                                                    {touched.shippingAddress1 && !errors.shippingAddress1 && <div className={style.accept_icon}></div>}
                                                </div>
                                                <CustomErrorMsg className={style.errMsg2} name="shippingAddress1" />
                                            </div>
                                            <div className={style.form_outter}>
                                                <div className={style.form_grp}>
                                                    <label className={style.form_label}>City:</label>
                                                    <Field 
                                                        name="shippingCity" 
                                                        className={style.form_control} 
                                                        placeholder="City*"
                                                    />
                                                    {touched.shippingCity && !errors.shippingCity && <div className={style.accept_icon}/>}
                                                </div>
                                                <CustomErrorMsg className={style.errMsg2} name="shippingCity" />
                                            </div>
                                            <div className={style.form_outter}>
                                                <div className={style.form_grp}>
                                                    <label className={style.form_label}>Country:</label>
                                                    <Field
                                                        name="country"
                                                        as="select"
                                                        placeholder="Select an option*"
                                                        className={style.form_control}
                                                        onChange={(event) => {
                                                            setFieldValue('country', event.target.value);
                                                            setFieldValue("shippingState", '');
                                                            handleCountryChange(event.target[event.target.selectedIndex].getAttribute('data-id')); 
                                                            // Call the custom function here if needed
                                                            }}
                                                        >
                                                        {CountryList.map((country) => (
                                                        <option key={country.id} data-id={country.id} value={country.short_name}>
                                                         {country.country}
                                                        </option>
                                                         ))}
                                                    </Field>
                                                </div>
                                                {/* <CustomErrorMsg className={style.errMsg2} name="country" /> */}
                                            </div>
                                            <div className={style.form_outter}>
                                                <div className={style.form_grp}>
                                                    <label className={style.form_label}>State:</label>
                                                    <Field
                                                        name="shippingState"
                                                        as="select"
                                                        placeholder="Select an option*"
                                                        className={style.form_control}
                                                        
                                                    >
                                                        <option value="" >Select state</option>
                                                        {states.map((state) => (
                                                            <option key={state.id} value={state.abbreviation}>
                                                                {state.state}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                </div>
                                                <CustomErrorMsg className={style.errMsg2} name="shippingState" />
                                            </div>
                                            <div className={style.form_outter}>
                                                <div className={style.form_grp}>
                                                    <label className={style.form_label}>Zip Code:</label>
                                                    <Field 
                                                        name="shippingZip" 
                                                        className={style.form_control} 
                                                        placeholder="Zip Code*"
                                                        maxLength="6"
                                                    />
                                                    {touched.shippingZip && !errors.shippingZip && <div className={style.accept_icon}></div>}
                                                </div>
                                                <CustomErrorMsg className={style.errMsg2} name="shippingZip" />
                                            </div>
                                            <div className={style.form_outter}>
                                                <div className={style.form_grp}>
                                                    <label className={style.form_label}>Phone:</label>
                                                    <Field 
                                                        type="tel" 
                                                        name="phone" 
                                                        className={style.form_control} 
                                                        placeholder="Phone*"  
                                                    />
                                                    {touched.phone && !errors.phone && <div className={style.accept_icon}/>}
                                                </div>
                                                <CustomErrorMsg className={style.errMsg2} name="phone" />
                                            </div>
                                            <div className={style.form_outter}>
                                                <div className={style.form_grp}>
                                                    <label className={style.form_label}>Email:</label>
                                                    <Field 
                                                        type="email" 
                                                        name="email" 
                                                        className={style.form_control} 
                                                        placeholder="Email*"
                                                    />
                                                    {touched.email && !errors.email && <div className={style.accept_icon}/>}
                                                </div>
                                                <CustomErrorMsg className={style.errMsg2} name="email" />
                                            </div>
                                            <div className={style.frm_btm}>
                                                <img width="199" height="10" className={style.lock} alt="" src={lock} />
                                                <div id="submit-link">
                                                    <button type="submit">
                                                        <div className={style.rush_order} id="rush_order_btn1"> <span id="process">Rush My Order</span> </div>
                                                    </button>
                                                </div>
                                                <img width="164" height="39" className={style.security} alt="" src={frmBtm} /> 
                                            </div>
                                        </Form>
                                    )}
                                    </Formik>
                                </div>
                                <div className={style.clearall}></div>
                                {/* <!-- end form --> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Section 1 end  */}
                {/* Section 2 Begin  */}
                <div id="section2" className={style.section2}>
                    <div className={style.sec2inner}>
                        <div className={style.contentWrap}>
                            <p className={style.sec2hding}><span>The Sexual Health Divide </span>
                                <br /> Are You Suffering From The Following Symptoms
                            </p>
                            <p className={style.s2txt}>Leading surveys on sexual health and satisfaction levels among
                                <br /> men have revealed the following:
                            </p>
                            <div className={style.box_area}>
                                <div className={style.s2box1}>
                                <span className="text-center"> <i className={`${style.sprite} ${style.sprite_s2one}`}></i> </span>
                                <p className={style.s2box_txt}>Say sexual health impacts on overall life satisfaction</p>
                                </div>
                                <div className={style.s2box2}>
                                <span className="text-center"> <i className={`${style.sprite} ${style.sprite_s2two}`}></i> </span>
                                <p className={style.s2box_txt}>Of men suffer from <br />  Small Penis
                                    <br /> Syndrome
                                </p>
                                </div>
                                <div className={style.s2box3}>
                                <span className="text-center"> <i className={`${style.sprite} ${style.sprite_s2three}`}></i> </span>
                                <p className={style.s2box_txt}>Believe
                                    <br /> embarrassment is a major sexual barrier
                                </p>
                                </div>
                                <div className={style.s2box4}>
                                <span className="text-center"> <i className={`${style.sprite} ${style.sprite_s2four}`}></i> </span>
                                <p className={style.s2box_txt}>Avoid sex altogether because of lack of sexual confidence</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Section 2 End  */}
                {/* Section 3 Begin  */}
                <div id="section3" className={style.section3}>
                    <div className={style.sec3inner}>
                        <div className={style.contentWrap}>
                            <div className={`${style.pro_pack_5_3} align-self-center`}>
                                <img src={primary} className={`${style.product_l} img-fluid`} alt="" />
                                <img src={primary} className={`${style.product_r} img-fluid`} alt="" />
                                <img src={primary} className={`${style.product_c} img-fuild`} alt="" />
                            </div>
                            <i className={`${style.sprite} ${style.sprite_us_seal} ${style.satisfaction_seal}`}></i>
                            <p className={style.s3hding}><span>Introducing Alpha Drive Rx</span>
                                <br /> Male Enhancement System
                            </p>
                            <p className={style.sec3txt}>
                                <b>Made with a blend of clinical strength ingredients, <span>Alpha Drive Rx is a male enhancement system</span> that has been formulated to restore your sexual youth and performance and help you experience an intense, blissful &amp; powerful sex life. </b><br />
                                <strong className='pt-3 d-inline-block'>Alpha Drive Rx's</strong> dual action formula not only gives you an instant surge in sexual power &amp; performance - but also treats the root cause of sexual dysfunctions, ensuring that you are able to satisfy your partner, consistently! Made with herbal extracts and active botanicals, <b>Alpha Drive Rx</b> is completely safe to use and is free from any harmful side effects. 
                                <br />
                            </p>
                            <p className={style.sec3txt2}>Triple IntenSity
                                <br /> <span>Male Enhancement </span>
                                <br /> For maximum Results
                            </p>
                            <p className={style.sec3txt3}>The pro-sexual nutrient matrix in Alpha Drive Rx Gummies helps boost the 3S's of Sex - Size, Stamina &amp; Satisfaction, helping you peak perform and pleasure your partner just like you did in your 20's!</p>
                            <p className={style.sec3txt4}><b>Alpha Drive Rx Gummies™</b> is proudly made at a certified manufacturing facility to meet statutory industry standards. You can enjoy the benefits of&nbsp;Alpha Drive Rx Gummies&nbsp;with confidence. </p>
                            <div className={style.btn_strip}>
                                <p className={style.btn_txt}><span>ORDER YOUR Alpha Drive Rx TODAY!</span>
                                <br /> Experience Sexual Power, Pleasure &amp; Performance
                                </p>
                                <Link to="javascript:void(0)" onClick={handleClick}> <img src={rushBtn} width="275" height="70" alt="" className={`${style.sec3btn} ${style.pulse}`} /> </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Section 3 End  */}
                {/* Section 4 Begin  */}
                <div id="section4" className={style.section4}>
                    <div className={style.contentWrap}>
                        <p className={style.s4hding}><span>THE SCIENCE BEHIND </span>
                            <br /> BETTER, LONGER &amp; MORE INTENSE SEX!
                        </p>
                        <i className={`${style.sprite1} ${style.sprite_s4seal} ${style.s4seal}`}></i>
                        <p className={style.s4txt}> <span>The blood flow to the penis is responsible for erections while the <br />  holding capacity of the penis chambers is what influences sexual stamina  <br />  and staying power. <b>Alpha Drive Rx Gummies</b> helps boost both to help you and your partner enjoy intense orgasms and complete satisfaction.</span> <br />
                            
                            <br /> <strong>Alpha Drive Rx Gummies</strong> pro-sexual nutrient blend is quickly absorbed into the bloodstream to stimulate Nitric Oxide production - this in turn boosts the flow of blood to the penile chambers helping you enjoy harder and stronger erections. On the other hand it also expands the penis chambers allowing it to hold more blood in order to drastically increase sexual stamina, strength and staying power.
                        </p>
                        <p className={style.s4txt2}><b>Alpha Drive Rx Gummies</b> utilizes a breakthrough rapid absorption and extended release technology. Rapid absorption of the ingredients into the bloodstream aid in delivering instant surge of sexual power while the extended release technology delivers sustained results that help you enjoy on command erections and stamina to last all night long.</p>
                        <p></p>
                        <i className={`${style.sprite1} ${style.sprite_s4img} ${style.s4img}`}></i>
                        <p className={style.s4txt3}><span>Alpha Drive Rx™</span> works by triggering the two mechanisms known
                            <br /> to increase penis size, function and performance. These are: 
                        </p>
                        <p className={style.s4txt4}>An increase in "free" testosterone and</p>
                        <p className={style.s4txt5}>Nitric Oxide production to the penis.</p>
                        <p className={style.s4txt6}><span>Alpha Drive Rx is the only product that does both.</span> Alpha Drive Rx contains the most
                            <br /> potent nitric oxide stimulators which maximize the delivery of the active
                            <br /> ingredients to your penile tissue giving you firmer, longer erections.
                        </p>
                        <div className={style.s4btn_strip}>
                            <p className={style.btn_txt}><span>ORDER YOUR Alpha Drive Rx TODAY!</span>
                                <br /> Experience Sexual Power, Pleasure &amp; Performance
                            </p>
                            <Link to="javascript:void(0)" onClick={handleClick}> <img src={rushBtn} width="275" height="70" alt="" className={`${style.sec3btn} ${style.pulse}`} /> </Link>
                        </div>
                    </div>
                </div>
                {/* Section 5  */}
                <div id="section5" className={style.section5}>
                    <div className={style.sec5inner}>
                        <div className={style.contentWrap}>
                            <p className={style.s5hding}><span>The Benefits of Alpha Drive Rx</span>
                                <br /> ADVANCED MALE ENHANCEMENT!
                            </p>
                            <p className={style.sec5txt}><span>Alpha Drive Rx Male Enhancement System</span> offers multiple sexual health benefits to help you
                                <br /> enjoy hard erections, increased stamina and peak performance.
                            </p>
                            <div className={style.s5benefits}>
                                <div className={style.s5box1}>
                                <p className={style.bnft_txt}>IMPROVED LIBIDO &amp; SEX DRIVE</p>
                                <p className={style.bnft_txt2}>Get ready to
                                    <br /> experience a torrent of desire &amp; passion with <b>Alpha Drive Rx</b>, which replenishes sexual energy stores across the body like never before.
                                </p>
                                </div>
                                <div className={style.s5box2}>
                                <p className={style.bnft_txt}>INCREASED STAYING
                                    <br /> POWER
                                </p>
                                <p className={style.bnft_txt2}>Bid goodbye to pre-
                                    <br /> mature ejaculations! <b>Alpha Drive Rx</b> floods your penile chambers with a gush of blood letting you last 5X more than usual and helping you last all night long!
                                </p>
                                </div>
                                <div className={style.s5box5}>
                                <p className={style.bnft_txt}>BIGGER, HARDER &amp; LONGER ERECTIONS</p>
                                <p className={style.bnft_txt2}><b>Alpha Drive Rx</b> lets you achieve rock hard erections on command helping you and your partner enjoy insane sexual sessions, whenever you desire.</p>
                                </div>
                                <div className={style.s5box4}>
                                <p className={style.bnft_txt}>IMPROVED SEXUAL CONFIDENCE</p>
                                <p className={style.bnft_txt2}>Equipped with youthful sexual powers &amp; energy, you are sure to experience sexual confidence like never before, gives you greater success with the most desirable women!</p>
                                </div>
                                <div className={style.s5box3}>
                                <p className={style.bnft_txt}>INCREASED
                                    <br /> PENIS
                                    <br /> SIZE
                                </p>
                                <p className={style.bnft_txt2}>Increase in penile chamber capacity and regular boost in blood flow may help add those inches to your penis size, both length &amp; girth wise.</p>
                                </div>
                            </div>
                            <div className={`${style.pro_pack_5_3} align-self-center`}>
                                <img src={primary} className={`${style.product_l} img-fluid`} alt="" />
                                <img src={primary} className={`${style.product_r} img-fluid`} alt="" />
                                <img src={primary} className={`${style.product_c} img-fuild`} alt="" />
                            </div>
                            <i className={`${style.sprite1} ${style.sprite_satisfaction_seal} ${style.s5img}`}></i> <i className={`${style.sprite1} ${style.sprite_s5img} ${style.s5img2}`}></i>
                            <p className={style.s5lft_txt}></p>
                            <p className={style.s5rgt_txt}><span>THE NUMBER ONE</span>
                                <br /> MALE ENHANCEMENT
                                <br /> <b> PILL IN United States</b> 
                            </p>
                            <div className={style.clearall}></div>
                            <div className={style.s5btn_strip}>
                                <p className={style.btn_txt}><span>ORDER YOUR Alpha Drive Rx TODAY!</span>
                                <br /> Experience Sexual Power, Pleasure &amp; Performance
                                </p>
                                <Link to="javascript:void(0)" onClick={handleClick}> <i className={`${style.sprite1} ${style.sprite_submit_btn} ${style.sec3btn} ${style.pulse}`}></i> </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Section 6  */}
                <div id="section6" className={style.section6}>
                    <div className={style.sec6inner}>
                        <div className={style.contentWrap}>
                            <p className={style.s6hding}><span>Powerful Ingredients</span>
                                <br /> For Bigger Results
                            </p>
                            <i className={`${style.sprite1} ${style.sprite_natural_seal} ${style.natural_seal}`}></i>
                            <div className={style.sec6lft}>
                                <p className={style.s6ingrnts1}>L-Arginine</p>
                                <p className={`${style.s6txt} ${style.s6txta}`}> Stimulates nitric oxide production to boost blood circulation to the penis helping achieve biggers and stronger erections. </p>
                                <p className={style.s6ingrnts2}>Ginko Biloba Extarct</p>
                                <p className={`${style.s6txt} ${style.s6txtb}`}>An aphrodisiac, it helps boost male sexual drive and libido. It also supports healthy testosterone levels.</p>
                            </div>
                            <div className={style.sec6rgt}>
                                <p className={style.s6ingrnts3}>MUIRA PUAMA EXTRACT</p>
                                <p className={style.s6txt2}>Called the "Viagra of Amazon", this herbal extract
                                <br /> replenishes sexual energy stores for improved
                                <br /> strength and stamina.
                                </p>
                                <div className={style.clearall}></div>
                                <div className={style.lftingrnts}>
                                <p className={style.s6ingrnts4}>ASIAN RED
                                    <br /> GINGER EXTRACTS
                                </p>
                                <p className={style.s6txt3}>Positively influences mood patterns to reduce stress and promote relaxation, enabling men to perform at their peak.</p>
                                </div>
                                <div className={style.rgtingrnts}>
                                <p className={style.s6ingrnts5}>SAW PALMETTO
                                    <br /> BERRY 
                                </p>
                                <p className={style.s6txt3}>Helps increase staying power ensuring you and your partner enjoy longer sessions with intense orgasms. </p>
                                </div>
                                <div className={style.clearall}></div>
                                <p className={style.s6ingrnts6}>HORNY GOAT WEED
                                <br />EXTRACT
                                </p>
                                <p className={style.s6txt2}>Works synergistically with the other pro-sexual nutrients to boost blood flow to the penile chambers for improved erections. It also helps expand the chambers to increase blood holding capacity and in-turn staying power.</p>
                            </div>
                            <div className={style.clearall}></div>
                            <p className={style.s6btm_txt}><span>BIOPERINE</span>
                                <br />Helps support the formula's quick absorption technology. This allows the key herbal ingredients that support male enhancement to be absorbed quickly into the blood stream, triggering an instant boost in sexual energy, stamina and erections. 
                            </p>
                            <div className={style.s6btn_strip}>
                                <p className={style.btn_txt}><span>ORDER YOUR Alpha Drive Rx TODAY!</span>
                                <br /> Experience Sexual Power, Pleasure &amp; Performance
                                </p>
                                <Link to="javascript:void(0)" onClick={handleClick}> <i className={`${style.sprite1} ${style.sprite_submit_btn} ${style.sec3btn} ${style.pulse} `}></i> </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Section 6 End  */}
                {/* Section 7  */}
                <div id="section7" className={style.section7}>
                    <div className={style.sec7inner}>
                        <div className={style.contentWrap}>
                            <p className={style.s7hding}><span>REAL MEN, REAL RESULTS</span>
                                <br /> Success Stories From Our Patrons
                            </p>
                            <p className={style.s7txt}><span>Alpha Drive Rx™ has helped hundreds of men across all ages</span> beat sexual dysfunction
                                <br /> and enjoy a fuller and satisfied sex life.
                            </p>
                            <div className={style.slider}>
                                <div className={style.slick_list}>
                                    <div className={`${style.slide2} ${style.slick_slide} ${style.slick_cloned}`}>
                                        <div className={style.lft_box}>
                                            <img src={sliderimg3} width="155" height="158" alt="" className={style.sliderimg} /> 
                                            <img src={star} width="101" height="18" alt="" className={style.star} />
                                            <p className={style.sldr_tstimnl}>"It's great to know that my favorite male enhancement supplement is now available in the market without a prescription! I have been using <b>Alpha Drive Rx</b> for a few months now and the results have been truly "huge"! I am able to enjoy harder erections, increased sexual drive and stamina, which lets me enjoy love making just like I used to when I was in my 30s!"</p>
                                            <p className={style.tstmnl_name}><span>- Vincent Harper, 49</span> </p>
                                        </div>
                                        <div className={style.rgt_box}>
                                            <p className={style.clearall}></p>
                                            <img src={sliderimg4} width="155" height="158" alt="" className={style.sliderimg} /> 
                                            <img src={star} width="101" height="18" alt="" className={style.star} />
                                            <p className={style.sldr_tstimnl}>"Age related decline in sexual health along with onset of mild ED had left me pretty shattered. I came across <b>Alpha Drive Rx</b> on a very reputed blog for male health and decided to give it a try. It has been the best decision I ever made. My sex drive has really taken off, my erections are back to their firm and the increase in sexual stamina is just amazing!"</p>
                                            <p className={style.tstmnl_name2}><span>- Sean Carter, 56</span> </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Section 7 End  */}
                {/* Section 8 Begin  */}
                <div id="section8" className={style.section8}>
                    <div className={style.contentWrap}>
                        <i className={`${style.sprite1} ${style.sprite_s1no_pres} ${style.s8no_pres}`}></i>
                        <p className={style.s8hding}>Medical Strength Male Enhancement</p>
                        <p className={style.s8hding2}>Get Maximum
                            <br /> <span>Sexual Benefits</span> 
                        </p>
                        <i className={`${style.sprite} ${style.sprite_us_seal} ${style.s8seal2}`}></i> 
                        <div className={`${style.pro_pack_5_3} align-self-center`}>
                            <img src={primary} className={`${style.product_l} img-fuild`} alt="" />
                            <img src={primary} className={`${style.product_r} img-fuild`} alt="" />
                            <img src={primary} className={`${style.product_c} img-fuild`} alt="" />
                        </div>
                        <ul className={style.s8list}>
                            <li><span>BIGGER &amp; Long-Lasting Erections </span>
                                <br /> Maximum pleasure &amp; intensified orgasms
                            </li>
                            <li><span>Surge In Sex Drive &amp; Energy </span>
                                <br /> Ramps up stamina &amp; staying power
                            </li>
                            <li><span>Increased Sexual Confidence </span>
                                <br /> Experience vitality &amp; peak performance
                            </li>
                        </ul>
                        <Link to="javascript:void(0)" onClick={handleClick}> <i className={`${style.sprite1} ${style.sprite_submit_btn} ${style.sec8btn} ${style.pulse}`}></i> </Link>
                    </div>
                </div>
                <div className={style.dPillHome_footer}>
                    <div className={style.wrap}>
                        <div className="text-center">
                            <div className={style.refer}>
                                <p>*Not endorsed by any international governing bodies. This product is not intended to diagnose, cure or prevent any disease. The information provided by this website, email, or this company is not a substitute for a face-to-face consultation with your health care professional and should not be construed as individual medical advice. If there is a change in your medical condition, please stop using our product immediately and consult your health care professional. Do not use if safety seal is broken or missing. For adult use only, keep out of reach of children under 18 years of age.</p>
                                {/* <!-- Testimonial Addition --> */}
                                <p>*Users names, images and locations have been changed to protect privacy. In some instances weight and measurements have been changed from imperial to metric and metric to imperial.</p>
                                <p>We are not responsible for any reviews, reports, emails, advertisements or blogs that may have led you to this page. If you feel any of these 3rd party reviews, advertisements, blogs or reports may be inaccurate, please contact us immediately. We do not condone or endorse any inaccurate information, statistics and/or claims made by 3rd parties in regards to our product.</p>
                            </div>
                            <p className={style.site_info}></p>
                        </div>
                    </div>
                </div>
                <Copyright/>
            </main>
        </>
    )
}

export default Get1hardppDesktop;