import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

function Faq() {
    const [id, setId] = useState('');
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [comment, setComment] = useState([]);
    const params2 = useParams();
    const [openAccordionId, setOpenAccordionId] = useState(null);

    const handleButtonClick = (id) => {
        setOpenAccordionId((prevId) => (prevId === id ? null : id));
    };

    /*const handleButtonClick = (id) => {
        setId(id);
    };*/

    useEffect(() => {
        axios.get(`api/getUserComments`).then(result => {
            console.log(result.data);
            setComment(result.data.data);
        });
    },[params2.slug]);

    return (
        <>
            <div className='sectionFaq'>
                <div className='container'>
                    <div className='faqFrame'></div>
                    <div className='faqWrapper'>
                        <h1>Frequently Asked Questions</h1>
                        <div className="accordion" id="accordionExample">
                            {comment.map((item) => (
                                <div className="accordion-item" key={item.id}>
                                    <h2 className="accordion-header" id={`heading${item.id}`}>
                                        <button 
                                            className={`accordion-button ${(item.id === openAccordionId) ? '' : 'collapsed'}`}
                                            type="button" 
                                            //data-bs-toggle="collapse" 
                                            data-bs-target={`#collapse${item.id}`}
                                            aria-expanded={(item.id === openAccordionId) ? true : false}
                                            aria-controls={`collapse${item.id}`} 
                                            onClick={() => handleButtonClick(item.id)}
                                        >
                                            {item.comments}
                                            <span className='plusIcon'></span>
                                        </button>
                                    </h2>
                                    <div 
                                        id={`collapse${item.id}`} 
                                        className={`accordion-collapse collapse ${(item.id === openAccordionId) ? 'show' : ''}`} 
                                        aria-labelledby={`heading${item.id}`} 
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <div dangerouslySetInnerHTML={{ __html: item.admin_reply ? item.admin_reply : '' }} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='page-content faq-button'>
                            <Link to="/api" variant="default" className='btn btn-default faq-btn'>Submit your question</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Faq